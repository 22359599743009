import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { InvoiceTemplateType } from 'tools/invoice-constants';
import { ICellRendererParams } from 'ag-grid-enterprise';

@Component({
	selector: 'dg-child-cell-invoice-renderer-component',
	template: `<a *ngIf="!params.data.typeId" (click)="invokeParentMethod(params.data.id)">{{transform(params.data.invoiceTemplateTypeId) | transloco}}</a>
			   <a *ngIf="params.data.typeId" (click)="invokeParentTwoMethod(params.data.id, params.data.typeId)">{{transform(params.data.invoiceTemplateTypeId) | transloco}}</a>`
})
export class ChildCellInvoiceRendererComponent implements ICellRendererAngularComp {
	public params: ICellRendererParams;

	agInit(params: ICellRendererParams): void {
		this.params = params;
	}

	public invokeParentMethod(value: number): void {
		this.params.context.componentParent.methodFromParent(value);
	}

	public invokeParentTwoMethod(id: number, value: number): void {
		this.params.context.componentParent.methodFromParent(id, value);
	}

	refresh(): boolean {
		return false;
	}

	transform(invoiceTemplateTypeId: InvoiceTemplateType): string {
		if (invoiceTemplateTypeId === InvoiceTemplateType.Invoice) {
			return 'child-cell-invoice-layout.invoiceTemplateType-option-Invoice_Layout';
		} else if (invoiceTemplateTypeId === InvoiceTemplateType.CreditNote) {
			return 'child-cell-invoice-layout.invoiceTemplateType-option-Credit_Note_Layout';
		}
	}
}
