import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Invoice } from 'domain/entities';
import { ICellRendererParams } from 'ag-grid-community';
import { Labels } from 'shared/modules/invoice-actions';

@Component({
	selector: 'dg-child-cell-status',
	template: `<span class="{{statusToLabelClass(params.data)}}">{{statusToLabel(params.data)}}</span>`
})
export class ChildLinkStatusRendererComponent implements ICellRendererAngularComp {
	public params: ICellRendererParams;

	agInit(params: ICellRendererParams): void {
		this.params = params;
	}

	public invokeParentMethod(id: number): void {
		this.params.context.componentParent.methodFromParentView(id);
	}

	refresh(): boolean {
		return false;
	}

	public statusToLabel(invoice: Invoice): string {
		return Labels.statusToLabel(invoice);
	}

	public statusToLabelClass(invoice: Invoice): string {
		return Labels.statusToLabelClass(invoice);
	}
}
