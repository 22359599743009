import { Component } from '@angular/core';
import { DgAlert, DgAlertService } from './alert.service';

@Component({
	selector: 'dg-alert',
	templateUrl: './alert.component.html',
	styleUrls: ['./alert.component.sass']
})
export class DgAlertComponent {
	type: string;
	message: string;

	show: boolean;
	timeoutId: NodeJS.Timeout;

	private hideAfter = 10000; //milliseconds

	constructor(private alertService: DgAlertService) {

		alertService.showAlert$.subscribe((alert:DgAlert) => {
			this.show = true;
			this.message = alert.message;
			this.type = alert.type;

			clearTimeout(this.timeoutId);

			this.timeoutId = setTimeout(() => {
				this.show = false;
			}, this.hideAfter);
		});

	}
}
