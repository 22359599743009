import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { InvoiceRepository } from 'repositories/invoice-repository';
import { ActivatedRoute } from '@angular/router';
import { saveAs } from 'file-saver-es';
import { Invoice } from 'domain/entities';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { Guid } from 'domain/types';
import { SettingsRepository } from 'repositories';
import { InvoiceFormatType } from 'domain/enums';

@Component({
	selector: 'dg-child-cell-invoice',
	template: `
		<a *ngIf="!params.data.isGenerating" class="invoice-link"
			[queryParams]="customerId ? {customerId: customerId} : {}"
			[routerLink]="params.data.invoiceDetailsNavLink">{{params.data.invoiceNumber}}
		</a>

		<a *ngIf="params.data.isGenerating" class="invoice-link">{{params.data.invoiceNumber}}</a>

		<div *ngIf="!params.data.canBeEmailed" class="invoice-email" aria-hidden="true" [title]="'child-cell-invoice.canBeEmailed-btn_hint' | transloco">
			<a routerLink="/customers/{{params.data.customerId}}/details">
				<svg class="email-warning-icon"
					 xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="25px"
					 viewBox="0 0 554.625 554.625">
					<g>
						<path
							d="M554.625,459l-76.5-130.05V133.875c0-21.038-17.213-38.25-38.25-38.25H38.25C17.212,95.625,0,112.837,0,133.875v229.5
							 c0,21.037,17.212,38.25,38.25,38.25h302.175L306,459H554.625z M453.263,120.487c3.825,3.825,5.737,7.65,5.737,13.388v162.562
							 l-28.688-47.812L388.237,321.3L306,248.625L453.263,120.487z M430.312,114.75l-191.25,166.388L47.812,114.75H430.312z
							 M24.862,376.763c-3.825-3.825-5.737-7.65-5.737-13.388v-229.5c0-5.737,1.913-9.562,5.737-13.388l147.263,128.138L24.862,376.763z
							 M47.812,382.5l139.612-120.487L239.062,306l51.638-43.987l87.975,76.5L351.9,382.5H47.812z M430.312,286.875l91.8,153H340.425
							 L430.312,286.875z"
							fill="#ec8582"></path>
						<rect x="420.75" y="325.125" width="19.125" height="57.375" fill="#ec8582"></rect>
						<rect x="420.75" y="401.625" width="19.125" height="19.125" fill="#ec8582"></rect>
					</g>
				</svg>
			</a>
		</div>

		<i *ngIf="params.data.isOnHold" class="fa fa-pause" aria-hidden="true" [title]="'child-cell-invoice.isOnHold-btn_hint' | transloco" ></i>

		<a *ngIf="invoiceDownloadAllowed(params)" aria-hidden="true" [title]="'child-cell-invoice.download-btn_hint' | transloco"
			(click)="onInvoiceDownload(params.data, false)"><i class="fa fa-download"></i></a>

		<a *ngIf="invoiceDownloadAllowed(params)" class="fa" aria-hidden="true" [title]="'child-cell-invoice.viewNewTab-btn_hint' | transloco"
		[ngClass]="{'fa-spinner fa-spin': params.data.isLoading, 'fa-eye': !params.data.isLoading && params.data.statusToField !== 'Uninvoiced'}"
			(click)="onInvoiceDownload(params.data, true)"></a>
        `
})
export class ChildLinkInvoiceRendererComponent implements ICellRendererAngularComp {
	public params: ICellRendererParams;
	public customerId: Guid;
	constructor(
		private readonly route: ActivatedRoute,
		private readonly invoiceRepository: InvoiceRepository,
		private readonly settingsRepo: SettingsRepository
	) {
		const id = new Guid(route.snapshot.params.customerId);
		this.customerId = id.isEmpty() ? undefined : id;
	}

	agInit(params: ICellRendererParams): void {
		this.params = params;
	}

	refresh(): boolean {
		return false;
	}

	invoiceDownloadAllowed(params: ICellRendererParams): boolean {
		return !params.data.isGenerating &&
			!params.data.isDraft &&
			params.data.status !== 3;
	}

	async onInvoiceDownload(invoice: Invoice, newTab: boolean): Promise<void> {
		invoice.isLoading = true;

		try {
			if (newTab) {
				const blob = await this.invoiceRepository.downloadInvoice(invoice.id, InvoiceFormatType.PDF);
				window.open(window.URL.createObjectURL(blob.body));
			} else {
				const settings = await this.settingsRepo.getInvoiceSettings();
				if (settings.useAdditionalInvoiceFormat) {
					try {
						const blobXML = await this.invoiceRepository.downloadInvoice(invoice.id, InvoiceFormatType.XML);
						if (blobXML.body) {
							saveAs(blobXML.body, `${invoice.invoiceNumber}.xml`);
						}
					} catch {}

					try {
						const blobPDF = await this.invoiceRepository.downloadInvoice(invoice.id, InvoiceFormatType.PDF);
						if (blobPDF.body) {
							saveAs(blobPDF.body, `${invoice.invoiceNumber}.pdf`);
						}
					} catch {}
				} else {
					const blob = await this.invoiceRepository.downloadInvoice(invoice.id, InvoiceFormatType.PDF);
					saveAs(blob.body, `${invoice.invoiceNumber}.pdf`);
				}
			}
		} finally {
			invoice.isLoading = false;
		}
	}
}
