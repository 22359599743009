<div class="modal" [ngClass]="className" *ngIf="visible">
	<div class="modal__content">
		<div class="modal__content-header">
			<span class="modal__content-header-title">{{title | transloco}}</span>
		</div>
		<div class="modal__content-body confirm">
			{{list.length ? '' : (message | transloco)}}

			<div *ngFor="let item of list">
				<div *ngIf="item.missingTemplates">
					<span>{{item.label}}</span>
					<ng-container *ngFor="let temp of item.missingTemplates" >
						<span style="display: flex; margin: 5px 0;">{{temp.code}}{{ temp.title ? ' - ' + temp.title : ''}}</span>
					</ng-container>
				</div>

				<ng-container *ngIf="item.priceInconsistencies" >
					<span>{{item.label}}</span>
					<ng-container *ngFor="let price of item.priceInconsistencies">
						<span style="display: flex; margin: 5px 0;">{{price.code}}{{ price.title ? ' - ' + price.title : ''}} {{price.correspondingPrice}} ({{price.importedPrice}})</span>
					</ng-container>
				</ng-container>
			</div>
		</div>
		<div class="modal__content-footer">
			<button class="btn btn--secondary modal__content-footer-button" type="button" (click)="onAnswer(true)">
				{{'global.modals_btn-confirm' | transloco}}
			</button>
			<button class="btn btn--default modal__content-footer-button" type="button" (click)="onAnswer(false)">
				{{'global.modals_btn-cancel' | transloco}}
			</button>
		</div>
	</div>
</div>
<div class="fade" *ngIf="visible"></div>
