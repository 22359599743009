<form novalidate autocomplete="do-not-show-ac" class="page-tile {{pageTileClass}}"
	[formGroup]="formGroup" (submit)="formGroup.valid && onSubmit()" (keydown)="handleKeyupEnter($event)">
	<div class="page-tile__panel {{pagePanelClass}}">

		<dg-button [name]="'backTo'" [type]="'button'" [title]="'dg-tile.back-btn_hint' | transloco: { backToTitle: backToTitle }" (click)="backTo()">
			<i class="fa fa-chevron-left" aria-hidden="true"></i>
		</dg-button>

		<div *ngxPermissionsOnly="permissions">
			<dg-button
				*ngIf="
					backToTitle !== 'Product Templates' &&
					backToTitle !== 'Kit Products' &&
					backToTitle !== 'Customer Users' &&
					!isPermissionsIgnore"
				[name]="'saveEdit'" [type]="'submit'" [title]="'dg-tile.save-btn_hint' | transloco"
				[disabled]="!formGroup.valid || formGroup.pristine || !isTaxDetails || busy">
				<i class="fa" aria-hidden="true" [ngClass]="{'fa-floppy-o': !busy, 'fa-spinner fa-spin btn-spinner': busy}"></i>
			</dg-button>
		</div>

		<div *ngxPermissionsOnly="permissions">
			<dg-button *ngIf="backToTitle === 'Product Templates'" [name]="'saveEdit'" [type]="'submit'"
				[title]="'dg-tile.save-btn_hint' | transloco"
				[disabled]="!formGroup.valid || formGroup.pristine || referenceCodeInvalid || !isCharge || busy">
				<i class="fa" aria-hidden="true"
					[ngClass]="{'fa-floppy-o': !busy, 'fa-spinner fa-spin btn-spinner': busy}"></i>
			</dg-button>
		</div>

		<div *ngxPermissionsOnly="permissions">
			<dg-button *ngIf="backToTitle === 'Kit Products'" [name]="'saveEdit'" [type]="'submit'" [title]="'dg-tile.save-btn_hint' | transloco"
				[disabled]="!formGroup.valid || formGroup.pristine || !isCharge || !isTaxDetails || busy">
				<i class="fa" aria-hidden="true"
					[ngClass]="{'fa-floppy-o': !busy, 'fa-spinner fa-spin btn-spinner': busy}"></i>
			</dg-button>
		</div>

		<dg-button *ngIf="isPermissionsIgnore" [name]="'saveEdit'" [type]="'submit'" [title]="'dg-tile.save-btn_hint' | transloco"
			[disabled]="!formGroup.valid || formGroup.pristine || busy">
			<i class="fa" aria-hidden="true"
				[ngClass]="{'fa-floppy-o': !busy, 'fa-spinner fa-spin btn-spinner': busy}"></i>
		</dg-button>

		<dg-button *ngIf="isDiscard" [name]="'discard'" [disabled]="formGroup.pristine" [type]="'button'"
			[title]="'dg-tile.discard-btn_hint' | transloco" (click)="discard()"><i class="fa fa-undo" aria-hidden="true"></i>
		</dg-button>

		<div *ngxPermissionsOnly="permissions">
			<dg-button *ngIf="isClone" [name]="'clone'" [disabled]="!formGroup.valid || isCloning" [type]="'button'"
				[title]="'dg-tile.clone-btn_hint' | transloco" (click)="onClone()">
				<i class="fa" aria-hidden="true"
					[ngClass]="{'fa-copy': !isCloning, 'fa-spinner fa-spin btn-spinner': isCloning}"></i>
			</dg-button>

		</div>
		<div *ngxPermissionsOnly="permissions">
			<dg-button *ngIf="isExport" [name]="'export'" [disabled]="!formGroup.valid || isExporting" [type]="'button'"
				[title]="'dg-tile.export-btn_hint' | transloco" (click)="onExport()">
				<i class="fa fa-share" aria-hidden="true"></i>
			</dg-button>
		</div>
		<div *ngxPermissionsOnly="permissions">
			<dg-button *ngIf="isDelete" [name]="'delete'" [type]="'button'" [title]="'dg-tile.delete-btn_hint' | transloco" (click)="onDelete()">
				<i class="fa fa-trash-o" aria-hidden="true"></i>
			</dg-button>
		</div>
	</div>
	<ng-content></ng-content>
</form>
