<div class="padding-b__70">
	<div *ngIf="total > 0" class="grid__info">
		<span>{{'grid-paging-status-info_msg' | transloco: { from: from, to: to, totalRecords: total } }}</span>
	</div>

	<div *ngIf="lastPage > 1" class="grid__paging">
		<a class="grid__paging-button grid__paging-button--first grid__paging-button--disabled"
			[class.grid__paging-button--disabled]="currentPage === 1" (click)="onBtFirst()">
			{{'grid-paging-first_label' | transloco}}
		</a>
		<a class="grid__paging-button grid__paging-button--previous grid__paging-button--disabled"
			[class.grid__paging-button--disabled]="currentPage === 1" (click)="onBtPrevious()"><i
				class="fa fa-long-arrow-left" aria-hidden="true"></i>
			&nbsp;{{'grid-paging-previous_label' | transloco}}
		</a>
		<span>{{'grid-paging-status_msg' | transloco: { currentPage: currentPage, lastPage: lastPage } }}</span>
		<a class="grid__paging-button grid__paging-button--next"
			[class.grid__paging-button--disabled]="currentPage === lastPage" (click)="onBtNext()">
			{{'grid-paging-next_label' | transloco}}&nbsp;<i class="fa fa-long-arrow-right" aria-hidden="true"></i>
		</a>
		<a class="grid__paging-button grid__paging-button--last"
			[class.grid__paging-button--disabled]="currentPage === lastPage" (click)="onBtLast()">
			{{'grid-paging-last_label' | transloco}}
		</a>
	</div>
</div>
