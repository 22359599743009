import jwt_decode from 'jwt-decode';
import { AuthDetails } from './auth-details';

const DG_AUTH = 'auth';

export interface JwtTokenData {
	BrandId: string;
	CatalogUid: string;
	CatalogUserId: string;
	ClientId: string;
	CustomerId: string;
	DisplayName: string;
	aud: string;
	exp: number;
	iss: string;
	nameid: string;
	permission: string[];
	resellerid: string;
	role: string[];
	shardid: string;
	unique_name: string;
}

export class AuthHelper {
	static getJwtTokenData(token?: string): JwtTokenData | undefined {
		token = token ?? AuthHelper.getAuthDetails()?.token;

		if (!token) return undefined;

		return jwt_decode<JwtTokenData>(token);
	}

	static getAuthDetails(): AuthDetails | undefined {
		const auth = localStorage.getItem(DG_AUTH);

		if (!auth) return undefined;

		const restoredAuth =  AuthDetails.restoreFromJson(auth);

		if (!restoredAuth.token || !restoredAuth.refreshToken) return undefined;

		return restoredAuth;
	}

	static saveAuthDetails(auth: AuthDetails): void {
		localStorage.setItem(DG_AUTH, JSON.stringify(auth));
	}

	static clearAuth(): void {
		localStorage.removeItem(DG_AUTH);
	}
}

