<ng-container [formGroup]="ipndForm">
    <dg-tile-select 
        formControlName="listTypeId" 
        [id]="'ddListTypeId'" 
        [items]="ipndListTypes" 
        [label]="'dg-ipnd-integration-settings.fields_listTypeId'"
        [bindLabel]="'name'" 
        [bindValue]="'id'"
        [isClearable]="false"
        [name]="'listTypeId'">
    </dg-tile-select>

    <dg-tile-select 
        formControlName="usageTypeId" 
        [id]="'ddUsageTypeId'" 
        [items]="ipndUsageTypes" 
        [label]="'dg-ipnd-integration-settings.fields_usageTypeId'"
        [bindLabel]="'name'" 
        [bindValue]="'id'"
        [isClearable]="false"
        [name]="'usageTypeId'">
    </dg-tile-select>

    <dg-tile-select 
        formControlName="serviceTypeId" 
        [id]="'ddServiceTypeId'" 
        [items]="ipndServiceTypes" 
        [label]="'dg-ipnd-integration-settings.fields_serviceTypeId'"
        [bindLabel]="'name'" 
        [bindValue]="'id'"
        [isClearable]="false"
        [name]="'serviceTypeId'">
    </dg-tile-select>

    <div class="page-tile__group">
        <label class="page-tile__group-label" for="cbxSendToIpnd">
            {{'dg-ipnd-integration-settings.fields_sendToIpnd' | transloco}}
        </label>
        <div class="page-tile__group-input page-tile__group-input--border-none">
            <dg-checkbox name="sendToIpnd" formControlName="sendToIpnd" ngDefaultControl [id]="'cbxSendToIpnd'">
            </dg-checkbox>
        </div>
    </div>
</ng-container>
