export interface DgPermissions {
	ViewCustomers: string;
	ManageCustomers: string;
	ViewServiceItems: string;
	ManageServiceItems: string;
	ViewProductTemplates: string;
	ManageProductTemplates: string;
	ViewProductKits: string;
	ManageProductKits: string;
	ViewCustomerProducts: string;
	ManageCustomerProducts: string;
	ViewCustomerSites: string;
	ManageCustomerSites: string;
	ViewCustomerInvoices: string;
	ManageCustomerInvoices: string;
	ViewCustomerTransactions: string;
	ManageCustomerTransactions: string;
	ViewCustomerRates: string;
	ManageCustomerRates: string;
	ViewCustomerUsers: string;
	ManageCustomerUsers: string;
	ViewOrganizationSettings: string;
	ManageOrganizationSettings: string;
	ViewCustomerUsage: string;
	ManageCustomerPortal: string;
	ImportData: string;
	ManageUsersAndGroups: string;
	ManageRates: string;
	CustomReports: string;
	CommissionReports: string;
	ManagePortalSettings: string;
	ViewInvoicingSettings: string;
	ManageInvoicingSettings: string;
	ViewIntegrationSettings: string;
	ManageIntegrationSettings: string;
	ViewEmailTemplatesSettings: string;
	ManageEmailTemplatesSettings: string;
	ViewGlCodesSettings: string;
	ManageGlCodesSettings: string;
	ViewProductCategoriesSettings: string;
	ManageProductCategoriesSettings: string;
	ViewSureTaxCodesSettings: string;
	ManageSureTaxCodesSettings: string;
	ManageFeeds: string;
	ViewExceptions: string;
	ViewFiles: string;
	ManageFiles: string;
	ViewSurcharges: string;
	ManageSurcharges: string;
	ViewAccountManagersSettings: string;
	ManageAccountManagersSettings: string;
	ManageUsageRecordTypesSettings: string;
	ViewUsageRecordTypesSettings: string;
	ViewCustomerSurcharges: string;
	ManageCustomerSurcharges: string;
	ManageBundles: string;
	ViewFeeds: string;
	ManageSsoSettings: string;
	ManageAutobillingJobTask: string;
	ViewCustomerAgreements: string;
	ManageCustomerAgreements: string;
	ViewExternalQuotes: string;
	ManageExternalQuotes: string;
	Invoicing: string;
	ManageIpnd: string;
}

export const permissions: DgPermissions = {
	ViewCustomers: '1',
	ManageCustomers: '2',
	ViewServiceItems: '3',
	ManageServiceItems: '4',
	ViewProductTemplates: '5',
	ManageProductTemplates: '6',
	ViewProductKits: '7',
	ManageProductKits: '8',
	ViewCustomerProducts: '9',
	ManageCustomerProducts: '10',
	ViewCustomerSites: '11',
	ManageCustomerSites: '12',
	ViewCustomerInvoices: '13',
	ManageCustomerInvoices: '14',
	ViewCustomerTransactions: '15',
	ManageCustomerTransactions: '16',
	ViewCustomerRates: '17',
	ManageCustomerRates: '18',
	ViewCustomerUsers: '19',
	ManageCustomerUsers: '20',
	ViewOrganizationSettings: '21',
	ManageOrganizationSettings: '22',
	ViewCustomerUsage: '23',
	ManageCustomerPortal: '24',
	ImportData: '25',
	ManageUsersAndGroups: '26',
	ManageRates: '27',
	CustomReports: '28',
	CommissionReports: '29',
	ManagePortalSettings: '31',
	ViewInvoicingSettings: '32',
	ManageInvoicingSettings: '33',
	ViewIntegrationSettings: '34',
	ManageIntegrationSettings: '35',
	ViewEmailTemplatesSettings: '36',
	ManageEmailTemplatesSettings: '37',
	ViewGlCodesSettings: '38',
	ManageGlCodesSettings: '39',
	ViewProductCategoriesSettings: '40',
	ManageProductCategoriesSettings: '41',
	ViewSureTaxCodesSettings: '42',
	ManageSureTaxCodesSettings: '43',
	ManageFeeds: '44',
	ViewExceptions: '45',
	ViewFiles: '46',
	ManageFiles: '47',
	ViewSurcharges: '48',
	ManageSurcharges: '49',
	ViewAccountManagersSettings: '50',
	ManageAccountManagersSettings: '51',
	ManageUsageRecordTypesSettings: '52',
	ViewUsageRecordTypesSettings: '53',
	ViewCustomerSurcharges: '54',
	ManageCustomerSurcharges: '55',
	ManageBundles: '56',
	ViewFeeds: '57',
	ManageSsoSettings: '58',
	ManageAutobillingJobTask: '59',
	ViewCustomerAgreements: '60',
	ManageCustomerAgreements: '61',
	Invoicing: '62',
	ManageIpnd: '63',
	ViewExternalQuotes: '64',
	ManageExternalQuotes: '65',
};
