<div class="multiple_emails-container">
	<ul class="multiple_emails-ul">
		<li *ngFor="let item of items; let i = index; last as isLast" class="multiple_emails-email">
			<span class="email_name-closebtn" (click)="remove(i)">×</span>
			<span class="email_name">{{item}}</span>
		</li>
	</ul>
	<ng-container [ngSwitch]="type">
		<div *ngSwitchCase="'email'" class="multi-input_block">
			<input name="email" class="multiple_emails-input" type="email" autocomplete="new-password"
				   [(ngModel)]="item"
				   (ngModelChange)="onInputValueChange()"
				   (keyup.enter)="addItem($event)">
			<span class="email_name-plusbtn" [class.dimmed]="!allowAdd" (click)="addItem($event)">+</span>
		</div>
		<div *ngSwitchCase="'text'" class="multi-input_block">
			<input *ngIf="items.length < 5" name="optionLine" class="multiple_emails-input" type="text" autocomplete="new-password"
				   [(ngModel)]="item"
				   (ngModelChange)="onInputValueChange()"
				   (keyup.enter)="addItem($event)">
			<span *ngIf="items.length < 5" class="email_name-plusbtn" [class.dimmed]="!allowAdd" (click)="addItem($event)">+</span>
		</div>
	</ng-container>
</div>
