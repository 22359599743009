<header>
	<!-- <div *ngIf="shouldStick" class="header__content-wrapper">
		<div *ngIf="versionIsOld" class="header__notification-panel">
			<span class="header__notification-panel-message">{{'layout-header.version-message' | transloco}}</span>
			<button type="button" class="btn btn--default header__notification-panel-button"
				[title]="'layout-header.title-refresh' | transloco">
				<i class="fa fa-refresh" aria-hidden="true"></i>
			</button>
		</div>
		<div class="header__content">
			<dg-crumbs #parent>
				<ol class="breadcrumbs">
					<ng-template ngFor let-route [ngForOf]="parent.breadcrumbs">
						<li *ngIf="!route.terminal" class="breadcrumbs__item"
							[ngClass]="{'': !isCrumbDisabled, 'breadcrumbs__item--disabled':isCrumbDisabled }">
							<a href="" class="breadcrumbs__element" [routerLink]="[route.url]">
								{{ route.displayName | transloco }}
							</a>
						</li>
						<li *ngIf="route.terminal" class="breadcrumbs__item breadcrumbs__item--active"
							aria-current="page">
							{{ route.displayName | transloco }}
						</li>
					</ng-template>
				</ol>
			</dg-crumbs>
			<div class="header__item-user flex">
				<div class="navbar">
					<div class="navbar__item">
						<a class="navbar__item-group-link">{{activeLang}}
							<i class="fa fa-caret-down" aria-hidden="true"></i></a>
						<ul class="navbar__item-group navbar__item-group--left">
							<li *ngFor="let lang of availableLangs" class="navbar__subitem">
								<a class="navbar__link navlink" (click)="onLangSelect(lang)">
									{{lang.label}}
								</a>
							</li>
						</ul>
					</div>
				</div>
				<div class="header__notifications-container"
					[title]="'layout-header.notifications_hint' | transloco">
					<dg-notifications></dg-notifications>
				</div>
				<div class="navbar">
					<div class="navbar__item">
						<a class="navbar__item-group-link">{{displayName}} <i class="fa fa-caret-down"
								aria-hidden="true"></i></a>
						<ul class="navbar__item-group navbar__item-group--left">
							<li class="navbar__subitem"><a class="navbar__link navlink"
									routerLink="/settings">{{'layout-header.profile-menu-labels_settings' |
									transloco}}</a></li>
							<li class="navbar__subitem"><a class="navbar__link navlink"
									routerLink="/settings/my-details">{{'layout-header.profile-menu-labels_details' |
									transloco}}</a>
							</li>
							<li class="navbar__subitem">
								<a class="navbar__link navlink" href (click)="signout($event); false;">
									{{'layout-header.profile-menu-labels_sign-out' | transloco}}
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div> -->

	<div #headerContent class="header__content-wrapper" [class.header__content--fixed]="shouldStick">
		<div *ngIf="versionIsOld" class="header__notification-panel" (click)="refreshVersion()">
			<span class="header__notification-panel-message">{{'layout-header.outdated-version-message_title' | transloco}}</span>
			<button type="button" class="btn btn--default header__notification-panel-button"
				[title]="'layout-header.notifications_hint' | transloco">
				<i class="fa fa-refresh" aria-hidden="true"></i>
			</button>
		</div>
		<div class="header__content">
			<dg-crumbs #parent>
				<ol class="breadcrumbs">
					<ng-template ngFor let-route [ngForOf]="parent.breadcrumbs">
						<li *ngIf="!route.terminal" class="breadcrumbs__item"
							[ngClass]="{'': !isCrumbDisabled, 'breadcrumbs__item--disabled': isCrumbDisabled}">
							<a href="" class="breadcrumbs__element" [routerLink]="[route.url]">
								{{ route.displayName | transloco }}
							</a>
						</li>
						<li *ngIf="route.terminal" class="breadcrumbs__item breadcrumbs__item--active"
							aria-current="page">
							{{ route.displayName | transloco }}
						</li>
					</ng-template>
				</ol>
			</dg-crumbs>

			<div class="header__item-user flex">
				<div class="navbar">
					<div class="navbar__item">
						<a class="navbar__item-group-link">{{activeLang}}
							<i class="fa fa-caret-down" aria-hidden="true"></i></a>
						<ul class="navbar__item-group navbar__item-group--left">
							<li *ngFor="let lang of availableLangs" class="navbar__subitem">
								<a class="navbar__link navlink" (click)="onLangSelect(lang)">
									{{lang.label}}
								</a>
							</li>
						</ul>
					</div>
				</div>
				<div class="header__notifications-container"
					[title]="'layout-header.notifications_hint' | transloco">
					<dg-notifications></dg-notifications>
				</div>
				<div class="navbar">
					<div class="navbar__item">
						<a class="navbar__item-group-link">{{displayName}} <i class="fa fa-caret-down"
								aria-hidden="true"></i></a>
						<ul class="navbar__item-group navbar__item-group--left">
							<li class="navbar__subitem"><a class="navbar__link navlink"
									routerLink="/settings">{{'layout-header.profile-menu-labels_settings' |
									transloco}}</a></li>
							<li class="navbar__subitem"><a class="navbar__link navlink"
									routerLink="/settings/my-details">{{'layout-header.profile-menu-labels_details' |
									transloco}}</a>
							</li>
							<li class="navbar__subitem">
								<a class="navbar__link navlink" href (click)="signout($event); false;">
									{{'layout-header.profile-menu-labels_sign-out' | transloco}}
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</header>
