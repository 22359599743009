import { HashMap, TranslateParams } from '@ngneat/transloco';
import { PortalIntegration, IntegrationCategory, IntegrationType } from 'domain/entities';
import { Guid } from 'domain/types';
import * as Moment from 'moment-timezone';
import { Constants } from './constants';
export class Utils {
	public static getAgreementBillingChargeHelpMessage(t: <T = string>(key: TranslateParams, params?: HashMap, lang?: string) => T, billingFrequencyTypeId: number, billingStartDate: string): string {
		if (!billingFrequencyTypeId || !billingStartDate) return '';

		const mDate = Moment.utc(billingStartDate);
		const mLastDayDate = Moment.utc(mDate).endOf('month');
		const daysToEnd = mLastDayDate.diff(mDate, 'days');
		const isShortMonth = mLastDayDate.daysInMonth() <= 30;

		let msg = '';

		switch (daysToEnd) {
			case 0:
				msg = t(`utils.getAgreementBillingChargeHelpMessage-last-freq-${billingFrequencyTypeId}_msg`);
				break;
			case 1:
				msg = t(`utils.getAgreementBillingChargeHelpMessage-relative-freq-${billingFrequencyTypeId}_msg`, { days: daysToEnd });
				break;
			case 2:
				msg = isShortMonth
					? t(`utils.getAgreementBillingChargeHelpMessage-direct-freq-${billingFrequencyTypeId}_msg`, { dateFormat: mDate.format('Do') })
					: t(`utils.getAgreementBillingChargeHelpMessage-relative-freq-${billingFrequencyTypeId}_msg`, { days: daysToEnd });
				break;
			default:
				msg = t(`utils.getAgreementBillingChargeHelpMessage-direct-freq-${billingFrequencyTypeId}_msg`, { dateFormat: mDate.format('Do') });
				break;
		}

		return msg;
	}

	public static toMomentUtcFormat(date: Date) : string {
		return date ? Moment.utc(date).format('L') : '';
	}

	public static toMomentTzFormat(date: Date) : string {
		return date ? Moment.tz(date, Constants.DefaultTimzone).format('L') : '';
	}

	public static toMomentTzFormatTime(date: Date) : string {
		return date ? Moment.tz(date, Constants.DefaultTimzone).format('L HH:mm:ss') : '';
	}

	public static isNumberArray = <T>(arr: T[]): boolean => {
		return Array.isArray(arr) && arr.every((value) => typeof value === 'number');
	};

	public static isStringArray = <T>(arr: T[]): boolean => {
		return Array.isArray(arr) && arr.every((value) => typeof value === 'string');
	};

	public static isGuidArray = <T>(arr: T[]): boolean => {
		return Array.isArray(arr) && arr.every((value) => value instanceof Guid);
	};

	public static roundTwoDecimals(val: number | string, roundTo: number = 2): string {
		if (typeof val === 'number') {
			if (roundTo === 2) {

				if (val === 0 || val % 1 === 0) {
					return val.toFixed(2);
				}
				return (Math.round((val + Number.EPSILON) * 100) / 100).toFixed(2);
			}
			if (roundTo === 4) {
				if (val === 0 || val % 1 === 0) {
					return val.toFixed(4);
				}
				return (Math.round((val + Number.EPSILON) * 10000) / 10000).toFixed(4);
			}
		}

		if (typeof val === 'string') {
			// remove % and $ symbols from pinned row
			return val.replace(/[|&;$%@"<>()+,]/g, '');
		}
	}

	public static convertToRecord(...args: any[]): Record<string, string> {
		const result: Record<string, string> = {};

		args.forEach(obj => {
			for (const key in obj) {
				if (typeof obj[key] === 'object' && obj[key] !== null) {
					// if the value is an object or an array, stringify it
					result[key] = JSON.stringify(obj[key]);
				} else {
					// if the value is a number, boolean, or null, convert it to string
					// if the value is undefined, it becomes 'undefined'
					result[key] = String(obj[key]);
				}
			}
		});

		return result;
	}
}
