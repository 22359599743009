import { Component, EventEmitter, Output, Input, OnDestroy, SimpleChanges, OnChanges, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { debounceTime, Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'dg-filter-box',
	templateUrl: './dg-filter-box.component.html',
	styleUrls: ['./dg-filter-box.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DgFilterBoxComponent implements OnInit, OnChanges, OnDestroy {
	@Input() storageValue = '';
	@Input() disabled = false;
	@Input() delay = 0;
	@Output() filterChanged: EventEmitter<string> = new EventEmitter<string>();

	private destroy$: Subject<void> = new Subject<void>();
	private filterInput$: Subject<string> = new Subject<string>();

	constructor(
		private _cdr: ChangeDetectorRef
	) {

	}

	ngOnInit(): void {
		this.setupFilterInput();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['delay'] && !changes['delay'].firstChange) {
			// Ensure any existing subscription is cleaned up before creating a new one
			this.destroy$.next();
			this.destroy$.complete();
			this.destroy$ = new Subject<void>();

			// Re-setup filter input if delay input changes
			this.setupFilterInput();
		}
	}

	private setupFilterInput(): void {
		this.filterInput$.pipe(
			debounceTime(this.delay),
			takeUntil(this.destroy$)
		).subscribe(value => {
			this.filterChanged.emit(value);
			this._cdr.markForCheck();
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	public onFilterTextBoxChanged(value: string): void {
		this.filterInput$.next(value);
	}
}
