import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

const noop = () => {
};

const DD_INADVANCE_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => DgDdInadvanceComponent),
	multi: true
};

@Component({
	selector: 'dg-dd-inadvance',
	templateUrl: './dg-dd-inadvance.component.html',
	styleUrls: ['./dg-dd-inadvance.component.sass'],
	providers: [DD_INADVANCE_CONTROL_VALUE_ACCESSOR]
})
export class DgDdInadvanceComponent {
	@Input() id: string;
	@Input() name: string;
	@Input() label: string;
	@Input() bindLabel: string;
	@Input() bindValue: string;
	@Input() isRequired = false;
	@Input() clearable = true;

	value: string;
	constructor() {
	}

	private _onTouchedCallback: () => void = noop;
	private _onChangeCallback: (_: any) => void = noop;

	writeValue(value: any): void {
		if (value !== undefined) {
			this.value = value;
		}
	}

	registerOnChange(fn: any): void {
		this._onChangeCallback = fn;
	}

	registerOnTouched(fn: any): void {
		this._onTouchedCallback = fn;
	}

	public onChanged(): void {
		this._onChangeCallback(this.value);
		this._onTouchedCallback();
	}

	public onTouched() {
		this._onTouchedCallback();
	}
}
