import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { NotificationRepository } from 'repositories/notification-repository';
import { Notification } from 'domain/entities';

@Injectable({
	providedIn: 'root'
})

export class NotificationService {
	private notifications: Notification[] = [];
	private notificationsSource = new BehaviorSubject<Notification[]>(null);
	public notifications$ = this.notificationsSource.asObservable();

	constructor(private readonly repository: NotificationRepository) {
		this.checkForNew();
	}

	public checkForNew(): void {
		this.repository.getAll()
			.subscribe(items => {
				this.notifications = items;
				this.notificationsSource.next(items);
			});
	}

	public markAllAsSeen(): void {

		const notificationsToUpdate = this.notifications.filter(x => !x.seen);

		if (notificationsToUpdate.length === 0)
			return;

		this.repository.markAsSeen(notificationsToUpdate)
			.subscribe(() => {

				for (const n of notificationsToUpdate) {
					n.seen = true;
				}

				this.notificationsSource.next(this.notifications);

			});
	}

	public suppress(): void {
		this.repository.suppressNotification().subscribe();
	}
}
