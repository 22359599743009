import { Serializable } from 'domain/models';

export class SsoSettings implements Serializable<SsoSettings> {
	propagateSettingToResellers: boolean;
	allowSsoAuth: boolean;
	authProtocol: string;
	useOnlySsoAuthForUsers: boolean;
	ssoDirectLoginUrl: string;
	clientType: number;
	samlSettings: SamlSettings;

	deserialize(json: any): SsoSettings {
		this.propagateSettingToResellers = json.propagateSettingToResellers;
		this.allowSsoAuth = json.allowSsoAuth;
		this.authProtocol = json.authProtocol;
		this.useOnlySsoAuthForUsers = json.useOnlySsoAuthForUsers;
		this.ssoDirectLoginUrl = json.ssoDirectLoginUrl;
		this.clientType = json.clientType;
		this.samlSettings = json.samlSettings;
		return this;
	}

	clone(): SsoSettings {
		return Object.assign(new SsoSettings(), this);
	}

	restore(src: SsoSettings): void {
		Object.assign(this, src);
	}
}

export class SamlSettings implements Serializable<SamlSettings> {

	ssoServiceName: string;
	samlIssuer: string;
	samlUrl: string;
	samlCertificate: string;
	samlAudience: string;
	allowedClockDrift: string;
	nameIdFormat: string;

	attributesAndRolesMapping: AttributesAndRolesMapping;
	endCustomerAttributesMapping: EndCustomerAttributesMapping;

	deserialize(json: any): SamlSettings {

		this.ssoServiceName = json.ssoServiceName;
		this.samlIssuer = json.samlIssuer;
		this.samlUrl = json.samlUrl;
		this.samlCertificate = json.samlCertificate;
		this.samlAudience = json.samlAudience;
		this.allowedClockDrift = json.allowedClockDrift;
		this.attributesAndRolesMapping = json.attributesAndRolesMapping;
		this.nameIdFormat = json.nameIdFormat;
		return this;
	}

	clone(): SsoSettings {
		return Object.assign(new SsoSettings(), this);
	}

	restore(src: SsoSettings): void {
		Object.assign(this, src);
	}
}

export class EndCustomerAttributesMapping implements Serializable<EndCustomerAttributesMapping> {

	emailMapping: string;
	customerMapping: string;

	deserialize(json: any): EndCustomerAttributesMapping {

		this.emailMapping = json.emailMapping;
		this.customerMapping = json.customerMapping;
		return this;
	}

	clone(): SsoSettings {
		return Object.assign(new SsoSettings(), this);
	}

	restore(src: SsoSettings): void {
		Object.assign(this, src);
	}
}

export class AttributesAndRolesMapping implements Serializable<AttributesAndRolesMapping> {

	emailMapping: string;
	rolesKey: string;
	allowRolesSync: boolean;
	rolesMapping: RolesMapping[];
	nameKey: string;
	lastNameKey: string;
	generateRandomUsername: boolean;

	deserialize(json: any): AttributesAndRolesMapping {

		this.emailMapping = json.emailMapping;
		this.rolesKey = json.rolesKey;
		this.allowRolesSync = json.allowRolesSync;
		this.rolesMapping = json.rolesMapping;
		return this;
	}

	clone(): SsoSettings {
		return Object.assign(new SsoSettings(), this);
	}

	restore(src: SsoSettings): void {
		Object.assign(this, src);
	}
}

export class RolesMapping implements Serializable<RolesMapping> {

	ipRole: string;
	spRole: string;

	deserialize(json: any): RolesMapping {

		this.ipRole = json.ipRole;
		this.spRole = json.spRole;
		return this;
	}

	clone(): SsoSettings {
		return Object.assign(new SsoSettings(), this);
	}

	restore(src: SsoSettings): void {
		Object.assign(this, src);
	}
}
