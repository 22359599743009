import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
	selector: 'child-cell-icon-label',
	template: `
	<a (click)="invokeParentMethod(params.data.id)">
		<div *ngIf="params.data.productKitId && !params.data.productLabel"
			   class="tooltip tooltip--transaction-type grid__type-icon grid__type-icon--kit">
            <span>Product Kit</span>
		</div>
		<div *ngIf="params.data.isOneOff"
          class="tooltip tooltip--transaction-type grid__type-icon grid__type-icon--one-off " title="">
                <span>One off</span>
		</div>
		<div *ngIf="!params.data.isOneOff && !params.data.isFromCdr && !params.data.label"
          class="tooltip--transaction-type grid__type-icon grid__type-icon--recurring tooltip" title="">
              <span>Recurring</span>
		</div>
		<div *ngIf="!params.data.isOneOff && params.data.isFromCdr"
          class="tooltip tooltip--transaction-type grid__type-icon grid__type-icon--ebill " title="">
              <span>E-Bill</span>
		</div>
		{{params.data.productLabel}}
	</a>`
})
export class ChildCellIconLabel implements ICellRendererAngularComp {
	public params: any;
	
	agInit(params: any): void {
		this.params = params;
	}
	
	public invokeParentMethod(value: any) {
		this.params.context.componentParent.methodFromParent(value);
	}
	
	refresh(): boolean {
		return false;
	}
}
