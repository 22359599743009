import { Injectable } from '@angular/core';
import { Observable, throwError, BehaviorSubject, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { HttpAuth } from 'core/auth';

import { CacheService } from 'services/cache.service';
import {
	BrandProperty,
	Feed,
	FeedFeedHandler,
	FeedHandler,
	FeedMappingConfiguration,
	FeedPropertyValue
} from 'domain/entities';

import { BaseRepository } from './base-repository';
import { ToasterService } from 'core/toaster-service';
import { AppConfig } from 'core/app-config';
import { Api, ApiData } from 'domain/types';

@Injectable({
	providedIn: 'root'
})
export class FeedsRepository extends BaseRepository {

	constructor(
		http: HttpAuth,
		private readonly toasterService: ToasterService,
		config: AppConfig,
		cache: CacheService
	) {
		super(http, config, cache);
	}

	private _feedDetailsSource = new BehaviorSubject<Feed>(null);

	async getFeeds(destroy?: Subject<void>): Promise<Feed[]> {
		const res = await this.http.promise(destroy).get<Api<Feed[]>>(`${this.config.apiUrl}${this.config.apiVersion}/feeds`);
		return res.body.map(x => new Feed().deserialize(x));
	}

	getFeedById(id: number): Observable<Feed> {
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/feeds/${id}`).pipe(
			map(res => new Feed().deserialize(res.body)));
	}

	getFeedHandler(): Observable<FeedHandler[]> {
		const key = 'feed-handler';

		const observable =
			this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/feed-handlers`)
				.pipe(
					map(res => {
						const feedHandler = res.body.map(x => new FeedHandler().deserialize(x));
						this.setCacheValue(key, feedHandler, null);
						return feedHandler;
					}));

		this.cache.set$(key, observable);

		return observable;
	}

	updateFeed(feed: Feed): Observable<void> {
		const key = 'feeds';

		return this.http.put<void>(`${this.config.apiUrl}${this.config.apiVersion}/feeds/${feed.id}`, null, feed)
			.pipe(
				map(() => {
					this.updateCacheCollectionItem(key, feed.id, feed);
					this._feedDetailsSource.next(feed);
				}));
	}

	getFeedFilesByPage(p: number, id: number): Observable<any> {
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/feeds-files?feedId=${id}&page=${p}&pageSize=100`);
	}

	getFeedsFilesByPage(p: number): Observable<any> {
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/feeds-files?page=${p}&pageSize=100`);
	}

	getFeedFileBlob(path: string): Observable<HttpResponse<Blob>> {

		const options = {
			responseType: 'blob'
		};

		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/feeds-files/download`, { path: path }, options);

	}

	addFeedHandler(feedId: number, handler: FeedFeedHandler): Observable<number> {
		return this.http.post<ApiData<number>>(`${this.config.apiUrl}${this.config.apiVersion}/feeds/${feedId}/handlers`, handler)
			.pipe(map(res => res.body.data));
	}

	updateFeedHandler(feedId: number, handler: FeedFeedHandler): Observable<void> {

		const cacheKey = 'handler-' + handler.handlerId;

		return this.http.put<void>(`${this.config.apiUrl}${this.config.apiVersion}/feeds/${feedId}/handlers/${handler.feedFeedHandlerId}`, null, handler)
			.pipe(
				map(res => this.cache.set(cacheKey, handler))
			);
	}

	updateFeedProperty(feedId: number, handlerId: number, property: FeedPropertyValue): Observable<any> {
		return this.http.put(`${this.config.apiUrl}${this.config.apiVersion}/feeds/${feedId}/handlers/${handlerId}/properties/${property.valueId}`, null, property)
			.pipe(map(res => res));
	}

	addFeedProperty(feedId: number, handlerId: number, property: FeedPropertyValue): Observable<number> {
		return this.http.post<ApiData<number>>(`${this.config.apiUrl}${this.config.apiVersion}/feeds/${feedId}/handlers/${handlerId}/properties`, property)
			.pipe(map(res => res.body.data));
	}

	deleteFeedHandlerAndProperties(feedId: number, handlerId: number): Observable<HttpResponse<void>> {
		return this.http.delete<void>(`${this.config.apiUrl}${this.config.apiVersion}/feeds/${feedId}/handlers/${handlerId}`);
	}

	feedMappingConfigurations(): Observable<FeedMappingConfiguration[]> {

		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/feed-configurations`)
			.pipe(
				map(res => res.body.data.map(x => new FeedMappingConfiguration().deserialize(x)))
			);
	}

	addFeed(feed: Feed): Observable<number> {
		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/feeds`, feed, null)
			.pipe(
				map(res => res.body)
			);
	}

	feedClone(feedId: number, newName: string, brandId: number): Observable<number> {
		const feed = {
			name: newName,
			brandId: brandId
		};

		return this.http.post<ApiData<number>>(`${this.config.apiUrl}${this.config.apiVersion}/feeds/${feedId}/clone`, feed)
			.pipe(
				map(res => res.body.data)
			);
	}

	getAllBrands(): Observable<BrandProperty[]> {
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/brands`)
			.pipe(
				map(res => res.body.data.map(x => new BrandProperty().deserialize(x)))
			);
	}

	async deleteFeeds(ids: number[], destroy$?: Subject<void>): Promise<void>  {
		const res = await this.http.promise(destroy$).post(`${this.config.apiUrl}${this.config.apiVersion}/feeds/delete`, { ids: ids });
		return res.body;
	}
}
