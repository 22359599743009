import { Serializable } from 'domain/models';
import { EntityName } from 'tools/decorators';
import { Guid } from 'domain/types';

@EntityName('Gl Code', 'Gl Codes')
export class GlCode implements Serializable<GlCode> {
	id: Guid;
	resellerId: number;
	code: string;
	description: string;

	deserialize(json: any): GlCode {

		this.id = json.id;
		this.resellerId = json.resellerId;
		this.code = json.code;
		this.description = json.description;

		return this;
	}

	clone(): GlCode {
		return Object.assign(new GlCode(), this);
	}
}
