import { Input, Output, Component, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

@Component({
	selector: 'dg-grid-paginator[total]',
	templateUrl: './dg-grid-paginator.component.html',
	styleUrls: ['./dg-grid-paginator.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DgGridPaginatorComponent<T> {
	@Input() pageSize = 50;
	@Input() currentPage = 1;
	@Input() total = 1;
	@Input() lastPage = 1;
	@Input() from = 1;
	@Input() to = 1;
	@Input()
	set countingRangeRecords(data: T) {
		if (!data) {
			return;
		} else {
			this.from = (this.currentPage - 1) * this.pageSize + 1;
			this.to = this.total;

			if (this.pageSize < this.total) {
				this.to = this.pageSize * this.currentPage;
				if (this.to > this.total) {
					this.to = this.total;
				}
			}

			this.lastPage = Math.ceil(this.total / this.pageSize);
		}
	}

	@Output() pageChanged = new EventEmitter<number>();

	constructor() { }

	onBtFirst(): void {
		this.currentPage = 1;
		this.pageChanged.emit(this.currentPage);
	}

	onBtLast(): void {
		this.currentPage = this.lastPage;
		this.pageChanged.emit(this.currentPage);
	}

	onBtPrevious(): void {
		if (this.currentPage > 1) {
			this.currentPage = this.currentPage - 1;
			this.pageChanged.emit(this.currentPage);
		}
	}

	onBtNext(): void {
		if (this.currentPage < this.lastPage) {
			this.currentPage = this.currentPage + 1;
			this.pageChanged.emit(this.currentPage);
		}
	}
}
