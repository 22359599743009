import { Injectable } from '@angular/core';
import { Guid } from 'domain/types';

@Injectable()
export class Globals {
//This variables are provide naming for Breadcrumbs, by entities Id
	//Customer Details
	customerName: string;
	customerId: Guid;
	//Customer Users
	customerUserName: string;
	customerUserId: Guid;
	//Customer products
	customerProductName: string;
	customerProductId: Guid;
	//Customer serviceItem
	customerServiceItemName: string;
	customerServiceItemId: Guid;
	//Customer transactions
	customerTransactionId: Guid;
	customerTransactionName: string;
	//Customer site
	customerSiteName: string;
	customerSiteId: Guid;
	//Customer site
	customerAssignmentName: string;
	customerAssignmentId: Guid;
	//Rating
	rateCardName: string;
	priceBookName: string;
	//Kits
	productKitId: Guid;
	productKitName: string;
	kitProductCode: string;
	kitProductCodeId: Guid;
	//Bundles
	bundleName: string;
	bundleId: Guid;
	bundleQuantityName: string;
	bundleQuantityId: Guid;

	//surcharges
	surchargeName: string;
	surchargeId: Guid;
	//Feeds
	feedName: string;
	feedId: number;
	//ServiceItem
	serviceItemId: Guid;
	serviceItemName: string;
	//Product Template
	productTemplateName: string;
	productTemplateId: Guid;
	//Reseller Users
	resellerUserId: Guid;
	resellerUserName: string;
	resellerRoleName: string;
	resellerRoleId: Guid;
	//Tax Rates
	taxRateId: Guid;
	taxRateName: number;
	// Rating - Ratecard
	rateCardRateId: Guid;
	priceBookProductId: Guid;
	invoiceName: string;
	agreementName: string;
	agreementId: Guid;

	paymentTerm: string;
	paymentTermId: Guid;

	serviceItemType: string;
	serviceItemTypeId: Guid;
	// avalara tax exemptions
	taxExemptionName: string;
	taxExemptionId: Guid;
}
