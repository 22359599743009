<form class="form form--export">
	<ul class="steps__wrapper">
		<li class="steps {{CheckStep(1)}}">
			<span class="steps__label">{{'global-export.step1_label' | transloco}}</span>
			<span class="steps__label">{{'cw-export.step1_title' | transloco}}</span>
		</li>
		<li class="steps {{CheckStep(2)}}">
			<span class="steps__label">{{'global-export.step2_label' | transloco}}</span>
			<span class="steps__label">{{'cw-export.step2_title' | transloco}}</span>
		</li>
		<li class="steps {{CheckStep(3)}}">
			<span class="steps__label">{{'global-export.step3_label' | transloco}}</span>
			<span class="steps__label">{{'cw-export.step3_title' | transloco}}</span>
		</li>
		<li class="steps {{CheckStep(4)}}">
			<span class="steps__label">{{'global-export.step4_label' | transloco}}</span>
			<span class="steps__label">{{'cw-export.step4_title' | transloco}}</span>
		</li>
		<li class="steps {{CheckStep(5)}}">
			<span class="steps__label">{{'global-export.step5_label' | transloco}}</span>
			<span class="steps__label">{{'cw-export.step5_title' | transloco}}</span>
		</li>
		<li class="steps {{CheckStep(6)}}">
			<span class="steps__label">{{'global-export.step6_label' | transloco}}</span>
			<span class="steps__label">{{'cw-export.step6_title' | transloco}}</span>
		</li>
		<li class="steps {{CheckStep(7)}}">
			<span class="steps__label">{{'global-export.step7_label' | transloco}}</span>
			<span class="steps__label">{{'cw-export.step7_title' | transloco}}</span>
		</li>
		<li class="steps {{CheckStep(8)}}">
			<span class="steps__label">{{'global-export.step8_label' | transloco}}</span>
			<span class="steps__label">{{'cw-export.step8_title' | transloco}}</span>
		</li>
	</ul>
	<div class="form__content">

		<fieldset class="form__section {{CheckStep(1)}}">
			<div *ngIf="loading" class="app-spinner-cw"></div>
			<h3 class="form__section-title">{{'cw-export.step1_title' | transloco}}</h3>
			<form #InvoicesForm="ngForm">
				<div class="form__group block">
					<div class="form__group-item form__group-item--500">
						<p>{{'cw-export.step1-field_selectedBillingStatusId' | transloco}}
							<i class="fa fa-question-circle tooltip tooltip--tile tooltip--export_1" aria-hidden="true">
								<span>{{'cw-export.step1-field_selectedBillingStatusId-tooltip' | transloco}}</span>
							</i>
						</p>
						<ng-select #inputBillingStatuses name="selectedBillingStatusId" bindLabel="name"
							required bindValue="id" [items]="billingStatuses" [searchable]="true"
							[clearable]="false" [(ngModel)]="selectedBillingStatusId">
							<ng-template>
								<input style="width: 100%; line-height: 24px" type="text"
									(input)="inputBillingStatuses.filter($event.target.value)" />
							</ng-template>
						</ng-select>
					</div>
				</div>
				<div *ngIf="!isUseInvoiceDueDate" class="form__group block">
					<div class="form__group-item form__group-item--500">
						<p>
							{{'cw-export.step1-field_selectedBillingTermsId' | transloco}}
						</p>
						<ng-select #inputProduct name="selectedBillingTermsId" bindLabel="name"
							required bindValue="id" [items]="billingTerms" [searchable]="true"
							[clearable]="false" [(ngModel)]="selectedBillingTermsId">
							<ng-template>
								<input style="width: 100%; line-height: 24px" type="text"
									(input)="inputProduct.filter($event.target.value)" />
							</ng-template>
						</ng-select>
					</div>
				</div>
				<div class="form__group block">
					<div class="form__group-item form__group-item--500">
						<p>{{'cw-export.step1-field_selectedInvoiceTemplateId' | transloco}}</p>
						<ng-select #inputInvoiceTemplates required name="selectedInvoiceTemplateId"
							[clearable]="false" [(ngModel)]="selectedInvoiceTemplateId">
							<ng-option *ngFor="let template of invoiceTemplates" [value]="template.id">
								{{template.name}}
							</ng-option>
						</ng-select>
					</div>
				</div>
				<div class="form__group block">
					<div class="form__group-item form__group-item--500">
						<p>{{'cw-export.step1-field_selectedVendorId' | transloco}}
							<i class="fa fa-question-circle tooltip tooltip--tile tooltip--export_1" aria-hidden="true">
								<span>{{'cw-export.step1-field_selectedVendorId-tooltip' | transloco}}</span>
							</i>
						</p>
						<ng-select #inputFocus required name="selectedVendorId" [clearable]="false"
							[(ngModel)]="selectedVendorId">
							<ng-option *ngFor="let vendor of vendors" [value]="vendor.id">{{vendor.name}}</ng-option>
						</ng-select>
					</div>
				</div>
				<div class="form__bottom form__bottom--border-none">
					<button type="button" class="btn btn--primary btn--import" (click)="backTo()">
						{{'global.export_btn-back' | transloco}}
					</button>
					<button type="button" class="btn btn--primary btn--import" [disabled]="!InvoicesForm.valid"
						(click)="GroupingTypeSelected()">
						{{'global.export_btn-next' | transloco}}
						<i *ngIf="isGrouping" class="fa fa-spinner fa-spin"></i>
					</button>
				</div>
			</form>
		</fieldset>

		<fieldset class="form__section {{CheckStep(2)}}">
			<h3 class="form__section-title">{{'cw-export.step2_title' | transloco}}</h3>
			<form #InvoicesForm="ngForm">
				<div class="form__group block">
					<div *ngFor="let item of invoicesMapping; let i = index"
						class="form__group-item form__group-item--500">
						<p>{{item.invoiceNumber}}</p>
						<ng-select #inputProduct bindLabel="name" required bindValue="id"
							name="selectedInvoiceId{{i}}" [items]="departments" [searchable]="true" [clearable]="false"
							[(ngModel)]="item.selectDepartmentId">
							<ng-template>
								<input style="width: 100%; line-height: 24px" type="text"
									(input)="inputProduct.filter($event.target.value)" />
							</ng-template>
						</ng-select>
					</div>
				</div>
				<div class="form__bottom form__bottom--border-none">
					<button type="button" class="btn btn--primary btn--import" (click)="BackStep()">
						{{'global.export_btn-back' | transloco}}
					</button>
					<button type="button" class="btn btn--primary btn--import" [disabled]="!InvoicesForm.valid"
						(click)="NextStepSelected()">
						{{'global.export_btn-next' | transloco}}
						<i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
					</button>
				</div>
			</form>
		</fieldset>

		<fieldset class="form__section {{CheckStep(3)}}">
			<h3 class="form__section-title">{{'cw-export.step3_title' | transloco}}</h3>
			<form #ProductsForm="ngForm">
				<div class="form__group block">
					<div class="form__group-item form__group-item--500-fs">
						<p>{{'cw-export.step3_header-service' | transloco}}</p>
						<p>{{'cw-export.step3_header-record' | transloco}}</p>
						<p>{{'cw-export.step3_header-product' | transloco}}</p>
					</div>
					<div *ngFor="let items of productsMapping | groupBy:'groupName'; let i = index"
						class="form__group-item form__group-item--500">
						<p>{{items.key}}</p>
						<div>
							<div *ngFor="let item of items.value | sort:['description', 'selectedProductId']; let i1 = index" class="form__group-item-custom1">
								<p>{{item.description}}</p>
								<ng-select #inputProduct bindLabel="identifier" required
									bindValue="id" name="selectedProductId{{i}}_{{i1}}" [items]="products"
									[searchable]="true" [clearable]="false" [(ngModel)]="item.selectedProductId">
									<ng-template>
										<input style="width: 100%; line-height: 24px" type="text"
											(input)="inputProduct.filter($event.target.value)" />
									</ng-template>
								</ng-select>
							</div>
						</div>
					</div>
				</div>

				<div class="form__bottom form__bottom--border-none">
					<button type="button" class="btn btn--primary btn--import" (click)="BackStep()">
						{{'global.export_btn-back' | transloco}}
					</button>
					<button type="button" class="btn btn--primary btn--import" [disabled]="!ProductsForm.valid"
						(click)="NextStepSelected()">
						{{'global.export_btn-next' | transloco}}
						<i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
					</button>
				</div>
			</form>
		</fieldset>

		<fieldset class="form__section {{CheckStep(4)}}">
			<h3 class="form__section-title">{{'cw-export.step4_title' | transloco}}</h3>
			<form #TransactionsForm="ngForm">
				<div class="form__group block">
					<div *ngFor="let item of transactionsMapping; let i = index"
						class="form__group-item form__group-item--800">
						<p>{{item.code}}-{{item.transactionName}}</p>
						<ng-select #inputProduct bindLabel="identifier" required bindValue="id"
							name="selectedProductId{{i}}" [items]="products" [searchable]="true" [clearable]="false"
							[(ngModel)]="item.selectedProductId">
							<ng-template>
								<input style="width: 100%; line-height: 24px" type="text"
									(input)="inputProduct.filter($event.target.value)" />
							</ng-template>
						</ng-select>
					</div>
				</div>
				<div class="form__bottom form__bottom--border-none">
					<button type="button" class="btn btn--primary btn--import" (click)="BackStep()">
						{{'global.export_btn-back' | transloco}}
					</button>
					<button type="button" class="btn btn--primary btn--import" [disabled]="!TransactionsForm.valid"
						(click)="NextStepSelected()">
						{{'global.export_btn-next' | transloco}}
						<i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
					</button>
				</div>
			</form>
		</fieldset>

		<fieldset class="form__section {{CheckStep(5)}}">
			<h3 class="form__section-title">{{'cw-export.step5_title' | transloco}}</h3>
			<form #SurchargeForm="ngForm">
				<div class="form__group block">
					<div class="form__group-item form__group-item--500 flex-start">
						<p>{{'cw-export.step5-field_combineSurchargesWithTaxes' | transloco}}</p>
						<dg-checkbox name="combineSurchargesWithTaxes" ngDefaultControl
							[id]="'combineSurchargesWithTaxes'" [(ngModel)]="combineSurchargesWithTaxes"></dg-checkbox>
					</div>

					<div *ngIf="!combineSurchargesWithTaxes && (resellerSurcharges$ | async)?.length > 1" class="form__group-item form__group-item--500 flex-start">
						<p>
							{{'cw-export.step5-field-surchargeSeparatelyMapping' | transloco}}
							<i class="fa fa-question-circle tooltip tooltip--export_1" aria-hidden="true">
								<span>{{'cw-export.step5-field-surchargeSeparatelyMapping-tooltip' | transloco}}</span>
							</i>
						</p>
						<dg-checkbox name="surchargeSeparatelyMapping" ngDefaultControl
							[id]="'surchargeSeparatelyMapping'" [(ngModel)]="surchargeSeparately"></dg-checkbox>
					</div>

					<div *ngIf="!combineSurchargesWithTaxes && !surchargeSeparately">
						<div *ngFor="let item of surchargeMapping; let i = index"
							class="form__group-item form__group-item--500">
							<p>{{item.surchargeName}}</p>
							<ng-select #inputProduct bindLabel="identifier" required
								bindValue="id" name="selectedProductId{{i}}" [items]="products"
								[searchable]="true" [clearable]="false" [(ngModel)]="item.selectedProductId">
								<ng-template>
									<input class="ng-select-input" type="text" (input)="inputProduct.filter($event.target.value)" />
								</ng-template>
							</ng-select>
						</div>
					</div>

					<div *ngIf="surchargeSeparately">
						<div *ngFor="let item of surchargeSeparatelyMapping; let i = index"
							class="form__group-item form__group-item--500">
							<p>{{item.surchargeName}}</p>
							<ng-select #inputProduct bindLabel="identifier" required
								bindValue="id" name="selectedProductId{{i}}" [items]="products"
								[searchable]="true" [clearable]="false" [(ngModel)]="item.selectedProductId">
								<ng-template>
									<input class="ng-select-input" type="text" (input)="inputProduct.filter($event.target.value)" />
								</ng-template>
							</ng-select>
						</div>
					</div>
				</div>
				<div class="form__bottom form__bottom--border-none">
					<button type="button" class="btn btn--primary btn--import" (click)="BackStep()">
						{{'global.export_btn-back' | transloco}}
					</button>
					<button type="button" class="btn btn--primary btn--import" [disabled]="!SurchargeForm.valid"
						(click)="NextStepSelected()">
						{{'global.export_btn-next' | transloco}}
						<i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
					</button>
				</div>
			</form>
		</fieldset>

		<fieldset class="form__section {{CheckStep(6)}}">
			<h3 *ngIf="!combineSurchargesWithTaxes" class="form__section-title">{{'cw-export.step6_title' | transloco}}</h3>
			<h3 *ngIf="combineSurchargesWithTaxes" class="form__section-title">{{'cw-export.step6_title-combineSurchargesWithTaxes' | transloco}}</h3>
			<form #TaxForm="ngForm">
				<div *ngIf="!combineSurchargesWithTaxes" class="form__group block">
					<div class="form__group-item form__group-item--500 flex-start">
						<p>
							{{'cw-export.step6-field_sureTaxMultiline' | transloco}}
							<i class="fa fa-question-circle tooltip tooltip--export_1" aria-hidden="true">
								<span>
									{{'cw-export.step6-field_sureTaxMultiline-tooltip' | transloco}}
								</span>
							</i>
						</p>
						<dg-checkbox name="sureTaxMultiline" ngDefaultControl [id]="'sureTaxMultiline'"
							[(ngModel)]="sureTaxMultiline"></dg-checkbox>
					</div>
					<div *ngIf="sureTaxMultiline" class="form__group-item form__group-item--500 flex-start">
						<p>
							{{'cw-export.step6-field_groupTaxByState' | transloco}}
							<i class="fa fa-question-circle tooltip tooltip--export_2" aria-hidden="true">
								<span>
									{{'cw-export.step6-field_groupTaxByState-tooltip' | transloco}}
								</span>
							</i>
						</p>
						<dg-checkbox name="groupTaxByState" ngDefaultControl [id]="'groupTaxByState'"
							[(ngModel)]="groupTaxByState"></dg-checkbox>
					</div>
					<div *ngIf="sureTaxMultiline && groupTaxByState">
						<div *ngFor="let line of sureTaxByStateLineMapping; let i = index"
							class="form__group-item form__group-item--800">
							<p>{{line.taxTypeDesc}} {{line.stateCode}} {{!line.taxInclusive ? ('global-export.exclusive_label' | transloco) : 'global-export.inclusive_label' | transloco}}</p>
							<ng-select #inputProduct bindLabel="identifier" required
								bindValue="id" name="selectedProductId{{i}}" [items]="products" [searchable]="true"
								[clearable]="false" [(ngModel)]="line.selectedProductId">
								<ng-template>
									<input style="width: 100%; line-height: 24px" type="text"
										(input)="inputProduct.filter($event.target.value)" />
								</ng-template>
							</ng-select>
						</div>
					</div>
					<div *ngIf="sureTaxMultiline && !groupTaxByState">
						<div *ngFor="let line of sureTaxLineMapping; let i = index"
							class="form__group-item form__group-item--800">
							<p>{{line.taxTypeDesc}} {{!line.taxInclusive ? ('global-export.exclusive_label' | transloco) : 'global-export.inclusive_label' | transloco}} {{line.selectedProductId}}</p>
							<ng-select #inputProduct bindLabel="identifier" required
								bindValue="id" name="selectedProductId{{i}}" [items]="products" [searchable]="true"
								[clearable]="false" [(ngModel)]="line.selectedProductId">
								<ng-template>
									<input style="width: 100%; line-height: 24px" type="text"
										(input)="inputProduct.filter($event.target.value)" />
								</ng-template>
							</ng-select>
						</div>
					</div>
					<div *ngIf="!sureTaxMultiline">
						<div *ngFor="let item of taxMapping; let i = index"
							class="form__group-item form__group-item--500">
							<p>{{item.taxName}} {{!item.taxInclusive ? ('global-export.exclusive_label' | transloco) : 'global-export.inclusive_label' | transloco}}</p>
							<ng-select #inputProduct bindLabel="identifier" bindValue="id"
								required name="selectedProductId{{i}}" [items]="products"
								[searchable]="true" [clearable]="false" [(ngModel)]="item.selectedProductId">
								<ng-template>
									<input style="width: 100%; line-height: 24px" type="text"
										(input)="inputProduct.filter($event.target.value)" />
								</ng-template>
							</ng-select>
						</div>

					</div>
				</div>
				<div *ngIf="combineSurchargesWithTaxes" class="form__group block">
					<div *ngFor="let item of surchargeWithTaxMapping; let i = index"
						class="form__group-item form__group-item--500">
						<p>{{item.combineName}}</p>
						<ng-select #inputProduct bindLabel="identifier" bindValue="id" required
							name="selectedProductId{{i}}" [items]="products" [searchable]="true"
							[clearable]="false" [(ngModel)]="item.selectedProductId">
							<ng-template>
								<input style="width: 100%; line-height: 24px" type="text"
									(input)="inputProduct.filter($event.target.value)" />
							</ng-template>
						</ng-select>
					</div>
				</div>
				<div class="form__bottom form__bottom--border-none">
					<button type="button" class="btn btn--primary btn--import" (click)="BackStep()">
						{{'global.export_btn-back' | transloco}}
					</button>
					<button type="button" class="btn btn--primary btn--import" [disabled]="!TaxForm.valid"
						(click)="NextStepSelected()">
						{{'global.export_btn-next' | transloco}}
						<i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
					</button>
				</div>
			</form>
		</fieldset>

		<fieldset class="form__section {{CheckStep(7)}}">
			<h3 class="form__section-title">
				{{'cw-export.step7_title' | transloco}}
			</h3>
			<div class="form__section-subtitle">
				{{'cw-export.step7_subtitle' | transloco}}
			</div>
			<div class="form__group">
				<div class="form__group-item ">
					<div class="radiobox">
						<input type="radio" id="KeepItems" name="radio-delete" [value]="false"
							[(ngModel)]="deleteExisting">
						<label class="radiobox-label" for="KeepItems">
							{{'cw-export.step7-field_deleteExisting-false' | transloco}}
						</label>
					</div>
					<div class="radiobox">
						<input type="radio" id="DeleteItems" name="radio-delete" [value]="true"
							[(ngModel)]="deleteExisting">
						<label class="radiobox-label" for="DeleteItems">
							{{'cw-export.step7-field_deleteExisting-true' | transloco}}
						</label>
					</div>
				</div>
			</div>

			<div class="form__bottom form__bottom--border-none">
				<button type="button" class="btn btn--primary btn--import" (click)="BackStep()">
					{{'global.export_btn-back' | transloco}}
				</button>
				<button type="button" class="btn btn--primary btn--import" (click)="NextStepSelected()">
					{{'global.export_btn-next' | transloco}}
					<i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
				</button>
			</div>
		</fieldset>

		<fieldset class="form__section {{CheckStep(8)}}">
			<h3 class="form__section-title">
				{{'cw-export.step8_title' | transloco}}
			</h3>
			<div class="form__section-subtitle">
				{{'cw-export.step8_subtitle' | transloco}}
			</div>

			<div class="form__group">
				<div class="form__group-item ">
					<div class="radiobox">
						<input class="radiobox-input" type="radio" name="price-radio" id="price-1"
							[value]="1" [(ngModel)]='selectedPriceCalculationTypeId' />
						<label class="radiobox-label" for="price-1">
							{{'cw-export.field_priceCalculationTypes-opt-0' | transloco}}
						</label>
					</div>
					<div class="sidebar__radiobox radiobox">
						<input class="radiobox-input" type="radio" name="price-radio" id="price-2"
							[value]="2" [(ngModel)]='selectedPriceCalculationTypeId' />
						<label class="radiobox-label" for="price-2">
							{{'cw-export.field_priceCalculationTypes-opt-1' | transloco}}
						</label>
					</div>
				</div>
			</div>

			<div class="form__bottom form__bottom--border-none">
				<button type="button" class="btn btn--primary btn--import" [disabled]="loading" (click)="BackStep()">
					{{'global.export_btn-back' | transloco}}
				</button>
				<button type="button" class="btn btn--primary btn--import" [disabled]="loading" (click)="Export()">
					{{'global.export_btn-export' | transloco}}
					<i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
				</button>
			</div>
		</fieldset>
	</div>
</form>

<div *ngIf="isExported" class="modal modal--tile modal--confirmation">
	<div class="modal__content">
		<form class="modal__content-body">
			<div class="page-tile__body page-tile__body--hidden-none">
				<div class="page-tile__title"> <b>{{'cw-export.confirmation_title' | transloco}}</b></div>

				<div class="page-tile__content">
					<div class="page-tile__group">
						<span>
							{{'cw-export.confirmation_msg' | transloco}}
						</span>
					</div>
					<div class="page-tile__content-item">
						<div *ngFor="let inv of exportedInvoices" class="page-tile__content-row">
							<i class="fa fa-circle" aria-hidden="true"></i>
							{{inv}}
						</div>
					</div>
				</div>

				<p class="page-tile__body-proceed"></p>

				<div class="form__bottom">
					<button type="button" class="btn btn--secondary width-100px" (click)="onExportConfirm()">
						{{'global.modals_btn-confirm' | transloco}}
					</button>
					<button type="button" class="btn btn--default width-100px" (click)="backTo()">
						{{'global.modals_btn-cancel' | transloco}}
					</button>
				</div>
			</div>
		</form>
	</div>
</div>

<div *ngIf="isExported" class="fade"></div>
