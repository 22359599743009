import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService, HttpAuth } from 'core/auth';
import { BillingPeriod } from 'domain/entities';
import { BaseRepository } from './base-repository';
import { CacheService } from 'services/cache.service';
import { AppConfig } from 'core/app-config';
import { Guid } from 'domain/types';

@Injectable({
	providedIn: 'root'
})
export class PeriodRepository extends BaseRepository {

	constructor(
		http: HttpAuth,
		config: AppConfig,
		private authService: AuthService,
		cache: CacheService
	) {

		super(http, config, cache);
	}

	getAll(): Observable<BillingPeriod[]> {

		const key = 'billing-periods';

		if (this.cacheHasKey(key))
			return this.getCache<BillingPeriod[]>(key);

		const observable =
			this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/billing-periods`)
				.pipe(map(res => {
					let periods: BillingPeriod[] = res.body.data.map(x => new BillingPeriod().deserialize(x));

					periods = periods.sort((a: BillingPeriod, b: BillingPeriod) => {
						return a.periodStart > b.periodStart ? -1 : 1;
					});

					this.setCacheValue(key, periods, null);

					return periods;
				})
				);

		this.cache.set$(key, observable);

		return observable;
	}

	getAgreementBillingPeriods(agreementId: Guid): Observable<BillingPeriod[]> {
		const key = `agreement-billing-periods-${agreementId}`;
		const condition = this.cache.get('agreements-periods-refresh');

		if (this.cacheHasKey(key) && condition !== true)
			return this.getCache<BillingPeriod[]>(key);

		const observable =
			this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/billing-periods/${agreementId}`)
				.pipe(map(res => {
					let periods: BillingPeriod[] = res.body.data.map(x => new BillingPeriod().deserialize(x));

					periods = periods.sort((a: BillingPeriod, b: BillingPeriod) => {
						return a.periodStart > b.periodStart ? -1 : 1;
					});

					this.setCacheValue(key, periods, null);

					return periods;
				})
				);

		this.cache.set$(key, observable);

		return observable;
	}
}
