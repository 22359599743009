import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})

export class RecordTypeService {

	private ratecardUpdatedSource = new BehaviorSubject<any>(null);

	public ratecardUpdated$ = this.ratecardUpdatedSource.asObservable();

	public updateRatecardGlobal(): void {
		this.ratecardUpdatedSource.next(true);
	}
}
