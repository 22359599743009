import { Serializable } from 'domain/models';

export interface HaloProduct {
	id: number;
	name: string;
}

export interface HaloContract {
	id: number;
	name: string;
}

export interface XeroBrandingTheme {
	value: string;
	label: string;
}

export class InvoiceMappingResponse implements Serializable<InvoiceMappingResponse> {
	public invoiceId: number;
	public invoiceNumber: string;
	public companyName: string;
	public customerId: number;
	public selectedHaloContractId: number;
	public contracts: HaloContract[];
	public selectedXeroBrandingThemeId: string;
	public xeroBrandingThemes: XeroBrandingTheme[];

	deserialize(json: any): InvoiceMappingResponse {
		this.invoiceId = json.invoiceId;
		this.invoiceNumber = json.invoiceNumber;
		this.companyName = json.companyName;
		this.customerId = json.customerId;
		this.selectedHaloContractId = json.selectedHaloContractId;
		this.contracts = json.contracts;
		this.xeroBrandingThemes = json.xeroBrandingThemes;
		this.selectedXeroBrandingThemeId = json.selectedXeroBrandingThemeId;
		return this;
	}
}

export class ContractMappingResponse implements Serializable<ContractMappingResponse> {
	public invoices: InvoiceMappingResponse[];
	public products: HaloProduct[];

	deserialize(json: any): ContractMappingResponse {
		this.invoices = json.invoices;
		this.products = json.products;
		return this;
	}
}

export class TransactionMappingResponse implements Serializable<TransactionMappingResponse> {
	public transactionName: string;
	public code: string;
	public selectedHaloItemId: number;

	deserialize(json: any): TransactionMappingResponse {
		this.transactionName = json.transactionName;
		this.code = json.code;
		this.selectedHaloItemId = json.selectedHaloItemId;
		return this;
	}
}

export class InvoiceLineMappingResponse implements Serializable<InvoiceLineMappingResponse> {
	public serviceId: number;
	public siteId: number;
	public recordType: number;
	public groupName: string;
	public description: string;
	public selectedHaloItemId: number;

	deserialize(json: any): InvoiceLineMappingResponse {
		this.serviceId = json.serviceId;
		this.siteId = json.siteId;
		this.recordType = json.recordType;
		this.groupName = json.groupName;
		this.description = json.description;
		this.selectedHaloItemId = json.selectedHaloItemId;
		return this;
	}
}

export class SurchargeMappingResponse implements Serializable<SurchargeMappingResponse> {
	public surchargeName: string;
	public selectedHaloItemId: number;

	deserialize(json: any): SurchargeMappingResponse {
		this.surchargeName = json.surchargeName;
		this.selectedHaloItemId = json.selectedHaloItemId;
		return this;
	}
}

export class SurchargeWithTaxMappingResponse implements Serializable<SurchargeWithTaxMappingResponse> {
	public combineName: string;
	public selectedHaloItemId: number;

	deserialize(json: any): SurchargeWithTaxMappingResponse {
		this.combineName = json.combineName;
		this.selectedHaloItemId = json.selectedHaloItemId;
		return this;
	}
}

export class TaxMappingResponse implements Serializable<TaxMappingResponse> {
	public taxName: string;
	public selectedHaloItemId: number;
	public taxInclusive: boolean;
	deserialize(json: any): TaxMappingResponse {
		this.taxName = json.taxName;
		this.selectedHaloItemId = json.selectedHaloItemId;
		this.taxInclusive = json.taxInclusive;

		return this;
	}
}

export class TaxLineMappingResponse implements Serializable<TaxLineMappingResponse> {
	public taxTypeDesc: string;
	public selectedHaloItemId: number;
	public taxInclusive: boolean;

	deserialize(json: any): TaxLineMappingResponse {
		this.taxTypeDesc = json.taxTypeDesc;
		this.selectedHaloItemId = json.selectedHaloItemId;
		this.taxInclusive = json.taxInclusive;

		return this;
	}
}

export class TaxLineStateMappingResponse implements Serializable<TaxLineStateMappingResponse> {
	public taxTypeDesc: string;
	public stateCode: string;
	public selectedHaloItemId: number;
	public taxInclusive: boolean;

	deserialize(json: any): TaxLineStateMappingResponse {
		this.taxTypeDesc = json.taxTypeDesc;
		this.stateCode = json.stateCode;
		this.selectedHaloItemId = json.selectedHaloItemId;
		this.taxInclusive = json.taxInclusive;

		return this;
	}
}

export class ExportModel {
	public invoices: InvoiceMappingResponse[];
	public transactionResponses: TransactionMappingResponse[];
	public invoiceLinesResponses: InvoiceLineMappingResponse[];
	public surchargeResponses: SurchargeMappingResponse[];
	public surchargesWithTaxesResponses: SurchargeWithTaxMappingResponse[];
	public totalTaxesResponses: TaxMappingResponse[];
	public taxesLinesResponses: TaxLineMappingResponse[];
	public taxesLinesStateResponses: TaxLineStateMappingResponse[];
	public selectedGroupingTypeId: number;
	public selectedPriceCalculationTypeId: number;
	public deleteExisting: boolean;
	public combineSurchargesWithTaxes: boolean;
	public taxMultiline: boolean;
	public taxForState: boolean;
	public tag: string;
	public uniteSameProductsInOne?: boolean;
}
