import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Guid } from 'domain/types';
import { ICellRendererParams } from 'ag-grid-enterprise';

@Component({
	selector: 'dg-child-cell-link-guid',
	template: `
		<a (click)="onQuoteClick(params.data.uuid)">{{ params.value }}</a>
	`
})
export class ChildQuoterRendererComponent implements ICellRendererAngularComp {
	public params: ICellRendererParams;

	agInit(params: ICellRendererParams): void {
		this.params = params;
	}

	public onQuoteClick(value: Guid): void {
		this.params.context.componentParent.onQuoteClick(value);
	}

	refresh(): boolean {
		return false;
	}
}
