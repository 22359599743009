import { HttpClient } from '@angular/common/http';
import {
	TRANSLOCO_LOADER,
	Translation,
	TranslocoLoader,
	TRANSLOCO_CONFIG,
	translocoConfig,
	TranslocoModule
} from '@ngneat/transloco';
import { Injectable, NgModule } from '@angular/core';
import { environment } from '@environments/environment';
import { TranslocoPersistLangModule, TRANSLOCO_PERSIST_LANG_STORAGE } from '@ngneat/transloco-persist-lang';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { CacheService } from 'services/cache.service';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
	constructor(
		private readonly http: HttpClient,
		private readonly cache: CacheService
	) {}

	getTranslation(lang: string): Observable<Translation> {
		return this.getAgGridTranslation(lang).pipe(
			switchMap(x => this.http.get<Translation>(`/assets/i18n/${lang}.json?v=${Date.now()}`))
		);
	}

	getAgGridTranslation(lang: string): Observable<Translation> {
		const cache = this.cache.get(`ag-grid-locale-${lang}`);
		if (cache) {
			return of(cache);
		}

		return this.http.get<Translation>(`/assets/i18n/${lang}-grid.json?v=${Date.now()}`)
			.pipe(tap(t => this.cache.set(`ag-grid-locale-${lang}`, t)));
	}
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/typedef
export function getLangFn({
	cachedLang,
	browserLang,
	cultureLang,
	defaultLang,
}) {
	if (cachedLang) return cachedLang;

	const availableLangs = ['en-us','en-gb'/*, 'fr'*/];
	const lang = browserLang && availableLangs.includes(browserLang)
		? browserLang
		: defaultLang;
	return lang;
}

@NgModule({
	imports: [
		TranslocoPersistLangModule.forRoot({
			getLangFn,
			storage: {
				provide: TRANSLOCO_PERSIST_LANG_STORAGE,
				useValue: localStorage,
			},
		})
	],
	exports: [TranslocoModule],
	providers: [
		{
			provide: TRANSLOCO_CONFIG,
			useValue: translocoConfig({
				availableLangs: [
					{ id: 'en-us', label: 'English (US)' },
					{ id: 'en-gb', label: 'English (GB)' },
					//{ id: 'fr', label: 'French' }
				],
				defaultLang: 'en-us',
				fallbackLang: 'en-us',
				missingHandler: {
					useFallbackTranslation: environment.env === 'production',
					logMissingKey: false
				},
				// Remove this option if your application doesn't support changing language in runtime.
				reRenderOnLangChange: environment.env !== 'production', // TODO: set to false for better performance
				prodMode: environment.env === 'production'
			})
		},
		{ provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader }
	]
})
export class TranslocoRootModule { }
