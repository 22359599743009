import { Component } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { DgDialog, DgDialogService } from './dialog.service';
import { IQuoterErrors } from 'pages/global/+external-quotes';

@Component({
	selector: 'dg-dialog',
	templateUrl: './dialog.component.html',
	styleUrls: ['./dialog.component.css']
})

export class DgDialogComponent {
	public className = '';
	public title: string;
	public message: string;
	public list: IQuoterErrors[] = [];

	public visible: boolean;
	private readonly stream: Subject<boolean> = new Subject<boolean>();

	private dialog: DgDialog;

	constructor(dialogService: DgDialogService) {

		dialogService.showDialog$.subscribe((dialog: DgDialog) => {
			this.visible = true;
			this.title = dialog.title;
			this.className = dialog.className;
			this.dialog = dialog;
			this.list = dialog.list ? dialog.list : [];
			this.message = Array.isArray(dialog.message) ? dialog.message[0] : dialog.message;
		});
	}

	public show(title: string, message: string | string[], list?: IQuoterErrors[], className?: string): Observable<boolean> {
		this.visible = true;
		this.title = title;
		this.className = className;
		this.message = Array.isArray(message) ? message[0] : message;
		this.list = list ? list : [];
		return this.stream.asObservable();
	}

	public onAnswer(answer: boolean): void {
		this.stream.next(answer);
		this.dialog.stream.next(answer);
		this.visible = false;
	}
}
