import { Serializable } from '../models';

export class IntegrationSettings implements Serializable<IntegrationSettings> {
	integrations: PortalIntegration[];

	deserialize(json: any): IntegrationSettings {

		this.integrations = json.integrations;

		return this;
	}
}

export class PortalIntegration implements Serializable<PortalIntegration> {
	category: IntegrationCategory;
	type: IntegrationType;
	deserialize(json: any): PortalIntegration {
		this.category = json.category;
		this.type = json.type;

		return this;
	}
}

export enum IntegrationCategory {
	Accounting = 'accounting',
	Tax = 'tax',
	Quoter = 'quoter',
}

export enum IntegrationType {
	ConnectWise = 'connectwise',
	Kaseya = 'kaseya',
	Xero = 'xero',
	QuickBooks = 'quickbooks',
	QuickBooksDesktop = 'quickbooks-desktop',
	AvalaraCommunications = 'avalara-communications',
	Csi = 'csi',
	Ceretax = 'ceretax',
	Suretax = 'suretax',
	Autotask = 'autotask',
	Halo = 'halo',
	Quoter = 'Quoter',
	ITQuoter = 'IT-Quoter',
	Ipnd = 'ipnd'
}
