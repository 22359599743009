import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import * as Moment from 'moment-timezone';

@Component({
	selector: 'child-cell-tooltip',
	template: ` <a class="tooltip tooltip--invoice-date">
 					{{formatDateTo(params.data.invoiceDate, 'L')}}
					<span> Generated On: {{formatDateTo(params.data.dateGenerated, 'H')}}</span>
				</a>`
})
export class ChildLinkTooltipRenderer implements ICellRendererAngularComp {
	public params: any;

	agInit(params: any): void {
		this.params = params;
	}

	public formatDateTo(date: Date, type: string): string {
		if (!date) {
			return '';
		} else if (type === 'L') {
			return Moment.utc(date).format('L');
		} else if (type === 'H') {
			return Moment.utc(date).format('L h:mm A');
		}
	}

	public invokeParentMethod(id: number): void {
		this.params.context.componentParent.methodFromParentView(id);
	}

	refresh(): boolean {
		return false;
	}
}
