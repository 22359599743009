import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { IpndListType, IpndServiceType, IpndUsageType, ServiceItemIpndSettings } from 'domain/models';
import { Guid } from 'domain/types';
import { IntegrationRepository, ServiceItemsRepository } from 'repositories';
import { Subject, map, takeUntil, tap } from 'rxjs';

@Component({
	selector: 'dg-ipnd-integration-settings',
	templateUrl: './dg-ipnd-integration-settings.component.html',
	styleUrls: ['./dg-ipnd-integration-settings.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DgIpndIntegrationSettingsComponent implements OnInit, OnDestroy {
	@Input() serviceItemId: Guid;
	@Output() ipndFormChanged = new EventEmitter<ServiceItemIpndSettings>();
	ipndListTypes: IpndListType[] = [];
	ipndUsageTypes: IpndUsageType[] = [];
	ipndServiceTypes: IpndServiceType[] = [];
	serviceItemIpndSettings!: ServiceItemIpndSettings;
	private readonly _integrationRepo = inject(IntegrationRepository);
	private readonly _serviceItemRepository = inject(ServiceItemsRepository);
	private readonly _fb = inject(FormBuilder);
	private readonly _cdr = inject(ChangeDetectorRef);
	private readonly _unsubscribe$ = new Subject<void>();
	ipndForm = this._fb.group({
		listTypeId: this._fb.control<number>(2, Validators.required),
		usageTypeId: this._fb.control<number>(2, Validators.required),
		serviceTypeId: this._fb.control<number>(4, Validators.required),
		sendToIpnd: this._fb.control<boolean>(true, Validators.required),
	});

	async ngOnInit(): Promise<void> {
		[this.ipndListTypes, this.ipndUsageTypes, this.ipndServiceTypes] = await Promise.all([
			this._integrationRepo.getIpndListTypes(this._unsubscribe$),
			this._integrationRepo.getIpndUsageTypes(this._unsubscribe$),
			this._integrationRepo.getIpndServiceTypes(this._unsubscribe$),
		]).withBusy();

		if (this.serviceItemId) {
			this.serviceItemIpndSettings = await this._serviceItemRepository.getServiceItemIpndSettings(this.serviceItemId, this._unsubscribe$);

			if (this.serviceItemIpndSettings) {
				this.fillForm(this.serviceItemIpndSettings);
			}
		}

		this.ipndForm.valueChanges
			.pipe(
				takeUntil(this._unsubscribe$),
				map(() => this.mapIpndFormToEntity()),
				tap((value) => this.ipndFormChanged.emit(value))
			).subscribe();

		this._cdr.markForCheck();
	}

	ngOnDestroy(): void {
		this._unsubscribe$.next();
		this._unsubscribe$.complete();
	}

	private fillForm(ent: ServiceItemIpndSettings): void {
		this.ipndForm.patchValue({
			listTypeId: ent.listTypeId,
			usageTypeId: ent.usageTypeId,
			serviceTypeId: ent.serviceTypeId,
			sendToIpnd: ent.sendToIpnd
		});

		this._cdr.markForCheck();
	}

	private mapIpndFormToEntity(): ServiceItemIpndSettings {
		const formValue = this.ipndForm.value;

		return <ServiceItemIpndSettings>{
			id: this.serviceItemIpndSettings?.id || undefined,
			listTypeId: formValue.listTypeId,
			usageTypeId: formValue.usageTypeId,
			serviceTypeId: formValue.serviceTypeId,
			sendToIpnd: formValue.sendToIpnd
		};
	}

	async addIpndSettings(seviceItemId: Guid): Promise<void> {
		this.serviceItemIpndSettings = this.mapIpndFormToEntity();

		await this._serviceItemRepository.addServiceItemIpndSettings(seviceItemId, this.serviceItemIpndSettings);
	}

	async updateIpndSettings(): Promise<void> {
		this.serviceItemIpndSettings = this.mapIpndFormToEntity();

		// In the case of configured ipnd integration and empty settings add first new settings, otherwise, update existed
		if (this.serviceItemIpndSettings?.id) {
			await this._serviceItemRepository.updateServiceItemIpndSettings(this.serviceItemId, this.serviceItemIpndSettings, this._unsubscribe$);
		} else {
			const ipndSettingId = await this._serviceItemRepository.addServiceItemIpndSettings(this.serviceItemId, this.serviceItemIpndSettings, this._unsubscribe$);

			if (!this.serviceItemIpndSettings?.id) {
				this.serviceItemIpndSettings.id = ipndSettingId;
			}
		}

		this._cdr.markForCheck();
	}

	ipndFormDiscard(): void {
		const ent = this.serviceItemIpndSettings;

		if (ent) {
			this.fillForm(ent);
		}
	}
}
