import { Serializable } from 'domain/models';
import { Utils } from 'tools/utils';

export class FeedFileEntity implements Serializable<FeedFileEntity> {
	feedName: string;
	fileName: string;
	stage: string;
	blobPath: string;
	downloadDate: string;

	deserialize(json: any): FeedFileEntity {
		this.feedName = json.feedName;
		this.fileName = json.fileName;
		this.stage = json.stage;
		this.blobPath = json.blobPath;
		this.downloadDate = Utils.toMomentTzFormat(json.downloadDate);
		return this;
	}
}

export class FeedHandler implements Serializable<FeedHandler> {
	id: number;
	name: string;
	description: string;
	properties: FeedProperty[];

	deserialize(json: any): FeedHandler {
		this.id = json.id;
		this.name = json.name;
		this.description = json.description;
		this.properties = json.properties;
		return this;
	}
}

export class FeedProperty implements Serializable<FeedProperty> {
	id: number;
	name: string;
	description: string;
	required: boolean;
	isMultiline: boolean;
	ordering: number;

	deserialize(json: any): FeedProperty {
		this.id = json.id;
		this.name = json.name;
		this.description = json.description;
		this.required = json.required;
		this.isMultiline = json.isMultiline;
		this.ordering = json.ordering;
		return this;
	}

}

export class Feed implements Serializable<Feed> {
	id: number;
	name: string;
	brandId: number;
	brandName: string;
	mappingConfigId: number;
	enabled: boolean;
	nextProcessTime: string;
	resetProcessTime: boolean;
	timeZone: string;
	handlers: FeedFeedHandler[];
	expectedMinFileSize: number;
	expectedFrequencyWindow: number;
	expectedFrequency: number;

	deserialize(json: any): Feed {
		this.id = json.id;
		this.name = json.name;
		this.brandId = json.brandId;
		this.brandName = json.brandName;
		this.mappingConfigId = json.mappingConfigId;
		this.enabled = json.enabled;
		this.nextProcessTime = json.nextProcessTime;
		this.timeZone = json.timeZone;
		this.handlers = json.handlers;

		this.expectedMinFileSize = json.expectedMinFileSize;
		this.expectedFrequencyWindow = json.expectedFrequencyWindow;
		this.expectedFrequency = json.expectedFrequency;

		return this;
	}

	clone(): Feed {
		const clone = Object.assign(new Feed(), this);
		return clone;
	}

	restore(src: Feed): void {
		Object.assign(this, src);
	}
}

export class FeedFeedHandler implements Serializable<FeedFeedHandler> {
	feedFeedHandlerId: number;
	name: string;
	description: string;
	ordering: number;
	handlerId: number;
	values: FeedPropertyValue[];

	deserialize(json: any): FeedFeedHandler {
		this.feedFeedHandlerId = json.feedFeedHandlerId;
		this.name = json.name;
		this.description = json.description;
		this.ordering = json.ordering;
		this.handlerId = json.handlerId;
		this.values = json.values;
		return this;
	}
}

export class FeedPropertyValue implements Serializable<FeedPropertyValue> {
	handlerId: number;
	propertyId: number;
	valueId: number;
	name: string;
	description: string;
	value: any;
	isMultiline: boolean;
	required: boolean;
	ordering: number;

	deserialize(json: any): FeedPropertyValue {
		this.handlerId = json.handlerId;
		this.propertyId = json.propertyId;
		this.valueId = json.valueId;
		this.name = json.name;
		this.isMultiline = json.isMultiline;
		this.description = json.description;
		this.value = json.value;
		this.required = json.required;
		this.ordering = json.ordering;
		return this;
	}
}

export class FeedMappingConfiguration implements Serializable<FeedMappingConfiguration> {
	id: number;
	name: string;

	deserialize(json: any): FeedMappingConfiguration {
		this.id = json.id;
		this.name = json.name;
		return this;
	}
}

export class BrandProperty implements Serializable<BrandProperty> {
	id: number;
	name: string;

	deserialize(json: any): BrandProperty {
		this.id = json.id;
		this.name = json.name;

		return this;
	}

}
