import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import * as Moment from 'moment-timezone';

@Component({
	selector: 'child-cell-double-date',
	template: `
	<a class="ag-cell__double" (click)="invokeParentMethod(params.data.id)">
		<span class="ag-cell__double-item">{{formatDateToL(params.data.dateFrom)}}</span>
		<span class="ag-cell__double-item" style="line-height: 1;">{{formatDateToL(params.data.dateTo)}}</span>
	</a>
`
})
export class ChildCellDoubleDateRenderer implements ICellRendererAngularComp {
	public params: any;

	agInit(params: any): void {
		this.params = params;
	}

	public formatDateToL(date: Date): string {
		if (Moment.utc(date).isValid() && date != null) {
			return Moment.utc(date).format('L');
		} else {
			return '';
		}
	}

	public invokeParentMethod(value: any) {
		this.params.context.componentParent.methodFromParent(value);
	}

	refresh(): boolean {
		return false;
	}
}
