import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { LicenseManager } from 'ag-grid-enterprise';

LicenseManager.setLicenseKey('Using_this_{AG_Grid}_Enterprise_key_{AG-063701}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{DATAGATE_INNOVATION_LIMITED}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Datagate_Portal}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Datagate_Portal}_need_to_be_licensed___{Datagate_Portal}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{19_September_2025}____[v3]_[01]_MTc1ODIzNjQwMDAwMA==7f0d4fd0b4986fc42c2fba761224fa32');

import './extensions/window';
import './extensions/array';
import './extensions/map';
import './extensions/promise';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { AppConfigModel, DG_APP_CONFIG } from 'core/app-config';

if (environment.env === 'production') {
	enableProdMode();
	if (window) {
		window.console.log = () => { };
	}
}

void fetch('assets/configs/app.' + environment.env + '.json?v=' + Date.now())
	.then(response => response.json())
	.then((config: AppConfigModel) => {
		platformBrowserDynamic([
			{ provide: DG_APP_CONFIG, useValue: config },
		])
			.bootstrapModule(AppModule)
			// eslint-disable-next-line no-console
			.catch(err => console.error(err));
	});
