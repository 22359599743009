import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Guid } from 'domain/types';

@Injectable({
	providedIn: 'root'
})

export class NavigatorService {

	constructor(private router: Router) {

	}
	public get currentUrl(): string {
		return this.router.url;
	}

	public toOperation(operationId: number): void {
		void this.router.navigate(['/operations/' + operationId]);
	}

	public toCustomerAgreements(customerId: Guid, type?: string | Guid): void {
		const navigateTo = '/customers/' + customerId.toString() + '/agreements/';

		if (!type) {
			void this.router.navigate([navigateTo]);
		}

		if (type === 'new') {
			void this.router.navigate([navigateTo + 'new']);
		} else if (type) {
			void this.router.navigate([navigateTo + `${type}`]);
		}
	}

	public toAvalaraCommunication(isTax: boolean, type?: string | Guid): void {
		const navigateTo = '/settings/integrations/avalara-communication';
		const navigateToTax = `${navigateTo}/tax-exemptions/`;

		if (isTax && type === 'new') {
			void this.router.navigate([navigateToTax + 'new']);
		} else if (type instanceof Guid) {
			void this.router.navigate([navigateToTax + `${type}`]);
		} else if (isTax) {
			void this.router.navigate([navigateToTax]);
		} else {
			void this.router.navigate([navigateTo]);
		}
	}

	public toArTransactions(customerId: Guid, type?: string | Guid): void {
		const navigateTo = '/customers/' + customerId.toString() + '/finances/ar-transactions/';

		if (!type) {
			void this.router.navigate([navigateTo]);
		}

		if (type === 'new') {
			void this.router.navigate([navigateTo + 'new']);
		} else if (type) {
			void this.router.navigate([navigateTo + `${type}`]);
		}
	}
}
