import { HttpResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { HttpAuth } from 'core/auth';
import { CacheService } from 'services/cache.service';
import { IIdentity } from 'domain/entities';
import { AppConfig } from 'core/app-config';
import { Guid } from 'domain/types';

export class BaseRepository {

	constructor(
		protected http: HttpAuth,
		protected config: AppConfig,
		protected cache: CacheService
	) { }

	public createEntity<T extends IIdentity>(url: string, entity: T): Observable<T> {

		const observable =
			this.http.post(`${this.config.apiUrl}${this.config.apiVersion}${url}`, entity, null)
				.pipe(map(res => {

					if (res.body === null) {
						return entity;
					} else {
						const newId = res.body.data;
						entity.id = newId;

						return entity;
					}
				}));

		return observable;
	}

	public updateEntity<T extends IIdentity>(url: string, entity: T): Observable<T> {
		const observable =
			this.http.put(`${this.config.apiUrl}${this.config.apiVersion}${url}`, null, entity)
				.pipe(
					map(() => entity)
				);

		return observable;
	}

	public deleteEntity(url: string): Observable<HttpResponse<any>> {

		const observable =
			this.http.delete(`${this.config.apiUrl}${this.config.apiVersion}${url}`);

		return observable;
	}

	public deleteEntities(url: string, ids: Guid[]): Observable<HttpResponse<any>> {

		const requestOptions = new DeleteRequest(ids);

		const observable =
			this.http.post(`${this.config.apiUrl}${this.config.apiVersion}${url}`, requestOptions, null);

		return observable;
	}

	public cacheHasKey(key: string): boolean {
		return this.cache.get(key) != null || this.cache.get$(key) != null;
	}

	protected getCache<T>(key: string): Observable<T> {
		if (this.cache.get(key)) {
			return of(this.cache.get(key));
		}

		if (this.cache.get$(key)) {
			return this.cache.get$(key);
		}

		return null;
	}

	protected getCacheSync<T>(key: string): T | undefined {
		if (this.cache.get(key)) {
			return this.cache.get(key);
		}

		return undefined;
	}

	protected setCacheValue(key: string, value: any, value$: any): void {
		this.cache.set(key, value);
		this.cache.set$(key, value$);
	}

	protected addCacheCollectionItem(key: string, value: any): void {

		if (!this.cache.get(key)) {

			this.cache.set(key, [value]);
			return;
		}

		const items = this.cache.get<IIdentity[]>(key);
		items.push(value);

		this.cache.set(key, items);
	}

	protected updateCacheCollectionItem(key: string, id: number | Guid, value: any): void {

		if (!this.cache.get(key))
			return;

		const items = this.cache.get<IIdentity[]>(key);
		let index;

		if (id instanceof Guid) {
			index = items.findIndex(x => (x.id as Guid).equals(id as Guid));
		} else {
			index = items.findIndex(x => x.id === id);
		}

		if (index > -1) {
			items[index] = value;
		}

		this.cache.set(key, items);
	}

	protected removeFromCacheCollection(key: string, id: number | Guid): void {

		if (!this.cache.get(key))
			return;

		const items = this.cache.get<IIdentity[]>(key);
		let index;

		if (id instanceof Guid) {
			index = items.findIndex(x => (x.id as Guid).equals(id as Guid));
		} else {
			index = items.findIndex(x => x.id === id);
		}

		if (index > -1) {
			items.splice(index, 1);
		}

		this.cache.set(key, items);
	}

	protected removeManyFromCacheCollection(key: string, ids: Guid[]): void {

		for (const id of ids) {
			this.removeFromCacheCollection(key, id);
		}
	}
}

export class DeleteRequest {
	constructor(public records: Guid[]) {

	}
}
