import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
	selector: 'child-cell-customer',
	template: `<a routerLink="/customers/{{params.data.customerId}}/details">{{params.data.customerName}}</a>`
})
export class ChildCellCustomerRendererComponent implements ICellRendererAngularComp {
	public params: any;

	agInit(params: any): void {
		this.params = params;
	}

	refresh(): boolean {
		return false;
	}
}
