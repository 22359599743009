import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
	selector: 'dg-slider',
	templateUrl: './dg-slider.component.html',
	styleUrls: ['./dg-slider.component.sass']
})
export class DgSliderComponent {
  @Input() title: string;
  @Input() open = true;
  @Input() hideButtonIsHidden = false;

  @Output() close: EventEmitter<void> = new EventEmitter<void>();

  public onCancelClicked(): void {
    this.close.emit();
  }
}
