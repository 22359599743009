<form class="form form--export">
	<ul class="steps__wrapper">
		<li class="steps steps--active">
			<span class="steps__label">{{'global-export.step1_label' | transloco}}</span>
			<span class="steps__label">{{'cw-invoice-number.step1_title' | transloco}}</span>
		</li>
	</ul>
	<div class="form__content">
		<!-- additional padding to display data from ng-select because of overlay -->
		<fieldset class="form__section steps--active" style="padding-bottom: 180px;">
			<div *ngIf="loading" class="app-spinner-cw"></div>
			<h3 class="form__section-title">
				{{'cw-invoice-number.step1_title' | transloco}}
			</h3>
			<form #CwInvoiceSync="ngForm">
				<div class="form__group block">
					<div class="form__group-item form__group-item--1200-fs">
						<p>
							{{'cw-invoice-number.step1_header-dg-invoice' | transloco}}
						</p>
						<p>
							{{'cw-invoice-number.step1_header-dg-customer' | transloco}}
						</p>
						<p>
							{{'cw-invoice-number.step1_header-dg-agreement' | transloco}}
						</p>
						<p>
							{{'cw-invoice-number.step1_header-cw-company' | transloco}}
						</p>
						<p>
							{{'cw-invoice-number.step1_header-cw-agreement' | transloco}}
						</p>
						<p>
							{{'cw-invoice-number.step1_header-cw-invoice' | transloco}}
						</p>
					</div>

					<div *ngFor="let item of invoiceSyncMapping; let i = index"
						 class="form__group-item form__group-item--1200-fs">
						<p>{{item.invoiceNumber}}</p>
						<p>{{item.customerLabel}}</p>
						<p>{{item.dgAgreementName}}</p>
						<p>{{item.cwCompanyName}}</p>

						<ng-select
							bindLabel="name"
							required
							name="selectedAgreementId{{i}}"
							[items]="item.cwAgreements"
							[searchable]="true"
							[clearable]="false"
							[(ngModel)]="item.selectedCwAgreement">
						</ng-select>

						<ng-select
							*ngIf="item.selectedCwAgreement?.invoices"
							bindLabel="invoiceNumber"
							required
							bindValue="id"
							name="cwInvoiceId{{i}}"
							[items]="item.selectedCwAgreement.invoices"
							[searchable]="true"
							[clearable]="false"
							[(ngModel)]="item?.selectedCwAgreement.selectedInvoiceId">
							<ng-template ng-option-tmp let-item="item">
								{{item.invoiceNumber}} <small><i *ngIf="item.synced" class="fa fa-check cell-check margin-l__10" aria-hidden="true"></i></small>
							</ng-template>
						</ng-select>
					</div>
				</div>

				<div *ngIf="invoiceSyncMapping" class="form__bottom form__bottom--border-none">
					<button type="button" class="btn btn--primary btn--import" [disabled]="!CwInvoiceSync.valid || loading || !invoiceSyncMapping.length"
							(click)="onCwInvoiceSyncProceed()">
							{{'cw-invoice-number.step1_btn-proceed-label' | transloco}}
						<i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
					</button>
				</div>
			</form>
		</fieldset>
	</div>
</form>
