import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Invoice } from 'domain/entities';
import { XeroExportData } from 'domain/models';
import { LocalSettingsRepository } from 'repositories';
import { NavigatorService } from 'services';
import { CacheModel, CacheService } from 'services/cache.service';
import { InvoiceActionsService } from 'shared/modules/invoice-actions';

@Injectable({
	providedIn: 'root'
})
export class ExportInvoiceNavigation {
	constructor(
		private readonly navigatorService: NavigatorService,
		private readonly invoiceService: InvoiceActionsService,
		private readonly cache: CacheService,
		private readonly router: Router,
		private readonly localSettingsRepository: LocalSettingsRepository
	) {
	}

	public exportInvoices(
		type: string,
		selectedInvoices: Invoice[],
		returnUrl: string,
		excludedInvoices?: Invoice[],
		xeroExport?: XeroExportData, isXeroExtended?: boolean): void {
		const tag = JSON.stringify({ url: `/files` });
		if (xeroExport?.paymentCode === 0) {
			xeroExport.paymentCode = undefined;
		}
		switch (type) {
			case 'halo': {
				const cacheObj = new CacheModel(selectedInvoices, returnUrl);
				this.cache.set('haloExportInvoices', cacheObj);

				if (excludedInvoices?.length) {
					const excludedCacheObj = new CacheModel(excludedInvoices, '');
					this.cache.set('haloExportInvoices-excluded', excludedCacheObj);
				} else {
					this.cache.removeKey('haloExportInvoices-excluded');
				}

				void this.router.navigate(['halo-export-multiline']);
				break;
			}
			case 'halo-charges': {
				const cacheObj = new CacheModel(selectedInvoices, returnUrl);
				this.cache.set('haloChargesExportInvoices', cacheObj);

				if (excludedInvoices?.length) {
					const excludedCacheObj = new CacheModel(excludedInvoices, '');
					this.cache.set('haloChargesExportInvoices-excluded', excludedCacheObj);
				} else {
					this.cache.removeKey('haloChargesExportInvoices-excluded');
				}

				void this.router.navigate(['halo-export-charges']);
				break;
			}
			case 'autotask': {
				const cacheObj = new CacheModel(selectedInvoices, returnUrl);
				this.cache.set('autotaskExportInvoices', cacheObj);

				if (excludedInvoices?.length) {
					const excludedCacheObj = new CacheModel(excludedInvoices, '');
					this.cache.set('autotaskExportInvoices-excluded', excludedCacheObj);
				} else {
					this.cache.removeKey('autotaskExportInvoices-excluded');
				}

				void this.router.navigate(['autotask-export-multiline']);
				break;
			}
			case 'autotaskInvoiceSync': {
				const cacheObj = new CacheModel(selectedInvoices, returnUrl);
				this.cache.set('autotaskInvoiceNumberSync', cacheObj);

				if (excludedInvoices?.length) {
					const excludedCacheObj = new CacheModel(excludedInvoices, '');
					this.cache.set('autotaskInvoiceNumberSync-excluded', excludedCacheObj);
				} else {
					this.cache.removeKey('autotaskInvoiceNumberSync-excluded');
				}

				void this.router.navigate(['at-invoice-sync']);
				break;

			}
			case 'xero': {
				this.localSettingsRepository.SetXeroWizardSetting(xeroExport);

				this.invoiceService.exportInvoicesToXero(selectedInvoices, xeroExport, returnUrl)
					.subscribe(result => {
						if (result.operationId) {
							this.navigatorService.toOperation(result.operationId);
						}
					});
				break;
			}
			case 'xeroMultiline': {
				this.localSettingsRepository.SetXeroWizardSetting(xeroExport);

				if (!isXeroExtended) {
					this.invoiceService.exportInvoicesToXeroMultiline(selectedInvoices, xeroExport, returnUrl, excludedInvoices)
						.subscribe(result => {
							if (result.operationId) {
								this.navigatorService.toOperation(result.operationId);
							}
						});
				} else {
					const cacheObj = new CacheModel(selectedInvoices, returnUrl);

					this.cache.set('exportXeroInvoicesMultiline', cacheObj);

					if (excludedInvoices?.length) {
						const excludedCacheObj = new CacheModel(excludedInvoices, '');
						this.cache.set('exportXeroInvoicesMultiline-excluded', excludedCacheObj);
					} else {
						this.cache.removeKey('exportXeroInvoicesMultiline-excluded');
					}

					void this.router.navigate(['xero-export-multiline']);
				}

				break;
			}
			case 'csv': {
				this.invoiceService.exportInvoicesToCsv(selectedInvoices, tag, excludedInvoices)
					.subscribe(result => {
						if (result.operationId) {
							this.navigatorService.toOperation(result.operationId);
						}
					});
				break;
			}
			case 'connectwise': {
				this.invoiceService.exportInvoicesToConnectwise(selectedInvoices, returnUrl, excludedInvoices)
					.subscribe(result => {
						if (result.operationId) {
							this.navigatorService.toOperation(result.operationId);
						}
					});
				break;
			}
			case 'multiline': {
				this.invoiceService.exportInvoicesMultiline(selectedInvoices, tag, excludedInvoices)
					.subscribe(result => {
						if (result.data.operationId) {
							this.navigatorService.toOperation(result.data.operationId);
						}
					});
				break;
			}
			case 'connectwiseInvoiceSync': {
				const cacheObj = new CacheModel(selectedInvoices, returnUrl);
				this.cache.set('connectwiseInvoiceNumberSync', cacheObj);

				if (excludedInvoices?.length) {
					const excludedCacheObj = new CacheModel(excludedInvoices, '');
					this.cache.set('connectwiseInvoiceNumberSync-excluded', excludedCacheObj);
				} else {
					this.cache.removeKey('connectwiseInvoiceNumberSync-excluded');
				}

				void this.router.navigate(['cw-invoice-sync']);
				break;

			}
			case 'connectwiseAgreements': {
				const cacheObj = new CacheModel(selectedInvoices, returnUrl);
				this.cache.set('exportInvoices', cacheObj);

				if (excludedInvoices?.length) {
					const excludedCacheObj = new CacheModel(excludedInvoices, '');
					this.cache.set('exportInvoices-excluded', excludedCacheObj);
				} else {
					this.cache.removeKey('exportInvoices-excluded');
				}

				void this.router.navigate(['invoice-export']);
				break;
			}
			case 'connectwiseMultiline': {
				const cacheObj = new CacheModel(selectedInvoices, returnUrl);
				this.cache.set('exportInvoicesMultiline', cacheObj);

				if (excludedInvoices?.length) {
					const excludedCacheObj = new CacheModel(excludedInvoices, '');
					this.cache.set('exportInvoicesMultiline-excluded', excludedCacheObj);
				} else {
					this.cache.removeKey('exportInvoicesMultiline-excluded');
				}

				void this.router.navigate(['invoice-export-multiline']);
				break;
			}
			case 'connectwiseReturnTaxes': {
				const cacheObj = new CacheModel(selectedInvoices, returnUrl);
				this.cache.set('exportCWReturnTaxes', cacheObj);

				if (excludedInvoices?.length) {
					const excludedCacheObj = new CacheModel(excludedInvoices, '');
					this.cache.set('exportInvoicesMultiline-excluded', excludedCacheObj);
				} else {
					this.cache.removeKey('exportCWReturnTaxes-excluded');
				}

				void this.router.navigate(['cw-return-taxes']);
				break;
			}
			case 'quickbooksMultiline': {
				const cacheObj = new CacheModel(selectedInvoices, returnUrl);
				this.cache.set('exportQbInvoicesMultiline', cacheObj);

				if (excludedInvoices?.length) {
					const excludedCacheObj = new CacheModel(excludedInvoices, '');
					this.cache.set('exportQbInvoicesMultiline-excluded', excludedCacheObj);
				} else {
					this.cache.removeKey('exportQbInvoicesMultiline-excluded');
				}
				void this.router.navigate(['qb-invoice-export-multiline']);
				break;
			}
			case 'qbd': {
				const cacheObj = new CacheModel(selectedInvoices, returnUrl);
				this.cache.set('exportQBD', cacheObj);

				if (excludedInvoices?.length) {
					const excludedCacheObj = new CacheModel(excludedInvoices, '');
					this.cache.set('exportQBD-excluded', excludedCacheObj);
				} else {
					this.cache.removeKey('exportQBD-excluded');
				}

				void this.router.navigate(['qbd-invoice-export']);
				break;
			}
			case 'csvQbdMultiline': {
				this.invoiceService.exportInvoicesCsvQbdMultiline(selectedInvoices, tag, excludedInvoices)
					.subscribe(result => {
						if (result.data.operationId) {
							this.navigatorService.toOperation(result.data.operationId);
						}
					});
			}
		}
	}
}
