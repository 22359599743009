import { Serializable } from 'domain/models';

export class Tag implements Serializable<Tag> {
	public name: string;
	public value: string;

	deserialize(input: any): Tag {
		this.name = input.name;
		this.value = input.value;
		return this;
	}

	clone(): Tag {
		return Object.assign(new Tag(), this);
	}

	restore(src: Tag): void {
		Object.assign(this, src);
	}
}
