/**
 * Class representing a GUID.
 */
export class Guid {
	private static readonly emptyGuidStr = '00000000-0000-0000-0000-000000000000';
	private static readonly emptyGuid = new Guid(Guid.emptyGuidStr);
	private static readonly validRFC4122 = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
	private static readonly validGuidLike = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
	private static uniqueCounter = 0;
	private readonly guid: string;

	/**
	 * Creates a new GUID object.
	 * @param guidStr - The GUID string.
	 */
	constructor(guidStr?: string) {
		if (guidStr && guidStr !== Guid.emptyGuidStr && !Guid.isValid(guidStr)) {
			throw new Error(`Provided Guid is invalid: ${guidStr}`);
		}
		this.guid = guidStr || Guid.emptyGuidStr;
	}

	/**
	 * Gets an empty GUID.
	 */
	static get empty(): Guid {
		return Guid.emptyGuid;
	}

	/**
	 * Generates a new random GUID.
	 */
	static generate(): Guid {
		let guidStr = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';

		if (window.crypto?.getRandomValues) {
			const randomBytes = new Uint8Array(16);
			window.crypto.getRandomValues(randomBytes);
			randomBytes[6] = (randomBytes[6] & 0x0f) | 0x40; // Set the version field (time_hi_and_version) to 4xxx
			randomBytes[8] = (randomBytes[8] & 0x3f) | 0x80; // Set the variant field (clock_seq_hi_and_reserved) to 8xxx

			guidStr = [...randomBytes].map((b, i) => {
				const hex = b.toString(16).padStart(2, '0');
				if (i === 4 || i === 6 || i === 8 || i === 10) {
					return '-' + hex;
				}
				return hex;
			}).join('');
		} else {
			let d = new Date().getTime();
			if (window.performance && typeof window.performance.now === 'function') {
				d += performance.now();
			}
			guidStr = guidStr.replace(/[xy]/g, c => {
				const r = (d + Math.random() * 16) % 16 | 0;
				return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
			});
		}

		return new Guid(guidStr.toUpperCase());
	}

	/**
	 * Generates a new sequential GUID.
	 */
	static generateSequential(): Guid {
		const guidBytes = new Uint8Array(16);

		const baseGuid = Guid.generate().toString();
		const baseGuidBytes = baseGuid.replace(/-/g, '');

		for (let i = 0; i < 20; i += 2) {
			guidBytes[i / 2] = parseInt(baseGuidBytes.slice(i, i + 2), 16);
		}

		const millisecondsSinceEpoch = Date.now() - Date.parse('1970-01-01T00:00:00Z');
		const uniqueCounter = Guid.uniqueCounter++;
		const timeBytes = new Uint8Array(new BigInt64Array([BigInt(millisecondsSinceEpoch + uniqueCounter)]).buffer);

		for (let i = 10; i < 16; i++) {
			guidBytes[i] = timeBytes[i - 10];
		}

		const guidStr = [
			Array.from(guidBytes.slice(0, 4)).map(b => b.toString(16).padStart(2, '0')).join(''),
			Array.from(guidBytes.slice(4, 6)).map(b => b.toString(16).padStart(2, '0')).join(''),
			Array.from(guidBytes.slice(6, 8)).map(b => b.toString(16).padStart(2, '0')).join(''),
			Array.from(guidBytes.slice(8, 10)).map(b => b.toString(16).padStart(2, '0')).join(''),
			Array.from(guidBytes.slice(10, 16)).map(b => b.toString(16).padStart(2, '0')).join(''),
		].join('-');

		return new Guid(guidStr.toUpperCase());
	}

	/**
	 * Checks if a given GUID is valid.
	 * @param guidLike - The GUID or GUID-like string to validate.
	 */
	static isValid(guidLike: string | Guid, strict: boolean = false): boolean {
		if (guidLike) {
			const guidString = guidLike instanceof Guid ? guidLike.toString() : guidLike;
			return strict
				? Guid.validRFC4122.test(guidString)
				: Guid.validGuidLike.test(guidString);
		}

		return false;
	}

	/**
	 * Checks if the provided GUID is sequential.
	 * @param guidLike A GUID-like string or a Guid instance.
	 * @returns A boolean value indicating if the provided GUID is sequential.
	 */
	static isSequential(guidLike: string | Guid): boolean {
		const guid = guidLike instanceof Guid ? guidLike.toString() : guidLike;

		if (!Guid.isValid(guid)) {
			return false;
		}

		const guidParts = guid.split('-');

		// Check if the version field (time_hi_and_version) is 4xxx
		if (!guidParts[2].startsWith('4')) {
			return false;
		}

		// Check if the variant field (clock_seq_hi_and_reserved) is 8, 9, A, or B
		const variantChar = guidParts[3].charAt(0);
		if (!['8', '9', 'a', 'b', 'A', 'B'].includes(variantChar)) {
			return false;
		}

		// Check if the time portion of the GUID is within a reasonable range of the current time
		const timeBytesStr = guidParts[4];
		const timeBytes = timeBytesStr.match(/.{1,2}/g)!.map(b => parseInt(b, 16)).slice(-6);
		const timeValue = new BigInt64Array(new Uint8Array([...timeBytes, 0, 0]).buffer)[0];
		const currentTime = BigInt(Date.now()) - BigInt(Date.parse('1970-01-01T00:00:00Z'));
		const timeDifference = currentTime > timeValue ? currentTime - timeValue : timeValue - currentTime;

		// If the time difference is within 5 years, consider the GUID sequential
		if (timeDifference <= 157784760000n) {
			return true;
		}

		return false;
	}

	/**
	 * Compares two GUIDs.
	 * @param guid1 - The first GUID or GUID string.
	 * @param guid2 - The second GUID or GUID string.
	 * @returns -1, 0, or 1 if the first GUID is less than, equal to, or greater than the second GUID, respectively.
	 */
	static compareSequential(guid1: Guid | string, guid2: Guid | string): -1 | 0 | 1 {
		const guid1Str = guid1 instanceof Guid ? guid1.toString() : guid1;
		const guid2Str = guid2 instanceof Guid ? guid2.toString() : guid2;

		const guid1Parts = guid1Str.split('-');
		const guid2Parts = guid2Str.split('-');

		const guid1Timestamp = BigInt(`0x${guid1Parts[4]}`);
		const guid2Timestamp = BigInt(`0x${guid2Parts[4]}`);

		return guid1Timestamp < guid2Timestamp ? -1 : guid1Timestamp > guid2Timestamp ? 1 : 0;
	}

	/*
	* Instance methods
	*/

	/**
	 * Checks if the current GUID instance is an empty GUID.
	 * @returns A boolean value indicating if the GUID instance is an empty GUID.
	 */
	isEmpty(): boolean {
		return this.guid === Guid.emptyGuidStr;
	}

	/**
	 * Checks if the current GUID instance is strictly compliant with RFC4122.
	 * @returns A boolean value indicating if the GUID instance is strictly compliant with RFC4122.
	 */
	isStrict(): boolean {
		return Guid.validRFC4122.test(this.guid);
	}

	/**
	 * Checks if the current GUID instance is sequential.
	 * @returns A boolean value indicating if the GUID instance is sequential.
	 */
	isSequential(): boolean {
		return Guid.isSequential(this);
	}

	/**
	 * Checks if the current GUID is equal to the specified GUID.
	 * @param other - The GUID to compare.
	 */
	equals(other: Guid): boolean {
		//disucuss this condition - sometimes 'other' recives a plain string insted of Guid
		//we catch other.guid.toUpperCase undefined
		if (!other || !(other instanceof Guid)) return false;

		return this.guid.toUpperCase() === other.guid.toUpperCase();
	}

	/**
	 * Checks if the current GUID is less than the specified GUID.
	 * @param other - The GUID to compare.
	 */
	isLessThan(other: Guid): boolean {
		return Guid.compareSequential(this, other) === -1;
	}

	/**
	 * Checks if the current GUID is greater than the specified GUID.
	 * @param other - The GUID to compare.
	 */
	isGreaterThan(other: Guid): boolean {
		return Guid.compareSequential(this, other) === 1;
	}

	/**
	 * Returns the GUID as a string.
	 */
	toString(): string {
		return this.guid;
	}

	/**
	 * Returns the GUID as a string.
	 */
	toLowerCase(): string {
		return this.guid;
	}

	/**
	 * Returns the GUID as a string.
	 */
	toUpperCase(): string {
		return this.guid;
	}

	/**
	 * Returns the GUID as a JSON string.
	 */
	toJSON(): string {
		return this.guid;
	}
}
