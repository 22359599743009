export class QbTaxRate {
	public value: number;
	public name: string;

	deserialize(json: any): QbTaxRate {
		this.value = +json.value;
		this.name = json.name;
		return this;
	}
}

export class QbTaxCode {
	public value: string;
	public name: string;

	deserialize(json: any): QbTaxCode {
		this.value = json.value;
		this.name = json.name;
		return this;
	}
}

export class QbTerms {
	public id: number;
	public name: string;

	deserialize(json: any): QbTerms {
		this.id = json.id;
		this.name = json.name;
		return this;
	}
}
