<div class="period-selector" *ngIf="!isCompare">
	<label class="period-selector__label">
		{{'dg-periodselector.agreement_title' | transloco}}
	</label>
	<select *ngIf="isCustomerAgreements" class="period-selector__select" [(ngModel)]="agreement"
			(change)="onAgreementSelected()">
		<option *ngFor="let a of customerAgreements" [ngValue]="a">{{a.agreementName}}</option>
	</select>
	<select *ngIf="!isCustomerAgreements" class="period-selector__select" [(ngModel)]="agreement"
			(change)="onAgreementSelected()">
		<option *ngFor="let a of agreements" [ngValue]="a">{{a.agreementName}}</option>
	</select>
</div>

<div class="period-selector margin-l__20" *ngIf="!isCompare">
	<label class="period-selector__label">
		{{'dg-periodselector.period_title' | transloco}}
	</label>
	<select id="{{id}}" class="period-selector__select" [(ngModel)]="period"
			(change)="onPeriodSelected()">
		<option *ngFor="let p of periods" [ngValue]="p">{{p.label}}</option>
	</select>
</div>

<div class="period-selector margin-l__20" *ngIf="isCompare">
	<label class="period-selector__label">
		{{'dg-periodselector.periodToCompare_title' | transloco}}
	</label>
	<select id="{{id}}" class="period-selector__select" [(ngModel)]="period"
			(change)="onPeriodSelected()">
		<option *ngFor="let p of comparePeriods" [ngValue]="p">{{p.label}}</option>
	</select>
</div>
