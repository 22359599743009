import { Injectable } from '@angular/core';
import { HttpAuth } from 'core/auth';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseRepository } from './base-repository';
import { CacheService } from 'services/cache.service';
import { AppConfig } from 'core/app-config';
import { Guid } from 'domain/types';

@Injectable({
	providedIn: 'root'
})
export class PaymentsRepository extends BaseRepository {
	constructor(
		http: HttpAuth,
		config: AppConfig,
		cache: CacheService
	) {
		super(http, config, cache);
	}

	public createPayment(invoiceId: Guid, amount: number, date: Date): Observable<any> {
		const model = {
			amount: amount,
			date: date
		};
		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/invoices/${invoiceId}/payments`, model, { params: model })
			.pipe(
				map(res => {
					return res.body.data;
				}));
	}

	public deletePayment(invoiceId: Guid, id: Guid): Observable<void> {
		return this.deleteEntity(`/invoices/${invoiceId}/payments/${id}`)
			.pipe(map(() => null));
	}
}
