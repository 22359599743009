import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpAuth } from 'core/auth';
import { CacheService } from 'services/cache.service';
import { TaxRate, TaxRateType } from 'domain/entities/tax-rates-entities';
import { BaseRepository } from './base-repository';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { ToasterService } from 'core/toaster-service';
import { AppConfig } from 'core/app-config';
import { Guid } from 'domain/types';

@Injectable({
	providedIn: 'root'
})
export class TaxRateRepository extends BaseRepository {

	constructor(
		http: HttpAuth,
		config: AppConfig,
		cache: CacheService,
		private toasterService: ToasterService
	) {
		super(http, config, cache);
	}

	getTaxRates(): Observable<TaxRate[]> {
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/tax-rates2`)
			.pipe(
				map(res => res.body.data.map(x => new TaxRate().deserialize(x))),
				catchError(err => {
					return throwError(() => err);
				}));
	}

	getTaxRateById(taxRateId: Guid): Observable<TaxRate> {
		const key = `tax-rate-types-${taxRateId}`;

		if (this.cacheHasKey(key)) {
			return this.getCache<TaxRate[]>(key)
				.pipe(map(site => site.find(x => x.id.equals(taxRateId))));
		}

		return this.getTaxRates().pipe(map(site => site.find(x => x.id.equals(taxRateId))));
	}

	public addTaxRate(rate: TaxRate): Observable<Guid> {
		return this.createEntity(`/tax-rates2`, rate)
			.pipe(
				tap(() => { this.cache.removeKey('taxRateType'); }),
				map(s => s.id),
				catchError(err => {
					return throwError(() => err);
				}));
	}

	public updateTaxRate(rate: TaxRate): Observable<any> {
		return this.updateEntity(`/tax-rates2/${rate.id}`, rate)
			.pipe(
				tap(() => { this.cache.removeKey('taxRateType'); }),
				catchError(err => {
					return throwError(() => err);
				}));
	}

	public deleteTaxRate(ids: Guid[]): Observable<HttpResponse<void>> {
		return this.deleteEntities(`/tax-rates2/delete`, ids);
	}

	// TAX RATE TYPES

	getTaxRateType(): Observable<TaxRateType[]> {
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/tax-rate-types`)
			.pipe(
				map(res => {
					const taxRateType = res.body.data.map(x => new TaxRateType().deserialize(x));
					return taxRateType;
				}),
				catchError(err => {
					return throwError(() => err);
				}));
	}

	public addTaxRateType(rateType: TaxRateType): Observable<Guid> {
		return this.createEntity(`/tax-rate-types`, rateType)
			.pipe(
				map(s => s.id),
				catchError(err => {
					return throwError(() => err);
				}));
	}

	public updateTaxRateType(rateType: TaxRateType): Observable<any> {
		return this.updateEntity(`/tax-rate-types/${rateType.id}`, rateType)
			.pipe(
				catchError(err => {
					return throwError(() => err);
				}));
	}

	public deleteTaxRateType(id: number): Observable<any> {
		return this.deleteEntity(`/tax-rate-types/${id}`)
			.pipe(
				catchError(err => {
					return throwError(() => err);
				}));
	}

}
