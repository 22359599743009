import { Injectable, EventEmitter } from '@angular/core';
import { Router, ActivatedRouteSnapshot, Event, NavigationEnd } from '@angular/router';

import { Breadcrumb } from './breadcrumb';

@Injectable()
export class DgCrumbsService {
	breadcrumbChanged = new EventEmitter<Breadcrumb[]>(false);

	private breadcrumbs = new Array<Breadcrumb>();

	constructor(private router: Router) {
		this.router.events.subscribe((routeEvent) => { this.onRouteEvent(routeEvent); });
	}

	public changeBreadcrumb(route: ActivatedRouteSnapshot, name: string): void {
		const rootUrl = this.createRootUrl(route);
		const breadcrumb = this.breadcrumbs.find(bc => bc.url === rootUrl);

		if (!breadcrumb) { return; }

		breadcrumb.displayName = name;

		this.breadcrumbChanged.emit(this.breadcrumbs);
	}

	private onRouteEvent(routeEvent: Event): void {
		if (!(routeEvent instanceof NavigationEnd)) { return; }

		let route = this.router.routerState.root.snapshot;
		let url = '';

		var breadCrumbIndex = 0;
		var newCrumbs = <Breadcrumb[]>[];

		while (route.firstChild != null) {
			route = route.firstChild;

			if (route.routeConfig === null) { continue; }
			if (!route.routeConfig.path) { continue; }

			url += `/${this.createUrl(route)}`;

			if (!route.data['breadcrumb']) { continue; }

			var newCrumb = this.createBreadcrumb(route, url);

			if (breadCrumbIndex < this.breadcrumbs.length) {
				var existing = this.breadcrumbs[breadCrumbIndex++];

				if (existing && existing.route === route.routeConfig) {
					newCrumb.displayName = existing.displayName;
				}
			}

			newCrumbs.push(newCrumb);
		}

		this.breadcrumbs = newCrumbs;
		this.breadcrumbChanged.emit(this.breadcrumbs);
	}

	private createBreadcrumb(route: ActivatedRouteSnapshot, url: string): Breadcrumb {
		return {
			displayName: route.data['breadcrumb'],
			terminal: this.isTerminal(route),
			url: url,
			route: route.routeConfig
		};
	}

	private isTerminal(route: ActivatedRouteSnapshot): boolean {
		return route.firstChild === null
            || route.firstChild.routeConfig === null
            || !route.firstChild.routeConfig.path;
	}

	private createUrl(route: ActivatedRouteSnapshot): string {
		return route.url.map(s => s.toString()).join('/');
	}

	private createRootUrl(route: ActivatedRouteSnapshot): string {
		let url = '';
		let next = route.root;

		while (next.firstChild !== null) {
			next = next.firstChild;

			if (next.routeConfig === null) { continue; }
			if (!next.routeConfig.path) { continue; }

			url += `/${this.createUrl(next)}`;

			if (next === route) { break; }
		}

		return url;
	}
}
