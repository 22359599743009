import { HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpAuth } from 'core/auth';
import { ToasterService } from 'core/toaster-service';
import { ChargeTypes, ProductAdjustCharge, ProductRatePlan, ProductRatePlanCharge, ScopeTypes } from 'domain/entities';
import { Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfig } from 'core/app-config';
import { Guid, ApiData } from 'domain/types';
import { BaseRepository } from './base-repository';
import { CacheService } from 'services/cache.service';

@Injectable({
	providedIn: 'root'
})
export class ChargeRepository extends BaseRepository {

	constructor(
		http: HttpAuth,
		config: AppConfig,
		cache: CacheService,
		private readonly toasterService: ToasterService
	) {

		super(http, config, cache);
	}

	getAllChargeType(): Observable<ChargeTypes[]> {

		const cacheKey = 'charge';

		if (this.cache.get(cacheKey)) {
			return of(this.cache.get(cacheKey));
		}

		const observable =
			this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/charges/model`)
				.pipe(
					map(res => {
						const charge = res.body.data.map(x => new ChargeTypes().deserialize(x));
						this.cache.set(cacheKey, charge);
						return charge;
					}));

		this.cache.set$(cacheKey, observable);

		return observable;
	}

	getAllScopeType(): Observable<ScopeTypes[]> {

		const cacheKey = 'scopeTypes';

		if (this.cache.get(cacheKey)) {
			return of(this.cache.get(cacheKey));
		}

		const observable =
			this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/charges/scope-types`)
				.pipe(
					map(res => {
						const charge = res.body.data.map(x => new ScopeTypes().deserialize(x));
						this.cache.set(cacheKey, charge);
						return charge;
					}));

		this.cache.set$(cacheKey, observable);

		return observable;
	}

	productRatePlanChargesAdjust(productId: Guid, chargeId: Guid, charge: ProductAdjustCharge, isAdjustment: boolean): Observable<{ chargeId: Guid; numberOfAdjustmentLines: number; }> {
		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/products/${productId}/charges/${chargeId}/adjust`, charge)
			.pipe(map(res => {

				if (res.body?.data.numberOfAdjustmentLines !== 0 && isAdjustment) {
					this.toasterService.success('toaster-service.toast-error-adjustment-created_msg');
				}

				return { chargeId: undefined, numberOfAdjustmentLines: 0 };
			}));
	}

	productRatePlanChargesEndDate(productId: Guid, chargeId: Guid, dateEnd: string, isAdjustment: boolean): Observable<boolean> {
		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/products/${productId}/charges/${chargeId}/end-date`, { date: dateEnd })
			.pipe(map(res => {

				if (res.body?.data.numberOfAdjustmentLines !== 0 && isAdjustment) {
					this.toasterService.success('toaster-service.toast-error-adjustment-created_msg');
				}

				return true;
			}));
	}

	productRatePlanChargesGet(productId: Guid): Observable<ProductRatePlanCharge[]> {
		const observable =
			this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/products/${productId}/charges`)
				.pipe(
					map(res => {
						const charge = res.body.data.map(x => new ProductRatePlanCharge().deserialize(x));

						// set every charge units current on first position
						charge.forEach((c) => {
							if (!c.units) return;
							c.origUnits = [...c.units].sort((a, b) => {
								const dateA: any = new Date(a.dateStart);
								const dateB: any = new Date(b.dateStart);
								return dateA - dateB;
							});

							c.units.forEach((u, i) => {
								if (u.current) {
									c.units.splice(i, 1);
									c.units.unshift(u);
								}
							});
						});

						return charge;
					}));

		return observable;
	}

	productRatePlanChargesAdd(productId: Guid, charge: ProductRatePlanCharge): Observable<HttpResponse<void>> {
		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/products/${productId}/charges`, charge);
	}

	productRatePlanChargesTemplateAdd(productId: Guid, charge: ProductRatePlanCharge): Observable<Guid> {
		return this.http.post<ApiData<Guid>>(`${this.config.apiUrl}${this.config.apiVersion}/products/${productId}/templates/charges`, charge)
			.pipe(map(res => res.body.data));
	}

	productRatePlanGet(productId: Guid): Observable<ProductRatePlan[]> {

		const observable =
			this.http.get<ApiData<ProductRatePlan[]>>(`${this.config.apiUrl}${this.config.apiVersion}/products/${productId}/charges/plan`)
				.pipe(map(res => res.body.data.map(x => new ProductRatePlan().deserialize(x))));

		return observable;
	}

	async getProductRatePlans(customerId: Guid, isAllRatePlans: boolean, destroy$?: Subject<void>): Promise<ProductRatePlan[]> {
		let params = new HttpParams();

		if(!customerId.isEmpty() && !isAllRatePlans) {
			params = params.set('customerId', customerId.toString());
		}

		const res = await this.http.promise(destroy$).get<ApiData<ProductRatePlan[]>>(`${this.config.apiUrl}${this.config.apiVersion}/charges/plans`, { params });
		const productRatePlans = res.body?.data.map(x => new ProductRatePlan().deserialize(x));

		return productRatePlans;
	}

	productRatePlanAdd(productId: Guid, plan: ProductRatePlan): Observable<Guid> {

		const observable =
			this.http.post<ApiData<Guid>>(`${this.config.apiUrl}${this.config.apiVersion}/products/${productId}/charges/plan`, plan)
				.pipe(map(res => res.body.data));

		return observable;
	}

	productRatePlanChargesTemplateUpdate(productId: Guid, charge: ProductRatePlanCharge): Observable<HttpResponse<void>> {
		return this.http.put(`${this.config.apiUrl}${this.config.apiVersion}/products/${productId}/templates/charges/${charge.id}`, null, charge);
	}

	productRatePlanChargesUpdate(productId: Guid, charge: ProductRatePlanCharge, isHistorical: boolean = false): Observable<HttpResponse<void>> {
		let data: ProductRatePlanCharge | object = charge;

		if (isHistorical) {
			data = {
				historical: true,
				name: charge.name,
				description: charge.description,
				id: charge.id
			};
		}

		const observable =
			this.http.put(`${this.config.apiUrl}${this.config.apiVersion}/products/${productId}/charges/${charge.id}`, null, data);

		return observable;
	}

	productRatePlanChargesDelete(productId: Guid, charge: ProductRatePlanCharge): Observable<HttpResponse<void>> {

		const observable =
			this.http.delete(`${this.config.apiUrl}${this.config.apiVersion}/products/${productId}/charges/${charge.id}`);

		return observable;
	}

	kitProductRatePlanChargesDelete(productId: Guid, kitId: Guid, charge: ProductRatePlanCharge): Observable<HttpResponse<void>> {
		const observable =
			this.http.delete(`${this.config.apiUrl}${this.config.apiVersion}/product-kits/${kitId}/products/${productId}/charges/${charge.id}`);

		return observable;
	}

	async customerProducKitChargesAdd(customerId: Guid, proxyProductId: Guid, productId: Guid, charge: ProductRatePlanCharge, destroy$?: Subject<void>): Promise<Guid> {
		const res = await this.http.promise(destroy$).post<ApiData<Guid>>(`${this.config.apiUrl}${this.config.apiVersion}/customers/${customerId}/kits/${proxyProductId}/products/${productId}/charges`, charge);
		return res.body?.data;
	}

	async customerProducKitChargesUpdate(customerId: Guid, proxyProductId: Guid, productId: Guid, charge: ProductRatePlanCharge, destroy$?: Subject<void>): Promise<Guid>  {
		const res = await this.http.promise(destroy$).put(`${this.config.apiUrl}${this.config.apiVersion}/customers/${customerId}/kits/${proxyProductId}/products/${productId}/charges/${charge.id}`, charge);
		return res.body;
	}

	async customerProductKitChargesDelete(customerId: Guid, proxyProductId: Guid, productId: Guid, chargeId: Guid, destroy$?: Subject<void>): Promise<HttpResponse<void>>  {
		const res = await this.http.promise(destroy$).delete(`${this.config.apiUrl}${this.config.apiVersion}/customers/${customerId}/kits/${proxyProductId}/products/${productId}/charges/${chargeId}`);
		return res.body;
	}
}
