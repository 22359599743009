import { Injectable } from '@angular/core';
import { HttpAuth } from 'core/auth';
import { ApiData, ApiOperation, Guid } from 'domain/types';
import {
	ExportMultilineModel, Invoice
} from 'domain/entities';
import { InvoiceActionsService } from 'shared/modules/invoice-actions';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfig } from 'core/app-config';

export interface IQBDModel {
	invoices: Guid[];
	tag: string;
	selectedPriceCalculationTypeId: number;
	deleteExisting: boolean;
	taxForState: boolean;
	taxMultiline: boolean;
	combineSurchargesWithTaxes: boolean;
	uniteSomeProductsInOne: boolean;
	surchargeSeparately: boolean;
	transactionResponses: ITransactionMapping[];
	invoiceLinesResponses: IInvoiceLineMapping[];
	surchargeResponses: ISurchargeMapping[];
	surchargeSeparatelyResponses: ISurchargeMapping[];
	surchargesWithTaxesResponses: ISurchargeWithTaxMapping[];
	totalTaxesResponses: ITaxMapping[];
	taxesLinesResponses: ITaxLineMapping[];
	taxesLinesStateResponses: ITaxLineStateMapping[];
}

export interface IQBDPrepareResponse {
	invoiceLinesResponses: IInvoiceLineMapping[];
	invoices: Guid[];
	emptyInvoices: Guid[];
	surchargeResponses: ISurchargeMapping[];
	surchargeSeparatelyResponses: ISurchargeMapping[];
	surchargesWithTaxesResponses: ISurchargeWithTaxMapping[];
	taxesLinesResponses: ITaxLineMapping[];
	taxesLinesStateResponses: ITaxLineStateMapping[];
	totalTaxesResponses: ITaxMapping[];
	transactionResponses: ITransactionMapping[];
}

export interface IQBDProduct {
	fullName: string;
	listID: string;
}

export interface QBDWizardSettings {
	'combine-surcharges-with-taxes': boolean;
	'price-calculation-type': number;
	'split-taxes-by-state': boolean;
	'surcharge-separately': boolean;
	'tax-multiline': boolean;
	'unite-some-products-in-one': boolean;
}

interface IInvoiceLineMapping {
	serviceId: number;
	siteId: number;
	recordType: number;
	groupName: string;
	description: string;
	selectedQbdProductId: string;
}

interface ISurchargeWithTaxMapping {
	combineName: string;
	selectedQbdProductId: string;
}

interface ISurchargeMapping {
	surchargeName: string;
	selectedQbdProductId: string;
}

interface ITransactionMapping {
	name: string;
	selectedQbdProductId: string;
}

interface ITaxMapping {
	taxName: string;
	taxInclusive: boolean;
	selectedQbdProductId: string;
}

interface ITaxLineMapping {
	taxTypeDesc: string;
	taxInclusive: boolean;
	selectedQbdProductId: string;
}

interface ITaxLineStateMapping {
	taxTypeDesc: string;
	stateCode: string;
	taxInclusive: boolean;
	selectedQbdProductId: string;
}

@Injectable({
	providedIn: 'root'
})
export class QBExportInvoiceRepository {
	constructor(
		private readonly http: HttpAuth,
		private readonly invoiceService: InvoiceActionsService,
		private readonly config: AppConfig
	) {
	}

	public GroupingTypePost(
		groupingType: number,
		invoices: Invoice[],
		isForce: boolean,
		excluded?: Invoice[],
		filterParams?: any
	): Observable<any> {
		const model = {
			groupingType: groupingType,
			invoices: invoices.map(w => w.id),
			excludedInvoices: excluded.map(x => x.id),
			selectedAll: this.invoiceService.selectedAll,
			force: isForce
		};
		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/quickbooks/invoices-multiline/grouping-type`, model, { params: filterParams })
			.pipe(
				map(res => res.body));
	}

	public Export(model: ExportMultilineModel): Observable<ApiOperation> {
		return this.http.post<ApiData<ApiOperation>>(`${this.config.apiUrl}${this.config.apiVersion}/quickbooks/invoices-multiline/export`, model)
			.pipe(map(res => res.body.data));
	}

	public exportQBDprepare(
		invoices: Invoice[],
		isForce: boolean,
		excluded?: Invoice[],
		filterParams?: any
	): Observable<IQBDPrepareResponse> {

		const model = {
			invoices: invoices.map(w => w.id),
			excludedInvoices: excluded.map(x => x.id),
			selectedAll: this.invoiceService.selectedAll,
			force: isForce
		};

		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/qbd/invoices/export/data-prepare`, model, { params: filterParams })
			.pipe(map(res => res.body.data));
	}

	public exportQBD(exportData: IQBDModel): Observable<ApiOperation> {
		return this.http.post<ApiData<ApiOperation>>(`${this.config.apiUrl}${this.config.apiVersion}/qbd/invoices/export`, exportData)
			.pipe(map(res => res.body.data));
	}

	public getQBDProducts(): Observable<IQBDProduct[]> {
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/qbd/products`)
			.pipe(map(res => res.body.data));
	}

	public getQBDWizardSettings(): Observable<QBDWizardSettings> {
		return this.http.get<ApiData<QBDWizardSettings>>(`${this.config.apiUrl}${this.config.apiVersion}/qbd/invoices/export/wizard`)
			.pipe(map(res => res.body.data));
	}
}
