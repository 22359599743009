import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'dg-dd-button[item1_title][item2_title]',
	templateUrl: './dg-dd-button.component.html',
	styleUrls: ['./dg-dd-button.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DgDdButtonComponent {
	@Input() title: string;
	@Input() item1_title: string;
	@Input() item2_title: string;
	@Input() isTransparent: boolean;
	@Input() isFirstItemDisabled: boolean;
	@Input() isSecondItemDisabled: boolean;
	public isOpen: boolean;

	@Output() itemClicked = new EventEmitter<boolean>();

	public onAddButtonClicked(item: boolean): void {
		this.itemClicked.emit(item);
	}

	public toggleDropDown(): void {
		this.isOpen = !this.isOpen;
	}
}
