import { APP_INITIALIZER, ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { ToastrModule } from 'ngx-toastr';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';

import { GlobalErrorHandler } from './global-error.handler';
import { ToasterService } from './toaster-service';
import { AppInitializer } from './app-initializer';
import { AppConfig } from './app-config';
import { BackgroundPollingInterceptor, OpenReplayInterceptor, RequestInterceptor, ResponseInterceptor } from './interceptors';
import { ReplaySessionService } from 'services';
import { AuthService, HttpAuth } from './auth';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		HttpClientModule,
		NgxPermissionsModule.forRoot(),
		ToastrModule.forRoot({
			timeOut: 10000,
			extendedTimeOut: 3000,
			positionClass: 'toast-top-right',
			preventDuplicates: true,
			countDuplicates: true,
			resetTimeoutOnDuplicate: true,
			includeTitleDuplicates: true
		}),
	],
	exports: [],
	providers: [
		AppConfig,
		AppInitializer,
		HttpAuth,
		AuthService,
		{ provide: ErrorHandler, useClass: GlobalErrorHandler },
		ToasterService,
		{	//appinnitialazer
			provide: APP_INITIALIZER,
			useFactory: (appInitializer: AppInitializer) => () => appInitializer.init(),
			deps: [AppInitializer, NgxPermissionsService],
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: RequestInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: BackgroundPollingInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ResponseInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: OpenReplayInterceptor,
			deps: [ReplaySessionService],
			multi: true
		}
	],
})
export class CoreModule {
	constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
		if (parentModule) {
			throw new Error(
				'CoreModule is already loaded. Import it in the AppModule only.'
			);
		}
	}
}
