namespace Date_Range {
	export const MIN_DATE: Date = new Date(-8640000000000000);
	export const MAX_DATE: Date = new Date(8640000000000000);
}

export namespace Timzones {
	export var DEFAULT_TIMEZONE = 'Pacific/Auckland';
}


export class Constants {

	public static get minDate(): Date {
		return Date_Range.MIN_DATE;
	}

	public static get maxDate(): Date {
		return Date_Range.MAX_DATE;
	}

	public static get BundlingTypeName(): object {
		return {
			1: 'Quantity Bundling',
			3: 'MUC',
			5: 'Volume Bundling'
		};
	}

	public static get DefaultTimzone(): string {
		return Timzones.DEFAULT_TIMEZONE;
	}

}
