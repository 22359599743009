import { AutoBillingGenerateInvoiceTaskType, AutobillingTaskType } from 'pages/+auto-billing/models';
import { Guid } from 'domain/types';

export interface AutoBillingTask {
	id: number;
	scheduleId: number;
	resellerId: number;
	name: AutobillingTaskType;
	type: AutoBillingGenerateInvoiceTaskType;
	whenTypeId: number;
	startInMinutes: number;
	isAgreementsBillingStartDay: boolean;
	agreementsBillingStartDay: number;
	invoiceDateType: number;
	invoiceDay: number;
	dayAfterOverdue: string;
	emailTemplateId: Guid;
	chargeTypeId: AutoBillingChargeOption;
	isIncludeProRataCharges: boolean;
	isAutomaticSuppress: boolean;
	statusLabel: string;
	params: {
		AdditionFilters?: {
			BillingStartDayEqNow: boolean;
			PlusDays: number;
		};
		GenerateOptions?: {
			InvoiceDate: number;
			Day: number;
			IncludeLastMonth: boolean;
			SuppressZeroInvoice: boolean;
		};
		NotificationOptions?: {
			DayAfterOverdue: number;
			EmailTemplateId: Guid;
		};
		ChargeOptions?: AutoBillingChargeOption;
	};
	hasErrors?: boolean;
	startTime?: string;
	endTime?: string;
	statusId?: number;
	scheduleTaskId?: number;
	messages?: AutoBillingRunTaskMessage[];
}

export interface AutoBillingRunTaskMessage {
	id: string;
	isError: false;
	message: string;
	time: string;
}

export enum AutoBillingChargeOption {
	Immediately = 1,
	DueDate = 2
}
