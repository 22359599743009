import { Component, SimpleChanges, Input, OnChanges } from '@angular/core';
import { Job } from 'domain/entities';

@Component({
	selector: 'dg-operation-go-button',
	templateUrl: './operation-goto-button.component.html',
	styleUrls: ['./operation-goto-button.component.sass']
})
export class OperationGotoButtonComponent implements OnChanges {

	public redirectUrl: string;
	@Input() job: Job;

	constructor() { }

	ngOnChanges(changes: SimpleChanges): void {

		const job = changes.job.currentValue as Job;

		if (job) {
			this.detectRedirectUrl(job);
		}
	}

	private detectRedirectUrl(job: Job): void {

		if ((job.jobType !== 6) && (job.tag?.url)) {
			this.redirectUrl = this.job.tag.url;

			if (job.tag.url.indexOf('?') > -1) {
				this.redirectUrl = this.job.tag.url.split('?')[0]; //remove query params
			}

		} else {
			switch (job.jobType) {
				case 23:
				case 15:
				case 33:
				case 38:
				case 39:
					this.redirectUrl = '/customers';
					break;
				case 6:
				case 17:
				case 18:
				case 19:
				case 24:
					this.redirectUrl = '/files';
					break;
				case 8:
					this.redirectUrl = '/transactions';
					break;
				default:
					this.redirectUrl = '/dashboard';
			}
		}
	}
}
