import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { HttpAuth } from 'core/auth';
import { ApiData, Guid } from 'domain/types';
import { ResellerUser } from 'domain/entities';
import { map } from 'rxjs/operators';
import { AppConfig } from 'core/app-config';
import { BaseRepository } from './base-repository';
import { CacheService } from 'services/cache.service';

@Injectable({
	providedIn: 'root'
})
export class ResellerUsersRepository extends BaseRepository {

	constructor(
		http: HttpAuth,
		config: AppConfig,
		cache: CacheService
	) {
		super(http, config, cache);
	}

	getAllResellerUsers$(): Observable<ResellerUser[]> {

		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/reseller-users`)
			.pipe(map(res => res.body.data.map(x => new ResellerUser().deserialize(x))));
	}

	async getAllResellerUsers(destroy?: Subject<void>): Promise<ResellerUser[]> {
		const res = await this.http.promise(destroy).get<ApiData<ResellerUser[]>>(`${this.config.apiUrl}${this.config.apiVersion}/reseller-users`);
		return res.body.data.map(x => new ResellerUser().deserialize(x));
	}

	async getResellerUserById(userId: Guid, destroy?: Subject<void>): Promise<ResellerUser> {
		const users = await this.getAllResellerUsers(destroy);
		return users.find(x => x.id.equals(userId));
	}

	public updateResellerUsers(user: ResellerUser): Observable<any> {

		return this.updateEntity(`/reseller-users/${user.id}`, user);
	}

	public addResellerUsers(user: ResellerUser): Observable<Guid> {
		return this.createEntity(`/reseller-users`, user)
			.pipe(map(s => s.id));
	}

	public deleteResellerUser(id: Guid): Observable<any> {
		return this.deleteEntity(`/reseller-users/${id}`);
	}

	public resetUser2fa(id: Guid): Observable<any> {
		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/reseller-users/${id}/2fa-reset`, null)
			.pipe(map(res => res));
	}
}
