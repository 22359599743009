import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule } from '@angular/router';

import { Globals } from 'tools/globals';

import { AppComponent } from 'app.component';

import { DgPagesRouting } from 'pages/pages-routing';

import { DgAlertModule } from 'tools/alert';
import { DgDialogModule } from 'tools/dialog';
import { CoreModule } from 'core/core.module';
import { SharedModule } from 'shared/shared.module';

import { HeaderComponent } from 'layout/header/header.component';
import { MainLayoutComponent } from 'layout/main-layout/main-layout.component';
import { NotificationsComponent } from 'layout/notifications/notifications.component';

import * as Resolvers from 'resolvers/Resolvers';

import { InvoiceExportMultilineComponent } from 'pages/global/invoice-export-multiline/invoice-export-multiline.component';
import { InvoiceExportComponent } from 'pages/global/invoice-export/invoice-export.component';
import { QbInvoiceExportMultilineComponent } from 'pages/global/qb-invoice-export-multiline/qb-invoice-export-multiline.component';
import { XeroTenantsComponent } from 'pages/global//xero-tenants/xero-tenants.component';
import { XeroExportComponent } from 'pages/global//xero-export/xero-export.component';
import { CwInvoiceNumberComponent } from 'pages/global//cw-invoice-number/cw-invoice-number.component';
import { AutotaskExportComponent } from 'pages/global//autotask-export/autotask-export.component';
import { DgInvoiceNumberComponent } from 'pages/global/at-invoice-number/at-invoice-number.component';
import { QbDesktopExportComponent } from 'pages/global//qb-desktop-export/qb-desktop-export.component';
import { CwReturnTaxesComponent } from 'pages/global//cw-return-taxes/cw-return-taxes.component';
import { HaloExportComponent } from 'pages/global//halo-export/halo-export.component';
import { CreditNoteComponent } from 'pages/global//credit-note/credit-note.component';
import { HaloChargesExportComponent } from 'pages/global/halo-charges-export/halo-charges-export.component';

@NgModule({
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		CoreModule,
		SharedModule,
		RouterModule.forRoot(DgPagesRouting, {
			preloadingStrategy: PreloadAllModules,
			paramsInheritanceStrategy: 'always'
		}),
		DgAlertModule,
		DgDialogModule
	],
	declarations: [
		AppComponent,

		MainLayoutComponent,
		HeaderComponent,
		NotificationsComponent,

		InvoiceExportComponent,
		InvoiceExportMultilineComponent,
		QbInvoiceExportMultilineComponent,
		XeroTenantsComponent,
		XeroExportComponent,
		CwInvoiceNumberComponent,
		AutotaskExportComponent,
		DgInvoiceNumberComponent,
		QbDesktopExportComponent,
		CwReturnTaxesComponent,
		CreditNoteComponent,
		HaloExportComponent,
		HaloChargesExportComponent,
	],
	providers: [
		Globals,
		Resolvers.OperationResolver,
		Resolvers.ResellerUsersRegistrationResolver,
		Resolvers.AgreementTypesResolver,
		Resolvers.VendorsResolver,
		Resolvers.VendorsResolver,
		Resolvers.GlCodesResolver,
		Resolvers.AgreementWizardSettingsResolver,
		Resolvers.MultilineWizardSettingsResolver,
		Resolvers.DisplayUnitsResolver,
		Resolvers.UsageUnitsResolver,
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
