<div class="page-tile__body page-tile__body--400 page-tile__body--hidden-none">
	<div *ngIf="!xeroTenants.length && !isError" class="app-spinner-cw"></div>
	<div class="page-tile__title">
		<span>
			{{'xero-tenants.tenants_title' | transloco}}
		</span>
		<span>{{'xero-tenants.tenants_msg' | transloco}}</span>
	</div>
	<div class="page-tile__group">
		<ng-select labelForId="ddXeroTenants" bindLabel="name" bindValue="value" name="xeroTenants" [items]="xeroTenants"
			[placeholder]="'xero-tenants.tenants-select_placeholder' | transloco" [(ngModel)]="tenantId">
		</ng-select>
	</div>

	<div class="form__bottom">
		<button type="button" class="btn width-100px" [title]="'xero-tenants.tenants-btn_hint' | transloco" (click)="onTenantSelect()">
			{{'xero-tenants.tenants-btn_label' | transloco}}
		</button>
	</div>
</div>
