import { Serializable } from 'domain/models';
import { Guid } from 'domain/types';

export class ExtraFieldValue implements Serializable<ExtraFieldValue> {
	public id: Guid;
	public value: string;

	deserialize(json: any): ExtraFieldValue {

		this.id = json.id;
		this.value = json.value;

		return this;
	}

	clone(): ExtraFieldValue {
		const clone = Object.assign(new ExtraFieldValue(), this);
		return clone;
	}

	restore(src: ExtraFieldValue): void {
		Object.assign(this, src);
	}
}

export class AccountManager implements Serializable<AccountManager> {
	public id: Guid;
	public name: string;
	public commissionRate: number;

	deserialize(json: any): AccountManager {

		this.id = json.id;
		this.name = json.name;
		this.commissionRate = json.commissionRate;

		return this;
	}

	clone(): AccountManager {
		const clone = Object.assign(new AccountManager(), this);
		return clone;
	}
}
