import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
	selector: '[dgFourDigitDecimaNumber]'
})
export class DgFourDigitDecimaNumberDirective {
	// Allow decimal numbers and positive values
	private regex = new RegExp(/^\d*\.?\d{0,4}$/g);
	// Allow key codes for special events. Reflect :
	// Backspace, tab, end, home
	private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];

	constructor(private el: ElementRef) {
	}
	@HostListener('keydown', ['$event'])
	onKeyDown(event: KeyboardEvent) {
		// Allow Backspace, tab, end, and home keys
		if (this.specialKeys.indexOf(event.key) !== -1) {
			return;
		}
		const current: string = this.el.nativeElement.value;
		const position = this.el.nativeElement.selectionStart;
		const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
		if (next && !String(next).match(this.regex)) {
			event.preventDefault();
		}
	}
}
