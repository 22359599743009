<div [className]="'datepicker__smart ' + customContainerCssClass">
	<label class="datepicker__smart-label">{{label ? label : 'dg-smart-datepicker.label_title' | transloco}}
		<span>:</span>
	</label>
	<ng-select [clearable]="false" [searchable]="false" [disabled]="isDisabled" [(ngModel)]="selectedRange"
		(change)="onDateChanged($event)">
		<ng-option value="billingDate">{{'dg-smart-datepicker.period-opts_billingDate' | transloco}}</ng-option>
		<ng-option value="currMonth">{{'dg-smart-datepicker.period-opts_currMonth' | transloco}}</ng-option>
		<ng-option value="prevMonth">{{'dg-smart-datepicker.period-opts_prevMonth' | transloco}}</ng-option>
		<ng-option value="last7">{{'dg-smart-datepicker.period-opts_last7' | transloco}}</ng-option>
		<ng-option value="last30">{{'dg-smart-datepicker.period-opts_last30' | transloco}}</ng-option>
		<ng-option value="picker">{{'dg-smart-datepicker.period-opts_picker' | transloco}}</ng-option>
		<ng-template ng-option-tmp let-item="item">
			<span *ngIf="item === 'billingDate'" class="datepicker__smart-span">
				{{'dg-smart-datepicker.period-opts_billingDate' | transloco}}
				<span>{{dateLabels('billingStartLabel')}} - {{dateLabels('billingEndLabel')}}</span></span>
			<span *ngIf="item === 'currMonth'" class="datepicker__smart-span">
				{{'dg-smart-datepicker.period-opts_currMonth' | transloco}}
				<span>{{dateLabels('currentMYLabel')}}</span></span>
			<span *ngIf="item === 'prevMonth'" class="datepicker__smart-span">
				{{'dg-smart-datepicker.period-opts_prevMonth' | transloco}}
				<span>{{dateLabels('previousMYLabel')}}</span></span>
			<span *ngIf="item === 'last7'" class="datepicker__smart-span">
				{{'dg-smart-datepicker.period-opts_last7' | transloco}}
				<span>{{dateLabels('last7MDLabel')}} - {{dateLabels('currentMDLabel')}}</span></span>
			<span *ngIf="item === 'last30'" class="datepicker__smart-span">
				{{'dg-smart-datepicker.period-opts_last30' | transloco}}
				<span>{{dateLabels('last30MDLabel')}} - {{dateLabels('currentMDLabel')}}</span></span>
			<span *ngIf="item === 'picker'" class="datepicker__smart-span ngx-daterangepicker-action"
				(click)="openDatepicker($event)">{{'dg-smart-datepicker.period-opts_picker' | transloco}} </span>
		</ng-template>
	</ng-select>
</div>
<input class="range-picker" style="display: none" type="text" ngxDaterangepickerMd [showDropdowns]="true"
	[locale]="locale" [showCancel]="true" [(ngModel)]="selected" (ngModelChange)="ngModelChange($event)" />
