import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
	selector: 'dg-child-cell-view',
	template: ` <button *ngIf="params.data.rateCardTypeDesc === 'RateCard'" type="button" class="users__row-action"
					(click)="onView(params.data.rateCardId, params.data.rateCardTypeDesc)">
					<i class="fa fa-eye" style="color: #444;" aria-hidden="true"></i>
					{{'ag-grid-cell-view.viewRateCard' | transloco}}
				</button>
				<button *ngIf="params.data.rateCardTypeDesc === 'PriceBook'" type="button" class="users__row-action"
					(click)="onView(params.data.rateCardId, params.data.rateCardTypeDesc)">
					<i class="fa fa-eye" style="color: #444;" aria-hidden="true"></i>
					{{'ag-grid-cell-view.viewPriceBook' | transloco}}
				</button>

				<button *ngIf="params.data.active && params.data.displayName" type="button" class="users__row-action"
					(click)="onView(params.data.id, params.data.active)">
					<i class="fa fa-eye" style="color: #444;" aria-hidden="true"></i>
					{{'ag-grid-cell-view.viewAs' | transloco}}
				</button>`
})
export class ChildLinkViewRendererComponent implements ICellRendererAngularComp {
	public params: ICellRendererParams;

	agInit(params: ICellRendererParams): void {
		this.params = params;
	}

	public onView(id: number, value: number): void {
		this.params.context.componentParent.methodFromParentView(id, value);
	}

	refresh(): boolean {
		return false;
	}
}
