import { Injectable } from '@angular/core';
import { HttpAuth } from 'core/auth';
import { ToasterService } from 'core/toaster-service';
import { ApiData, Guid } from 'domain/types';
import {
	AutotaskExportMultilineModel, AutotaskInvoiceLines, AutotaskInvoiceSyncMapping, AutotaskMapping, AutotaskSurcharge, AutotaskSurchargesWithTaxes, AutotaskTaxesLines, AutotaskTaxesLinesState, AutotaskTotalTaxes, AutotaskTransaction, AutotaskWizardSettings, ExportAutotaskSyncInvoices, Invoice
} from 'domain/entities';
import { InvoiceActionsService } from 'shared/modules/invoice-actions';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppConfig } from 'core/app-config';

@Injectable({
	providedIn: 'root'
})
export class AutotaskExportRepository {
	constructor(
		private readonly http: HttpAuth,
		private readonly invoiceService: InvoiceActionsService,
		private readonly toasterService: ToasterService,
		private readonly config: AppConfig
	) {

	}

	public contractsTypesPost(
		selectedContractType: number,
		invoices: Guid[],
		isForce: boolean,
		excluded?: Invoice[],
		filterParams?: any): Observable<AutotaskMapping> {

		const model = {
			selectedContractType: selectedContractType,
			invoices: invoices,
			excludedInvoices: excluded.map(x => x.id),
			selectedAll: this.invoiceService.selectedAll,
			force: isForce
		};
		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/autotask/invoices-multiline/contracts-prepare`,
			model, { params: filterParams })
			.pipe(
				map(res => {
					return new AutotaskMapping().deserialize(res.body.data);
				}));
	}

	public contractsGroupingTypePost(groupType: number, invoices: number[]): Observable<any> {
		const model = {
			groupType: groupType,
			invoices: invoices
		};
		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/autotask/invoices-multiline/data-prepare`, model, null)
			.pipe(
				map(res => res.body.data),
				map(data => {
					const invoiceLinesResponses = data.invoiceLinesResponses ?
						data.invoiceLinesResponses.map(x => new AutotaskInvoiceLines().deserialize(x)) : null;
					const surchargeResponses = data.surchargeResponses.map(x => new AutotaskSurcharge().deserialize(x));
					const surchargesWithTaxesResponses = data.surchargesWithTaxesResponses.map(x => new AutotaskSurchargesWithTaxes().deserialize(x));
					const taxesLinesResponses = data.taxesLinesResponses.map(x => new AutotaskTaxesLines().deserialize(x));
					const taxesLinesStateResponses = data.taxesLinesStateResponses.map(x => new AutotaskTaxesLinesState().deserialize(x));
					const totalTaxesResponses = data.totalTaxesResponses.map(x => new AutotaskTotalTaxes().deserialize(x));
					const transactionResponses = data.transactionResponses.map(x => new AutotaskTransaction().deserialize(x));

					const responseModel = {
						invoiceLinesMapping: invoiceLinesResponses,
						surchargeMapping: surchargeResponses,
						surchargesWithTaxesMapping: surchargesWithTaxesResponses,
						taxLinesMapping: taxesLinesResponses,
						taxesLinesStateMapping: taxesLinesStateResponses,
						totalTaxesMapping: totalTaxesResponses,
						transactionsMapping: transactionResponses
					};

					return responseModel;
				}), catchError(er => {
					return throwError(() => er);
				}));
	}

	public export(model: AutotaskExportMultilineModel): Observable<any> {
		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/autotask/invoices-multiline/export`, model, null)
			.pipe(map(res => res.body.data));
	}

	public autotaskInvoiceNumberSyncRequest(invoices: Guid[], excluded?: Invoice[], filterParams?: any): Observable<any> {
		const model = {
			invoices: invoices,
			excludedInvoices: excluded.map(x => x.id),
			selectedAll: this.invoiceService.selectedAll
		};
		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/autotask/invoices/sync/prepare`, model, { params: filterParams })
			.pipe(
				map(res => {
					return res.body.data.map(x => new AutotaskInvoiceSyncMapping().deserialize(x));
				}));
	}

	public ExportCWSyncInvoices(model: ExportAutotaskSyncInvoices): Observable<any> {
		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/autotask/invoices/sync`, model, null)
			.pipe(map(res => res.body.data));
	}

	async getAtWizardSettings(destroy$?: Subject<void>): Promise<AutotaskWizardSettings> {
		const res = await this.http.promise(destroy$).get<ApiData<AutotaskWizardSettings>>(`${this.config.apiUrl}${this.config.apiVersion}/autotask/invoices-multiline/wizard-settings`);
		return new AutotaskWizardSettings().deserialize(res.body?.data);
	}
}
