import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';

import { HttpAuth } from 'core/auth';
import { InvoiceLayoutEntity } from 'domain/entities';
import { CacheService } from 'services/cache.service';
import { BaseRepository } from './base-repository';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { ToasterService } from 'core/toaster-service';
import { AppConfig } from 'core/app-config';
import { ApiDataError, Guid } from 'domain/types';

@Injectable({
	providedIn: 'root'
})
export class InvoiceLayoutRepository extends BaseRepository {

	constructor(
		http: HttpAuth,
		config: AppConfig,
		cache: CacheService,
		private readonly toasterService: ToasterService
	) {
		super(http, config, cache);
	}

	getInvoiceLayouts(): Observable<InvoiceLayoutEntity[]> {

		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/invoice-layouts`)
			.pipe(
				map(res => {
					const layouts = res.body.data.map(x => new InvoiceLayoutEntity().deserialize(x));

					return layouts;
				}));
	}

	getInvoiceLayoutById(id: Guid): Observable<InvoiceLayoutEntity> {
		return this.getInvoiceLayouts().pipe(map(layouts => layouts.find(x => x.id.equals(id))));
	}

	public updateInvoiceLayout(layout: InvoiceLayoutEntity, file: File, isAddLayout: boolean = true, isFileUpdate: boolean = false): Observable<number> {
		const formData: FormData = new FormData();

		formData.append('file', file);
		if (!isAddLayout) {
			formData.append('id', layout.id.toString());
		}
		formData.append('name', layout.name);
		formData.append('fileUpdated', `${isFileUpdate}`);
		formData.append('description', layout.description ? layout.description : '');
		formData.append('invoiceTemplateId', layout.invoiceTemplateId.toString());
		formData.append('invoiceTemplateTypeId', layout.invoiceTemplateTypeId.toString());

		return this.http.put(`${this.config.apiUrl}${this.config.apiVersion}/invoice-layouts`, { headers: new HttpHeaders() }, formData)
			.pipe(
				map(s => null),
				catchError(err => {
					return throwError(() => err);
				}));
	}

	public deleteInvoiceLayout(ids: number[]): Observable<HttpResponse<ApiDataError<any>>> {
		return this.http.post<ApiDataError<any>>(`${this.config.apiUrl}${this.config.apiVersion}/invoice-layouts/delete`, { records: ids }, null);
	}
}
