import { Component } from '@angular/core';

import { Breadcrumb } from './breadcrumb';
import { DgCrumbsService } from './dg-crumbs.service';

@Component({
	selector: 'dg-crumbs',
	templateUrl: './dg-crumbs.component.html',
	styleUrls: ['./dg-crumbs.component.sass']
})
export class DgCrumbsComponent {
	breadcrumbs: Breadcrumb[];

	constructor(private dgCrumbsService: DgCrumbsService) {
		dgCrumbsService.breadcrumbChanged.subscribe((crumbs: Breadcrumb[]) => { this.onBreadcrumbChange(crumbs); });
	}

	private onBreadcrumbChange(crumbs: Breadcrumb[]): void {
		this.breadcrumbs = crumbs;
	}
}
