import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

const noop = () => {
};

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => DgCheckboxComponent),
	multi: true
};

@Component({
	selector: 'dg-checkbox',
	templateUrl: './dg-checkbox.component.html',
	styleUrls: ['./dg-checkbox.component.css'],
	providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class DgCheckboxComponent implements ControlValueAccessor {

	@Input() id: string;
	@Input() isGridCheckbox = false;

	@Input() class: string;
	@Input() labelClass: string;
	@Input() inputClass: string;

	isDisabled = false;

	value: boolean;

	private _onTouchedCallback: () => void = noop;
	private _onChangeCallback: (_: any) => void = noop;

	writeValue(value: any): void {
		if (value !== undefined) {
			this.value = value;
		}
	}

	registerOnChange(fn: any): void {
		this._onChangeCallback = fn;
	}

	registerOnTouched(fn: any): void {
		this._onTouchedCallback = fn;
	}

	public onChanged(): void {
		this._onChangeCallback(this.value);
		this._onTouchedCallback();
	}

	public onTouched(): void {
		this._onTouchedCallback();
	}

	setDisabledState(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
	}

}
