<form class="form form--export">
	<ul class="steps__wrapper">
		<li class="steps {{CheckStep(1)}}">
			<span class="steps__label">{{'global-export.step1_label' | transloco}}</span>
			<span class="steps__label">{{'cw-agreement-export.step1_title' | transloco}}</span>
		</li>
		<li class="steps {{CheckStep(2)}}">
			<span class="steps__label">{{'global-export.step2_label' | transloco}}</span>
			<span class="steps__label">{{'cw-agreement-export.step2_title' | transloco}}</span>
		</li>
		<li class="steps {{CheckStep(3)}}">
			<span class="steps__label">{{'global-export.step3_label' | transloco}}</span>
			<span class="steps__label">{{'cw-agreement-export.step3_title' | transloco}}</span>
		</li>
		<li class="steps {{CheckStep(4)}}">
			<span class="steps__label">{{'global-export.step4_label' | transloco}}</span>
			<span class="steps__label">{{'cw-agreement-export.step4_title' | transloco}}</span>
		</li>
		<li class="steps {{CheckStep(5)}}">
			<span class="steps__label">{{'global-export.step5_label' | transloco}}</span>
			<span class="steps__label">{{'cw-agreement-export.step5_title' | transloco}}</span>
		</li>
		<li class="steps {{CheckStep(6)}}">
			<span class="steps__label">{{'global-export.step6_label' | transloco}}</span>
			<span class="steps__label">{{'cw-agreement-export.step6_title' | transloco}}</span>
		</li>
		<li class="steps {{CheckStep(7)}}">
			<span class="steps__label">{{'global-export.step7_label' | transloco}}</span>
			<span class="steps__label">{{'cw-agreement-export.step7_title' | transloco}}</span>
		</li>
		<li class="steps {{CheckStep(8)}}">
			<span class="steps__label">{{'global-export.step8_label' | transloco}}</span>
			<span class="steps__label">{{'cw-agreement-export.step8_title' | transloco}}</span>
		</li>
		<li class="steps {{CheckStep(9)}}">
			<span class="steps__label">{{'global-export.step9_label' | transloco}}</span>
			<span class="steps__label">{{'cw-agreement-export.step9_title' | transloco}}</span>
		</li>
	</ul>
	<div class="form__content">
		<fieldset class="form__section {{CheckStep(1)}}">
			<h3 class="form__section-title">
				{{'cw-agreement-export.step1_title' | transloco}}
			</h3>
			<form #InvoicesForm="ngForm">
				<div class="form__group block">
					<div class="form__group-item form__group-item--500">
						<p>
							{{'cw-agreement-export.step1-field_selectedTypeId' | transloco}}
						</p>
						<select #inputFocus class="width-280px" required name="selectedTypeId"
							[(ngModel)]="selectedTypeId">
							<option [ngValue]="0">{{'cw-agreement-export.step1-field_selectedTypeId-opt-all' |
								transloco}}</option>
							<option *ngFor="let type of agreementTypes" [value]="type.id">{{type.name}}</option>
						</select>
					</div>
				</div>
				<div class="form__group block">
					<div class="form__group-item form__group-item--500">
						<p>
							{{'cw-agreement-export.step1-field_selectedVendorId' | transloco}}
							<i class="fa fa-question-circle tooltip tooltip--tile tooltip--export_1" aria-hidden="true">
								<span>
									{{'cw-agreement-export.step1-field_selectedVendorId-tooltip' | transloco}}
								</span>
							</i>
						</p>
						<select #inputFocus class="width-280px" required name="selectedVendorId"
							[(ngModel)]="selectedVendorId">
							<option [ngValue]="null">
								{{'cw-agreement-export.step1-field_selectedVendorId-opt-all' | transloco}}
							</option>
							<option *ngFor="let vendor of vendors" [value]="vendor.id">{{vendor.name}}</option>
						</select>
					</div>
				</div>
				<div class="form__bottom form__bottom--border-none">
					<button type="button" class="btn btn--primary btn--import" (click)="backTo()">
						{{'global.export_btn-back' | transloco}}
					</button>
					<button type="button" class="btn btn--primary btn--import" [disabled]="!selectedTypeIdValid || loading"
						(click)="AgreementTypesSelected()">
						{{'global.export_btn-next' | transloco}}
						<i *ngIf="loading" class="fa fa-spinner fa-spin btn-spinner"></i>
					</button>
				</div>
			</form>
		</fieldset>

		<fieldset class="form__section {{CheckStep(2)}}">
			<h3 class="form__section-title">
				{{'cw-agreement-export.step2_title' | transloco}}
			</h3>
			<form #AgreementsForm="ngForm">
				<div class="form__group block">
					<div class="form__group-item form__group-item--750-fs">
						<p>{{'cw-agreement-export.step2_header-agreement-name' | transloco}}</p>
						<p>{{'cw-agreement-export.step2_header-invoice' | transloco}}</p>
						<p>{{'cw-agreement-export.step2_header-company-name' | transloco}}</p>
						<p>{{'cw-agreement-export.step2_header-agreement' | transloco}}</p>
					</div>
					<div *ngFor="let item of agreementsMapping; let i = index"
						class="form__group-item form__group-item--750-fs">
						<p>{{item.invoice.agreementName}}</p>
						<p>{{item.invoice.invoiceNumber}}</p>
						<p>{{item.company.name}}</p>

						<ng-select *ngIf="item.agreements" #inputAgreement required
							bindLabel="name" bindValue="id" name="selectedAgreementId{{i}}"
							[items]="item.agreements" [searchable]="true" [clearable]="false" [(ngModel)]="item.selectedAgreementId"
							(change)="onChangeAgreements(item)">
						</ng-select>
						<p *ngIf="!item.agreements">
							{{'cw-agreement-export.step2_agreements-not-found' | transloco}}
						</p>
					</div>
				</div>
				<div class="form__bottom form__bottom--border-none">
					<button type="button" class="btn btn--primary btn--import" (click)="BackStep()">
						{{'global.export_btn-back' | transloco}}
					</button>
					<button type="button" class="btn btn--primary btn--import" 
						[disabled]="!AgreementsForm.valid || !isAgreementMappingValid"
						(click)="NextStep()">
						{{'global.export_btn-next' | transloco}}
						<i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
					</button>
				</div>
			</form>

		</fieldset>

		<fieldset class="form__section {{CheckStep(3)}}">
			<h3 class="form__section-title">
				{{'cw-agreement-export.step3_title' | transloco}}
			</h3>
			<div class="form__section-subtitle">
				{{'cw-agreement-export.step3_subtitle' | transloco}}
			</div>

			<div class="form__group">
				<div class="form__group-item ">
					<div class="radiobox">
						<input class="radiobox-input" type="radio" name="groupingType-radio" id="groupingType-1"
							[value]="1" [(ngModel)]='selectedGroupingTypeId' />
						<label class="radiobox-label" for="groupingType-1">
							{{'cw-agreement-export.field-selectedGroupingTypeId-opt-0' | transloco}}
						</label>
					</div>
					<div class="sidebar__radiobox radiobox">
						<input class="radiobox-input" type="radio" name="groupingType-radio" id="groupingType-2"
							[value]="2" [(ngModel)]='selectedGroupingTypeId' />
						<label class="radiobox-label" for="groupingType-2">
							{{'cw-agreement-export.field-selectedGroupingTypeId-opt-1' | transloco}}
						</label>
					</div>
					<div class="sidebar__radiobox radiobox">
						<input class="radiobox-input" type="radio" name="groupingType-radio" id="groupingType-3"
							[value]="3" [(ngModel)]='selectedGroupingTypeId' />
						<label class="radiobox-label" for="groupingType-3">
							{{'cw-agreement-export.field-selectedGroupingTypeId-opt-2' | transloco}}
						</label>
					</div>
				</div>
			</div>

			<div class="form__bottom form__bottom--border-none">
				<button type="button" class="btn btn--primary btn--import" [disabled]="loading" (click)="BackStep()">
					{{'global.export_btn-back' | transloco}}
				</button>
				<button type="button" class="btn btn--primary btn--import" [disabled]="loading" (click)="GroupingTypeSelected()">
					{{'global.export_btn-next' | transloco}}
					<i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
				</button>
			</div>
		</fieldset>

		<fieldset class="form__section {{CheckStep(4)}}">
			<h3 class="form__section-title">
				{{'cw-agreement-export.step4_title' | transloco}}
			</h3>
			<form #ProductsForm="ngForm">
				<div *ngIf="selectedGroupingTypeId === 1" class="form__group block">
					<div class="form__group-item form__group-item--500-fs">
						<p>{{'cw-agreement-export.step4_header-service' | transloco}}</p>
						<p>{{'cw-agreement-export.step4_header-record' | transloco}}</p>
						<p>{{'cw-agreement-export.step4_header-product' | transloco}}</p>
					</div>
					<div *ngFor="let items of productsMapping | groupBy:'groupName'; let i = index"
						class="form__group-item form__group-item--500">
						<p>{{items.key}}</p>
						<div>
							<div *ngFor="let item of items.value | sort:['description', 'selectedProductId']; let i1 = index" class="form__group-item-custom1">
								<p>{{item.description}}</p>
								<ng-select #inputProduct bindLabel="identifier" required
									bindValue="id" name="selectedProductId{{i}}_{{i1}}" [items]="products"
									[searchable]="true" [clearable]="false" [(ngModel)]="item.selectedProductId">
									<ng-template>
										<input class="custom-input" type="text"
											(input)="inputProduct.filter($event.target.value)" />
									</ng-template>
								</ng-select>
							</div>
						</div>
					</div>
				</div>

				<div *ngIf="selectedGroupingTypeId === 2" class="form__group block">
					<div class="form__group-item form__group-item--500-sb width-550px">
						<p>{{'cw-agreement-export.step4_header-cost-centre' | transloco}}</p>
						<p>{{'cw-agreement-export.step4_header-product' | transloco}}</p>
					</div>
					<div *ngFor="let item of productsMapping; let i = index"
						class="form__group-item form__group-item--800">
						<p>{{item.groupName}}</p>
						<ng-select #inputProduct bindLabel="identifier" required bindValue="id"
							name="selectedProductId{{i}}" [items]="products" [searchable]="true" [clearable]="false"
							[(ngModel)]="item.selectedProductId">
							<ng-template>
								<input class="custom-input" type="text"
									(input)="inputProduct.filter($event.target.value)" />
							</ng-template>
						</ng-select>
					</div>
				</div>

				<div *ngIf="selectedGroupingTypeId === 3" class="form__group block">
					<div class="form__group-item form__group-item--500-sb width-550px">
						<p>{{'cw-agreement-export.step4_header-site' | transloco}}</p>
						<p>{{'cw-agreement-export.step4_header-product' | transloco}}</p>
					</div>
					<div *ngFor="let item of productsMapping; let i = index"
						class="form__group-item form__group-item--800">
						<p>{{item.groupName}}</p>
						<ng-select #inputProduct bindLabel="identifier" required bindValue="id"
							name="selectedProductId{{i}}" [items]="products" [searchable]="true" [clearable]="false"
							[(ngModel)]="item.selectedProductId">
							<ng-template>
								<input class="custom-input" type="text"
									(input)="inputProduct.filter($event.target.value)" />
							</ng-template>
						</ng-select>
					</div>
				</div>

				<div class="form__bottom form__bottom--border-none">
					<button type="button" class="btn btn--primary btn--import" (click)="BackStep()">
						{{'global.export_btn-back' | transloco}}
					</button>
					<button type="button" class="btn btn--primary btn--import" [disabled]="!ProductsForm.valid"
						(click)="NextStep()">
						{{'global.export_btn-next' | transloco}}
						<i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
					</button>
				</div>
			</form>
		</fieldset>

		<fieldset class="form__section {{CheckStep(5)}}">
			<h3 class="form__section-title">{{'cw-agreement-export.step5_title' | transloco}}</h3>
			<form #TransactionsForm="ngForm">
				<div class="form__group block">
					<div class="form__group-item form__group-item--500 flex-start">
						<p>{{'cw-agreement-export.step5-field_excludeRecurringProducts' | transloco}}
							<i class="fa fa-question-circle tooltip tooltip--export_1" aria-hidden="true">
								<span>
									{{'cw-agreement-export.step5-field_excludeRecurringProducts-tooltip' | transloco}}
								</span>
							</i>
						</p>
						<dg-checkbox name="excludeRecurringProducts" ngDefaultControl
							[id]="'excludeRecurringProducts'" [(ngModel)]="excludeRecurringProducts"></dg-checkbox>
					</div>
					<div *ngFor="let item of transactionsMapping; let i = index"
						class="form__group-item form__group-item--800">
						<p *ngIf="excludeRecurringProducts ? !item.recurringTransaction : true">
							{{item.transactionName}}
						</p>
						<ng-select *ngIf="excludeRecurringProducts ? !item.recurringTransaction : true" #inputProduct
							bindLabel="identifier" required bindValue="id"
							name="selectedProductId{{i}}" [items]="products" [searchable]="true" [clearable]="false"
							[(ngModel)]="item.selectedProductId">
							<ng-template>
								<input class="custom-input" type="text"
									(input)="inputProduct.filter($event.target.value)" />
							</ng-template>
						</ng-select>
					</div>
				</div>
				<div class="form__bottom form__bottom--border-none">
					<button type="button" class="btn btn--primary btn--import" (click)="BackStep()">
						{{'global.export_btn-back' | transloco}}
					</button>
					<button type="button" class="btn btn--primary btn--import" [disabled]="!TransactionsForm.valid"
						(click)="NextStep()">
						{{'global.export_btn-next' | transloco}}
						<i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
					</button>
				</div>
			</form>
		</fieldset>

		<fieldset class="form__section {{CheckStep(6)}}">
			<h3 class="form__section-title">
				{{'cw-agreement-export.step6_title' | transloco}}
			</h3>
			<form #SurchargeForm="ngForm">
				<div class="form__group block">
					<div class="form__group-item form__group-item--500 flex-start">
						<p>
							{{'cw-agreement-export.step6-field_combineSurchargesWithTaxes' | transloco}}
						</p>
						<dg-checkbox name="combineSurchargesWithTaxes" ngDefaultControl
							[id]="'combineSurchargesWithTaxes'" [(ngModel)]="combineSurchargesWithTaxes"></dg-checkbox>
					</div>

					<div *ngIf="!combineSurchargesWithTaxes && (resellerSurcharges$ | async)?.length > 1" class="form__group-item form__group-item--500 flex-start">
						<p>
							{{'cw-agreement-export.step6_field-surchargeSeparatelyMapping' | transloco}}
							<i class="fa fa-question-circle tooltip tooltip--export_1" aria-hidden="true">
								<span>{{'cw-agreement-export.step6_field-surchargeSeparatelyMapping-tooltip' | transloco}}</span>
							</i>
						</p>
						<dg-checkbox name="surchargeSeparatelyMapping" ngDefaultControl
							[id]="'surchargeSeparatelyMapping'" [(ngModel)]="surchargeSeparately"></dg-checkbox>
					</div>
					
					<div *ngIf="!combineSurchargesWithTaxes && !surchargeSeparately">
						<div *ngFor="let item of surchargeMapping; let i = index"
							class="form__group-item form__group-item--500">
							<p>{{item.surchargeName}}</p>
							<ng-select #inputProduct bindLabel="identifier" required
								bindValue="id" name="selectedProductId{{i}}" [items]="products"
								[searchable]="true" [clearable]="false" [(ngModel)]="item.selectedProductId">
								<ng-template>
									<input class="custom-input" type="text"
										(input)="inputProduct.filter($event.target.value)" />
								</ng-template>
							</ng-select>
						</div>
					</div>
					<div *ngIf="surchargeSeparately">
						<div *ngFor="let item of surchargeSeparatelyMapping; let i = index"
							class="form__group-item form__group-item--500">
							<p>{{item.surchargeName}}</p>
							<ng-select #inputProduct bindLabel="identifier" required
								bindValue="id" name="selectedProductId{{i}}" [items]="products"
								[searchable]="true" [clearable]="false" [(ngModel)]="item.selectedProductId">
								<ng-template>
									<input class="ng-select-input" type="text" (input)="inputProduct.filter($event.target.value)" />
								</ng-template>
							</ng-select>
						</div>
					</div>
				</div>
				<div class="form__bottom form__bottom--border-none">
					<button type="button" class="btn btn--primary btn--import" (click)="BackStep()">
						{{'global.export_btn-back' | transloco}}
					</button>
					<button type="button" class="btn btn--primary btn--import" [disabled]="!SurchargeForm.valid"
						(click)="NextStep()">
						{{'global.export_btn-next' | transloco}}
						<i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
					</button>
				</div>
			</form>
		</fieldset>

		<fieldset class="form__section {{CheckStep(7)}}">
			<h3 class="form__section-title">
				{{combineSurchargesWithTaxes ? ('cw-agreement-export.step7_title-combineSurchargesWithTaxes' | transloco) : 'cw-agreement-export.step7_title' | transloco }}
			</h3>
			<form #TaxForm="ngForm">
				<div *ngIf="!combineSurchargesWithTaxes" class="form__group block">
					<div class="form__group-item form__group-item--500 flex-start">
						<p>{{'cw-agreement-export.step7-field_excludeTaxes' | transloco}}
							<i class="fa fa-question-circle tooltip tooltip--export_1" aria-hidden="true">
								<span>
									{{'cw-agreement-export.step7-field_excludeTaxes-tooltip' | transloco}}
								</span>
							</i>
						</p>
						<dg-checkbox name="excludeTaxes" ngDefaultControl [id]="'excludeTaxes'"
									 [(ngModel)]="excludeTaxes"></dg-checkbox>
					</div>
					<div *ngIf="!excludeTaxes" class="form__group-item form__group-item--500 flex-start">
						<p>{{'cw-agreement-export.step7-field_sureTaxMultiline' | transloco}}
							<i class="fa fa-question-circle tooltip tooltip--export_1" aria-hidden="true">
								<span>
									{{'cw-agreement-export.step7-field_sureTaxMultiline-tooltip' | transloco}}
								</span>
							</i>
						</p>
						<dg-checkbox name="sureTaxMultiline" ngDefaultControl [id]="'sureTaxMultiline'"
							[(ngModel)]="sureTaxMultiline"></dg-checkbox>
					</div>
					<div *ngIf="sureTaxMultiline && !excludeTaxes" class="form__group-item form__group-item--500 flex-start">
						<p>{{'cw-agreement-export.step7-field_groupTaxByState' | transloco}}
							<i class="fa fa-question-circle tooltip tooltip--export_2"
								aria-hidden="true">
								<span>{{'cw-agreement-export.step7-field_groupTaxByState-tooltip' | transloco}}</span>
							</i>
						</p>
						<dg-checkbox name="groupTaxByState" ngDefaultControl [id]="'groupTaxByState'"
							[(ngModel)]="groupTaxByState"></dg-checkbox>
					</div>
					<div *ngIf="sureTaxMultiline && groupTaxByState && !excludeTaxes">
						<div *ngFor="let line of sureTaxByStateLineMapping; let i = index"
							class="form__group-item form__group-item--800">
							<p>{{line.taxTypeDesc}} {{line.stateCode}} {{!line.taxInclusive ? ('global-export.exclusive_label' | transloco) : 'global-export.inclusive_label' | transloco}}</p>
							<ng-select #inputProduct bindLabel="identifier" required
								bindValue="id" name="selectedProductId{{i}}" [items]="products" [searchable]="true"
								[clearable]="false" [(ngModel)]="line.selectedProductId">
								<ng-template>
									<input class="custom-input" type="text"
										(input)="inputProduct.filter($event.target.value)" />
								</ng-template>
							</ng-select>
						</div>
					</div>
					<div *ngIf="sureTaxMultiline && !groupTaxByState && !excludeTaxes">
						<div *ngFor="let line of sureTaxLineMapping; let i = index"
							class="form__group-item form__group-item--800">
							<p>{{line.taxTypeDesc}} {{!line.taxInclusive ? ('global-export.exclusive_label' | transloco) : 'global-export.inclusive_label' | transloco}} {{line.selectedProductId}}</p>
							<ng-select #inputProduct bindLabel="identifier" required
								bindValue="id" name="selectedProductId{{i}}" [items]="products" [searchable]="true"
								[clearable]="false" [(ngModel)]="line.selectedProductId">
								<ng-template>
									<input class="custom-input" type="text"
										(input)="inputProduct.filter($event.target.value)" />
								</ng-template>
							</ng-select>
						</div>
					</div>
					<div *ngIf="!sureTaxMultiline && !excludeTaxes">
						<div *ngFor="let item of taxMapping; let i = index"
							class="form__group-item form__group-item--500">
							<p>{{item.taxName}} {{!item.taxInclusive ? ('global-export.exclusive_label' | transloco) : 'global-export.inclusive_label' | transloco}}</p>
							<ng-select #inputProduct bindLabel="identifier" bindValue="id"
								required name="selectedProductId{{i}}" [items]="products"
								[searchable]="true" [clearable]="false" [(ngModel)]="item.selectedProductId">
								<ng-template>
									<input class="custom-input" type="text"
										(input)="inputProduct.filter($event.target.value)" />
								</ng-template>
							</ng-select>
						</div>
					</div>
				</div>
				<div *ngIf="combineSurchargesWithTaxes" class="form__group block">
					<div *ngFor="let item of surchargeWithTaxMapping; let i = index"
						class="form__group-item form__group-item--500">
						<p>{{item.combineName}}</p>
						<ng-select #inputProduct bindLabel="identifier" bindValue="id" required
							name="selectedProductId{{i}}" [items]="products" [searchable]="true"
							[clearable]="false" [(ngModel)]="item.selectedProductId">
							<ng-template>
								<input class="custom-input" type="text"
									(input)="inputProduct.filter($event.target.value)" />
							</ng-template>
						</ng-select>
					</div>
				</div>
				<div class="form__bottom form__bottom--border-none">
					<button type="button" class="btn btn--primary btn--import" (click)="BackStep()">
						{{'global.export_btn-back' | transloco}}
					</button>
					<button type="button" class="btn btn--primary btn--import" [disabled]="!TaxForm.valid" (click)="NextStep()">
						{{'global.export_btn-next' | transloco}}
						<i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
					</button>
				</div>
			</form>
		</fieldset>

		<fieldset class="form__section {{CheckStep(8)}}">
			<h3 class="form__section-title">
				{{'cw-agreement-export.step8_title' | transloco}}
			</h3>
			<div class="form__section-subtitle">
				{{'cw-agreement-export.step8_subtitle' | transloco}}
			</div>
			<div class="form__group">
				<div class="form__group-item ">
					<div class="radiobox">
						<input type="radio" id="KeepItems" name="radio-delete" [value]=0
							[(ngModel)]="optionExistingItems">
						<label class="radiobox-label" for="KeepItems">
							{{'cw-agreement-export.step8-field_deleteExisting-false' | transloco}}
							<i class="fa fa-question-circle tooltip tooltip--export_1" aria-hidden="true">
								<span>
									{{'cw-agreement-export.step8-field_keepExisting_tooltip' | transloco}}
								</span>
							</i>
						</label>
					</div>
					<div class="radiobox">
						<input type="radio" id="DeleteItems" name="radio-delete" [value]=1
							[(ngModel)]="optionExistingItems">
						<label class="radiobox-label" for="DeleteItems">
							{{'cw-agreement-export.step8-field_deleteExisting-true' | transloco}}
							<i class="fa fa-question-circle tooltip tooltip--export_1" aria-hidden="true">
								<span>
									{{'cw-agreement-export.step8-field_deleteExisting_tooltip' | transloco}}
								</span>
							</i>
						</label>
					</div>
					<div class="radiobox">
						<input type="radio" id="UpdateItems" name="radio-delete" [value]=2
							   [(ngModel)]="optionExistingItems">
						<label class="radiobox-label" for="UpdateItems">
							{{'cw-agreement-export.step8-field_updateExisting' | transloco}}
							<i class="fa fa-question-circle tooltip tooltip--export_1" aria-hidden="true">
								<span>
									{{'cw-agreement-export.step8-field_updateExisting_tooltip' | transloco}}
								</span>
							</i>
						</label>
					</div>
				</div>
			</div>

			<div class="form__bottom form__bottom--border-none">
				<button type="button" class="btn btn--primary btn--import" (click)="BackStep()">
					{{'global.export_btn-back' | transloco}}
				</button>
				<button type="button" class="btn btn--primary btn--import" (click)="NextStep()">
					<i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
					{{'global.export_btn-next' | transloco}}
				</button>
			</div>
		</fieldset>

		<fieldset class="form__section {{CheckStep(9)}}">
			<h3 class="form__section-title">{{'cw-agreement-export.step9_title' | transloco}}</h3>
			<div class="form__section-subtitle">
				{{'cw-agreement-export.step9_subtitle' | transloco}}
			</div>

			<div class="form__group">
				<div class="form__group-item ">
					<div class="radiobox">
						<input class="radiobox-input" type="radio" name="price-radio" id="price-1"
							[value]="1" [(ngModel)]='selectedPriceCalculationTypeId' />
						<label class="radiobox-label" for="price-1">
							{{'cw-agreement-export.field_priceCalculationTypes-opt-0' | transloco}}
						</label>
					</div>
					<div class="sidebar__radiobox radiobox">
						<input class="radiobox-input" type="radio" name="price-radio" id="price-2"
							[value]="2" [(ngModel)]='selectedPriceCalculationTypeId' />
						<label class="radiobox-label" for="price-2">
							{{'cw-agreement-export.field_priceCalculationTypes-opt-1' | transloco}}
						</label>
					</div>
				</div>
			</div>

			<div class="form__bottom form__bottom--border-none">
				<button type="button" class="btn btn--primary btn--import" [disabled]="loading" (click)="BackStep()">
					{{'global.export_btn-back' | transloco}}
				</button>
				<button type="button" class="btn btn--primary btn--import" [disabled]="loading" (click)="Export()">
					{{'global.export_btn-export' | transloco}}
					<i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
				</button>
			</div>
		</fieldset>
	</div>
</form>

<div *ngIf="isExported" class="modal modal--tile modal--confirmation">
	<div class="modal__content">
		<form class="modal__content-body">
			<div class="page-tile__body page-tile__body--hidden-none">
				<div class="page-tile__title"> <b>{{'cw-agreement-export.confirmation_title' | transloco}}</b></div>

				<div class="page-tile__content">
					<div class="page-tile__group">
						<span>
							{{'cw-agreement-export.confirmation_msg' | transloco}}
						</span>
					</div>
					<div class="page-tile__content-item">
						<div *ngFor="let inv of exportedInvoices" class="page-tile__content-row">
							<i class="fa fa-circle" aria-hidden="true"></i>
							{{inv}}
						</div>
					</div>
				</div>

				<p class="page-tile__body-proceed"></p>

				<div class="form__bottom">
					<button type="button" class="btn btn--secondary width-100px" (click)="onExportConfirm()">
						{{'global.modals_btn-confirm' | transloco}}
					</button>
					<button type="button" class="btn btn--default width-100px" (click)="backTo()">
						{{'global.modals_btn-cancel' | transloco}}
					</button>
				</div>
			</div>
		</form>
	</div>
</div>

<div *ngIf="isExported" class="fade"></div>
