import { Location } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HashMap, TranslateParams, TranslocoService } from '@ngneat/transloco';
import {
	AgreementMapping,
	AgreementType,
	ExportInvoiceModel,
	ExportModel,
	Invoice,
	Product,
	ProductMapping,
	SurchargeMapping,
	SurchargeSeparatelyMapping,
	SurchargeWithTaxMapping,
	SureTaxByStateLineMapping,
	SureTaxLineMapping,
	TaxMapping,
	TransactionMapping,
	Vendor
} from 'domain/entities';
import { SurchargeRepository } from 'repositories';
import { CWRepository } from 'repositories/cw-repository';
import { EMPTY, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { NavigatorService } from 'services';
import { CacheModel, CacheService } from 'services/cache.service';
import { InvoiceActionsService } from 'shared/modules/invoice-actions';
import { DgAlertService } from 'tools/alert';

@Component({
	selector: 'dg-invoice-export',
	templateUrl: './invoice-export.component.html',
	styleUrls: ['./invoice-export.component.sass']
})

export class InvoiceExportComponent implements OnDestroy {
	currentStep: number;
	invoices: Invoice[];
	excluded: Invoice[];
	agreementTypes: AgreementType[];
	vendors: Vendor[];
	selectedVendorId?: number;
	selectedTypeId = 0;
	agreementsMapping: AgreementMapping[];
	productsMapping: ProductMapping[];
	transactionsMapping: TransactionMapping[];
	taxMapping: TaxMapping[];
	surchargeWithTaxMapping: SurchargeWithTaxMapping[];
	sureTaxLineMapping: SureTaxLineMapping[];
	sureTaxByStateLineMapping: SureTaxByStateLineMapping[];
	surchargeSeparatelyMapping: SurchargeSeparatelyMapping[];
	surchargeMapping: SurchargeMapping[];
	products: Product[];
	loading = false;
	selectedGroupingTypeId: number;
	selectedPriceCalculationTypeId: number;
	optionExistingItems: number;
	public cacheModel: CacheModel;
	public cacheModelExcluded: CacheModel;
	public combineSurchargesWithTaxes = false;
	public surchargeSeparately = false;
	public sureTaxMultiline = false;
	public groupTaxByState = false;
	public excludeRecurringProducts = false;
	public wizardSettings: Map<string, unknown>;
	public isExported = false;
	public exportedInvoices: string[];
	public excludeTaxes: boolean;
	public resellerSurcharges$ = this.surchargeRepository.getSurcharges();
	t: <T = string>(key: TranslateParams, params?: HashMap, lang?: string) => T;
	private destroy$ = new Subject<void>();
	constructor(
		private readonly route: ActivatedRoute,
		private readonly cache: CacheService,
		private readonly surchargeRepository: SurchargeRepository,
		private readonly navigator: NavigatorService,
		private readonly router: Router,
		private readonly repository: CWRepository,
		private readonly invoiceRepo: InvoiceActionsService,
		private readonly transloco: TranslocoService,
		private readonly alertService: DgAlertService,
		private readonly location: Location
	) {
		this.t = transloco.translate.bind(transloco);
		this.route.data
			.subscribe((data: Partial<{ agreementTypes: AgreementType[]; wizardSettings: Map<string, unknown>; vendors: Vendor[]; }>) => {
				this.agreementTypes = data.agreementTypes;
				this.wizardSettings = data.wizardSettings;
				this.vendors = data.vendors;
			});

		this.currentStep = 1;

		this.setWizardSettings();

		this.cacheModel = this.cache.get('exportInvoices');
		this.cacheModelExcluded = this.cache.get('exportInvoices-excluded');

		this.invoices = this.cacheModel ? <Invoice[]>this.cacheModel.Object : [];
		this.excluded = this.cacheModelExcluded ? <Invoice[]>this.cacheModelExcluded.Object : [];

		if (!this.invoices.length) {
			this.location.back();
		}
	}

	get selectedTypeIdValid(): boolean {
		return this.selectedTypeId >= 0;
	}

	get isAgreementMappingValid(): boolean {
		return this.agreementsMapping?.every(agreement => agreement.selectedAgreementId);
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	private setWizardSettings(): void {
		this.selectedVendorId = null;
		this.selectedTypeId = this.wizardSettings[ConnectWiseWizardSettings.AgreementType] || 0;
		this.selectedGroupingTypeId = this.wizardSettings[ConnectWiseWizardSettings.GroupingType];
		this.selectedPriceCalculationTypeId = this.wizardSettings[ConnectWiseWizardSettings.PriceCalculationType];
		this.optionExistingItems = this.wizardSettings[ConnectWiseWizardSettings.OptionExistingItems];
		this.combineSurchargesWithTaxes = this.wizardSettings[ConnectWiseWizardSettings.CombineSurchargesWithTaxes];
		this.excludeRecurringProducts = this.wizardSettings[ConnectWiseWizardSettings.ExcludeRecurringProducts];
		this.sureTaxMultiline = this.wizardSettings[ConnectWiseWizardSettings.MultilineTaxes];
		this.groupTaxByState = this.wizardSettings[ConnectWiseWizardSettings.SplitTaxesByState];
		this.excludeTaxes = this.wizardSettings[ConnectWiseWizardSettings.ExcludeTaxes];
		this.surchargeSeparately = this.wizardSettings[ConnectWiseWizardSettings.SurchargesSeparately];
	}

	public CheckStep(value: number): string {
		return this.currentStep === value ? 'steps--active' : '';
	}

	public AgreementTypesSelected(force: boolean = false): void {
		if (this.invoices) {
			this.loading = true;

			const exportModel = new ExportInvoiceModel();
			exportModel.agreementTypeId = +this.selectedTypeId;
			exportModel.invoices = this.invoices.map(x => x.id);
			exportModel.excludedInvoices = this.excluded.map(x => x.id);
			exportModel.selectedAll = this.invoiceRepo.selectedAll;
			exportModel.force = force;
			exportModel.selectedVendorId = this.selectedVendorId;

			const actionFilters = this.cache.get('invoices-action-filterParams');
			const filterParams = this.invoiceRepo.selectedAll && actionFilters ? actionFilters : '';

			this.repository.GetAgreements(exportModel, filterParams)
				.pipe(
					takeUntil(this.destroy$)
				).subscribe({
					next: res => {
						if (res.length) {
							this.agreementsMapping = res;
							this.NextStep();
						} else {
							this.alertService.show(this.t('cw-agreement-export.not-found-warning'), 'error');
						}
						this.loading = false;
					},
					error: (err) => {
						this.loading = false;
						if (Array.isArray(err.error.references)) {
							this.isExported = true;
							this.exportedInvoices = err.error.references;
							return EMPTY;
						} else {
							throw err;
						}
					}
				});
		}
	}

	public onChangeAgreements(item: AgreementMapping): void {
		item.selectedAgreementId = +item.selectedAgreementId;
	}

	public GroupingTypeSelected(): void {
		this.loading = true;
		const groupingTypeId = +this.selectedGroupingTypeId;

		let vendorId = undefined;

		if (this.selectedVendorId)
			vendorId = +this.selectedVendorId;

		this.repository.GroupingTypePost(groupingTypeId, this.agreementsMapping, vendorId)
			.pipe(takeUntil(this.destroy$))
			.subscribe(res => {
				this.surchargeSeparatelyMapping =  res.surchargeSeparatelyMapping;
				this.productsMapping = res.productsMapping;
				this.transactionsMapping = res.transactionsMapping;
				this.taxMapping = res.taxMapping;
				this.surchargeMapping = res.surchargeMapping;
				this.surchargeWithTaxMapping = res.surchargeWithTaxMapping;
				this.products = res.products;
				this.sureTaxLineMapping = res.sureTaxLineMapping;
				this.sureTaxByStateLineMapping = res.sureTaxByStateLineMapping;

				this.loading = false;
				this.NextStep();
			});
	}

	public Export(): void {
		this.loading = true;
		const exportModel = new ExportModel();
		exportModel.selectedGroupingTypeId = this.selectedGroupingTypeId;
		exportModel.selectedPriceCalculationTypeId = this.selectedPriceCalculationTypeId;
		exportModel.optionExistingItems = this.optionExistingItems;
		exportModel.agreementsMapping = this.agreementsMapping;
		exportModel.productsMapping = this.productsMapping;
		exportModel.transactionMapping = this.transactionsMapping;
		exportModel.taxMapping = this.taxMapping;
		exportModel.sureTaxLineMapping = this.sureTaxLineMapping;
		exportModel.sureTaxByStateLineMapping = this.sureTaxByStateLineMapping;
		exportModel.surchargeMapping = this.surchargeMapping;
		exportModel.surchargeWithTaxMapping = this.surchargeWithTaxMapping;
		exportModel.combineSurchargesWithTaxes = this.combineSurchargesWithTaxes;
		exportModel.products = this.products;
		exportModel.sureTaxMultiline = this.sureTaxMultiline;
		exportModel.groupTaxByState = this.groupTaxByState;
		exportModel.excludeRecurringProducts = this.excludeRecurringProducts;
		exportModel.selectedAgreementTypeId = +this.selectedTypeId;
		exportModel.excludeTaxes = this.excludeTaxes;
		exportModel.surchargeSeparatelyMapping = this.surchargeSeparatelyMapping;
		exportModel.surchargesSeparately = this.surchargeSeparately;

		exportModel.tag = this.cacheModel.Tag;

		this.repository.Export(exportModel)
			.pipe(takeUntil(this.destroy$), finalize(() => this.loading = false))
			.subscribe({
				next: (result) => {
					if (result.operationId) {
						this.navigator.toOperation(result.operationId);
					}
				}
			});
	}

	NextStep(): void {
		if (this.currentStep < 9) {
			this.currentStep = this.currentStep + 1;
		}
	}

	PreviosStep(): void {
		if (this.currentStep > 1) {
			this.currentStep = this.currentStep - 1;
		}
	}

	backTo(): void {
		const backUrl = (this.cacheModel.Tag.split(':').pop()).slice(2, -2);
		void this.router.navigate([backUrl]);
	}

	public BackStep(): void {
		this.PreviosStep();
	}

	onExportConfirm(): void {
		this.isExported = false;
		this.exportedInvoices = [];
		this.AgreementTypesSelected(true);
	}
}

enum ConnectWiseWizardSettings {
	AgreementType = 'agreement-type',
	GroupingType = 'grouping-type',
	PriceCalculationType = 'price-calculation-type',
	CombineSurchargesWithTaxes = 'combine-surcharges-with-taxes',
	ExcludeRecurringProducts = 'exclude-recurring-products',
	MultilineTaxes = 'multiline-taxes',
	SplitTaxesByState = 'split-taxes-by-state',
	ExcludeTaxes = 'exclude-taxes',
	OptionExistingItems = 'option-existing-items',
	SurchargesSeparately = 'surcharges-separately',
}
