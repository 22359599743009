import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const noop = () => {
};

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => DgTileSelectComponent),
	multi: true
};

@Component({
	selector: 'dg-tile-select',
	templateUrl: './dg-tile-select.component.html',
	styleUrls: ['./dg-tile-select.component.sass'],
	providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})

export class DgTileSelectComponent implements ControlValueAccessor {
	@Input() id: string;
	@Input() name: string;
	@Input() label: string;
	@Input() tooltip: string;
	@Input() isDisabled = false;
	@Input() bindLabel: string;
	@Input() bindValue: string;
	@Input() ddPosition: 'bottom' | 'top' | 'auto';
	@Input() ddHeight: string;
	@Input() placeholder: string;
	@Input() isClearable = true;
	@Input() isRequired = false;
	@Input() isDescription = false;

	@Input() items: any;

	value: string;

	private _onTouchedCallback: () => void = noop;
	private _onChangeCallback: (_: any) => void = noop;

	writeValue(value: any): void {
		if (value !== undefined) {
			this.value = value;
		}
	}

	registerOnChange(fn: any): void {
		this._onChangeCallback = fn;
	}

	registerOnTouched(fn: any): void {
		this._onTouchedCallback = fn;
	}

	public onChanged(): void {
		this._onChangeCallback(this.value);
		this._onTouchedCallback();
	}

	public onTouched(): void {
		this._onTouchedCallback();
	}

	compareFn(item: any, selected: any): boolean {
		if (typeof item !== 'object') {
			return item === selected;
		} else {
			if (!Object.hasOwn(item, this.bindValue)) {
				return true;
			}

			const id = selected.toString();
			return item[this.bindValue].toString() === id;
		}
	}
}
