export enum InvoiceTemplateType {
	Unknown = 0,
	Invoice = 1,
	CreditNote = 2
}

export enum InvoiceTemplateTextType {
	Unknown = '',
	Invoice = 'Invoice',
	CreditNote = 'CreditNote'
}
