import { Injector } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HashMap, TranslateParams, TranslocoEvents, TranslocoService } from '@ngneat/transloco';
import { ColumnState, GridReadyEvent } from 'ag-grid-community';
import { ColDef, DomLayoutType, GetMainMenuItemsParams, GridApi, GridOptions, ICellRendererParams, RowClassParams, RowStyle } from 'ag-grid-enterprise';
import { AuthHelper } from 'core/auth';
import { DueDateOption, Invoice, Tag } from 'domain/entities';
import * as Moment from 'moment-timezone';
import { combineLatest, Subject } from 'rxjs';
import { filter, startWith, takeUntil } from 'rxjs/operators';
import { Labels } from 'shared/modules/invoice-actions';
import { TranslocoHttpLoader } from 'transloco-root.module';

const DG_USER_GRIDS = 'dgUserGrids';
interface UserGridState {
	columnState: ColumnState[];
	filterModel: { [key: string]: any; };
}
interface UserGrids {
	[key: string]: UserGridState;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export abstract class DgAgGridBase<TData = any> {
	public t: <T = string>(key: TranslateParams, params?: HashMap, lang?: string) => T;
	public defaultColDef: ColDef<TData> = {
		resizable: true,
		sortable: true,
		filter: true,
		autoHeight: true
	};

	public domLayout: DomLayoutType = 'normal';
	public rowSelection: 'single' | 'multiple' = 'multiple';
	public getRowStyle = (params: RowClassParams<TData>): RowStyle | undefined => params.node.rowPinned ? { 'font-weight': 'bolder' } : undefined;

	public gridOptions: GridOptions<TData> = {
		defaultColDef: {
			resizable: true,
			sortable: true,
			filter: true,
			floatingFilter: true,
			autoHeight: true
		},
		context: { componentParent: this },
		domLayout: 'normal',
		rowSelection: 'multiple',
		pagination: true,
		suppressScrollOnNewData: true,
		getMainMenuItems: params => this.getMainMenuItems(params),
		onGridReady: readyEvent => {
			this.BaseOnGridReady(readyEvent);
		},
		getRowStyle: this.getRowStyle.bind(this)
	};

	protected readonly transloco: TranslocoService;
	readonly translocoHttpLoader: TranslocoHttpLoader;
	protected readonly baseRoute: ActivatedRoute;

	constructor(
		public router: Router,
		protected readonly injector: Injector
	) {
		this.transloco = injector.get(TranslocoService);
		this.translocoHttpLoader = injector.get(TranslocoHttpLoader);
		this.baseRoute = injector.get(ActivatedRoute);

		this.t = this.transloco.translate.bind(this.transloco);

		const lang = this.transloco.getActiveLang();
		this.translocoHttpLoader.getAgGridTranslation(lang)
			.subscribe(translation => {

				this.gridOptions.getLocaleText = params => {
					return translation[params.key] || params.defaultValue;
				};
			});
	}

	BaseOnGridReady(readyEvent: GridReadyEvent<TData>): void {
		const token = AuthHelper.getJwtTokenData();
		const gridsCache = localStorage.getItem(DG_USER_GRIDS);
		let componentUniqueId = this.baseRoute.snapshot.data?.breadcrumb;
		if (!componentUniqueId) {
			console.warn(`componentUniqueId not found. Cannot find breadcrumb for url:${this.router.url}, using url as a componentUniqueId`);
			componentUniqueId = this.router.url;
		}
		const gridKey = `${componentUniqueId}_${token.nameid}`;

		let userGrids: UserGrids = JSON.parse(gridsCache);
		if (userGrids) {
			const userGridState = userGrids[gridKey];
			if (gridsCache && userGridState) {
				if (userGridState.columnState) {
					readyEvent.columnApi.applyColumnState({
						state: userGridState.columnState,
						applyOrder: true
					});
				}
				if (userGridState.filterModel) {
					readyEvent.api.setFilterModel(userGridState.filterModel);
				}
			}
		}

		readyEvent.api.addGlobalListener((eventName, event) => {
			if (['columnResized', 'columnMoved', 'sortChanged', 'filterChanged', 'rowDragEnd', 'columnVisible'].includes(eventName)) {
				const columnStates = readyEvent.columnApi.getColumnState();
				const filterModel = readyEvent.api.getFilterModel();
				if (!userGrids) {
					userGrids = <UserGrids>{};
				}
				userGrids[gridKey] = {
					columnState: columnStates,
					filterModel: filterModel
				};
				localStorage.setItem(DG_USER_GRIDS, JSON.stringify(userGrids));
			}
		});
	}

	public RefreshGridLocale(gridApi: GridApi<TData>, unsubscribe$: Subject<void>): void {
		if (!this.transloco) {
			return;
		}
		combineLatest([
			this.transloco.langChanges$,
			this.transloco.events$.pipe(
				filter(e => e.type === 'translationLoadSuccess'),
				startWith(<TranslocoEvents>{})
			)
		])
			.pipe(takeUntil(unsubscribe$))
			.subscribe((payload) => {
				this.translocoHttpLoader.getAgGridTranslation(payload[0])
					.pipe(takeUntil(unsubscribe$))
					.subscribe(translation => {
						this.gridOptions.getLocaleText = params=> {
							return translation[params.key] || params.defaultValue;
						};
					});
				if (gridApi) {
					gridApi.refreshHeader();
				}
			});
	}

	public cellLinkRenderer(params: ICellRendererParams<TData>, navigateTo: string, queryParams?: Params ): HTMLAnchorElement {
		const eLink = document.createElement('a');
		eLink.innerHTML = params.value;
		eLink.addEventListener('click', (ev) => {
			if (ev.ctrlKey || ev.metaKey) {
				const url = this.router.serializeUrl(this.router.createUrlTree([navigateTo], { queryParams }));
				window.open(url, '_blank');
			} else {
				void this.router.navigate([navigateTo],  queryParams );
			}
		});

		return eLink;
	}

	public cellStatusRenderer(params: ICellRendererParams): HTMLSpanElement {
		const eStatus = document.createElement('span');

		eStatus.className = this.statusToLabelClass(params.data);
		eStatus.innerHTML = this.statusToLabel(params.data);

		return eStatus;
	}

	public getMainMenuItems(params: GetMainMenuItemsParams<TData>): string[] {

		switch (params.column.getId()) {
			default:
				const countryMenuItems = [];
				const itemsToExclude = ['separator', 'pinSubMenu', 'valueAggSubMenu'];
				params.defaultItems.forEach(item => {
					if (itemsToExclude.indexOf(item) < 0) {
						countryMenuItems.push(item);
					}
				});
				return countryMenuItems;
		}
	}

	public cellTooltipRenderer(params: ICellRendererParams): HTMLSpanElement {
		const eStatus = document.createElement('span');

		eStatus.className = 'tooltip tooltip--invoice-date';
		eStatus.innerHTML = this.formatDateTo(params.data.invoiceDate, 'L') +
			`<span> ${this.t('cell-renderer.generated-on_label')}` + this.formatDateTo(params.data.dateGenerated, 'H') + '</span>';

		return eStatus;
	}

	public cellCheckRenderer(params: ICellRendererParams, type: string): HTMLSpanElement {
		const eCheck = document.createElement('span');
		switch (type) {
			case 'isOnHold':
				eCheck.innerHTML = this.definePauseCell(params.data[type]);
				break;
			default:
				eCheck.innerHTML = this.defineCheckBoxCell(params.data[type]);
		}

		return eCheck;
	}

	private defineCheckBoxCell(item: boolean): string {
		return item ? '<i class="fa fa-check cell-check" aria-hidden="true"></i>' : '';
	}

	private definePauseCell(item: boolean): string {
		return item ? '<i style="color: #c7c7c7;" class="fa fa-pause" aria-hidden="true"></i>' : '';
	}

	static cellEmailStatus(params: ICellRendererParams): HTMLSpanElement {
		const eEmail = document.createElement('span');
		const status = params.data.status;

		if (status === 'Open' || status === 'Delivered') {
			eEmail.className = 'label label--success label--status';
		} else if (status === 'Dropped' || status === 'Bounce' || status === 'Deferred') {
			eEmail.className = 'label label--error label--status';
		}

		eEmail.innerHTML = params.data.status;

		return eEmail;
	}

	public cellNoteRenderer(params: ICellRendererParams): HTMLSpanElement {
		const eNote = document.createElement('span');

		if (params.data.existNotes) {
			eNote.innerHTML = '<i class="fa fa-sticky-note-o" aria-hidden="true"></i>';
			eNote.addEventListener('click', () => this.router.navigate([`customers/${params.data.customerId}/invoices/${params.data.id}/notes`]));
		} else {
			eNote.innerHTML = '';
		}

		return eNote;
	}

	public totalBottomRowData(toRound: number): string {
		const isInteger = !Number.isInteger(toRound) ? toRound.toFixed(2) : toRound.toFixed();
		return this.t('cell-renderer.total_label') + isInteger;
	}

	public toFixed2(toRound: number): string {
		return toRound.toFixed(2);
	}

	public formatDateTo(date: Date, type: string): string {
		if (!date) {
			return '';
		} else if (type === 'L') {
			return Moment.utc(date).format('L');
		} else if (type === 'H') {
			return Moment.utc(date).format('L h:mm A');
		}
	}

	static assignTags<TData extends { tags: Tag[]; }>(data: TData[], tags: Tag[]): void {
		data.forEach(item => {
			tags.forEach(tag => {
				item[tag.name] = '';

				if (item.tags) {
					const keyValue = item.tags.find(w => w.name === tag.name);
					if (keyValue) {
						item[tag.name] = keyValue.value;
					}
				}
			});
		});
	}

	static dateComparator(date1: string, date2: string): number {
		const date1Number = DgAgGridBase.monthToComparableNumber(date1);
		const date2Number = DgAgGridBase.monthToComparableNumber(date2);

		if (date1Number === undefined && date2Number === undefined) {
			return 0;
		}
		if (date1Number === undefined) {
			return -1;
		}
		if (date2Number === undefined) {
			return 1;
		}
		return date1Number - date2Number;
	}

	static monthToComparableNumber(date: string): number | undefined {
		// eslint-disable-next-line no-null/no-null
		if (date === undefined || date === null || date.length !== 10) {
			return undefined;
		}
		const yearNumber = +date.substring(6, 10);
		const monthNumber = +date.substring(3, 5);
		const dayNumber = +date.substring(0, 2);

		return yearNumber * 10000 + monthNumber * 100 + dayNumber;
	}

	static DefaultDueDate(id: number): string {
		const dueDateOptions: DueDateOption[] = DueDateOption.allOptions;
		return id !== 0 ? dueDateOptions.find(x => x.id === id).name : '';
	}

	static checkDate(date: string): string {
		return (date && Moment.utc(date).isBefore('2050-01-01') && Moment.utc(date).isValid()) ? Moment.utc(date).format('L') : '';
	}

	public getColumnIndex<TData>(columns: ColDef<TData>[], field: string): number {
		return columns.findIndex(x => x.field === field);
	}

	private statusToLabel(invoice: Invoice): string {
		return Labels.statusToLabel(invoice);
	}

	private statusToLabelClass(invoice: Invoice): string {
		return Labels.statusToLabelClass(invoice);
	}

}
