import { Serializable } from 'domain/models';
import { Guid } from 'domain/types';

export class OrganizationSettings implements Serializable<OrganizationSettings> {
	id?: Guid;
	companyName: string;
	address1: string;
	address2: string;
	phone: string;
	city: string;
	zipCode: string;
	bccEmailAddress: string;
	ccEmailAddress: string;
	senderEmail: string;
	checkPaymentTo: string;
	portalName: string;


	logoSmallURL?: string;
	accountsEmail: string;
	sendersEmail: string;
	bccEmails: string;
	ccEmails: string;
	gstNumber: string;
	swiftCode: string;
	bankAccount: string;
	invoiceAddress1: string;
	invoiceAddress2: string;
	invoiceCity: string;
	invoicePostCode: string;
	invoiceCompanyName: string;
	invoiceChequeTo: string;
	invoicePortalName: string;
	invoicePhone: string;
	invoiceTerms: string;
	invoiceTitle: string;

	paymentOptionLines: string;
	state: string;
	country: string;
	companyTimeZone: string;
	commissionRate: number;

	//Hidden fields:
	buildingFloorNumber: string;
	buildingFloorNumberSuffix: string;
	buildingFloorType: string;
	buildingLocation: string;
	buildingProperty: string;
	locality: string;
	streetFirstName: string;
	streetFirstSuffix: string;
	streetFirstType: string;
	streetHouseFirstNumber: string;
	streetHouseFirstNumberSuffix: string;
	streetHouseSecondNumber: string;
	streetHouseSecondNumberSuffix: string;
	streetSecondName: string;
	streetSecondSuffix: string;
	streetSecondType: string;
	unitFirstNumber: string;
	unitFirstSuffix: string;
	unitSecondNumber: string;
	unitSecondSuffix: string;
	unitType: string;
	validated: boolean;
	externalReference: string;
	schemeId: string;
	electronicAddressId: string;

	deserialize(json: any): OrganizationSettings {
		this.id = json.id;

		this.companyName = json.companyName;
		this.address1 = json.address1;
		this.address2 = json.address2;
		this.phone = json.phone;
		this.city = json.city;
		this.zipCode = json.zipCode;
		this.bccEmailAddress = json.bccEmailAddress;
		this.ccEmailAddress = json.ccEmailAddress;
		this.senderEmail = json.senderEmail;
		this.checkPaymentTo = json.checkPaymentTo;
		this.portalName = json.portalName;

		this.logoSmallURL = json.logoSmallURL;
		this.accountsEmail = json.accountsEmail;
		this.sendersEmail = json.sendersEmail;
		this.bccEmails = json.bccEmails;
		this.ccEmails = json.ccEmails;
		this.gstNumber = json.gstNumber;
		this.swiftCode = json.swiftCode;
		this.bankAccount = json.bankAccount;
		this.invoiceAddress1 = json.invoiceAddress1;
		this.invoiceAddress2 = json.invoiceAddress2;
		this.invoiceCity = json.invoiceCity;
		this.invoicePostCode = json.invoicePostCode;
		this.invoiceCompanyName = json.invoiceCompanyName;
		this.paymentOptionLines = json.paymentOptionLines;
		this.invoiceChequeTo = json.invoiceChequeTo;
		this.invoicePortalName = json.invoicePortalName;
		this.invoicePhone = json.invoicePhone;
		this.invoiceTerms = json.invoiceTerms;
		this.invoiceTitle = json.invoiceTitle;
		this.state = json.state;
		this.country = json.country;
		this.companyTimeZone = json.companyTimeZone;
		this.commissionRate = json.commissionRate ? Number(json.commissionRate.toFixed(4)) : null;
		this.buildingFloorNumber = json.buildingFloorNumber;
		this.buildingFloorNumberSuffix = json.buildingFloorNumberSuffix;
		this.buildingFloorType = json.buildingFloorType;
		this.buildingLocation = json.buildingLocation;
		this.buildingProperty = json.buildingProperty;
		this.locality = json.locality;
		this.streetFirstName = json.streetFirstName;
		this.streetFirstSuffix = json.streetFirstSuffix;
		this.streetFirstType = json.streetFirstType;
		this.streetHouseFirstNumber = json.streetHouseFirstNumber;
		this.streetHouseFirstNumberSuffix = json.streetHouseFirstNumberSuffix;
		this.streetHouseSecondNumber = json.streetHouseSecondNumber;
		this.streetHouseSecondNumberSuffix = json.streetHouseSecondNumberSuffix;
		this.streetSecondName = json.streetSecondName;
		this.streetSecondSuffix = json.streetSecondSuffix;
		this.streetSecondType = json.streetSecondType;
		this.unitFirstNumber = json.unitFirstNumber;
		this.unitFirstSuffix = json.unitFirstSuffix;
		this.unitSecondNumber = json.unitSecondNumber;
		this.unitSecondSuffix = json.unitSecondSuffix;
		this.unitType = json.unitType;
		this.validated = json.validated;
		this.externalReference = json.externalReference;
		this.schemeId = json.schemeId;
		this.electronicAddressId = json.electronicAddressId;
		return this;
	}

	clone(): OrganizationSettings {
		const clone = Object.assign(new OrganizationSettings(), this);
		return clone;
	}
}

export class PortalSettings implements Serializable<PortalSettings> {
	showCustomerBalance = false;
	showCustomerExtraFields: boolean;
	showServiceItemExtraFields: boolean;
	showProductExtraFields: boolean;
	showAgreementExtraFields: boolean;
	periodAutoLocking: number;
	maximumJobSize: number;
	priorPeriods: number;
	defaultBillingStartDay: number;
	exportProductMatchType: number;
	roundTaxes: boolean;
	defaultProRata: boolean;
	defaultPaymentPortalId: number;
	enabledTwoFactor: boolean;
	eventTwoFactor: boolean;
	recalculateInvoicedProductCharges: boolean;
	taxesForInvoiceSubtotal: boolean;
	useProductChargesHistoricity: boolean;
	allowMultiplePaymentPortals: boolean;
	useAutoBilling: boolean;
	doNotCreateDefaultAgreement: boolean;
	public businessUnitId: Guid;
	public businessUnit: string;
	public requiredGLCodeForProducts: boolean;
	public enableSpecialTerms: boolean;
	public defaultTelecomSpecialTermId: number;
	displayAgreementsOptionId: number;
	displayProductsOptionId: number;
	allowUseNextUninvoicedPeriodForUsage = false;
	currencyId: number;

	deserialize(json: any): PortalSettings {
		this.taxesForInvoiceSubtotal = json.taxesForInvoiceSubtotal;
		this.showCustomerExtraFields = json.showCustomerExtraFields;
		this.showServiceItemExtraFields = json.showServiceItemExtraFields;
		this.showProductExtraFields = json.showProductExtraFields;
		this.showAgreementExtraFields = json.showAgreementExtraFields;
		this.periodAutoLocking = json.periodAutoLocking;
		this.priorPeriods = json.priorPeriods;
		this.defaultBillingStartDay = json.defaultBillingStartDay;
		this.exportProductMatchType = json.exportProductMatchType;
		this.roundTaxes = json.roundTaxes;
		this.enabledTwoFactor = json.enabledTwoFactor || false;
		this.defaultProRata = json.defaultProRata;
		this.defaultPaymentPortalId = json.defaultPaymentPortalId;
		this.maximumJobSize = json.maximumJobSize;
		this.recalculateInvoicedProductCharges = json.recalculateInvoicedProductCharges;
		this.useProductChargesHistoricity = json.useProductChargesHistoricity;
		this.allowMultiplePaymentPortals = json.allowMultiplePaymentPortals;
		this.useAutoBilling = json.useAutoBilling;
		this.doNotCreateDefaultAgreement = json.doNotCreateDefaultAgreement;
		this.businessUnitId = json.businessUnitId;
		this.businessUnit = json.businessUnit;
		this.requiredGLCodeForProducts = json.requiredGLCodeForProducts;
		this.showCustomerBalance = json.showCustomerBalance;
		this.defaultTelecomSpecialTermId = json.defaultTelecomSpecialTermId;
		this.enableSpecialTerms = json.enableSpecialTerms;
		this.displayAgreementsOptionId = json.displayAgreementsOptionId;
		this.displayProductsOptionId = json.displayProductsOptionId;
		this.allowUseNextUninvoicedPeriodForUsage = json.allowUseNextUninvoicedPeriodForUsage;
		this.currencyId = json.currencyId;

		return this;
	}
}

export class EmailSetting implements Serializable<EmailSetting> {

	resellerId: number;
	dateFormat: string;

	deserialize(json: any): EmailSetting {

		this.resellerId = json.resellerId;
		this.dateFormat = json.dateFormat;

		return this;
	}
}

export class CurrencyEntity implements Serializable<CurrencyEntity> {

	id: number;
	code: string;
	name: string;

	deserialize(json: any): CurrencyEntity {

		this.id = json.id;
		this.code = json.code;
		this.name = json.name;

		return this;
	}
}

export class InvoiceSettings implements Serializable<InvoiceSettings> {
	invoiceLogoUrl: string;
	useAdditionalInvoiceFormat: boolean;
	useDifferentLayouts: boolean;
	invoiceLayoutId: Guid;
	agreementInvoiceLayoutId: Guid;
	miscellaneousInvoiceLayoutId: Guid;

	deserialize(json: any): InvoiceSettings {
		this.invoiceLogoUrl = json.invoiceLogoUrl;
		this.invoiceLayoutId = json.invoiceLayoutId;
		this.useAdditionalInvoiceFormat = json.useAdditionalInvoiceFormat;

		this.useDifferentLayouts = json.useDifferentLayouts;
		this.agreementInvoiceLayoutId = json.agreementInvoiceLayoutId;
		this.miscellaneousInvoiceLayoutId = json.miscellaneousInvoiceLayoutId;

		return this;
	}

	clone(): InvoiceSettings {
		return Object.assign(new InvoiceSettings(), this);
	}
}

export interface InvoiceControlSettings {
	invoicePrefix: string;
	invoiceNumber: number;
	nextInvoiceNumber: string;
}

export class ProviderTypesOptions {

	constructor(public id: number, public code: string) {

	}

	public static get allOptions(): ProviderTypesOptions[] {

		const result = [];

		result.push(new ProviderTypesOptions(0, 'ILEC'));
		result.push(new ProviderTypesOptions(1, 'IXC'));
		result.push(new ProviderTypesOptions(2, 'CLEC'));
		result.push(new ProviderTypesOptions(3, 'VOIP'));
		result.push(new ProviderTypesOptions(4, 'ISP'));
		result.push(new ProviderTypesOptions(5, 'Wireless'));
		result.push(new ProviderTypesOptions(99, 'Retail Sales'));

		return result;
	}
}

export class DeliveryEntity implements Serializable<DeliveryEntity> {
	id: Guid;
	name: string;
	isDefault: boolean;
	isEmail: boolean;
	isPrint: boolean;

	deserialize(json: any): DeliveryEntity {
		this.id = json.id;
		this.name = json.name;
		this.isDefault = json.default;
		this.isEmail = json.email;
		this.isPrint = json.print;

		return this;
	}
}

export class ExtraFieldEntity implements Serializable<ExtraFieldEntity> {
	extraField1: string;
	extraField2: string;
	extraField3: string;
	extraField4: string;
	extraField5: string;
	extraFieldType: string;
	extraFieldTypeId: number;

	deserialize(json: any): ExtraFieldEntity {
		this.extraField1 = json.extraField1;
		this.extraField2 = json.extraField2;
		this.extraField3 = json.extraField3;
		this.extraField4 = json.extraField4;
		this.extraField5 = json.extraField5;

		this.extraFieldType = json.extraFieldType;
		this.extraFieldTypeId = json.extraFieldTypeId;

		return this;
	}
}
