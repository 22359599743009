<form class="form form--export">
	<ul class="steps__wrapper">
		<li class="steps steps--active">
			<span class="steps__label">{{'global-export.step1_label' | transloco}}</span>
			<span class="steps__label">{{'at-invoice-number.step1_title' | transloco}}</span>
		</li>
	</ul>
	<div class="form__content">
		<!-- additional padding to display data from ng-select because of overlay -->
		<fieldset class="form__section steps--active" style="padding-bottom: 180px;">
			<div *ngIf="loading" class="app-spinner-cw"></div>
			<h3 class="form__section-title">
				{{'at-invoice-number.step1_title' | transloco}}
			</h3>
			<form #CwInvoiceSync="ngForm">
				<div class="form__group block">
					<div class="form__group-item form__group-item--1000-fs">
						<p>
							{{'at-invoice-number.step1_header-dg-invoice' | transloco}}
						</p>
						<p>
							{{'at-invoice-number.step1_header-dg-customer' | transloco}}
						</p>
						<p>
							{{'at-invoice-number.step1_header-at-company' | transloco}}
						</p>
						<p>
							{{'at-invoice-number.step1_header-at-invoice' | transloco}}
						</p>
					</div>

					<div *ngFor="let line of invoiceSyncMapping; let i = index"
						class="form__group-item form__group-item--1000-fs">
						<p>{{line.invoiceNumber}}</p>
						<p>{{line.customerLabel}}</p>
						<p>{{line.atCompanyName}}</p>
						<ng-select
							bindLabel="invoiceNumber"
							required
							bindValue="id"
							name="cwInvoiceId{{i}}"
							[items]="line.atInvoices"
							[searchable]="true"
							[clearable]="false"
							[(ngModel)]="line.selectedAtInvoiceId">
							<ng-template ng-option-tmp let-item="item">
								{{item.invoiceNumber}} <small><i *ngIf="item.synced" class="fa fa-check cell-check margin-l__10" aria-hidden="true"></i></small>
							</ng-template>
						</ng-select>
					</div>
				</div>

				<div *ngIf="invoiceSyncMapping" class="form__bottom form__bottom--border-none">
					<button type="button" class="btn btn--primary btn--import" [disabled]="!CwInvoiceSync.valid || loading || !invoiceSyncMapping.length"
						(click)="onCwInvoiceSyncProceed()">
						{{'at-invoice-number.step1_btn-proceed-label' | transloco}}
						<i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
					</button>
				</div>
			</form>
		</fieldset>
	</div>
</form>
