import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AuthHelper, HttpAuth } from 'core/auth';
import { AppConfig } from 'core/app-config';
import { ApiData } from 'domain/types';

@Injectable({
	providedIn: 'root'
})
export class VersionRepository {

	constructor(
		private readonly http: HttpAuth,
		private readonly config: AppConfig
	) {

	}

	getLatestVersion(): Observable<string> {
		const authDetails = AuthHelper.getAuthDetails();

		if (!authDetails) {
			return;
		}

		return this.http.get<ApiData<string>>(`${this.config.apiUrl}${this.config.apiVersion}/settings/ui-version`).pipe(
			map(res => res.body.data),
			catchError(_ => of(undefined))
		);
	}
}
