<div *ngIf="open" class="modal modal--tile">
	<div class="modal__content">
		<form class="modal__content-body">
			<div class="page-tile__body page-tile__body--hidden-none">
				<div class="page-tile__title"> <b>{{recordTitle}}</b></div>
				<div class="page-tile__group">
					<span>
						{{recordMsg}}
					</span>
				</div>
				<div class="page-tile__content">
					<div *ngFor="let items of records" class="page-tile__content-item">
						<span [hidden]="recordsLength === 1">{{items.entityName}} {{entityType}}:</span>
						<div *ngFor="let row of items | slice:0:items.show; let i=index" class="page-tile__content-row">
							<i class="fa fa-circle" aria-hidden="true"></i>
							<div [ngSwitch]="row.entityType">
								<div *ngSwitchCase="'Rate Card Record'">
									{{'dg-delete-entity.entity-rate_msg' | transloco: { rateCard: row?.rateCard, entityName: row?.entityName } }}
								</div>
								<div *ngSwitchCase="'Price Book Product'">
									{{'dg-delete-entity.entity-pb_msg' | transloco: { priceBook: row?.priceBook, entityName: row?.entityName } }}
								</div>
								<div *ngSwitchCase="'Rate Card or Price Book'">
									{{(row?.customerName && row?.productName ? 'dg-delete-entity.entity-rcr-cust-product_msg' : '') | transloco: { customerName: row?.customerName, productName: row?.productName, entityName: row?.entityName } }}
									{{(!row?.customerName && !row?.productName ? 'dg-delete-entity.entity-rcr_msg' : '') | transloco: { entityName: row?.entityName} }}

									{{(row?.customerName && !row?.productName ? 'dg-delete-entity.entity-rcr-cust_msg' : '') | transloco: { entityName: row?.entityName, customerName: row?.customerName } }}
									{{(!row?.customerName && row?.productName ? 'dg-delete-entity.entity-rcr-product_msg' : '') | transloco: { productName: row?.productName, entityName: row?.entityName } }}
								</div>
								<div *ngSwitchCase="'Product Template'">
									{{(row?.usedInCW ? 'dg-delete-entity.entity-product-template_msg' : 'dg-delete-entity.entity-default_msg') | transloco: { entityType: row?.entityType, entityName: row?.entityName } }}
								</div>
								<div *ngSwitchCase="'Integration'">
									{{'dg-delete-entity.entity-integration_msg' | transloco: { entityName: row?.entityName } }}
								</div>
								<div *ngSwitchDefault>
									{{(row?.customerName ? 'dg-delete-entity.entity-default-cust_msg' : 'dg-delete-entity.entity-default_msg') | transloco: { entityType: row?.entityType, entityName: row?.entityName, customerName: row?.customerName } }}
								</div>
							</div>

						</div>
						<div *ngIf="items.show < items.length" class="page-tile__content-more" (click)="onMore(items)">
							{{'dg-delete-entity.more-btn_label' | transloco}}
						</div>
					</div>
				</div>

				<div class="form__bottom">
					<button type="button" class="btn btn--secondary width-100px" [title]="'dg-delete-entity.close-btn_label' | transloco" (click)="onClose()">
						{{'dg-delete-entity.close-btn_label' | transloco}}
					</button>
				</div>
			</div>
		</form>
	</div>
</div>

<div *ngIf="open" class="fade"></div>
