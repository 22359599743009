import { HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpAuth } from 'core/auth';
import { ToasterService } from 'core/toaster-service';
import { XeroTenants } from 'domain/entities';
import { CacheService } from 'services/cache.service';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppConfig } from 'core/app-config';

@Injectable({
	providedIn: 'root'
})
export class XeroAuthService {
	constructor(
		private readonly http: HttpAuth,
		private readonly config: AppConfig,
		private readonly toasterService: ToasterService,
		private readonly cache: CacheService
	) {

	}

	public getXeroAccounts(skipCache: boolean = false): Observable<any> {
		const key = 'xero-accounts';

		if (!skipCache && this.cache.get(key))
			return of(this.cache.get(key));

		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/xero/accounts`)
			.pipe(
				map(res => {
					const result = res.body.data;
					this.cache.set(key, result);
					return result;
				})
				, catchError(this.handleError.bind(this)));
	}

	public getXeroPayments(skipCache: boolean = false): Observable<any> {
		const key = 'xero-payments';

		if (!skipCache && this.cache.get(key))
			return of(this.cache.get(key));

		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/xero/payments`)
			.pipe(
				map(res => {
					const result = res.body.data;
					this.cache.set(key, result);
					return result;
				})
				, catchError(this.handleError.bind(this)));
	}

	public getXeroAuthorization$(): Observable<any> {
		const key = 'xero-auth';

		if (this.cache.get(key))
			return of(this.cache.get(key));

		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/xero/connect`)
			.pipe(
				map(res => {
					const result = res.body.data;
					this.cache.set(key, result);
					return result;
				})
				, catchError(this.handleError.bind(this)));
	}

	async getXeroAuthorization(destroy$?: Subject<void>): Promise<any> {
		const res = await this.http.promise(destroy$).get(`${this.config.apiUrl}${this.config.apiVersion}/xero/connect`);
		return res.body.data;
	}

	public getXeroTest(): Observable<HttpResponse<void>> {

		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/xero/test`);
	}

	public getXeroTenants(uniqueId: string): Observable<XeroTenants[]> {
		const key = `xero-tenents-{uniqueId}`;

		if (this.cache.get(key))
			return of(this.cache.get(key));

		const params = new HttpParams()
			.set('uniqueId', uniqueId.toString());
		const requestOptions = { params: params };
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/xero/tenants`, requestOptions)
			.pipe(
				map(res => {
					return res.body.data.map(x => new XeroTenants().deserialize(x));
				})
				, catchError(this.handleError.bind(this)));
	}

	public postSelectedTenant(uniqueId: string, tenantId: number): Observable<HttpResponse<void>> {

		const requestOptions = {
			uniqueId: uniqueId,
			tenantId: tenantId
		};

		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/xero/select-tenant`, requestOptions)
			.pipe(catchError(this.handleError.bind(this)));

	}

	private handleError(error: HttpResponse<any> | any) {
		const xeroError = error.error;

		if (xeroError?.Message && xeroError.Status === 403) {
			this.toasterService.warning(xeroError.Message);
		}

		return throwError(() => error);
	}
}
