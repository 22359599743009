import { Injectable } from '@angular/core';
import { HttpAuth } from 'core/auth';
import {
	BillingStatus, BillingTerms, Department,
	ExportMultilineModel, ICWReturnMappingResponse, ICWReturnTaxesModel, Invoice, InvoiceMapping, InvoiceTemplate, Product,
	ProductMapping, SurchargeMapping, SurchargeSeparatelyMapping, SurchargeWithTaxMapping, SureTaxByStateLineMapping, SureTaxLineMapping,
	TaxMapping,
	TransactionMapping
} from 'domain/entities';
import { InvoiceActionsService } from 'shared/modules/invoice-actions';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfig } from 'core/app-config';

@Injectable({
	providedIn: 'root'
})
export class CWExportInvoiceRepository {
	constructor(
		private readonly http: HttpAuth,
		private readonly invoiceService: InvoiceActionsService,
		private readonly config: AppConfig
	) {
	}

	public GroupingTypePost(
		groupingType: number,
		invoices: Invoice[],
		force: boolean,
		excluded?: Invoice[],
		filterParams?: any,
		selectedVendorId?: number): Observable<any> {
		const model = {
			invoices: invoices.map(w => w.id),
			excludedInvoices: excluded.map(x => x.id),
			selectedAll: this.invoiceService.selectedAll,
			groupingType,
			force,
			selectedVendorId
		};
		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/connectwise/invoices-multiline/grouping-type`, model, { params: filterParams })
			.pipe(
				map(res => {
					const productsMapping = res.body.data.productsMapping.map(x => new ProductMapping().deserialize(x));
					const transactionsMapping = res.body.data.transactionsMapping.map(x => new TransactionMapping().deserialize(x));
					const taxMapping = res.body.data.taxMapping.map(x => new TaxMapping().deserialize(x));
					const surchargeMapping = res.body.data.surchargeMapping.map(x => new SurchargeMapping().deserialize(x));
					const surchargesWithTaxes = res.body.data.surchargeWithTaxMapping.map(x => new SurchargeWithTaxMapping().deserialize(x));
					const products = res.body.data.products.map(x => new Product().deserialize(x));
					const invoicesMapping = res.body.data.invoicesMapping.map(x => new InvoiceMapping().deserialize(x));
					const sureTaxLineMapping = res.body.data.sureTaxLinesMapping.map(x => new SureTaxLineMapping().deserialize(x));
					const sureTaxByStateLineMapping = res.body.data.sureTaxByStateLinesMapping.map(x => new SureTaxByStateLineMapping().deserialize(x));
					const surchargeSeparatelyMapping = res.body.data.surchargeSeparatelyMapping.map(x => new SurchargeSeparatelyMapping().deserialize(x));

					const responseModel = {
						invoicesMapping: invoicesMapping,
						productsMapping: productsMapping,
						transactionsMapping: transactionsMapping,
						taxMapping: taxMapping,
						surchargeMapping: surchargeMapping,
						surchargeWithTaxMapping: surchargesWithTaxes,
						products: products,
						sureTaxLineMapping: sureTaxLineMapping,
						sureTaxByStateLineMapping: sureTaxByStateLineMapping,
						surchargeSeparatelyMapping,
						invoices: res.body.data.invoices
					};
					return responseModel;
				}));
	}

	public GetCwGeneralData(): Observable<any> {
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/connectwise/invoices-multiline/general-data`)
			.pipe(
				map(res => {
					const departments = res.body.data.departments.map(x => new Department().deserialize(x));
					const billingStatuses = res.body.data.billingStatuses.map(x => new BillingStatus().deserialize(x));
					const billingTerms = res.body.data.billingTerms.map(x => new BillingTerms().deserialize(x));
					const invoiceTemplates = res.body.data.invoiceTemplates.map(x => new InvoiceTemplate().deserialize(x));

					const responseModel = {
						departments: departments,
						billingStatuses: billingStatuses,
						billingTerms: billingTerms,
						invoiceTemplates: invoiceTemplates
					};
					return responseModel;
				}));
	}

	public export(model: ExportMultilineModel): Observable<any> {
		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/connectwise/invoices-multiline/export`, model)
			.pipe(map(res => res.body.data));
	}

	public cwReturnTaxesprepare(
		invoices: Invoice[],
		excluded?: Invoice[],
		filterParams?: any
	): Observable<ICWReturnMappingResponse> {

		const model = {
			invoices: invoices.map(w => w.id),
			excludedInvoices: excluded.map(x => x.id),
			selectedAll: this.invoiceService.selectedAll
		};

		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/connectwise/return-taxes/data-prepare`, model, { params: filterParams })
			.pipe(
				map(res => {
					return res.body.data;
				}));
	}

	public exportCwTaxes(exportData: ICWReturnTaxesModel) {
		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/connectwise/return-taxes/export`, exportData)
			.pipe(
				map(res => res.body.data));
	}
}
