import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { HashMap, TranslateParams, TranslocoService } from '@ngneat/transloco';

@Component({
	selector: 'dg-delete-entity',
	templateUrl: './dg-delete-entity.component.html',
	styleUrls: ['./dg-delete-entity.component.sass']
})
export class DgDeleteEntityComponent implements OnChanges{
	@Input() open = true;
	@Input() entityType: string;
	@Input() records: any;
	@Output() close = new EventEmitter<void>();
	recordsLables: string[];
	show = 7;
	t: <T = string>(key: TranslateParams, params?: HashMap, lang?: string) => T;

	constructor(public readonly transloco: TranslocoService) {
		this.t = transloco.translate.bind(transloco);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.records.currentValue) {
			this.records = Object.keys(changes.records.currentValue).map(key => {
				const currentValue = changes.records.currentValue[key];
				currentValue.show = this.show;
				currentValue.entityName = key;
				return currentValue;
			});
		}
	}

	public onClose(): void {
		this.close.emit();
	}

	get recordsLength(): number {
		return this.records.length;
	}

	get recordTitle(): string {
		return this.recordsLength === 1 ? this.t('dg-delete-entity.record_title') : this.t('dg-delete-entity.records_title');
	}

	get recordMsg(): string {
		return this.recordsLength === 1 ? this.t('dg-delete-entity.record_msg') : this.t('dg-delete-entity.records_msg');
	}

	onMore(items: any): void {
		items.show = items.length;
	}
}
