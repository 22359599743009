import { Injectable } from '@angular/core';
import { AppConfig } from 'core/app-config';
import { HttpAuth } from 'core/auth';
import { ApiData, Guid } from 'domain/types';
import { EmailTemplate, EmailTemplateResponse, EmailTemplateTest } from 'domain/entities';
import { Subject } from 'rxjs';
import { BaseRepository } from './base-repository';
import { CacheService } from 'services/cache.service';

@Injectable({
	providedIn: 'root'
})
export class EmailTemplateRepository extends BaseRepository {

	constructor(
		http: HttpAuth,
		config: AppConfig,
		cache: CacheService
	) {
		super(http, config, cache);
	}

	async getEmailTemplatesResponse(destroy$?: Subject<void>): Promise<EmailTemplateResponse> {
		const emailTemplatesResponse = await this.http.promise(destroy$).get<ApiData<EmailTemplateResponse>>(`${this.config.apiUrl}${this.config.apiVersion}/email-templates`);
		return emailTemplatesResponse.body?.data;
	}

	async getEmailTemplates(destroy$?: Subject<void>): Promise<EmailTemplate[]> {
		const templatesResponse = await this.http.promise(destroy$).get<ApiData<EmailTemplateResponse>>(`${this.config.apiUrl}${this.config.apiVersion}/email-templates`);
		return templatesResponse.body?.data.templates;
	}

	async addEmailTemplate(instance: EmailTemplate, destroy$?: Subject<void>): Promise<void> {
		const res = await this.http.promise(destroy$).post(`${this.config.apiUrl}${this.config.apiVersion}/email-templates`, instance);
		return res.body;
	}

	async updateEmailTemplate(instance: EmailTemplate, destroy$?: Subject<void>): Promise<void> {
		const res = await this.http.promise(destroy$).put(`${this.config.apiUrl}${this.config.apiVersion}/email-templates/${instance.id}`, instance);
		return res.body;
	}

	async emailTestSend(instance: EmailTemplateTest, destroy$?: Subject<void>): Promise<void> {
		const res = await this.http.promise(destroy$).post(`${this.config.apiUrl}${this.config.apiVersion}/email-templates-test`, instance);
		return res.body;
	}

	async deleteEmailTemplate(templateId: Guid, destroy$?: Subject<void>): Promise<void>  {
		const res = await this.http.promise(destroy$).delete(`${this.config.apiUrl}${this.config.apiVersion}/email-templates/${templateId}`);
		return res.body;
	}
}
