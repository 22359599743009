import { ApiError } from 'domain/types';

export function hasApiError(entity: unknown): entity is ApiError {
	return !!entity &&
		typeof entity === 'object' &&
		'errorCode' in entity &&
		'userMessage' in entity;
}

export function getApiErrorTitle(apiError: ApiError): string {
	let title = `Api Error ${apiError.errorCode}`;

	if ((apiError.errorCode >= 3000 && apiError.errorCode < 4000) ||
		(apiError.errorCode >= 4000 && apiError.errorCode < 5000) ||
		(apiError.errorCode >= 5000 && apiError.errorCode < 6000) ||
		(apiError.errorCode >= 7000 && apiError.errorCode < 8000)) {
		title = `Validation Error`;
	}

	return title;
}

export function getApiErrorMessage(apiError: ApiError): string {
	let errorMessage = `${apiError.userMessage}`;
	if (apiError.references && typeof apiError.references === 'string') {
		errorMessage += '\n' + apiError.references;
	} else if (Array.isArray(apiError.references) && apiError.references.length > 0) {
		errorMessage += '\n' + apiError.references.join(', ');
	}

	return errorMessage;
}
