import * as Integrations from 'pages/+portal-settings/portal-integrations/integration-service';
import { Serializable } from 'domain/models';
import { Guid } from 'domain/types';

export class IpndEntity implements Serializable<IpndEntity> {
	id: Guid;
	accountDetails: string;
	source: string;
	alt: string = Integrations.IntegrationService.Ipnd;
	iconSrc = 'Ipnd_logo.png';
	title = 'IPND (Integrated Public Number Database)';
	isConfigured = false;
	deserialize(json: any): IpndEntity {
		this.id = json.id;
		this.accountDetails = json.accountDetails;
		this.source = json.source;
		
		if (this.accountDetails.length) {
			this.isConfigured = true;
		}

		return this;
	}
}

export interface IpndListType extends BaseIpndType {}

export interface IpndUsageType extends BaseIpndType {}

export interface IpndServiceType extends BaseIpndType {}

interface BaseIpndType {
	id: number;
	name: string;
}
