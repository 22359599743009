import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class PollingControlService {
	private stopBackgroundPollingSubject = new Subject<void>();

	get stopPolling$(): Observable<void> {
		return this.stopBackgroundPollingSubject.asObservable();
	}

	stopBackgroundPolling(): void {
		this.stopBackgroundPollingSubject.next();
	}
}
