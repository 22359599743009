import { Location } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ICWReturnMappingResponse, ICWReturnTaxesModel, Invoice } from 'domain/entities';
import { CWExportInvoiceRepository } from 'repositories';
import { Subject, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { NavigatorService } from 'services';
import { CacheModel, CacheService } from 'services/cache.service';
import { InvoiceActionsService } from 'shared/modules/invoice-actions';

@Component({
	selector: 'dg-cw-return-taxes',
	templateUrl: './cw-return-taxes.component.html',
	styleUrls: ['./cw-return-taxes.component.sass']
})
export class CwReturnTaxesComponent implements OnDestroy {
	currentStep: number;
	invoices: Invoice[];
	excluded: Invoice[];
	cwReturnTaxes: ICWReturnMappingResponse;

	public loading = false;
	public sureTaxMultiline = false;
	public groupTaxByState = false;
	private cacheModel: CacheModel;
	private cacheModelExcluded: CacheModel;
	private destroy$ = new Subject<void>();
	constructor(
		private readonly cache: CacheService,
		private readonly navigator: NavigatorService,
		private readonly router: Router,
		private readonly cwRepository: CWExportInvoiceRepository,
		private readonly invoiceRepo: InvoiceActionsService,
		private readonly location: Location
	) {
		this.currentStep = 1;

		this.cacheModel = this.cache.get('exportCWReturnTaxes');
		this.cacheModelExcluded = this.cache.get('exportCWReturnTaxes-excluded');

		this.invoices = this.cacheModel ? <Invoice[]>this.cacheModel.Object : [];
		this.excluded = this.cacheModelExcluded ? <Invoice[]>this.cacheModelExcluded.Object : [];

		if (!this.invoices.length) {
			this.location.back();
		} else {
			this.cwReturnTaxesPrepare();
		}
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	public cwReturnTaxesPrepare(): void {
		this.loading = true;

		const actionFilters = this.cache.get('invoices-action-filterParams');
		const filterParams = this.invoiceRepo.selectedAll && actionFilters ? actionFilters : '';

		this.cwRepository.cwReturnTaxesprepare(this.invoices, this.excluded, filterParams)
			.pipe(
				takeUntil(this.destroy$),
				catchError(response => {
					this.loading = false;
					return throwError(() => response);
				})).subscribe((prepareData) => {
				this.cwReturnTaxes = prepareData;
				this.loading = false;
			});
	}

	public onExport(): void {
		this.loading = true;

		const exportModel: ICWReturnTaxesModel = {
			tag: this.cacheModel.Tag,
			invoices: this.invoices.map(x => x.id),
			taxForState: this.groupTaxByState,
			taxMultiline: this.sureTaxMultiline,
			taxesLinesResponses: this.cwReturnTaxes.taxesLinesResponses,
			taxesLinesStateResponses: this.cwReturnTaxes.taxesLinesStateResponses,
			totalTaxesResponses: this.cwReturnTaxes.totalTaxesResponses
		};

		this.cwRepository.exportCwTaxes(exportModel)
			.pipe(
				takeUntil(this.destroy$),
				catchError(response => {
					this.loading = false;
					return throwError(() => response);
				}))
			.subscribe(result => {
				if (result.operationId) {
					this.navigator.toOperation(result.operationId);
				}
			});
	}

	onBackTo(): void {
		const backUrl = (this.cacheModel.Tag.split(':').pop()).slice(2, -2);
		void this.router.navigate([backUrl]);
	}
}
