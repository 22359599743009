import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { AuthHelper, HttpAuth } from 'core/auth';
import { Notification } from 'domain/entities';
import { AppConfig } from 'core/app-config';

@Injectable({
	providedIn: 'root'
})
export class NotificationRepository {

	constructor(
		private readonly http: HttpAuth,
		private readonly config: AppConfig
	) {

	}

	getAll(): Observable<Notification[]> {
		const authDetails = AuthHelper.getAuthDetails();

		if (!authDetails) {
			return;
		}

		return this.http.get<Notification[]>(`${this.config.apiUrl}${this.config.apiVersion}/notifications`).pipe(
			map(res => res.body.map(x => new Notification().deserialize(x))),
			catchError(_ => of([]))
		);

	}

	suppressNotification(): Observable<any> {
		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/notifications/suppress`, null, null);
	}

	markAsSeen(notifications: Notification[]): Observable<HttpResponse<any>> {

		const data = notifications
			.map((x) => {
				return { id: x.id };
			});

		return this.http.patch(`${this.config.apiUrl}${this.config.apiVersion}/notifications`, data, null);
	}
}
