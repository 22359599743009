import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as Moment from 'moment';
import { Constants } from 'tools/constants';
@Pipe({ name: 'groupBy' })
export class GroupByPipe implements PipeTransform {
	transform(collection: any[], property: string): any {
		// prevents the application from breaking if the array of objects doesn't exist yet
		if (!collection) {
			return null;
		}

		const groupedCollection = collection.reduce((previous, current) => {
			if (!previous[current[property]]) {
				previous[current[property]] = [current];
			} else {
				previous[current[property]].push(current);
			}

			return previous;
		}, {});
		// this will return an array of objects, each object containing a group of objects
		return Object.keys(groupedCollection).map(key => ({ key, value: groupedCollection[key] }));
	}
}

@Pipe({ name: 'sort' })
export class ArraySortPipe implements PipeTransform {
	transform(array: any, field: string[]): any[] {
		if (!Array.isArray(array)) {
			return;
		}
		array.sort((a: any, b: any) => {
			if (this.isNull(a[field[1]]) && !this.isNull(b[field[1]])) {
				return 1;
			} else if (!this.isNull(a[field[1]]) && this.isNull(b[field[1]])) {
				return -1;
			} else {
				if (a[field[0]] < b[field[0]]) {
					return -1;
				} else if (a[field[0]] > b[field[0]]) {
					return 1;
				} else {
					return 0;
				}
			}
		});
		return array;
	}

	isNull(item: any): boolean {
		if (item) {
			return true;
		} else {
			return false;
		}
	}
}

@Pipe({
	name: 'momentDoDate'
})
export class MomentDoDate implements PipeTransform {
	transform(date: Date): string {
		return Moment.tz(date, Constants.DefaultTimzone).format('Do');
	}
}

@Pipe({
	name: 'momentFullDate'
})
export class MomentFullDate implements PipeTransform {
	transform(date: Date): string {
		return Moment.tz(date, Constants.DefaultTimzone).format('dddd, MMMM Do YYYY');
	}
}

@Pipe({
	name: 'splitByCaps'
})
export class SplitByCapsPipe implements PipeTransform {
	// ExampleString -> Example String
	transform(value: string): string {
		return value ? value.split(/(?=[A-Z])/).join(' ') : '';
	}
}

@Pipe({ name: 'sanitizeHtml' })
export class SanitizeHtmlPipe implements PipeTransform {

	constructor(private _sanitizer: DomSanitizer) { }

	transform(value: string): SafeHtml {
		return this._sanitizer.bypassSecurityTrustHtml(value);
	}

}
