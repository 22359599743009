import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as Moment from 'moment-timezone';

@Component({
	selector: 'dg-date-selector',
	templateUrl: './dg-date-selector.component.html',
	styleUrls: ['./dg-date-selector.component.sass']
})
export class DgDateSelectorComponent implements OnInit {

	@Input() field: string;
	selectedYear: number;
	selectedMonth: string;
	selectedMonthName: string;
	years: number[];
	months: string[];
	firstYear: number;
	lastYear: number;
	selectYearForm: boolean;
	selectMonthForm: boolean;
	selectDateForm: boolean;

	currentYear: number;
	daysOfCurrentMonth = [];
	daysOfPreviousMonth = [];
	daysOfNextMonth = [];

	@Output() result: EventEmitter<string> = new EventEmitter<string>();

	constructor() {
	}

	ngOnInit(): void {
		this.months = Moment.months();
		this.months.forEach((part, index, theArray) => {
			theArray[index] = theArray[index].toUpperCase().substring(3, 0);
		});
		this.getYears();
		this.selectedYear = this.currentYear;
		this.selectedMonthName = this.months[Number(this.selectedMonth) - 1];
		this.getDays();
		this.selectDateForm = true;
	}

	selectDay(day: number): void {
		const dayStr = (day < 10) ? '0'.concat(day.toString()) : day.toString();

		this.field = `${this.selectedYear}-${this.selectedMonth}-${dayStr}`;
		this.selectDateForm = true;

		this.result.emit(this.field);
	}

	getYears(): void {
		const currentDate = this.field || Moment().format('YYYY-MM-DD');
		this.currentYear = Number(currentDate.split('-')[0]);

		const a = this.months.indexOf(Moment(currentDate).format('MMMM').toUpperCase().substring(3, 0)) + 1;
		if (a < 10) {
			this.selectedMonth = '0'.concat(a.toString());
		} else {
			this.selectedMonth = a.toString();
		}
		this.years = [];
		this.firstYear = this.currentYear - 6;
		this.lastYear = this.currentYear + 5;
		for (let i = this.firstYear; i <= this.lastYear; i++) {
			this.years.push(i);
		}
	}

	getBackToMonth(): void {
		this.selectDateForm = false;
		this.selectMonthForm = true;
	}

	getBackToYear(): void {
		this.selectMonthForm = false;
		this.selectYearForm = true;
	}

	navigateToPrevYears(): void {
		this.lastYear = this.firstYear - 1;
		this.firstYear = this.firstYear - 12;
		this.years = [];
		for (let i = this.firstYear; i <= this.lastYear; i++) {
			this.years.push(i);
		}
	}

	navigateToNextYears(): void {
		this.firstYear = this.lastYear + 1;
		this.lastYear = this.lastYear + 12;
		this.years = [];
		for (let i = this.firstYear; i <= this.lastYear; i++) {
			this.years.push(i);
		}
	}

	goToPrevMonth(): void {
		if (Number(this.selectedMonth) !== 1) {
			const month = this.months[Number(this.selectedMonth) - 2];
			this.showSelectDate(month);
		} else {
			const month = this.months[11];
			this.selectedYear = this.selectedYear - 1;
			this.showSelectDate(month);
		}
	}

	goToNextMonth(): void {
		if (Number(this.selectedMonth) !== 12) {
			const month = this.months[Number(this.selectedMonth)];
			this.showSelectDate(month);
		} else {
			const month = this.months[0];
			this.selectedYear = this.selectedYear + 1;
			this.showSelectDate(month);
		}
	}

	showSelectDate(month: string): void {
		this.selectedMonthName = month;
		const i = this.months.indexOf(month) + 1;

		if (i < 10) {
			this.selectedMonth = '0'.concat(i.toString());
		} else {
			this.selectedMonth = i.toString();
		}

		this.getDays();
		this.selectMonthForm = false;
		this.selectDateForm = true;
	}

	getDays(): void {
		const days = new Date(this.selectedYear, Number(this.selectedMonth), 0).getDate();

		this.daysOfCurrentMonth = [];
		this.daysOfPreviousMonth = [];
		this.daysOfNextMonth = [];
		for (let i = 1; i <= days; i++) {
			this.daysOfCurrentMonth.push(i);
		}
		this.getPreviousMonthDays();
		this.getNextMonthDays();
	}
	getPreviousMonthDays(): void {
		if (this.selectedMonth !== '01') {
			const days = new Date(this.selectedYear, (Number(this.selectedMonth) - 1), 0).getDate();
			for (let i = 1; i <= days; i++) {
				this.daysOfPreviousMonth.push(i);
			}
		} else {
			const days = new Date(this.selectedYear - 1, 12, 0).getDate();
			for (let i = 1; i <= days; i++) {
				this.daysOfPreviousMonth.push(i);
			}
		}

		const d = this.parseDate(`${this.selectedYear}-${Number(this.selectedMonth)}-${this.daysOfCurrentMonth[0]}`);
		const dayOfWeek = d.toString().substring(3, 0);
		const dayIndex = 7 - this.getDayIndex(dayOfWeek);

		this.daysOfPreviousMonth = this.daysOfPreviousMonth.slice(this.daysOfPreviousMonth.length - dayIndex,
			this.daysOfPreviousMonth.length);
	}

	checkCurrentYear(year: number): string {
		return this.currentYear === year ? 'period-picker__grid-cell--current' : '';
	}

	checkCurrentDay(day: number): string {
		const currentDate = this.field || Moment().format('YYYY-MM-DD');

		if (day < 10) {
			const selectedDay = '0'.concat(day.toString());
			const date = `${this.selectedYear}-${this.selectedMonth}-${selectedDay}`;
			return currentDate === date ? 'period-picker__grid-cell--current' : '';

		} else {
			const date = `${this.selectedYear}-${this.selectedMonth}-${day}`;
			return currentDate === date ? 'period-picker__grid-cell--current' : '';
		}
	}

	checkCurrentMonth(month: string): string {
		if (this.currentYear === this.selectedYear) {
			const currentDate = this.field || Moment().format('YYYY-MM-DD');

			const currentMonth = Moment(currentDate).format('MMMM').toUpperCase().substring(3, 0);
			return currentMonth === month ? 'period-picker__grid-cell--current' : '';
		} else {
			return '';
		}
	}

	getNextMonthDays(): void {
		let days: number;

		if (this.selectedMonth !== '12') {
			days = new Date(this.selectedYear, (Number(this.selectedMonth) + 1), 0).getDate();
			for (let i = 1; i <= days; i++) {
				this.daysOfNextMonth.push(i);
			}
		} else {
			days = new Date(this.selectedYear + 1, 1, 0).getDate();
			for (let i = 1; i <= days; i++) {
				this.daysOfNextMonth.push(i);
			}
		}
		const d = this.parseDate(`${this.selectedYear}-${Number(
			this.selectedMonth)}-${this.daysOfCurrentMonth[this.daysOfCurrentMonth.length - 1]}`);
		const dayOfWeek = d.toString().substring(3, 0);
		const dayIndex = this.getDayIndex(dayOfWeek);
		if (this.daysOfPreviousMonth.length + dayIndex > 8) {
			this.daysOfNextMonth = this.daysOfNextMonth.slice(0, dayIndex - 1);
		} else {
			this.daysOfNextMonth = this.daysOfNextMonth.slice(0, dayIndex + 6);
		}
	}

	getDayIndex(day: string): number {
		switch (day) {
			case 'Sun':
				return 1;
			case 'Sat':
				return 2;
			case 'Fri':
				return 3;
			case 'Thu':
				return 4;
			case 'Wed':
				return 5;
			case 'Tue':
				return 6;
			case 'Mon':
				return 7;
		}
	}

	parseDate(input: string): Date {
		const parts = input.match(/(\d+)/g);
		return new Date(Number(parts[0]), Number(parts[1]) - 1, Number(parts[2]));
	}

	navigateToNextYear(): void {
		this.selectedYear = this.selectedYear + 1;
	}

	navigateToPrevYear(): void {
		this.selectedYear = this.selectedYear - 1;
	}

	showSelectMonth(year: number): void {
		this.selectedYear = year;
		this.selectYearForm = false;
		this.selectMonthForm = true;
	}
}
