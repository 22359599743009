import { Component, Injector, ViewContainerRef } from '@angular/core';
import {
	// import as RouterEvent to avoid confusion with the DOM Event
	Event as RouterEvent, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router
} from '@angular/router';
import { NgSelectConfig } from '@ng-select/ng-select';
import { HashMap, TranslateParams, TranslocoService } from '@ngneat/transloco';


@Component({
	selector: 'dg-app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent {
	loading = true;
	public t: <T = string>(key: TranslateParams, params?: HashMap, lang?: string) => T;
	constructor(
		private readonly vcr: ViewContainerRef,
		private readonly injector: Injector,
		readonly router: Router,
		readonly transloco: TranslocoService,
		private readonly ngSelectConfig: NgSelectConfig
	) {
		// Hook up app.module injector for global app use
		window.DgGlobals.appModuleInjector = injector;

		this.t = transloco.translate.bind(transloco);
		const lang = this.transloco.getActiveLang();
		this.transloco.load(lang)
			.subscribe(() => {
				this.ngSelectConfig.notFoundText = this.t('ng-select.notFoundText');
				this.ngSelectConfig.clearAllText = this.t('ng-select.clearAllText');

			});
		router.events.subscribe((event: RouterEvent) => {
			this.navigationInterceptor(event);
		});
	}

	// Shows and hides the loading spinner during RouterEvent changes
	navigationInterceptor(event: RouterEvent): void {
		if (event instanceof NavigationStart) {
			this.loading = true;
		}
		if (event instanceof NavigationEnd) {
			this.loading = false;
		}

		// Set loading state to false in both of the below events to hide the spinner in case a request fails
		if (event instanceof NavigationCancel) {
			this.loading = false;
		}
		if (event instanceof NavigationError) {
			this.loading = false;
		}
	}

}
