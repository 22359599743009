import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class DgAlertService {
	// Observable string sources
	private notificationSource = new Subject<DgAlert>();

	// Observable string streams
	showAlert$ = this.notificationSource.asObservable();

	// Service message commands
	show(message: string, type: string): void {
		const alert = new DgAlert(message, type);
		this.notificationSource.next(alert);
	}

	error(message: string): void {
		const alert = new DgAlert(message, 'error');
		this.notificationSource.next(alert);
	}

	warning(message: string): void {
		const alert = new DgAlert(message, 'warning');
		this.notificationSource.next(alert);
	}
}

export class DgAlert {
	constructor(public message: string, public type: string) {
	}
}
