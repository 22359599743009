import 'reflect-metadata';

export function EntityName(singular: string, plural: string) {
	return (ctor: Function) => {
		Reflect.defineMetadata('EntityNameDecoratorKey', { singular, plural }, ctor);
	};
}

export function getEntityNames(ctor: Function): any {
	return Reflect.getOwnMetadata('EntityNameDecoratorKey', ctor);
}
