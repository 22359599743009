import { Injectable } from '@angular/core';
import { ActiveToast, IndividualConfig, ToastrService } from 'ngx-toastr';
import { TranslocoService, TranslateParams, HashMap } from '@ngneat/transloco';

@Injectable()
export class ToasterService {
	private t: <T = string>(key: TranslateParams, params?: HashMap, lang?: string) => T;

	private configError: Partial<IndividualConfig> = {
		toastClass: 'ngx-toastr dg-toast',
		disableTimeOut: true,
		easeTime: 150,
	};
	private configWarning: Partial<IndividualConfig> = {
		toastClass: 'ngx-toastr dg-toast',
		timeOut: 10000
	};
	private configSuccess: Partial<IndividualConfig> = {
		toastClass: 'ngx-toastr dg-toast',
		timeOut: 5000
	};
	private configInfo: Partial<IndividualConfig> = {
		toastClass: 'ngx-toastr dg-toast',
		timeOut: 15000,
		progressBar: true,
		tapToDismiss: false,
		enableHtml: true,
		closeButton: true
	};

	constructor(
		private toastrService: ToastrService,
		public readonly transloco: TranslocoService
	) {
		this.t = transloco.translate.bind(transloco);
	}

	/**
	 * Displays a success toast with the specified message, title, and configuration.
	 * @param message - The message to be displayed in the toast.
	 * @param title - The title to be displayed in the toast.
	 * @param config - The configuration options for the toast.
	 */
	success<TPayload>(message?: string, title?: string, config?: Partial<IndividualConfig<TPayload>>): ActiveToast<TPayload>;
	/**
	 * Displays a success toast with the specified message and configuration, using the default title.
	 * @param message - The message to be displayed in the toast.
	 * @param config - The configuration options for the toast.
	 */
	success<TPayload>(message: string, config: Partial<IndividualConfig<TPayload>>): ActiveToast<TPayload>;
	/**
	 * Displays a success toast with the specified configuration, using the default message and title.
	 * @param config - The configuration options for the toast.
	 */
	success<TPayload>(config: Partial<IndividualConfig<TPayload>>): ActiveToast<TPayload>;
	/**
	 * Displays a success toast, using the default message, title and configuration.
	 */
	success<TPayload>(): ActiveToast<TPayload>;
	success<TPayload>(message?: string | Partial<IndividualConfig<TPayload>>, titleOrConfig?: string | Partial<IndividualConfig<TPayload>>, config?: Partial<IndividualConfig<TPayload>>): ActiveToast<TPayload> {
		const successMsg = this.t(typeof message === 'string' ? message : undefined) ?? this.t('toaster-service.toast-success-generic_msg');
		const titleText = this.t(typeof titleOrConfig === 'string' ? titleOrConfig : undefined) ?? this.t('toaster-service.toast-success_title');
		const mergedConfig = (typeof message !== 'string' && message) || (typeof titleOrConfig !== 'string' && titleOrConfig) || config || {};

		return this.toastrService.success<TPayload>(successMsg, titleText, { ...this.configSuccess, ...mergedConfig });
	}

	/**
	* Displays an info toast with the specified message, title, and configuration.
	* @param message - The message to be displayed in the toast.
	* @param title - The title to be displayed in the toast.
	* @param config - The configuration options for the toast.
	*/
	info<TPayload>(message: string, title?: string, config?: Partial<IndividualConfig<TPayload>>): ActiveToast<TPayload>;
	/**
	* Displays an info toast with the specified message and configuration, using the default title.
	* @param message - The message to be displayed in the toast.
	* @param config - The configuration options for the toast.
	*/
	info<TPayload>(message: string, config: Partial<IndividualConfig<TPayload>>): ActiveToast<TPayload>;
	info<TPayload>(message: string, titleOrConfig?: string | Partial<IndividualConfig<TPayload>> | undefined, config?: Partial<IndividualConfig<TPayload>>): ActiveToast<TPayload> {
		const isTitleDefined = typeof titleOrConfig === 'string' || titleOrConfig === undefined;
		const titleText = this.t(isTitleDefined ? (<string>titleOrConfig || 'toaster-service.toast-info_title') : 'toaster-service.toast-info_title');
		const mergedConfig = isTitleDefined ? config : titleOrConfig;

		return this.toastrService.info<TPayload>(this.t(message), titleText, { ...this.configInfo, ...mergedConfig });
	}

	/**
	 * Displays a warning toast with the specified message, title, and configuration.
	 * @param message - The message to be displayed in the toast.
	 * @param title - The title to be displayed in the toast.
	 * @param config - The configuration options for the toast.
	 */
	warning<TPayload>(message: string, title?: string, config?: Partial<IndividualConfig<TPayload>>): ActiveToast<TPayload>;
	/**
	 * Displays a warning toast with the specified message and configuration, using the default title.
	 * @param message - The message to be displayed in the toast.
	 * @param config - The configuration options for the toast.
	 */
	warning<TPayload>(message: string, config: Partial<IndividualConfig<TPayload>>): ActiveToast<TPayload>;
	warning<TPayload>(message: string, titleOrConfig?: string | Partial<IndividualConfig<TPayload>> | undefined, config?: Partial<IndividualConfig<TPayload>>): ActiveToast<TPayload> {
		const isTitleDefined = typeof titleOrConfig === 'string' || titleOrConfig === undefined;
		const titleText = this.t(isTitleDefined ? (<string>titleOrConfig || 'toaster-service.toast-warning_title') : 'toaster-service.toast-warning_title');
		const mergedConfig = isTitleDefined ? config : titleOrConfig;

		return this.toastrService.warning<TPayload>(this.t(message), titleText, { ...this.configWarning, ...mergedConfig });
	}

	/**
	 * Displays an error toast with the specified message, title, and configuration.
	 * @param message - The message to be displayed in the toast.
	 * @param title - The title to be displayed in the toast.
	 * @param config - The configuration options for the toast.
	 */
	error<TPayload>(message?: string, title?: string, config?: Partial<IndividualConfig<TPayload>>): ActiveToast<TPayload>;
	/**
	 * Displays an error toast with the specified message and configuration, using the default title.
	 * @param message - The message to be displayed in the toast.
	 * @param config - The configuration options for the toast.
	 */
	error<TPayload>(message: string, config: Partial<IndividualConfig<TPayload>>): ActiveToast<TPayload>;
	/**
	 * Displays an error toast with the specified configuration, using the default message and title.
	 * @param config - The configuration options for the toast.
	 */
	error<TPayload>(config: Partial<IndividualConfig<TPayload>>): ActiveToast<TPayload>;
	/**
	 * Displays an error toast, using the default message, title and configuration.
	 */
	error<TPayload>(): ActiveToast<TPayload>;
	error<TPayload>(message?: string | Partial<IndividualConfig<TPayload>>, titleOrConfig?: string | Partial<IndividualConfig<TPayload>>, config?: Partial<IndividualConfig<TPayload>>): ActiveToast<TPayload> {
		const errorMsg = this.t(typeof message === 'string' ? message : undefined) ?? this.t('toaster-service.toast-error-generic_msg');
		const titleText = this.t(typeof titleOrConfig === 'string' ? titleOrConfig : undefined) ?? this.t('toaster-service.toast-error_title');
		const mergedConfig = (typeof message !== 'string' && message) || (typeof titleOrConfig !== 'string' && titleOrConfig) || config || {};

		return this.toastrService.error<TPayload>(errorMsg, titleText, { ...this.configError, ...mergedConfig });
	}

	/**
     * Remove all or a single toast by id
     */
	clear(toastId?: number): void {
		this.toastrService.clear(toastId);
	}
}
