import { Location } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { CWInvoiceSyncMapping, ExportCWSyncInvoices, Invoice, InvoiceNumberMapping } from 'domain/entities';
import { CWRepository } from 'repositories';
import { Subject, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { NavigatorService } from 'services';
import { CacheModel, CacheService } from 'services/cache.service';
import { InvoiceActionsService } from 'shared/modules/invoice-actions';

@Component({
	selector: 'dg-cw-invoice-number',
	templateUrl: './cw-invoice-number.component.html',
	styleUrls: ['./cw-invoice-number.component.sass']
})
export class CwInvoiceNumberComponent implements OnDestroy {
	private invoices: Invoice[];
	private excluded: Invoice[];
	public cacheModel: CacheModel;
	public cacheModelExcluded: CacheModel;
	public invoiceSyncMapping: CWInvoiceSyncMapping[];
	public loading = false;
	private destroy$ = new Subject<void>();
	constructor(
		private readonly cwRepo: CWRepository,
		private readonly invoiceService: InvoiceActionsService,
		private readonly navigator: NavigatorService,
		private readonly cache: CacheService,
		private readonly location: Location
	) {
		this.cacheModel = this.cache.get('connectwiseInvoiceNumberSync');
		this.cacheModelExcluded = this.cache.get('connectwiseInvoiceNumberSync-excluded');

		this.invoices = this.cacheModel ? <Invoice[]>this.cacheModel.Object : [];
		this.excluded = this.cacheModelExcluded ? <Invoice[]>this.cacheModelExcluded.Object : [];

		if (this.invoices.length) {
			this.InvoiceNumberSyncSelected();
		} else {
			this.location.back();
		}
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	public InvoiceNumberSyncSelected(): void {
		this.loading = true;

		const actionFilters = this.cache.get('invoices-action-filterParams');
		const filterParams = this.invoiceService.selectedAll && actionFilters ? actionFilters : '';
		const invIds = this.invoices.map(x => x.id);

		this.cwRepo.InvoiceNumberSyncRequest(invIds, this.excluded, filterParams)
			.pipe(
				takeUntil(this.destroy$),
				catchError(err => {
					this.loading = false;
					return throwError(() => err);
				}))
			.subscribe(res => {
				this.invoiceSyncMapping = res;
				this.loading = false;
			});

	}

	public onCwInvoiceSyncProceed(): void {
		this.loading = true;

		const exportModel = new ExportCWSyncInvoices();
		const invoicesMappingModel: InvoiceNumberMapping[] = [];

		this.invoiceSyncMapping.forEach(el => {
			invoicesMappingModel.push({
				invoiceNumber: el.invoiceNumber,
				invoiceId: el.invoiceId,
				cwInvoiceId: el.selectedCwAgreement.selectedInvoiceId || null
			});
		});

		exportModel.invoicesMapping = invoicesMappingModel;
		exportModel.tag = this.cacheModel.Tag;

		this.cwRepo.ExportCWSyncInvoices(exportModel)
			.pipe(
				takeUntil(this.destroy$),
				catchError(err => {
					this.loading = false;
					return throwError(() => err);
				}))
			.subscribe(result => {
				if (result.operationId) {
					this.loading = false;
					this.navigator.toOperation(result.operationId);
				}
			});
	}
}
