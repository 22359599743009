import { Injectable, NgZone } from '@angular/core';
import {
	HttpRequest,
	HttpResponse,
} from '@angular/common/http';
import OpenReplay from '@openreplay/tracker';
import trackerAssist from '@openreplay/tracker-assist';
import { environment } from '@environments/environment';
import { AppConfig } from 'core/app-config';
import { AuthHelper } from 'core/auth';
import { Utils } from 'tools/utils';


type ReqRespType = {
	request: HttpRequest<any>;
	response: HttpResponse<any>;
};

@Injectable({
	providedIn: 'root'
})
export class ReplaySessionService {
	tracker?: OpenReplay;

	private _inited = false;

	constructor(
		private readonly appConfig: AppConfig,
		private readonly zone: NgZone
	) {
	}

	async startOpenReplay(): Promise<void> {
		if (environment.env === 'development-local')
			return;

		await this.zone.runOutsideAngular(async () => {
			this.tracker = new OpenReplay({
				projectKey: this.appConfig.openReplayProjectKey,
				//ingestPoint: 'https://api.openreplay.com/ingest',
				__DISABLE_SECURE_MODE: true,
				verbose: true,
				__debug__: true,
				obscureInputDates: false,
				obscureInputEmails: false,
				obscureInputNumbers: false,
				obscureTextEmails: false,
				obscureTextNumbers: false,
				defaultInputMode: 0
			});
			this.tracker.use(trackerAssist({}));

			const authDetails = AuthHelper.getJwtTokenData();
			if (!authDetails) {
				await this.tracker.start();
			} else {
				await this.tracker.start({
					userID: `${authDetails.DisplayName}`
				});
				const metadata = Utils.convertToRecord(authDetails, this.appConfig.myDetails);
				Object.entries(metadata).map(([key, value]) => this.tracker.setMetadata(key, value));
			}
		});

		console.warn('OpenReplay service instantiated!');
		this._inited = true;
	}

	sendEventToReplaySession(event: string, params: ReqRespType): void {
		if (!this._inited || environment.env === 'development-local')
			return;

		this.zone.runOutsideAngular(() => {
			if (!this.tracker)
				throw new Error('OpenReplay tracker undefined!');
			const { request, response } = params;
			this.tracker?.event(event + '[request]', {
				method: request.method,
				url: request.url,
				params: request.params
			});
			this.tracker?.event(event + '[response]', {
				body: response.body,
				status: response.status,
				headers: response.headers
			});
		});

	}

	trackNewUser(): void {
		if (!this._inited || environment.env === 'development-local')
			return;

		const authDetails = AuthHelper.getJwtTokenData();
		this.zone.runOutsideAngular(() => {
			this.tracker.setUserID(`${authDetails.DisplayName}`);
			const metadata = Utils.convertToRecord(authDetails, this.appConfig.myDetails);
			Object.entries(metadata).map(([key, value]) => this.tracker.setMetadata(key, value));
		});
	}
}
