import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { AgreementEntity, BillingPeriod } from 'domain/entities';
import { Guid } from 'domain/types';
import * as Moment from 'moment-timezone';
import { AgreementsRepository, PeriodRepository } from 'repositories';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'dg-periodselector',
	templateUrl: './dg-period-selector.component.html',
	styleUrls: ['./dg-period-selector.component.sass']
})

export class DgPeriodSelectorComponent implements OnDestroy, OnChanges {
	@Input() placeholder: string = null;
	@Input() id = 'ddlPeriods';
	@Input() customerId: Guid;
	@Input() isCompare = false;
	@Input() isCustomerAgreements = false;
	@Input() comparePeriods: BillingPeriod[] = [];
	@Input() customerAgreements: AgreementEntity[] = [];
	@Output() periodSelect: EventEmitter<BillingPeriod> =
		new EventEmitter<BillingPeriod>();

	@Output() periodsSelect: EventEmitter<BillingPeriod[]> =
		new EventEmitter<BillingPeriod[]>();

	@Output() agreementSelect: EventEmitter<AgreementEntity> =
		new EventEmitter<AgreementEntity>();
		
	agreements: AgreementEntity[] = [];
	periods: BillingPeriod[] = [];

	period: BillingPeriod;
	agreement: AgreementEntity;

	cachedAgreement: AgreementEntity = JSON.parse(sessionStorage.getItem('transaction-agreement'));
	cachedPeriod: BillingPeriod = JSON.parse(sessionStorage.getItem('transaction-period'));
	private destroy$ = new Subject<void>();
	constructor(
		private readonly periodRepo: PeriodRepository,
		private readonly agreementsRepo: AgreementsRepository) {

		setTimeout(() => {
			if (!this.isCompare) {
				this.agreementsRepo
					.getCustomerAgreements(this.customerId)
					.pipe(takeUntil(this.destroy$))
					.subscribe(agreements => {
						this.agreements = agreements;
						this.agreement = this.cachedAgreement ? this.getCachedAgreement(this.cachedAgreement) : agreements[0];

						if (this.agreement) {
							this.agreementSelect.emit(this.agreement);
							this.loadPeriods();
						}
					});
			}
		}, 0);
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	public onAgreementSelected(): void {
		this.clearPeriods();
		this.agreementSelect.emit(this.agreement);
		this.loadPeriods();
	}

	private getCachedAgreement(agr: AgreementEntity): AgreementEntity {
		const agreement = this.agreements.find(item => item.id.toString() === agr.id.toString());
		// in case if customer changed we take first agreement
		return !agreement ? this.agreements[0] : agreement;
	}

	private getCachedPeriod(billingPeriod: BillingPeriod): BillingPeriod {
		const period = this.periods.find(item => item.id.toString() === billingPeriod.id.toString());
		// in case if customer changed we take first period
		return !period ? this.periods[0] : period;
	}

	private loadPeriods(): void {
		this.periodRepo
			.getAgreementBillingPeriods(this.agreement.id)
			.pipe(takeUntil(this.destroy$))
			.subscribe(periods => {

				periods.forEach((value) => {
					if (!value.month) {
						value.label = Moment.utc(value.periodStart).format('L') + ' - ' + Moment.utc(value.periodEnd).format('L');
					}
				});

				this.periods = periods;
				this.period = this.cachedPeriod ? this.getCachedPeriod(this.cachedPeriod) : periods[0];

				this.periodSelect.emit(this.period);
				this.periodsSelect.emit(this.periods);
			});
	}

	ngOnChanges(changes: SimpleChanges): void {
		// clear periods when changed cutomer on usage reports
		if (changes['customerId']) {
			this.clearPeriods();
		}
	}

	private clearPeriods(): void {
		this.period = null;
		this.periodsSelect.emit([]);
	}

	public onPeriodSelected(): void {
		this.periodSelect.emit(this.period);
	}
}
