import { Location } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ToasterService } from 'core/toaster-service';
import {
	Invoice
} from 'domain/entities';
import { Guid } from 'domain/types';
import { IQBDModel, IQBDPrepareResponse, IQBDProduct, QBDWizardSettings, QBExportInvoiceRepository } from 'repositories';
import { forkJoin, Observable, Subject, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { NavigatorService } from 'services';
import { CacheModel, CacheService } from 'services/cache.service';
import { InvoiceActionsService } from 'shared/modules/invoice-actions';

@Component({
	selector: 'dg-qb-desktop-export',
	templateUrl: './qb-desktop-export.component.html',
	styleUrls: ['./qb-desktop-export.component.sass']
})
export class QbDesktopExportComponent implements OnDestroy {
	currentStep = 1;
	invoices: Invoice[];
	excluded: Invoice[];

	qbdPrepareEmptyInvoices: Invoice[];
	qbdPrepareFilledInvoices: Invoice[];

	loading = false;
	products: IQBDProduct[] = [];
	public cacheModel: CacheModel;
	public cacheModelExcluded: CacheModel;
	public combineSurchargesWithTaxes = false;
	public groupTaxByState = false;
	public qbdPrepareRes: IQBDPrepareResponse;
	public deleteExisting: boolean;
	public surchargeSeparately: boolean;
	public uniteSomeProductsInOne: boolean;
	public sureTaxMultiline: boolean;
	public selectedPriceCalculationTypeId = 1;
	public isDataPrepared = true;
	exportConfShow = false;
	private destroy$ = new Subject<void>();
	constructor(
		private readonly cache: CacheService,
		private readonly navigator: NavigatorService,
		private readonly router: Router,
		private readonly qbRepository: QBExportInvoiceRepository,
		private readonly invoiceRepo: InvoiceActionsService,
		private readonly toasterService: ToasterService,
		private readonly location: Location
	) {
		this.cacheModel = this.cache.get('exportQBD');
		this.cacheModelExcluded = this.cache.get('exportQBD-excluded');

		this.invoices = this.cacheModel ? <Invoice[]>this.cacheModel.Object : [];
		this.excluded = this.cacheModelExcluded ? <Invoice[]>this.cacheModelExcluded.Object : [];

		if (!this.invoices.length) {
			this.location.back();
		} else {
			this.qbdPrepareData();
		}
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	public qbdPrepareData(force: boolean = false): void {
		this.loading = true;

		const actionFilters = this.cache.get('invoices-action-filterParams');
		const filterParams = this.invoiceRepo.selectedAll && actionFilters ? actionFilters : '';

		const qbdProduct$: Observable<IQBDProduct[]> = this.qbRepository.getQBDProducts();
		const qbdWizardsSettings$: Observable<QBDWizardSettings> = this.qbRepository.getQBDWizardSettings();
		const qbdPrepare$: Observable<IQBDPrepareResponse> = this.qbRepository.exportQBDprepare(this.invoices, force, this.excluded, filterParams);

		forkJoin([qbdProduct$, qbdPrepare$, qbdWizardsSettings$]).pipe(
			takeUntil(this.destroy$),
			catchError(response => {
				this.toasterService.error(response);
				this.loading = false;
				this.isDataPrepared = false;
				return throwError(() => response);
			})).subscribe(([products, prepareData, qbdWizardsSettings]) => {
			if (prepareData.emptyInvoices?.length) {
				this.exportConfShow = true;
				this.qbdPrepareEmptyInvoices = this.searchInvoicesByNumber(prepareData.emptyInvoices, this.invoices);
				this.qbdPrepareFilledInvoices = this.searchInvoicesByNumber(prepareData.invoices, this.invoices);
			}

			this.setWizardSettings(qbdWizardsSettings);
			this.products = products;
			this.qbdPrepareRes = prepareData;
			this.loading = false;
		});
	}

	private searchInvoicesByNumber(invoicesId: Guid[], invoices: Invoice[]): Invoice[] {
		const ret: Invoice[] = [];
		if (!invoicesId) {
			return ret;
		}
		invoicesId.forEach((item, index) => {
			const found: Invoice = invoices.find(x => x.id.equals(item));
			if (found) {
				ret.push(found);
			}
		});
		return ret;
	}

	private setWizardSettings(settings: QBDWizardSettings): void {
		this.combineSurchargesWithTaxes = settings['combine-surcharges-with-taxes'];
		this.selectedPriceCalculationTypeId = settings['price-calculation-type'];
		this.surchargeSeparately = settings['surcharge-separately'];
		this.sureTaxMultiline = settings['tax-multiline'];
		this.uniteSomeProductsInOne = settings['unite-some-products-in-one'];
		this.groupTaxByState = settings['split-taxes-by-state'];
	}

	public CheckStep(value: number): string {
		return this.currentStep === value ? 'steps--active' : '';
	}

	public Export(): void {
		this.loading = true;

		const exportModel: IQBDModel = {
			tag: this.cacheModel.Tag,
			invoices: this.invoices.map(x => x.id),
			invoiceLinesResponses: this.qbdPrepareRes.invoiceLinesResponses,
			transactionResponses: this.qbdPrepareRes.transactionResponses,
			surchargeResponses: this.qbdPrepareRes.surchargeResponses,
			surchargesWithTaxesResponses: this.qbdPrepareRes.surchargesWithTaxesResponses,
			totalTaxesResponses: this.qbdPrepareRes.totalTaxesResponses,
			taxesLinesStateResponses: this.qbdPrepareRes.taxesLinesStateResponses,
			taxesLinesResponses: this.qbdPrepareRes.taxesLinesResponses,
			surchargeSeparatelyResponses: this.qbdPrepareRes.surchargeSeparatelyResponses,

			selectedPriceCalculationTypeId: this.selectedPriceCalculationTypeId,
			deleteExisting: this.deleteExisting,
			combineSurchargesWithTaxes: this.combineSurchargesWithTaxes,
			taxMultiline: this.sureTaxMultiline,
			taxForState: this.groupTaxByState,
			uniteSomeProductsInOne: this.uniteSomeProductsInOne,
			surchargeSeparately: this.combineSurchargesWithTaxes ? false : this.surchargeSeparately
		};

		this.qbRepository.exportQBD(exportModel).pipe(
			takeUntil(this.destroy$),
			catchError(response => {
				this.toasterService.error(response);
				this.loading = false;
				return throwError(() => response);
			}))
			.subscribe(result => {
				if (result.operationId) {
					this.loading = false;
					this.navigator.toOperation(result.operationId);
				}
			});
	}

	backTo(): void {
		const backUrl = (this.cacheModel.Tag.split(':').pop()).slice(2, -2);
		void this.router.navigate([backUrl]);
	}

	onNextStep(): void {
		if (this.currentStep < 5) {
			this.currentStep = this.currentStep + 1;
		}
	}

	onPreviosStep(): void {
		if (this.currentStep > 1) {
			this.currentStep = this.currentStep - 1;
		}
	}

	onExportConfirm(): void {
		if (!this.qbdPrepareFilledInvoices?.length) {
			this.backTo();
			return;
		}

		this.qbdPrepareEmptyInvoices.forEach(emptyItem => {
			this.invoices.forEach((item, index) => {
				if (item.id.equals(emptyItem.id)) {
					this.invoices.splice(index, 1);
				}
			});
		});

		this.exportConfShow = false;
	}
}
