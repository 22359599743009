import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoRootModule } from 'transloco-root.module';
import { DgInvoiceActionBarComponent } from './dg-invoice-action-bar/dg-invoice-action-bar.component';
import { DgInvoiceActionsFloatComponent } from './dg-invoice-actions-float/dg-invoice-actions-float.component';
import { InvoiceActionsService } from './invoice-actions.service';

@NgModule({
	imports: [
		CommonModule,
		TranslocoRootModule
	],
	declarations: [
		DgInvoiceActionsFloatComponent,
		DgInvoiceActionBarComponent
	],
	exports: [
		DgInvoiceActionsFloatComponent,
		DgInvoiceActionBarComponent
	],
	providers: [
		InvoiceActionsService
	]
})

export class DgInvoiceActionsModule {
}
