import { Injectable } from '@angular/core';
import { AuthHelper, AuthService } from 'core/auth';
import * as Moment from 'moment-timezone';
import { NgxPermissionsService } from 'ngx-permissions';
import { SettingsRepository } from 'repositories';
import { IntegrationSettingsRepository } from 'repositories/integration-settings-repository';
import { MyDetailsRepository } from 'repositories/my-details-repository';
import { ReplaySessionService } from 'services';
import { AppConfig } from './app-config';

@Injectable()
export class AppInitializer {
	constructor(
		private readonly authService: AuthService,
		private readonly config: AppConfig,
		private readonly permissionsService: NgxPermissionsService,
		private readonly myDetailsRepository: MyDetailsRepository,
		private readonly portalSettingsRepository: SettingsRepository,
		private readonly replaySessionService: ReplaySessionService,
		private readonly integrationSsettingsService: IntegrationSettingsRepository
	) {
	}

	async init(): Promise<boolean> {
		const browserLang = window.navigator.language;
		Moment.locale(browserLang);

		const authDetails = AuthHelper.getAuthDetails();

		if (authDetails?.token) {
			const permissions = this.getPermissions(authDetails.token);
			this.permissionsService.loadPermissions(permissions);
		}

		if (authDetails) {
			try {
				const [me, portalSettings, integrationSettings] = await Promise.all([
					this.myDetailsRepository.get(true),
					this.portalSettingsRepository.getPortalSettings(),
					this.integrationSsettingsService.getSettings()
				]);
				this.config.setMyDetails(me);
				this.config.setPortalSettings(portalSettings);
				this.config.setIntegrationSettings(integrationSettings);
			} catch (error) {
				// eslint-disable-next-line no-console
				console.warn(error);
				// eslint-disable-next-line no-console
				console.warn('reset auth and redirecting to login due to failed auth');
				this.authService.clearAuthAndRedirectToLogin();
			}
		}

		await this.replaySessionService.startOpenReplay();

		return true;
	}

	private getPermissions(token: string): string[] {
		let permissions: string[] = [];
		if (token) {
			const decodedToken = AuthHelper.getJwtTokenData(token);
			if (decodedToken.permission) {
				permissions = decodedToken.permission;
			}
		}
		return permissions;
	}
}
