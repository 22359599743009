import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'core/auth';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import {
	AccountManager,
	AgreementEntity,
	AgreementType,
	AutotaskContractCategory,
	AutotaskEntity,
	AvalaraCompany,
	AvalaraEntity,
	AvalaraLocation,
	AvalaraTaxExemption,
	AvalaraTaxTypes,
	BrandProperty,
	Bundle,
	BundleQuantity,
	ChargeTypes,
	CsiTaxEntity,
	CurrencyEntity,
	CustomerDetails,
	CustomerProduct,
	CustomerSite,
	CustomerTransaction,
	DeliveryEntity,
	DisplayUnit,
	ExemptionCode,
	ExtraFieldEntity,
	ExtraFieldValue,
	Feed,
	FeedHandler,
	FeedMappingConfiguration,
	GlCode,
	Invoice,
	InvoiceGroupingMethod,
	InvoiceLayoutEntity,
	InvoiceTemplateEntity,
	Job,
	KaseyaEntity,
	KitProduct,
	MonerisEntity,
	Note,
	OrganizationSettings,
	PaymentTerm,
	PinchEntity,
	PortalGroupingMethod,
	PriceBook,
	ProductCategory,
	ProductKit,
	ProductRatePlan,
	ProductRatePlanCharge,
	ProductTemplate,
	QBDEntity,
	QuickBooksEntity,
	RateCard,
	RatingAssignment,
	RatingType,
	ResellerService,
	ScopeTypes,
	Service,
	ServiceItem,
	ServiceItemAliasDto,
	ServiceItemTypes,
	Supplier,
	Surcharge,
	SureTaxCode,
	SureTaxEntity,
	SureTaxRegCodeEntity,
	Tag,
	UsageRecordType,
	UsageUnit,
	Vendor,
	WebHookEntity,
	XeroEntity
} from 'domain/entities';

import { TaxRate, TaxRateType } from 'domain/entities/tax-rates-entities';

import { SsoSettings } from 'domain/entities/sso-settings';
import { ResellerUserTokenCheckResult } from 'domain/models';
import { Guid } from 'domain/types';
import {
	AgreementsRepository,
	BundleRepository,
	ChargeRepository,
	CustomerRepository,
	CWRepository,
	ExtraFieldRepository,
	FeedsRepository,
	GlCodesRepository,
	IntegrationRepository,
	InvoiceRepository,
	InvoiceTemplateRepository,
	JobRepository,
	LookupRepository,
	NoteRepository,
	PricebookRepository,
	ProductCategoryRepository,
	ProductKitRepository,
	ProductTemplateRepository,
	RatecardRepository,
	RatingRepository,
	ServiceItemsRepository,
	ServiceRepository,
	SettingsRepository,
	SupplierRepository,
	SurchargeRepository,
	SureTaxCodeRepository,
	TagRepository,
	TaxRateRepository
} from 'repositories';
import { InvoiceLayoutRepository } from 'repositories/invoice-layout-repository';

@Injectable()
export class CustomersResolver implements Resolve<CustomerDetails[]> {
	constructor(private repository: CustomerRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CustomerDetails[]> {

		return this.repository.getAll$();
	}
}

@Injectable()
export class CustomerProductsResolver implements Resolve<CustomerProduct[]> {
	constructor(private repository: CustomerRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CustomerProduct[]> {
		const customerId = new Guid(route.params['customerId']);

		return this.repository.getCustomerProducts$(customerId);
	}
}

@Injectable()
export class CustomerProductResolver implements Resolve<CustomerProduct> {
	constructor(private repository: CustomerRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CustomerProduct> {
		const customerId = new Guid(route.params['customerId']);

		const customerProductId = new Guid(route.params['customerProductId']);

		return this.repository.getCustomerProductById$(customerId, customerProductId);
	}
}

@Injectable()
export class CustomerServiceItemsResolver implements Resolve<ServiceItem[]> {
	constructor(private repository: ServiceItemsRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ServiceItem[]> {
		const customerId = new Guid(route.params['customerId']);

		return this.repository.getCustomerServiceItems(customerId);
	}
}

@Injectable()
export class CustomerServiceItemResolver implements Resolve<ServiceItem> {
	constructor(private repository: ServiceItemsRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ServiceItem> {
		const customerId = new Guid(route.params['customerId']);

		const customerServiceItemId = new Guid(route.params['customerServiceItemId']);

		return this.repository.getCustomerServiceItemById(customerServiceItemId, customerId);

	}
}

@Injectable()
export class CustomerInvoicesResolver implements Resolve<Invoice[]> {
	constructor(private repository: CustomerRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Invoice[]> {

		const customerId = new Guid(route.params['customerId']);

		return this.repository.getCustomerInvoices(customerId);
	}
}

@Injectable()
export class InvoiceResolver implements Resolve<Invoice[]> {
	constructor(private repository: CustomerRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Invoice[]> {
		const customerId = new Guid(route.params['customerId']);
		const invoiceId = new Guid(route.params['invoiceId']);

		return this.repository.getCustomerInvoice(customerId, invoiceId);
	}
}

@Injectable()
export class CustomerInvoiceResolver implements Resolve<any> {
	constructor(private repository: CustomerRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
		let invoiceId = new Guid(route.params['invoiceId']);

		if (!invoiceId || invoiceId.isEmpty())
			invoiceId = new Guid(route.params['miscellaneousInvoiceId']);

		return this.repository.getCustomerInvoiceById(invoiceId);

	}
}

@Injectable()
export class CustomerInvoiceEmailsResolver implements Resolve<any> {
	constructor(private repository: CustomerRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
		const invoiceId = new Guid(route.params['invoiceId']);

		return this.repository.getCustomerInvoiceEmails(invoiceId);

	}
}

@Injectable()
export class CustomerDetailsResolver implements Resolve<CustomerDetails> {
	constructor(private repository: CustomerRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CustomerDetails> {
		const customerId = new Guid(route.params['customerId']);

		return this.repository.getById(customerId);
	}
}

@Injectable()
export class CustomerTransactionResolver implements Resolve<CustomerTransaction> {
	constructor(private repository: CustomerRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CustomerTransaction> {
		const customerId = new Guid(route.params['customerId']);
		const customerTransactionId = new Guid(route.params['customerTransactionId']);

		return this.repository.getCustomerTransactionById(customerTransactionId, customerId);
	}
}

@Injectable()
export class CustomerSitesResolver implements Resolve<CustomerSite[]> {
	constructor(private repository: CustomerRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CustomerSite[]> {
		const customerId = new Guid(route.params['customerId']);

		return this.repository.getCustomerSites(customerId);
	}
}

@Injectable()
export class CustomerAgreementsResolver implements Resolve<AgreementEntity[]> {
	constructor(private repository: AgreementsRepository, private customerRepository: CustomerRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AgreementEntity[]> {
		const customerId = new Guid(route.params['customerId']);

		if (customerId) {
			return this.repository.getCustomerAgreements(customerId);
		}

	}
}

@Injectable()
export class CustomerAgreementResolver implements Resolve<AgreementEntity> {
	constructor(private repository: AgreementsRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AgreementEntity> {
		const customerId = new Guid(route.params['customerId']);
		const customerAgreementId = new Guid(route.params['customerAgreementId']);
		return this.repository.getCustomerAgreementById(customerId, customerAgreementId);

	}
}

@Injectable()
export class ActiveCustomersResolver implements Resolve<CustomerDetails[]> {
	constructor(private repository: CustomerRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CustomerDetails[]> {

		return this.repository.getActiveCustomers$();
	}
}

@Injectable()
export class MiscellaneousInvoiceTransactions implements Resolve<CustomerTransaction[]> {
	constructor(private repository: InvoiceRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CustomerTransaction[]> {
		const invoiceId = new Guid(route.params['miscellaneousInvoiceId']);

		return this.repository.getMiscellaneousInvoiceTransactions(invoiceId);
	}
}

@Injectable()
export class CurrencyResolver implements Resolve<CurrencyEntity[]> {
	constructor(private repository: SettingsRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CurrencyEntity[]> {

		return this.repository.getCurrencies();
	}
}

@Injectable()
export class ExtraFieldsResolver implements Resolve<ExtraFieldEntity[]> {
	constructor(private repository: SettingsRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ExtraFieldEntity[]> {

		return this.repository.getExtraFieldsLabels$();
	}
}

@Injectable()
export class GlCodesResolver implements Resolve<GlCode[]> {
	constructor(private repository: GlCodesRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<GlCode[]> {

		return this.repository.getGlCodes();
	}
}

@Injectable()
export class GlCodeResolver implements Resolve<GlCode> {
	constructor(private repository: GlCodesRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<GlCode> {
		const glCodeId = new Guid(route.params['glCodeId']);

		return this.repository.getGlCodeById(glCodeId);

	}
}

@Injectable()
export class SurchargesResolver implements Resolve<Surcharge[]> {
	constructor(private repository: SurchargeRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Surcharge[]> {

		return this.repository.getSurcharges();
	}
}

@Injectable()
export class SurchargeResolver implements Resolve<Surcharge> {
	constructor(private repository: SurchargeRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Surcharge> {
		const surchargeId = new Guid(route.params['surchargeId']);

		return this.repository.getSurchargeById(surchargeId);

	}
}

@Injectable()
export class TaxRatesResolver implements Resolve<TaxRate[]> {
	constructor(private repository: TaxRateRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<TaxRate[]> {

		return this.repository.getTaxRates();
	}
}

@Injectable()
export class TaxRateTypesResolver implements Resolve<TaxRateType[]> {
	constructor(private repository: TaxRateRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<TaxRateType[]> {

		return this.repository.getTaxRateType();
	}
}

@Injectable()
export class TaxRateResolver implements Resolve<TaxRate> {
	constructor(private repository: TaxRateRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<TaxRate> {
		const taxRateId = new Guid(route.params['taxRateId']);

		return this.repository.getTaxRateById(taxRateId);

	}
}

@Injectable()
export class ProductCategoryResolver implements Resolve<ProductCategory[]> {
	constructor(private repository: ProductCategoryRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ProductCategory[]> {

		return this.repository.getProductCategory();
	}
}

@Injectable()
export class SureTaxCodeResolver implements Resolve<SureTaxCode[]> {
	constructor(private repository: SureTaxCodeRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<SureTaxCode[]> {

		return this.repository.getSureTaxCodes();
	}
}

@Injectable()
export class ResellerUsersRegistrationResolver implements Resolve<Observable<ResellerUserTokenCheckResult>> {
	constructor(private service: AuthService, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ResellerUserTokenCheckResult> {
		const resellerUsersToken = route.params['resellerUsersToken'];

		return this.service.getRegistrationData(resellerUsersToken);
	}
}

@Injectable()
export class SureTaxIntegrationResolver implements Resolve<SureTaxEntity> {
	constructor(private repository: IntegrationRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<SureTaxEntity> {

		return this.repository.getSureTaxIntegration$();
	}
}

@Injectable()
export class AutotaskContractCategoryResolver implements Resolve<AutotaskContractCategory[]> {
	constructor(private repository: IntegrationRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AutotaskContractCategory[]> {

		return this.repository.getAutotaskContractCategory();
	}
}

@Injectable()
export class AutotaskIntegrationResolver implements Resolve<AutotaskEntity> {
	constructor(private repository: IntegrationRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AutotaskEntity> {

		return this.repository.getAutotaskIntegration();
	}
}

@Injectable()
export class DeliverySettingsResolver implements Resolve<DeliveryEntity[]> {
	constructor(private repository: SettingsRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<DeliveryEntity[]> {

		return this.repository.getDeliverySettings();
	}
}

@Injectable()
export class CsiTaxIntegrationResolver implements Resolve<CsiTaxEntity[]> {
	constructor(private repository: IntegrationRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CsiTaxEntity[]> {

		return this.repository.getCsiTaxIntegration$();
	}
}

@Injectable()
export class QBDIntegrationResolver implements Resolve<QBDEntity> {
	constructor(private repository: IntegrationRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<QBDEntity> {

		return this.repository.getQBDIntegration();
	}
}

@Injectable()
export class ExistsQuickBooksIntegrationResolver implements Resolve<boolean> {
	constructor(private repository: IntegrationRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		return this.repository.testQuickBooksAccount();
	}
}

@Injectable()
export class SureTaxRegCodeResolver implements Resolve<SureTaxRegCodeEntity[]> {
	constructor(private repository: IntegrationRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<SureTaxRegCodeEntity[]> {

		return this.repository.getRegulatoryCode();
	}
}

@Injectable()
export class FeedResolver implements Resolve<Feed> {
	constructor(private repository: FeedsRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Feed> {
		const feedId = Number(route.params['feedId']);

		return this.repository.getFeedById(feedId);
	}
}

@Injectable()
export class ChargeTypeResolver implements Resolve<ChargeTypes[]> {
	constructor(private repository: ChargeRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ChargeTypes[]> {

		return this.repository.getAllChargeType();

	}
}

@Injectable()
export class TagResolver implements Resolve<Tag[]> {
	constructor(private repository: TagRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Tag[]> {

		return this.repository.getTags$();

	}
}

@Injectable()
export class CustomerNoteResolver implements Resolve<Note[]> {
	constructor(private repository: NoteRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Note[]> {
		const customerId = new Guid(route.params['customerId']);

		return this.repository.getCustomerNotes(customerId);

	}
}

@Injectable()
export class InvoiceNoteResolver implements Resolve<Note[]> {
	constructor(private repository: NoteRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Note[]> {

		const invoiceId = route.params['invoiceId'];

		return this.repository.getInvoiceNotes(invoiceId);

	}
}

@Injectable()
export class FeedHandlerResolver implements Resolve<FeedHandler[]> {
	constructor(private repository: FeedsRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<FeedHandler[]> {

		return this.repository.getFeedHandler();

	}
}

@Injectable()
export class ProductTemplatesResolver implements Resolve<ProductTemplate[]> {
	constructor(private repository: ProductTemplateRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ProductTemplate[]> {

		return this.repository.getAll$();
	}
}

@Injectable()
export class ProductTemplateResolver implements Resolve<ProductTemplate> {
	constructor(private repository: ProductTemplateRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ProductTemplate> {
		const productTemplateId = new Guid(route.params['productTemplateId']);

		return this.repository.getProductTemplateById(productTemplateId);
	}
}

@Injectable()
export class ServiceItemResolver implements Resolve<ServiceItem> {
	// tslint:disable-next-line:max-line-length
	constructor(private repository: ServiceItemsRepository, private serviceRepository: ServiceRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ServiceItem> {
		const serviceItemId = new Guid(route.params['serviceItemId']);

		const serviceId = this.serviceRepository.getAll$()
			.pipe(
				map(services => services[0].id)
				, mergeMap(serviceId => this.repository.get(serviceId)));

		return this.repository.getServiceItemById(serviceItemId, serviceId);

	}
}

@Injectable()
export class GroupsResolver implements Resolve<ExtraFieldValue[]> {
	constructor(private repository: ExtraFieldRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ExtraFieldValue[]> {

		return this.repository.getGroups$();
	}
}

@Injectable()
export class BusinessUnitsResolver implements Resolve<ExtraFieldValue[]> {
	constructor(private repository: ExtraFieldRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ExtraFieldValue[]> {

		return this.repository.getBusinessUnits();
	}
}

@Injectable()
export class MonerisIntegrationResolver implements Resolve<MonerisEntity | boolean> {
	constructor(private repository: IntegrationRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<MonerisEntity | boolean> {

		return this.repository.getMonerisIntegration();
	}
}

@Injectable()
export class CensusIntegrationResolver implements Resolve<boolean> {
	constructor(private repository: IntegrationRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

		return this.repository.getCensusIntegration();
	}
}

@Injectable()
export class PinchIntegrationResolver implements Resolve<PinchEntity | boolean> {
	constructor(private repository: IntegrationRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PinchEntity | boolean> {

		return this.repository.getPinchIntegration();
	}
}

@Injectable()
export class KaseyaIntegrationResolver implements Resolve<KaseyaEntity | boolean> {
	constructor(private repository: IntegrationRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<KaseyaEntity | boolean> {

		return this.repository.getKaseyaIntegration();
	}
}

@Injectable()
export class AvalaraIntegrationResolver implements Resolve<AvalaraEntity[] | boolean> {
	constructor(private repository: IntegrationRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AvalaraEntity[] | boolean> {

		return this.repository.getAvalaraIntegration();
	}
}

@Injectable()
export class AvalaraCompanyResolver implements Resolve<AvalaraCompany[] | boolean> {
	constructor(private repository: IntegrationRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AvalaraCompany[] | boolean> {
		return this.repository.getAvalaraCompany();
	}
}

@Injectable()
export class XeroIntegrationResolver implements Resolve<boolean> {
	constructor(private repository: IntegrationRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

		return this.repository.getXeroIntegration();
	}
}

@Injectable()
export class XeroSyncSettingsResolver implements Resolve<XeroEntity> {
	constructor(private repository: IntegrationRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<XeroEntity> {

		return this.repository.getXeroSyncSettings$();
	}
}

@Injectable()
export class QuickBooksIntegrationResolver implements Resolve<QuickBooksEntity> {
	constructor(private repository: IntegrationRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<QuickBooksEntity> {

		return this.repository.getQuickBooksIntegration();
	}
}

@Injectable()
export class WebHooksIntegrationResolver implements Resolve<WebHookEntity[]> {
	constructor(private repository: IntegrationRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<WebHookEntity[]> {

		return this.repository.getWebHookIntegration();
	}
}

@Injectable()
export class ServicesResolver implements Resolve<Service[]> {
	constructor(private repository: ServiceRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Service[]> {

		return this.repository.getAll$();
	}
}

@Injectable()
export class OperationResolver implements Resolve<Job> {
	constructor(private repository: JobRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Job> {
		const operationId = Number(route.params['operationId']);

		return this.repository.get(operationId);
	}
}

@Injectable()
export class PricebookResolver implements Resolve<PriceBook> {
	constructor(private repository: PricebookRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PriceBook> {
		const pricebookId = new Guid(route.params['pricebookId']);
		return this.repository.getById(pricebookId);
	}
}

@Injectable()
export class RatingTypeResolver implements Resolve<RatingType> {
	constructor(private repository: PricebookRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<RatingType> {
		return this.repository.getRatingType();
	}
}

@Injectable()
export class AccountManagersResolver implements Resolve<AccountManager[]> {
	constructor(private repository: ExtraFieldRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AccountManager[]> {

		return this.repository.getAccountManager();
	}
}

@Injectable()
export class PaymentTermsResolver implements Resolve<PaymentTerm[]> {
	constructor(private repository: SettingsRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PaymentTerm[]> {

		return this.repository.getPaymentTerms();
	}
}

@Injectable()
export class PaymentTermResolver implements Resolve<PaymentTerm> {
	constructor(private repository: SettingsRepository) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PaymentTerm> {
		const paymentTermId = new Guid(route.params['paymentTermId']);
		return this.repository.getPaymentTermById(paymentTermId);

	}
}

@Injectable()
export class ServiceItemTypesResolver implements Resolve<ServiceItemTypes[]> {
	constructor(private repository: SettingsRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ServiceItemTypes[]> {

		return this.repository.getServiceItemTypes();
	}
}

@Injectable()
export class ServiceItemAliasesResolver implements Resolve<ServiceItemAliasDto[]> {
	constructor(private repository: ServiceItemsRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ServiceItemAliasDto[]> {
		let serviceItemId = new Guid(route.params['serviceItemId']);
		if (!serviceItemId || serviceItemId.isEmpty())
			serviceItemId = new Guid(route.params['customerServiceItemId']);
		return this.repository.getServiceItemAliases(serviceItemId);
	}
}

@Injectable()
export class ServiceItemTypeResolver implements Resolve<ServiceItemTypes> {
	constructor(private repository: SettingsRepository) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ServiceItemTypes> {
		const serviceItemTypeId = new Guid(route.params['serviceItemTypeId']);

		return this.repository.getServiceItemTypeById(serviceItemTypeId);

	}
}

@Injectable()
export class ResellerServicesResolver implements Resolve<ResellerService[]> {
	constructor(private repository: LookupRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ResellerService[]> {

		return this.repository.getResellerServices();
	}
}

@Injectable()
export class InvoiceGroupingMethodsResolver implements Resolve<InvoiceGroupingMethod[]> {
	constructor(private repository: LookupRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<InvoiceGroupingMethod[]> {

		return this.repository.getInvoiceGroupingMethods();
	}
}

@Injectable()
export class PortalGroupingMethodsResolver implements Resolve<PortalGroupingMethod[]> {
	constructor(private repository: LookupRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PortalGroupingMethod[]> {

		return this.repository.getPortalGroupingMethods();
	}
}

@Injectable()
export class UsageRecordTypesResolver implements Resolve<UsageRecordType[]> {
	constructor(private repository: SettingsRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UsageRecordType[]> {

		return this.repository.getUsageRecordTypes();
	}
}

@Injectable()
export class UsageRecordTypeResolver implements Resolve<UsageRecordType> {
	constructor(private repository: SettingsRepository) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UsageRecordType> {

		const usageRecordTypeId = new Guid(route.params['usageRecordTypeId']);

		return this.repository.getUsageRecordTypesById(usageRecordTypeId);

	}
}

@Injectable()
export class AccountManagerResolver implements Resolve<AccountManager> {
	constructor(private repository: ExtraFieldRepository) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AccountManager> {
		const managerId = new Guid(route.params['managerId']);
		return this.repository.getAccountManagerById(managerId);

	}
}

@Injectable()
export class BundlesResolver implements Resolve<Bundle[]> {
	constructor(private repository: BundleRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Bundle[]> {

		return this.repository.getAllBundles$();
	}
}

@Injectable()
export class BundleReslover implements Resolve<Bundle> {
	constructor(private repository: BundleRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Bundle> {
		const bundleId = new Guid(route.params['bundleId']);

		return this.repository.getBundle(bundleId);
	}
}

@Injectable()
export class BundleQuantityResolver implements Resolve<BundleQuantity[]> {
	constructor(private repository: BundleRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<BundleQuantity[]> {
		const bundleId = new Guid(route.params['bundleId']);

		return this.repository.getBundleQuantities(bundleId);
	}
}

@Injectable()
export class BundleQuantityByIdResolver implements Resolve<Bundle> {
	constructor(private repository: BundleRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Bundle> {
		const bundleId = new Guid(route.params['bundleId']);
		const quantityId = new Guid(route.params['quantityId']);

		return this.repository.getBundleQuantity(quantityId, bundleId);
	}
}

@Injectable()
export class RateCardResolver implements Resolve<RateCard> {
	constructor(private repository: RatecardRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<RateCard> {
		const ratecardId = new Guid(route.params['ratecardId']);

		return this.repository.getById(ratecardId);
	}
}

@Injectable()
export class AssignmentsResolver implements Resolve<RatingAssignment[]> {
	constructor(private repository: RatingRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<RatingAssignment[]> {

		return this.repository.getAllAssignments();
	}
}

@Injectable()
export class SuppliersResolver implements Resolve<Supplier[]> {
	constructor(private repository: SupplierRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Supplier[]> {

		return this.repository.getAll$();
	}
}

@Injectable()
export class ExemptionCodeResolver implements Resolve<ExemptionCode[]> {
	constructor(private repository: SureTaxCodeRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ExemptionCode[]> {

		return this.repository.getExemptionCode();
	}
}

@Injectable()
export class InvoiceTemplatesResolver implements Resolve<InvoiceTemplateEntity[]> {
	constructor(private repository: InvoiceTemplateRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<InvoiceTemplateEntity[]> {

		return this.repository.getAll();
	}
}

@Injectable()
export class InvoiceLayoutsResolver implements Resolve<InvoiceLayoutEntity[]> {
	constructor(private repository: InvoiceLayoutRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<InvoiceLayoutEntity[]> {

		return this.repository.getInvoiceLayouts();
	}
}

@Injectable()
export class InvoiceLayoutResolver implements Resolve<InvoiceLayoutEntity> {
	constructor(private repository: InvoiceLayoutRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<InvoiceLayoutEntity> {
		const invoiceLayoutId = new Guid(route.params.invoiceLayoutId);

		return this.repository.getInvoiceLayoutById(invoiceLayoutId);
	}
}

@Injectable()
export class OrganizationSettingsResolver implements Resolve<OrganizationSettings> {
	constructor(private repository: SettingsRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<OrganizationSettings> {

		return this.repository.getOrgSettings();
	}
}

@Injectable()
export class SsoResellerPortalSettingsResolver implements Resolve<SsoSettings> {
	constructor(private repository: SettingsRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<SsoSettings> {

		return this.repository.getSsoSettings(1);
	}
}

@Injectable()
export class SsoEndCustomerSettingsResolver implements Resolve<SsoSettings> {
	constructor(private repository: SettingsRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<SsoSettings> {

		return this.repository.getSsoSettings(2);
	}
}

@Injectable()
export class ProductKitsResolver implements Resolve<ProductKit[]> {
	constructor(private repository: ProductKitRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ProductKit[]> {
		return this.repository.getAll$();
	}
}

@Injectable()
export class ProductKitResolver implements Resolve<ProductKit> {
	constructor(private repository: ProductKitRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ProductKit> {
		const productKidId = new Guid(route.params['kitTemplateId']);

		return this.repository.getKitById(productKidId);
	}
}

@Injectable()
export class KitProductsResolver implements Resolve<KitProduct[]> {
	constructor(private repository: ProductKitRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<KitProduct[]> {
		const productKidId = new Guid(route.params['kitTemplateId']);

		return this.repository.getKitProducts(productKidId);
	}
}

@Injectable()
export class KitProductResolver implements Resolve<KitProduct> {
	constructor(private repository: ProductKitRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<KitProduct> {
		const productKidId = new Guid(route.params['kitTemplateId']);
		const kitProductId = new Guid(route.params['kitProductId']);

		return this.repository.getKitProductById(productKidId, kitProductId);
	}
}

@Injectable()
export class FeedMappingConfigurationResolver implements Resolve<FeedMappingConfiguration[]> {
	constructor(private repository: FeedsRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<FeedMappingConfiguration[]> {
		return this.repository.feedMappingConfigurations();
	}
}

@Injectable()
export class FeedBrandsResolver implements Resolve<BrandProperty[]> {
	constructor(private repository: FeedsRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<BrandProperty[]> {
		return this.repository.getAllBrands();
	}
}

@Injectable()
export class AgreementTypesResolver implements Resolve<AgreementType[]> {
	constructor(private repository: CWRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AgreementType[]> {
		return this.repository.GetAgreementTypes();
	}
}

@Injectable()
export class VendorsResolver implements Resolve<Vendor[]> {
	constructor(private repository: CWRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Vendor[]> {
		return this.repository.GetVendors();
	}
}

@Injectable()
export class MultilineWizardSettingsResolver implements Resolve<Map<string, any>> {
	constructor(private repository: CWRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Map<string, any>> {
		return this.repository.GetMultilineWizardSettings();
	}
}

@Injectable()
export class AgreementWizardSettingsResolver implements Resolve<Map<string, any>> {
	constructor(private repository: CWRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Map<string, any>> {
		return this.repository.GetWizardSettings();
	}
}

@Injectable()
export class ScopeTypesResolver implements Resolve<ScopeTypes[]> {
	constructor(private repository: ChargeRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ScopeTypes[]> {

		return this.repository.getAllScopeType();

	}
}

@Injectable()
export class ProductRatePlanChargesResolver implements Resolve<ProductRatePlanCharge[]> {
	constructor(private repository: ChargeRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ProductRatePlanCharge[]> {
		let productId = new Guid(route.params['customerProductId']);

		if (!productId || productId.isEmpty()) {
			productId = new Guid(route.params['productTemplateId']);
		}

		if (!productId || productId.isEmpty()) {
			productId = new Guid(route.params['kitProductId']);
		}

		const customerProductKitProductId = new Guid(route.params['customerProductKitProductId'])

		if (!customerProductKitProductId.isEmpty()) {
			productId = customerProductKitProductId;
		}

		return this.repository.productRatePlanChargesGet(productId);
	}
}

@Injectable()
export class ProductRatePlanResolver implements Resolve<ProductRatePlan[]> {
	constructor(private repository: ChargeRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ProductRatePlan[]> {
		let productId = new Guid(route.params['customerProductId']);

		if (!productId || productId.isEmpty()) {
			productId = new Guid(route.params['productTemplateId']);
		}

		if (!productId || productId.isEmpty()) {
			productId = new Guid(route.params['kitProductId']);
		}

		const customerProductKitProductId = new Guid(route.params['customerProductKitProductId'])

		if (!customerProductKitProductId.isEmpty()) {
			productId = customerProductKitProductId;
		}

		return this.repository.productRatePlanGet(productId);

	}
}

@Injectable()
export class TaxExemptionsResolver implements Resolve<AvalaraTaxExemption[]> {
	constructor(private repository: IntegrationRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AvalaraTaxExemption[]> {
		return this.repository.getAvalaraTaxExemption();
	}
}

@Injectable()
export class TaxExemptionResolver implements Resolve<AvalaraTaxExemption> {
	constructor(private repository: IntegrationRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AvalaraTaxExemption> {
		const taxExemptionId = new Guid(route.params['taxExemptionId']);

		return this.repository.getAvalaraTaxExemptionById(taxExemptionId);
	}
}

@Injectable()
export class AvalaraLocactionResolver implements Resolve<AvalaraLocation[]> {
	constructor(private repository: IntegrationRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AvalaraLocation[]> {
		const taxExemptionId = new Guid(route.params['taxExemptionId']);

		return this.repository.getAvalaraLocation(taxExemptionId);
	}
}

@Injectable()
export class AvalaraTaxTypesResolver implements Resolve<AvalaraTaxTypes[]> {
	constructor(private repository: IntegrationRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AvalaraTaxTypes[]> {
		return this.repository.getAvalaraTaxTypes();
	}
}

@Injectable()
export class DisplayUnitsResolver implements Resolve<DisplayUnit[]> {
	constructor(private repository: LookupRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<DisplayUnit[]> {
		return this.repository.getDisplayUnits();
	}
}

@Injectable()
export class UsageUnitsResolver implements Resolve<UsageUnit[]> {
	constructor(private repository: LookupRepository, private router: Router) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UsageUnit[]> {
		return this.repository.getUsageUnits();
	}
}
