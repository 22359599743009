import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from 'core/app-config';
import { HttpAuth } from 'core/auth';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ImportTypes } from 'tools/import-types';

@Injectable({
	providedIn: 'root'
})
export class ImportRepository {

	constructor(
		private http: HttpAuth,
		private config: AppConfig
	) {

	}

	public createNewSession(FileUrl: string, type: string, tag: string): Observable<any> {
		const obj = { FileUrl, type, tag };

		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/import/newsession`, obj, null)
			.pipe(map(res => res.body.data));
	}

	public getHeaders(uid: string, delimiter: string): Observable<any> {
		const obj = { uid, delimiter };

		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/import/headers`, obj, null)
			.pipe(map(res => res.body.data));
	}

	public checkForErrors(uid: string, parameters: any): Observable<any> {
		const obj = { uid, parameters: parameters };

		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/import/errors`, obj, null)
			.pipe(map(res => res.body.data));
	}

	public importFile(uid: string, parameters: any): Observable<any> {
		const obj = { uid, parameters: parameters };

		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/import/import`, obj, null)
			.pipe(map(res => res.body.data));

	}

	uploadImportedFile(file: File): Observable<any> {
		const formData: FormData = new FormData();

		formData.append('uploadFile', file, file.name);

		const headers = new HttpHeaders();
		const options = { headers: headers };

		return this.http.put(`${this.config.apiUrl}${this.config.apiVersion}/import/file`, options, formData)
			.pipe(map(res => res.body.data));
	}

	onDownloadTemplate(type: ImportTypes): Observable<HttpResponse<Blob>> {
		const options = { responseType: 'blob' };

		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/import/template/${type}`, options);
	}
}
