import { Injectable } from '@angular/core';
import { HttpAuth } from 'core/auth';
import { MyDetails } from 'domain/entities/my-details-entities';
import { BehaviorSubject, Subject } from 'rxjs';
import { AppConfig } from 'core/app-config';
import { Timzones } from 'tools/constants';
import { ApiData } from 'domain/types';
import { BaseRepository } from './base-repository';
import { CacheService } from 'services/cache.service';

interface IChangePasswordRequest {
	currentPassword: string;
	newPassword: string;
}

@Injectable({
	providedIn: 'root'
})
export class MyDetailsRepository extends BaseRepository {
	private _myDetailsSource = new BehaviorSubject<MyDetails>(undefined);
	public myDetails$ = this._myDetailsSource.asObservable();


	constructor(
		http: HttpAuth,
		config: AppConfig,
		cache: CacheService
	) {
		super(http, config, cache);
	}

	async get(ignoreCache: boolean = false, cancel$?: Subject<void>): Promise<MyDetails> {
		const key = 'me';
		const cached = this.getCacheSync<MyDetails>(key);
		if (cached && !ignoreCache)
			return cached;

		const me = await this.http.promise(cancel$).get<ApiData<MyDetails>>(`${this.config.apiUrl}${this.config.apiVersion}/me`);

		const myDetails = new MyDetails().deserialize(me.body.data);
		this.setCacheValue(key, myDetails, undefined);

		Timzones.DEFAULT_TIMEZONE = myDetails.timeZone;

		return myDetails;
	}

	async update(myDetails: MyDetails, cancel$?: Subject<void>): Promise<void> {
		const key = 'me';
		await this.http.promise(cancel$).put<void>(`${this.config.apiUrl}${this.config.apiVersion}/me`, myDetails);
		this._myDetailsSource.next(myDetails);
		this.setCacheValue(key, myDetails, undefined);
		Timzones.DEFAULT_TIMEZONE = myDetails.timeZone;
	}

	async changePassword(currentPassword: string, newPassword: string, cancel$?: Subject<void>): Promise<void> {
		const data = <IChangePasswordRequest>{
			currentPassword,
			newPassword
		};

		await this.http.promise(cancel$).post(`${this.config.apiUrl}${this.config.apiVersion}/me/password`, data);
	}
}
