<div class="page-tile__group page-tile__group--select-{{ddHeight}}" [ngClass]="{'ng-select-disabled': isDisabled, '': !isDisabled}">
	<label class="page-tile__group-label" for="{{id}}">{{label | transloco}}
		<i *ngIf="isRequired" class="fa fa-asterisk" aria-hidden="true"></i>
		<i *ngIf="tooltip" class="fa fa-question-circle tooltip tooltip--tile" aria-hidden="true">
			<span>{{tooltip | transloco}}</span>
		</i>
	</label>
	<ng-select
		[items]="items"
		labelForId="{{id}}"
		bindLabel="{{bindLabel}}"
		bindValue="{{bindValue}}"
		name="{{name}}"
		[clearable]="isClearable"
		[dropdownPosition]="ddPosition"
		[required]="isRequired"
		[compareWith]="compareFn"
		placeholder="{{placeholder | transloco}}"
		[(ngModel)]="value"
		(ngModelChange)="onChanged()"
		(blur)="onTouched()">
		<ng-template *ngIf="isDescription" ng-option-tmp let-item="item">
			{{item.name || item.code}} <br/>
			<small>{{item.description || item.label || item.desc}} </small>
		</ng-template>
	</ng-select>
</div>
