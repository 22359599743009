<dg-page>
	<div page-title>{{'operation-progress-page.title' | transloco}}</div>
	<div page-body>
		<div class="page__operation-progress">
			<div class="page__operation-progress-container">
				<h3>{{jobTitle}}</h3>
				<dg-operation-progress-bar [job]="job"></dg-operation-progress-bar>
				<p *ngIf="job.isSucceded" class="process-message">
					{{'operation-progress-page.job-isSucceded_label' | transloco}}
				</p>
				<p *ngIf="job.isFailed" class="process-message">
					{{'operation-progress-page.job-isFailed_label' | transloco}}
				</p>
				<p *ngIf="!job.isCompleted && job.percentComplete !== null" class="process-message_gray">
					<span>
						{{job.percentComplete}}% {{'operation-progress-page.job-percentComplete_label' | transloco}}
					</span>
				</p>
				<!-- eslint-disable-next-line @angular-eslint/template/conditional-complexity -->
				<div *ngIf="job.isCompleted && (job.jobType === 15
					|| job.jobType === 23
					|| job.jobType === 2
					|| job.jobType === 16
					|| job.jobType === 10
					|| job.jobType === 5
					|| job.jobType === 26
					|| job.jobType === 20
					|| job.jobType === 22
					|| job.jobType === 3
					|| job.jobType === 12
					|| job.jobType === 27
					|| job.jobType === 28
					|| job.jobType === 29
					|| job.jobType === 30
					|| job.jobType === 33
					|| job.jobType === 34
					|| job.jobType === 25
					|| job.jobType === 32
					|| job.jobType === 35
					|| job.jobType === 36
					|| job.jobType === 37
					|| job.jobType === 38
					|| job.jobType === 39
					|| job.jobType === 49
					|| job.jobType === 55
					|| job.jobType === 56
					|| job.jobType === 4
					|| (job.jobType === 14 && (job.tag.url === '/dashboard' || job.tag.url === '/service-items'
					|| job.tag.url === '/rating' || job.tag.url === '/product-templates'
					|| job.tag.url === '/customers'|| job.tag.url === '/import'
					|| job.tag.url === '/bundles'))) && job.stats">
					<p class="process-message_gray">{{numberItemsCompleted1}} {{messageItemsCompleted1}}</p>
					<p class="process-message_gray">{{numberItemsCompleted2}} {{messageItemsCompleted2}}</p>
					<p class="process-message_gray">{{numberItemsCompleted3}} {{messageItemsCompleted3}}</p>
					<p class="process-message_gray">{{numberItemsCompleted4}} {{messageItemsCompleted4}}</p>
					<p class="process-message_gray">{{numberItemsCompleted5}} {{messageItemsCompleted5}}</p>
				</div>
				<div *ngIf="job.messages && job.messages.length > 0">
					<p class="process-message">
						{{'operation-progress-page.failedRecords_label' | transloco}}
					</p>
					<div *ngFor="let item of job.messages">
						<p class="process-message_gray">{{item.message}}</p>
					</div>
				</div>

				<p class="process-message_gray">
					{{'operation-progress-page.jobStarted_label' | transloco}} {{jobStarted}}
				</p>
			</div>
			<div *ngIf="job.isCompleted" class="page__operation-progress-footer">
				<dg-operation-go-button [job]="job"></dg-operation-go-button>
			</div>
		</div>
	</div>
</dg-page>
