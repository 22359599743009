import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfig } from 'core/app-config';
import { HttpAuth } from 'core/auth';
import { Job } from 'domain/entities';
import { map } from 'rxjs/operators';
import { CacheService } from 'services/cache.service';
import { BaseRepository } from './base-repository';

@Injectable({
	providedIn: 'root'
})
export class JobRepository extends BaseRepository {

	constructor(
		http: HttpAuth,
		config: AppConfig,
		cache: CacheService
	) {
		super(http, config, cache);
	}

	get(id: number, ignoreCache?: boolean): Observable<Job> {

		const key = 'job-' + id;

		if (!ignoreCache) {
			if (this.cacheHasKey(key))
				return this.getCache<Job>(key);
		}

		const observable =
			this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/operations/${id}`)
				.pipe(
					map(res => {
						const job = new Job().deserialize(res.body);
						this.setCacheValue(key, job, null);
						return job;
					}));

		this.cache.set$(key, observable);

		return observable;
	}
}
