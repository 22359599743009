import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize, takeUntil } from 'rxjs/operators';
import { NavigatorService } from 'services';
import { CacheModel, CacheService } from 'services/cache.service';
import { DgAlertService } from 'tools/alert';

import { Location } from '@angular/common';
import { HashMap, TranslateParams, TranslocoService } from '@ngneat/transloco';
import {
	BillingStatus,
	BillingTerms,
	Department,
	ExportMultilineModel,
	Invoice,
	InvoiceMapping,
	InvoiceTemplate,
	Product,
	ProductMapping,
	SurchargeMapping,
	SurchargeSeparatelyMapping,
	SurchargeWithTaxMapping,
	SureTaxByStateLineMapping,
	SureTaxLineMapping,
	TaxMapping,
	TransactionMapping,
	Vendor
} from 'domain/entities';
import { IntegrationRepository, SurchargeRepository } from 'repositories';
import { CWExportInvoiceRepository } from 'repositories/cw-export-invoice-repository';
import { EMPTY, Subject } from 'rxjs';
import { InvoiceActionsService } from 'shared/modules/invoice-actions';

@Component({
	selector: 'dg-invoice-export-multiline',
	templateUrl: './invoice-export-multiline.component.html',
	styleUrls: ['./invoice-export-multiline.component.sass']
})
export class InvoiceExportMultilineComponent implements OnInit, OnDestroy {
	currentStep: number;
	invoices: Invoice[];
	excluded: Invoice[];
	invoicesMapping: InvoiceMapping[];
	productsMapping: ProductMapping[];
	transactionsMapping: TransactionMapping[];
	taxMapping: TaxMapping[];
	surchargeMapping: SurchargeMapping[];
	surchargeWithTaxMapping: SurchargeWithTaxMapping[];
	sureTaxLineMapping: SureTaxLineMapping[];
	sureTaxByStateLineMapping: SureTaxByStateLineMapping[];
	surchargeSeparatelyMapping: SurchargeSeparatelyMapping[];
	products: Product[];
	departments: Department[];
	billingStatuses: BillingStatus[];
	billingTerms: BillingTerms[];
	invoiceTemplates: InvoiceTemplate[] = [];
	loading = false;
	isGrouping = false;
	selectedGroupingTypeId: number;
	selectedPriceCalculationTypeId: number;
	selectedBillingStatusId = 0;
	selectedBillingTermsId = 0;
	selectedInvoiceTemplateId = 0;
	deleteExisting: boolean;
	isUseInvoiceDueDate: boolean;
	public combineSurchargesWithTaxes = false;
	public sureTaxMultiline = false;
	public surchargeSeparately = false;
	public groupTaxByState = false;
	public cacheModel: CacheModel;
	public cacheModelExcluded: CacheModel;
	filteredInvoices: number[];
	public wizardSettings: Map<string, undefined>;
	public isExported = false;
	public exportedInvoices: string[];
	public vendors: Vendor[];
	public selectedVendorId?: number;
	public vendorsAllVendorsOption = <Vendor>{
		id: -1,
		name: 'All Vendors'
	};
	public force = false;
	public resellerSurcharges$ = this.surchargeRepository.getSurcharges();
	t: <T = string>(key: TranslateParams, params?: HashMap, lang?: string) => T;
	private destroy$ = new Subject<void>();
	constructor(
		private readonly route: ActivatedRoute,
		private readonly cache: CacheService,
		private readonly invoiceRepo: InvoiceActionsService,
		private readonly navigator: NavigatorService,
		private readonly surchargeRepository: SurchargeRepository,
		private readonly integrationRepo: IntegrationRepository,
		private readonly transloco: TranslocoService,
		private readonly router: Router,
		private readonly repository: CWExportInvoiceRepository,
		private readonly alertService: DgAlertService,
		private readonly location: Location
	) {
		this.t = transloco.translate.bind(transloco);
		this.route.data
			.subscribe((data: Partial<{
				wizardSettings: Map<string, undefined>;
				vendors: Vendor[];
			}>) => {
				this.wizardSettings = data.wizardSettings;
				const vendors = data.vendors;
				vendors.unshift(this.vendorsAllVendorsOption);
				this.vendors = vendors;
			});

		this.currentStep = 1;
		this.selectedGroupingTypeId = 1;
		this.selectedVendorId = this.vendorsAllVendorsOption.id;

		this.cacheModel = this.cache.get('exportInvoicesMultiline');
		this.cacheModelExcluded = this.cache.get('exportInvoicesMultiline-excluded');

		this.invoices = this.cacheModel ? <Invoice[]>this.cacheModel.Object : [];
		this.excluded = this.cacheModelExcluded ? <Invoice[]>this.cacheModelExcluded.Object : [];

		if (this.invoices.length) {
			this.GetCwGeneralData();
		} else {
			this.location.back();
		}

		const invDefault = new InvoiceTemplate();
		invDefault.id = 0;
		invDefault.name = 'Use Company Default Template';

		this.invoiceTemplates.push(invDefault);
	}

	async ngOnInit(): Promise<void> {
		const connectWiseSettings = await this.integrationRepo.getConnectWiseIntegration(this.destroy$).withBusy();
		this.isUseInvoiceDueDate = connectWiseSettings.invoiceDueDateId === 1;
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	private setWizardSettings(): void {
		this.selectedBillingStatusId = this.wizardSettings['billing-status'];
		this.selectedPriceCalculationTypeId = this.wizardSettings['price-calculation-type'];
		this.deleteExisting = this.wizardSettings['delete-existing'];
		this.combineSurchargesWithTaxes = this.wizardSettings['combine-surcharges-with-taxes'];
		this.surchargeSeparately = this.wizardSettings['surcharges-separately'];
		this.selectedInvoiceTemplateId = this.wizardSettings['invoice-template'] ? this.wizardSettings['invoice-template'] : 0;
	}

	public GroupingTypeSelected(): void {
		this.isGrouping = true;
		const groupingTypeId = +this.selectedGroupingTypeId;
		const actionFilters = this.cache.get('invoices-action-filterParams');
		const filterParams = this.invoiceRepo.selectedAll && actionFilters ? actionFilters : '';
		let vendorId = undefined;

		if (this.selectedVendorId && this.selectedVendorId !== this.vendorsAllVendorsOption.id) {
			vendorId = +this.selectedVendorId;
		}

		this.repository.GroupingTypePost(groupingTypeId, this.invoices, this.force ,this.excluded, filterParams, vendorId)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: res => {
					this.surchargeSeparatelyMapping =  res.surchargeSeparatelyMapping;
					this.invoicesMapping = res.invoicesMapping;
					this.productsMapping = res.productsMapping;
					this.transactionsMapping = res.transactionsMapping;
					this.taxMapping = res.taxMapping;
					this.surchargeMapping = res.surchargeMapping;
					this.surchargeWithTaxMapping = res.surchargeWithTaxMapping;
					this.products = res.products;
					this.sureTaxLineMapping = res.sureTaxLineMapping;
					this.sureTaxByStateLineMapping = res.sureTaxByStateLineMapping;
					this.filteredInvoices = res.invoices;

					this.isGrouping = false;
					this.NextStepSelected();
				},
				error: res => {
					this.isGrouping = false;

					if (Array.isArray(res.error.references)) {
						this.isExported = true;
						this.exportedInvoices = res.error.references;
						return EMPTY;
					} else {
						throw res;
					}
				}
			});
	}

	public GetCwGeneralData(): void {
		this.loading = true;

		this.repository.GetCwGeneralData()
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: res => {
					this.departments = res.departments;
					this.billingStatuses = res.billingStatuses;
					this.billingTerms = res.billingTerms;
					this.invoiceTemplates = this.invoiceTemplates.concat(res.invoiceTemplates);
					this.setWizardSettings();
					this.loading = false;
				},
				error: res => {
					this.loading = false;

					if (Array.isArray(res.error.references)) {
						this.isExported = true;
						this.exportedInvoices = res.error.references;
						return EMPTY;
					} else {
						throw res;
					}
				}
			});
	}

	public Export(): void {
		if (!this.invoices) {
			this.alertService.show(this.t('cw-export.step8_none-exported-warning'), 'error');
			return;
		}
		this.loading = true;
		const exportModel = new ExportMultilineModel();
		exportModel.selectedGroupingTypeId = this.selectedGroupingTypeId;
		exportModel.selectedPriceCalculationTypeId = this.selectedPriceCalculationTypeId;
		exportModel.deleteExisting = this.deleteExisting;
		exportModel.invoices = this.filteredInvoices;
		exportModel.invoicesMapping = this.invoicesMapping;
		exportModel.productsMapping = this.productsMapping;
		exportModel.transactionMapping = this.transactionsMapping;
		exportModel.taxMapping = this.taxMapping;
		exportModel.surchargeMapping = this.surchargeMapping;
		exportModel.surchargeWithTaxMapping = this.surchargeWithTaxMapping;
		exportModel.combineSurchargesWithTaxes = this.combineSurchargesWithTaxes;
		exportModel.departments = this.departments;
		exportModel.sureTaxLineMapping = this.sureTaxLineMapping;
		exportModel.sureTaxMultiline = this.sureTaxMultiline;
		exportModel.selectedBillingStatusId = this.selectedBillingStatusId;
		exportModel.selectedBillingTermsId = this.selectedBillingTermsId;
		exportModel.surchargeSeparatelyMapping = this.surchargeSeparatelyMapping;
		exportModel.surchargesSeparately = this.surchargeSeparately;

		exportModel.selectedInvoiceTemplateId = +this.selectedInvoiceTemplateId || 0;
		exportModel.sureTaxByStateLineMapping = this.sureTaxByStateLineMapping;
		exportModel.groupTaxByState = this.groupTaxByState;

		exportModel.tag = this.cacheModel.Tag;

		this.repository.export(exportModel)
			.pipe(takeUntil(this.destroy$), finalize(() => this.loading = false))
			.subscribe({
				next: result => {
					if (result.operationId) {
						this.navigator.toOperation(result.operationId);
					}
				}
			});
	}

	public CheckStep(value: number): string {
		return this.currentStep === value ? 'steps--active' : '';
	}

	public NextStepSelected(): void {
		if (this.currentStep < 8) {
			this.currentStep = this.currentStep + 1;
		}
	}

	PreviosStep(): void {
		if (this.currentStep > 1) {
			this.currentStep = this.currentStep - 1;
		}
	}

	backTo(): void {
		const backUrl = (this.cacheModel.Tag.split(':').pop()).slice(2, -2);
		void this.router.navigate([backUrl]);
	}

	BackStep(): void {
		this.PreviosStep();
	}

	onExportConfirm(): void {
		this.isExported = false;
		this.exportedInvoices = [];
		this.force = true;
	}
}
