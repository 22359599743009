import { Serializable } from 'domain/models';
import { Guid } from 'domain/types';
import { Invoice } from './main-entities';

export class CWInvoiceSyncMapping implements Serializable<CWInvoiceSyncMapping> {
	public cwAgreements: CWSyncAgreement[];
	public invoiceId: number;
	public invoiceNumber: string;
	public customerLabel: string;
	public cwCompanyName: string;
	public selectedAgreementId: number;
	public selectedInvoiceId: number;
	public selectedCwAgreement: CWSyncAgreement;
	public dgAgreementName: string;

	deserialize(json: any): CWInvoiceSyncMapping {
		this.cwCompanyName = json.cwCompanyName;
		this.customerLabel = json.customerLabel;
		this.invoiceNumber = json.invoiceNumber;
		this.invoiceId = json.invoiceId;
		this.cwAgreements = json.cwAgreements;
		this.selectedAgreementId = json.selectedAgreementId;
		this.selectedInvoiceId = json.selectedInvoiceId;
		this.selectedCwAgreement = json.cwAgreements.find(f => f.id === this.selectedAgreementId);

		if (json.selectedInvoiceId) {
			this.selectedCwAgreement.selectedInvoiceId = json.selectedInvoiceId;
		}

		this.dgAgreementName = json.dgAgreementName;

		return this;
	}
}

interface CWSyncAgreement {
	id: number;
	name: string;
	invoices?: CWSyncAgreementInvoices[];
	selectedInvoiceId: number;
}

interface CWSyncAgreementInvoices {
	id: number;
	invoiceNumber: string;
	synced: boolean;
}

export class InvoiceNumberMapping {
	public invoiceId: number;
	public invoiceNumber: string;
	public cwInvoiceId: number;
}

export class AgreementType implements Serializable<AgreementType> {
	public id: number;
	public name: string;

	deserialize(json: any): AgreementType {
		this.id = json.id;
		this.name = json.name;
		return this;
	}
}

export class ExportInvoiceModel {
	public agreementTypeId: number;
	public invoices: Guid[];
	public excludedInvoices: Guid[];
	public selectedAll: any;
	public force? : boolean;
	public selectedVendorId?: number;
}

export class AgreementMapping implements Serializable<AgreementMapping> {
	public customerId: number;
	public company: Company;
	public invoice: Invoice;
	public agreements: Agreement[];
	public selectedAgreementId: number;

	deserialize(json: any): AgreementMapping {
		this.customerId = json.customerId;
		this.company = json.company;
		this.invoice = json.invoice;
		this.agreements = json.agreements;
		this.selectedAgreementId = json.selectedAgreementId;
		return this;
	}
}

interface Company {
	id: number;
	identifier: string;
	name: string;
}

interface Agreement {
	id: number;
	name: string;
	company: Company;
	type: AgreementType;
}

export class ProductMapping implements Serializable<ProductMapping> {
	public serviceId: number;
	public siteId: number;
	public recordType: number;
	public groupName: string;
	public description: string;
	public products: Product[];
	public selectedProductId: number;

	deserialize(json: any): ProductMapping {
		this.groupName = json.groupName;
		this.products = json.products;
		this.description = json.description;
		this.selectedProductId = json.selectedProductId;
		this.serviceId = json.serviceId;
		this.recordType = json.recordType;
		this.siteId = json.siteId;
		return this;
	}
}

export class TransactionMapping implements Serializable<TransactionMapping> {
	public transactionName: string;
	public code: string;
	public products: Product[];
	public selectedProductId: number;
	public recurringTransaction: boolean;

	deserialize(json: any): TransactionMapping {
		this.transactionName = json.transactionName;
		this.code = json.code;
		this.products = json.products;
		this.selectedProductId = json.selectedProductId;
		this.recurringTransaction = json.recurringTransaction;
		return this;
	}
}

export class TaxMapping implements Serializable<TaxMapping> {
	public taxName: string;
	public taxInclusive: boolean;
	public products: Product[];
	public selectedProductId: number;

	deserialize(json: any): TaxMapping {
		this.taxName = json.taxName;
		this.products = json.products;
		this.selectedProductId = json.selectedProductId;
		this.taxInclusive = json.taxInclusive;

		return this;
	}
}

export class SurchargeMapping implements Serializable<SurchargeMapping> {
	public surchargeName: string;
	public products: Product[];
	public selectedProductId: number;

	deserialize(json: any): SurchargeMapping {
		this.surchargeName = json.surchargeName;
		this.products = json.products;
		this.selectedProductId = json.selectedProductId;
		return this;
	}
}

export class SurchargeWithTaxMapping implements Serializable<SurchargeWithTaxMapping> {
	public combineName: string;
	public products: Product[];
	public selectedProductId: number;

	deserialize(json: any): SurchargeWithTaxMapping {
		this.combineName = json.combineName;
		this.products = json.products;
		this.selectedProductId = json.selectedProductId;
		return this;
	}
}

export class SurchargeSeparatelyMapping implements Serializable<SurchargeSeparatelyMapping> {
	public surchargeName: string;
	public products: Product[];
	public selectedProductId: number;

	deserialize(json: any): SurchargeSeparatelyMapping {
		this.surchargeName = json.surchargeName;
		this.products = json.products;
		this.selectedProductId = json.selectedProductId;
		return this;
	}
}

export class Product {
	public id: number;
	public identifier: string;
	public group: string;
	public description: string;
	public customerDescription: string;

	deserialize(json: any): Product {
		this.id = json.id;
		this.identifier = json.identifier;
		this.group = json.group;
		this.description = json.description;
		this.customerDescription = json.customerDescription;
		return this;
	}
}

export class Department {
	public id: number;
	public name: string;

	deserialize(json: any): Department {
		this.id = json.id;
		this.name = json.name;
		return this;
	}
}

export class BillingStatus {
	public id: number;
	public name: string;

	deserialize(json: any): BillingStatus {
		this.id = json.id;
		this.name = json.name;
		return this;
	}
}

export class BillingTerms {
	public id: number;
	public name: string;

	deserialize(json: any): BillingTerms {
		this.id = json.id;
		this.name = json.name;
		return this;
	}
}

export class InvoiceTemplate {
	public id: number;
	public name: string;

	deserialize(json: any): InvoiceTemplate {
		this.id = json.id;
		this.name = json.name;
		return this;
	}
}

export class InvoiceMapping implements Serializable<InvoiceMapping> {
	invoiceId: number;
	invoiceNumber: string;
	customerId: number;
	selectDepartmentId: number;

	deserialize(json: any): InvoiceMapping {
		this.invoiceId = json.invoiceId;
		this.invoiceNumber = json.invoiceNumber;
		this.customerId = json.customerId;
		this.selectDepartmentId = json.selectDepartmentId;
		return this;
	}
}

export class Vendor {
	public id: number;
	public name: string;

	deserialize(json: any): Vendor {
		this.id = json.id;
		this.name = json.name;
		return this;
	}
}

export class SureTaxLineMapping implements Serializable<SureTaxLineMapping> {
	taxTypeDesc: string;
	taxInclusive: boolean;
	selectedProductId: number;

	deserialize(json: any): SureTaxLineMapping {
		this.taxTypeDesc = json.taxTypeDesc;
		this.selectedProductId = json.selectedProductId;
		this.taxInclusive = json.taxInclusive;

		return this;
	}
}

export class SureTaxByStateLineMapping implements Serializable<SureTaxByStateLineMapping> {
	taxTypeDesc: string;
	stateCode: string;
	selectedAccountCode: number;
	selectedProductId: number;
	taxInclusive: boolean;

	deserialize(json: any): SureTaxByStateLineMapping {
		this.taxTypeDesc = json.taxTypeDesc;
		this.selectedAccountCode = json.selectedAccountCode;
		this.stateCode = json.stateCode;
		this.selectedProductId = json.selectedProductId;
		this.taxInclusive = json.taxInclusive;

		return this;
	}
}

export class XeroTaxMapping implements Serializable<XeroTaxMapping> {
	public taxName: string;
	public selectedAccountCode: number;

	deserialize(json: any): XeroTaxMapping {
		this.taxName = json.taxName;
		this.selectedAccountCode = json.selectedPselectedAccountCoderoductId;
		return this;
	}
}

export class XeroTaxLineMapping implements Serializable<XeroTaxLineMapping> {
	taxTypeDesc: string;
	selectedAccountCode: number;

	deserialize(json: any): XeroTaxLineMapping {
		this.taxTypeDesc = json.taxTypeDesc;
		this.selectedAccountCode = json.selectedAccountCode;
		return this;
	}
}

export class XeroTaxLineByStateMapping implements Serializable<XeroTaxLineByStateMapping> {
	taxTypeDesc: string;
	stateCode: string;
	selectedAccountCode: number;

	deserialize(json: any): XeroTaxLineByStateMapping {
		this.taxTypeDesc = json.taxTypeDesc;
		this.selectedAccountCode = json.selectedAccountCode;
		this.stateCode = json.stateCode;
		return this;
	}
}

export class XeroBrandingThemes implements Serializable<XeroBrandingThemes> {
	brandingThemes: BrandingTheme[];
	brandingThemesMapping: BrandingThemesMapping[];

	deserialize(json: any): XeroBrandingThemes {
		this.brandingThemes = json.brandingThemes;
		this.brandingThemesMapping = json.brandingThemesMapping;
		return this;
	}
}

export class BrandingTheme implements Serializable<BrandingTheme> {
	brandingThemeID: string;
	name: string;

	deserialize(json: any): BrandingTheme {
		this.brandingThemeID = json.brandingThemeID;
		this.name = json.name;
		return this;
	}
}

export class BrandingThemesMapping implements Serializable<BrandingThemesMapping> {
	invoiceId: string;
	invoiceNumber: string;
	selectedXeroBrandingThemeId: string;

	deserialize(json: any): BrandingThemesMapping {
		this.invoiceId = json.invoiceId;
		this.invoiceNumber = json.invoiceNumber;
		this.selectedXeroBrandingThemeId = json.selectedXeroBrandingThemeId;
		return this;
	}
}

export class XeroAccount implements Serializable<XeroAccount> {
	name: string;
	code: string;

	deserialize(json: any): XeroAccount {
		this.name = json.name;
		this.code = json.value;
		return this;
	}
}

export class ExportCWSyncInvoices {
	public invoicesMapping: InvoiceNumberMapping[];
	public tag: string;
}

export class ExportModel {
	public selectedGroupingTypeId: number;
	public selectedPriceCalculationTypeId: number;
	public optionExistingItems: number;
	public productsMapping: ProductMapping[];
	public transactionMapping: TransactionMapping[];
	public taxMapping: TaxMapping[];
	public sureTaxLineMapping: SureTaxLineMapping[];
	public sureTaxByStateLineMapping: SureTaxByStateLineMapping[];
	public surchargeMapping: SurchargeMapping[];
	public surchargeWithTaxMapping: SurchargeWithTaxMapping[];
	public agreementsMapping: AgreementMapping[];
	public products: Product[];
	public tag: string;
	public combineSurchargesWithTaxes: boolean;
	public excludeRecurringProducts: boolean;
	public sureTaxMultiline: boolean;
	public groupTaxByState: boolean;
	public selectedAgreementTypeId: number;
	public excludeTaxes: boolean;
	public surchargeSeparatelyMapping: SurchargeSeparatelyMapping[];
	public surchargesSeparately: boolean;
}

export class ExportMultilineModel {
	public selectedGroupingTypeId: number;
	public selectedPriceCalculationTypeId: number;
	public selectedBillingStatusId: number;
	public selectedBillingTermsId: number;
	public selectedInvoiceTemplateId: number;
	public deleteExisting: boolean;
	public invoicesMapping: InvoiceMapping[];
	public productsMapping: ProductMapping[];
	public transactionMapping: TransactionMapping[];
	public taxMapping: TaxMapping[];
	public surchargeMapping: SurchargeMapping[];
	public surchargeWithTaxMapping: SurchargeWithTaxMapping[];
	public sureTaxLineMapping: SureTaxLineMapping[];
	public sureTaxByStateLineMapping: SureTaxByStateLineMapping[];
	public products: Product[];
	public departments: Department[];
	public invoices: number[];
	public tag: string;
	public combineSurchargesWithTaxes: boolean;
	public sureTaxMultiline: boolean;
	public useProductsTaxRates: boolean;
	public groupTaxByState: boolean;
	public sureTaxLinesForInvoiceTaxesMapping: SureTaxLineMapping[];
	public sureTaxByStateLinesForInvoiceTaxesMapping: SureTaxByStateLineMapping[];
	public groupTaxByStateForInvoiceTaxes: boolean;
	public uniteProducts: boolean;
	public selectedPaymentTermId?: number;
	public selectedEntityTypeId: number;
	public selectedProductIdForAdjustmentLine?: number;
	public surchargeSeparatelyMapping?: SurchargeSeparatelyMapping[];
	public surchargesSeparately?: boolean;
}

export class XeroExportMultilineModel {
	public payments: XeroAccount[];
	public accounts: XeroAccount[];
	public taxes: XeroTaxMapping[];
	public taxLines: XeroTaxLineMapping[];
	public taxLinesByState: XeroTaxLineByStateMapping[];

	public reference: string;
	public accountCode: string;
	public paymentCode: string;
	public sentToContact: boolean;
	public invoiceStatus: number;
	public useGlCode: boolean;

	public tag: string;
	public extendedExport: boolean;
	public taxMultiline: boolean;
	public taxByState: boolean;
	public invoices: number[];

	public brandingThemesMapping: BrandingThemesMapping[];
}

export class AutotaskMapping implements Serializable<AutotaskMapping> {
	public invoices: AutotaskInvoices[];
	public products: AutoTaskProducts[];

	deserialize(json: any): AutotaskMapping {
		this.invoices = json.invoices;
		this.products = json.products;
		return this;
	}
}

export interface AutoTaskProducts {
	id: number;
	name: string;
}

interface AutoTaskContracts {
	id: number;
	name: string;
}

export class AutotaskWizardSettings implements Serializable<AutotaskWizardSettings> {
	public combineSurchargesWithTaxes = false;
	public selectedPriceCalculationTypeId = 1;
	public sureTaxMultiline = false;
	public groupTaxByState = false;
	public deleteExisting = false;
	public selectedExportProductTransactions = 2;
	public selectedGroupingTypeId = 1;

	deserialize(json: any): AutotaskWizardSettings {
		this.combineSurchargesWithTaxes = json['combine-surcharges-with-taxes'];
		this.selectedPriceCalculationTypeId = json['price-calculation-type'];
		this.sureTaxMultiline = json['multiline-taxes'];
		this.groupTaxByState = json['split-taxes-by-state'];
		this.deleteExisting = json['delete-existing'];
		this.selectedExportProductTransactions = json['export-transaction-type'];
		this.selectedGroupingTypeId = json['grouping-type'];

		return this;
	}
}

export class AutotaskTotalTaxes implements Serializable<AutotaskTotalTaxes> {
	public taxName: string;
	public taxInclusive: boolean;
	public selectedAtProductId: number;

	deserialize(json: any): AutotaskTotalTaxes {
		this.taxName = json.taxName;
		this.selectedAtProductId = json.selectedAtProductId;
		this.taxInclusive = json.taxInclusive;

		return this;
	}
}

export class AutotaskTaxesLines implements Serializable<AutotaskTaxesLines> {
	public taxTypeDesc: string;
	public taxInclusive: boolean;
	public selectedAtProductId: number;

	deserialize(json: any): AutotaskTaxesLines {
		this.taxTypeDesc = json.taxTypeDesc;
		this.selectedAtProductId = json.selectedAtProductId;
		this.taxInclusive = json.taxInclusive;

		return this;
	}
}

export class AutotaskTaxesLinesState implements Serializable<AutotaskTaxesLinesState> {
	public taxTypeDesc: string;
	public stateCode: string;
	public taxInclusive: boolean;
	public selectedAtProductId: number;

	deserialize(json: any): AutotaskTaxesLinesState {
		this.taxTypeDesc = json.taxTypeDesc;
		this.selectedAtProductId = json.selectedAtProductId;
		this.stateCode = json.stateCode;
		this.taxInclusive = json.taxInclusive;

		return this;
	}

}

export class AutotaskSurchargesWithTaxes implements Serializable<AutotaskSurchargesWithTaxes> {
	public combineName: string;
	public selectedAtProductId: number;

	deserialize(json: any): AutotaskSurchargesWithTaxes {
		this.combineName = json.combineName;
		this.selectedAtProductId = json.selectedAtProductId;
		return this;
	}
}

export class AutotaskSurcharge implements Serializable<AutotaskSurcharge> {
	public surchargeName: string;
	public selectedAtProductId: number;
	deserialize(json: any): AutotaskSurcharge {
		this.surchargeName = json.surchargeName;
		this.selectedAtProductId = json.selectedAtProductId;
		return this;
	}
}

export class AutotaskInvoiceLines implements Serializable<AutotaskInvoiceLines> {
	public groupName: string;
	public description: string;
	public siteId: number;
	public recordType: number;
	public serviceId: number;
	public selectedAtProductId: number;

	deserialize(json: any): AutotaskInvoiceLines {
		this.groupName = json.groupName;
		this.siteId = json.siteId;
		this.description = json.description;
		this.recordType = json.recordType;
		this.serviceId = json.serviceId;
		this.selectedAtProductId = json.selectedAtProductId;
		return this;
	}
}

export class AutotaskTransaction implements Serializable<AutotaskTransaction> {
	public transactionName: string;
	public code: string;
	public selectedAtProductId: number;
	public isOneOff: boolean;

	deserialize(json: any): AutotaskTransaction {
		this.transactionName = json.transactionName;
		this.code = json.code;
		this.isOneOff = json.isOneOff;
		this.selectedAtProductId = json.selectedAtProductId;
		return this;
	}
}

export class AutotaskInvoices implements Serializable<AutotaskInvoices> {
	public companyName: string;
	public invoiceNumber: number;
	public selectedAtContractId: number;
	public invoiceId: number;
	public contracts: AutoTaskContracts[];
	public agreementId: Guid;
	public agreementName: string;
	deserialize(json: any): AutotaskInvoices {
		this.companyName = json.companyName;
		this.contracts = json.contracts;
		this.invoiceNumber = json.invoiceNumber;
		this.selectedAtContractId = json.selectedAtContractId;
		this.invoiceId = json.invoiceId;
		this.agreementId = json.agreementId;
		this.agreementName = json.agreementName;
		return this;
	}
}

export class AutotaskExportMultilineModel {
	public invoices: AutotaskInvoices[];
	public transactionResponses: AutotaskTransaction[];
	public invoiceLinesResponses: AutotaskInvoiceLines[];
	public surchargeResponses: AutotaskSurcharge[];

	public surchargesWithTaxesResponses: AutotaskSurchargesWithTaxes[];
	public totalTaxesResponses: AutotaskTotalTaxes[];
	public taxesLinesResponses: AutotaskTaxesLines[];
	public taxesLinesStateResponses: AutotaskTaxesLinesState[];

	public tag: string;
	public selectedGroupingTypeId: number;
	public selectedPriceCalculationTypeId: number;
	public selectedExportProductTransactions: number;
	public combineSurchargesWithTaxes: boolean;
	public taxMultiline: boolean;
	public taxForState: boolean;
	public deleteExisting: boolean;
}

export class AutotaskInvoiceSyncMapping implements Serializable<AutotaskInvoiceSyncMapping> {
	public invoiceId: number;
	public invoiceNumber: string;
	public customerId: number;
	public customerLabel: string;
	public atCompanyName: string;
	public atCompanyId: number;
	public selectedAtInvoiceId: number;
	public atInvoices: AtInvoices[];
	public synced: boolean;

	deserialize(json: any): AutotaskInvoiceSyncMapping {
		this.customerLabel = json.customerLabel;
		this.invoiceNumber = json.invoiceNumber;
		this.invoiceId = json.invoiceId;
		this.selectedAtInvoiceId = json.selectedAtInvoiceId;
		this.atCompanyId = json.atCompanyId;
		this.atCompanyName = json.atCompanyName;
		this.customerId = json.customerId;
		this.atInvoices = json.atInvoices;
		this.synced = json.synced;
		return this;
	}
}

interface AtInvoices {
	id: number;
	invoiceNumber: string;
	synced: number;
}

export class ExportAutotaskSyncInvoices {
	public invoicesMapping: AtInvoiceNumberMapping[];
	public tag: string;
}

export class AtInvoiceNumberMapping {
	public invoiceId: number;
	public invoiceNumber: string;
	public atInvoiceId: number;
}

// CW ReturnTaxes
interface ICWReturnTaxMapping {
	taxName: string;
	selectedCwProductId: number;
	taxInclusive: boolean;
}

interface ICWReturnTaxLineMapping {
	taxTypeDesc: string;
	taxInclusive: boolean;
	selectedCwProductId: number;
}

interface ICWReturnTaxLineStateMapping {
	taxTypeDesc: string;
	stateCode: string;
	taxInclusive: boolean;
	selectedCwProductId: number;
}

export interface ICWReturnMappingResponse {
	cwProducts: ICWReturnProduct[];
	totalTaxesResponses: ICWReturnTaxMapping[];
	taxesLinesResponses: ICWReturnTaxLineMapping[];
	taxesLinesStateResponses: ICWReturnTaxLineStateMapping[];
	invoices: number[];
}

export interface ICWReturnTaxesModel {
	invoices: Guid[];
	tag: string;
	taxForState: boolean;
	taxMultiline: boolean;
	taxesLinesResponses: ICWReturnTaxLineMapping[];
	taxesLinesStateResponses: ICWReturnTaxLineStateMapping[];
	totalTaxesResponses: ICWReturnTaxMapping[];
}

interface ICWReturnProduct {
	identifier: string;
	id: number;
}
