import { Injectable } from '@angular/core';
import { IQuoterErrors } from 'pages/global/+external-quotes';
import { firstValueFrom, Observable, Subject } from 'rxjs';

@Injectable()
export class DgDialogService {
	// Observable string sources
	private dialogSource = new Subject<DgDialog>();

	// Observable string streams
	showDialog$ = this.dialogSource.asObservable();

	/**
	 *  @deprecated DO NOT USE - will be removed. Use async confirm method instead
	 */
	confirm$(title: string, message: string | string[], list?: IQuoterErrors[], className?: string): Observable<boolean> {
		const dialog = new DgDialog(title, message, list, className);
		this.dialogSource.next(dialog);
		return dialog.stream.asObservable();
	}

	async confirm(title: string, message: string | string[], list?: IQuoterErrors[], className?: string): Promise<boolean> {
		return firstValueFrom(this.confirm$(title, message, list, className));
	}
}

export class DgDialog {
	public stream: Subject<boolean> = new Subject<boolean>();

	constructor(public title: string, public message: string | string[], public list?: IQuoterErrors[], public className?: string) {

	}
}
