import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpAuth } from 'core/auth';
import { ApiData, Guid } from 'domain/types';
import { ResellerRole, RolePermission } from 'domain/entities';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfig } from 'core/app-config';
import { BaseRepository } from './base-repository';
import { CacheService } from 'services/cache.service';

@Injectable({
	providedIn: 'root'
})
export class RoleRepository extends BaseRepository {

	constructor(
		http: HttpAuth,
		config: AppConfig,
		cache: CacheService
	) {
		super(http, config, cache);
	}

	getRoles$(): Observable<ResellerRole[]> {
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/roles`)
			.pipe(
				map(res => res.body.data.map(x => new ResellerRole().deserialize(x))));
	}

	async getRoles(destroy?: Subject<void>): Promise<ResellerRole[]> {
		const res = await this.http.promise(destroy).get<ApiData<ResellerRole[]>>(`${this.config.apiUrl}${this.config.apiVersion}/roles`);
		return res.body.data.map(x => new ResellerRole().deserialize(x));
	}

	async getRoleById(id: Guid, destroy$?: Subject<void>): Promise<ResellerRole> {
		const res = await this.http.promise(destroy$).get<ApiData<ResellerRole>>(`${this.config.apiUrl}${this.config.apiVersion}/roles/${id}`);
		return new ResellerRole().deserialize(res.body.data);
	}

	async getRolesPermissions(destroy?: Subject<void>): Promise<RolePermission[]> {
		const res = await this.http.promise(destroy).get<ApiData<RolePermission[]>>(`${this.config.apiUrl}${this.config.apiVersion}/roles-permissions`);
		return res.body.data.map(x => new RolePermission().deserialize(x));
	}

	manageRolePermission(rolePermission: RolePermission[]): Observable<HttpResponse<any>> {
		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/roles-permissions`, { data: rolePermission }, null);
	}

	roleAdd(role: ResellerRole): Observable<number> {
		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/roles`, role, null)
			.pipe(map(r => r.body.data));
	}

	roleUpdate(role: ResellerRole): Observable<HttpResponse<any>> {
		return this.http.put(`${this.config.apiUrl}${this.config.apiVersion}/roles/${role.id}`, null, role);
	}

	rolesDelete(ids: Guid[]): Observable<HttpResponse<any>> {
		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/roles/delete`, { records: ids }, null);
	}
}
