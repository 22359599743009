import { Component, OnDestroy, OnInit } from '@angular/core';
import * as Moment from 'moment';

import { NotificationService } from '../../services';
import { Notification } from 'domain/entities';

const REFRESH_INTERVAL = 30000;

@Component({
	selector: 'dg-notifications',
	templateUrl: './notifications.component.html',
	styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit, OnDestroy {
	public notifications: Notification[] = [];
	public newNotifications = 0;
	public hasNotifications = false;
	public panelOpen = false;
	private timerHandle?: NodeJS.Timer;

	constructor(
		private readonly notificationService: NotificationService
	) {

		// Enables polling mechanism
		//this.timerHandle = setInterval(() => this.notificationService.checkForNew(), REFRESH_INTERVAL);

		this.notifications = [];
	}

	ngOnInit(): void {
		this.notificationService.notifications$.subscribe(ns => this.onNotifications(ns));
	}

	ngOnDestroy(): void {
		if (this.timerHandle) {
			clearTimeout(this.timerHandle);
		}
	}

	trackByNotification = (index: number, item: Notification): number => item?.id;

	public onNotificationsDismiss(): void {
		this.notificationService.suppress();
		this.notifications = [];
		this.hasNotifications = this.notifications.length > 0;
	}

	private onNotifications(notifications: Notification[]): void {
		if (!notifications)
			return;

		this.notifications = notifications;
		this.hasNotifications = notifications.length > 0;

		// do not show NewNofitifations icon if panel is open
		// in this case just mark such notifications as seen
		// otherwise it will be wierd to see that icon with open panel
		if (this.panelOpen) {
			this.notificationService.markAllAsSeen();
			this.newNotifications = 0;
		} else {
			const notSeenNumber = notifications.filter(x => !x.seen).length;
			this.newNotifications = notSeenNumber;
		}
	}

	public togglePanel(): void {
		this.panelOpen = !this.panelOpen;

		if (this.panelOpen) {
			this.notificationService.markAllAsSeen();

			// download new on each click
			this.notificationService.checkForNew();
		}
	}

	public formatDate(notification: Notification): string {

		const date = notification.date;
		const now = Moment();

		let formatToUse = 'D MMM h:mm A';

		if (date.year() === now.year()
			&& date.month() === now.month()
			&& date.date() === now.date()) {
			formatToUse = 'h:mm A';
		}

		return date.format(formatToUse);
	}

}
