export class AuthDetails {
	constructor(public token: string,
		public expirationDate: Date,
		public refreshToken: string) {

	}

	static restoreFromJson(json: string): AuthDetails {
		const auth = JSON.parse(json);
		return new AuthDetails(
			auth.token,
			new Date(Date.parse(auth.expirationDate)),
			auth.refreshToken
		);
	}

	public get expired(): boolean {
		return this.expirationDate < new Date();
	}
}
