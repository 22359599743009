import * as Integrations from 'pages/+portal-settings/portal-integrations/integration-service';
import { Serializable } from 'domain/models';
import { Guid } from 'domain/types';

export class CereTaxEntity implements Serializable<CereTaxEntity> {
	apiKey: string;
	clientProfileID: string;
	businessTypeId: number;
	sandbox: boolean;

	alt: string = Integrations.IntegrationService.CereTax;
	iconSrc = 'CereTax_logo.png';
	title: string = Integrations.IntegrationService.CereTax;
	isConfigured = false;

	deserialize(input: any): CereTaxEntity {
		this.apiKey = input.apiKey;
		this.clientProfileID = input.clientProfileID;
		this.businessTypeId = input.businessTypeId;
		this.sandbox = input.sandbox;

		if (this.clientProfileID?.length) {
			this.isConfigured = true;
		}

		return this;
	}
}

export interface CeretaxTaxExemption {
	id: Guid;
	code: string;
	state: string;
	taxLevel: string;
	taxType: string;
	taxTypeClass: string;
	taxPercentage: number;
	reason: string;
}

export interface CeretaxTaxLevels {
	taxLevel: string;
	taxLevelDescription: string;
}

export interface CeretaxStates {
	countryIsoCode: string;
	stateAbbreviation: string;
	state: string;
}

export interface CeretaxTaxTypes {
	taxType: string;
	taxTypeDescription: string;
}

export interface CeretaxTaxTypeClasses {
	taxTypeClass: string;
	taxTypeClassDescription: string;
}

export interface CeretaxDataLookups {
	taxLevels: CeretaxTaxLevels[];
	states: CeretaxStates[];
	taxTypes: CeretaxTaxTypes[];
	taxTypeClasses: CeretaxTaxTypeClasses[];
}
