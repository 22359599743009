import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class OperationService {
	private activeOperationSource = new BehaviorSubject<number>(null);

	public activeOperation$ = this.activeOperationSource.asObservable();

	public changeActiveOperation(id: number): void {
		this.activeOperationSource.next(id);
	}
}
