import { Serializable } from 'domain/models';
import { EntityName } from 'tools/decorators';
import { Guid } from 'domain/types';

@EntityName('Product Category', 'Product Categories')
export class ProductCategory implements Serializable<ProductCategory> {
	id: Guid;
	resellerId: number;
	name: string;
	description: string;
	glCodeId: Guid;
	glCode: string;

	deserialize(json: any): ProductCategory {

		this.id = json.id;
		this.resellerId = json.resellerId;
		this.name = json.name;
		this.description = json.description;
		this.glCodeId = json.glCodeId;
		this.glCode = json.glCode;

		return this;
	}
}
