import { Serializable } from 'domain/models';
import { Guid } from 'domain/types';
import * as Moment from 'moment-timezone';

export class Note implements Serializable<Note> {
	id: Guid;
	note: string;
	dateCreated:  Moment.Moment;
	dateModified: Date;
	createdBy: number;
	userName: string;
	isNoteEdit: boolean;

	deserialize(json: any): Note {

		this.id = json.id;
		this.note = json.note;
		this.dateCreated = Moment(json.dateCreated);
		this.dateModified = json.dateModified;
		this.createdBy = json.createdBy;
		this.userName = json.userName;

		return this;
	}

	clone(): Note {
		return Object.assign(new Note(), this);
	}

	restore(src: Note): void {
		Object.assign(this, src);
	}

}
