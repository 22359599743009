import { Serializable } from 'domain/models';

export class DashboardRevenueItem implements Serializable<DashboardRevenueItem> {

	month: Date;
	year: Date;
	periodLabel: string;
	invoiceAmount: number;
	periodStart: Date;
	periodEnd: Date;

	deserialize(json: any): DashboardRevenueItem {

		this.month = json.month;
		this.year = json.year;
		this.periodLabel = json.periodLabel;
		this.invoiceAmount = Math.round(json.invoiceAmount);
		this.periodStart = json.periodStart;
		this.periodEnd = json.periodEnd;

		return this;
	}
}

export class DashboardYearToDateItem implements Serializable<DashboardYearToDateItem> {

	invoiceAmount: number;
	goal: number;

	deserialize(json: any): DashboardYearToDateItem {

		this.goal = json.goal;
		this.invoiceAmount = Math.round(json.invoiceAmount);

		return this;
	}
}

export class DashboardErrorCounter implements Serializable<DashboardErrorCounter> {

	recordsProcessing?: number;
	cannotMatchServiceItem: number;
	periodLocked: number;
	noRate: number;
	noRateCard: number;

	deserialize(json: any): DashboardErrorCounter {

		this.recordsProcessing = json.recordsProcessing;
		this.cannotMatchServiceItem = json.cannotMatchServiceItem;
		this.periodLocked = json.periodLocked;
		this.noRate = json.noRate;
		this.noRateCard = json.noRateCard;

		return this;
	}
}

export class DashboardInvoiceTotal implements Serializable<DashboardInvoiceTotal> {
	totalAmount: number;
	totalTaxAmount: number;

	deserialize(json: any): DashboardInvoiceTotal {
		this.totalAmount = json.InvoiceAmount;
		this.totalTaxAmount = json.TaxAmount;
		return this;
	}
}

export class DashboardInvoiceStatus implements Serializable<DashboardInvoiceStatus> {

	invoiceStatusId?: number;
	statusName: string;
	numInvoices: number;

	deserialize(json: any): DashboardInvoiceStatus {

		this.invoiceStatusId = json.invoiceStatusId;
		this.statusName = json.statusName;
		this.numInvoices = json.numInvoices;

		return this;
	}
}

export class DashboardUnInvoicedStatus implements Serializable<DashboardUnInvoicedStatus> {
	numUnInvoiced: number;
	numIgnored: number;

	deserialize(json: any): DashboardUnInvoicedStatus {

		this.numIgnored = json.numIgnored;
		this.numUnInvoiced = json.numUnInvoiced;

		return this;
	}
}

export class DashboardInvoiceDate implements Serializable<DashboardInvoiceDate> {

	invoiceDueDate?: Date;
	numInvoices: number;
	subTotal: number;

	deserialize(json: any): DashboardInvoiceDate {

		this.invoiceDueDate = json.invoiceDueDate ? new Date(json.invoiceDueDate) : null;
		this.numInvoices = json.numInvoices;
		this.subTotal = Math.round(json.subTotal * 100) / 100;

		return this;
	}
}

export class DashboardUnknownCustomer implements Serializable<DashboardUnknownCustomer> {

	serviceName: string;
	records: number;

	deserialize(json: any): DashboardUnknownCustomer {

		this.serviceName = json.serviceName;
		this.records = json.records;

		return this;
	}
}

export interface DashboardWidgetPosition {
	id: number;
	x: number;
	y: number;
}

export class UnprocessedUsagesCachedReport implements Serializable<UnprocessedUsagesCachedReport> {
	id: number;
	resellerId: number;
	unprocessedUsagesCount: number;
	resetSign: boolean;
	inProgress: boolean;
	dateUpdated: string;

	deserialize(json: any): UnprocessedUsagesCachedReport {

		this.id = json.Id;
		this.resellerId = json.ResellerId;
		this.unprocessedUsagesCount = json.UnprocessedUsagesCount;
		this.resetSign = json.ResetSign;
		this.inProgress = json.InProgress;
		this.dateUpdated = json.DateUpdated;

		return this;
	}
}

export class UnprocessedEBillsCachedReport implements Serializable<UnprocessedEBillsCachedReport> {
	id: number;
	resellerId: number;
	unprocessedEBillsCount: number;
	resetSign: boolean;
	inProgress: boolean;
	dateUpdated: string;

	deserialize(json: any): UnprocessedEBillsCachedReport {

		this.id = json.Id;
		this.resellerId = json.ResellerId;
		this.unprocessedEBillsCount = json.UnprocessedEBillsCount;
		this.resetSign = json.ResetSign;
		this.inProgress = json.InProgress;
		this.dateUpdated = json.DateUpdated;

		return this;
	}
}
