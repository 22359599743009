import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class CacheService {

	private cache: { [key: string]: any; } = {};

	constructor() {

	}

	public get<T>(key: string): T {
		return this.cache[key];
	}

	public get$<T>(key: string): Observable<T> {
		return this.get<Observable<T>>(key + '$');
	}

	public set<T>(key: string, value: T): void {
		this.cache[key] = value;
	}

	public set$<T>(key: string, value: T): void {
		this.set(key + '$', value);
	}

	public removeKey(key: string): void {
		this.cache[key] = null;
	}

	public reset(): void {
		this.cache = {};
	}
}

export class CacheModel {
	public Object: any;
	public Tag: string;

	constructor(object: any, tag: string) {
		this.Object = object;
		this.Tag = tag;
	}
}
