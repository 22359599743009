import { Pipe, PipeTransform } from '@angular/core';

const ordinalNumbers: string[] = ['th', 'st', 'nd', 'rd'];
@Pipe({ name: 'ordinalNumber' })
export class OrdinalNumberPipe implements PipeTransform {
	transform(value: number): string {
		const v = Number(value) % 100;
		return value + (ordinalNumbers[(v - 20) % 10] || ordinalNumbers[v] || ordinalNumbers[0]);
	}
}
