import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
	selector: 'dg-child-cell-view',
	template: `
	<a class="ag-cell__double" (click)="onCellClick(params.data)">
        <span class="ag-cell__double-item">
          {{params.data.serviceItemsLabel}}
        </span>
		<span class="ag-cell__double-item_">
			{{ params.data.serviceItemsDescription}}
        </span>
	</a>`
})
export class ChildCellServiceItemDescriptionComponent implements ICellRendererAngularComp {
	public params: ICellRendererParams;

	agInit(params: ICellRendererParams): void {
		this.params = params;
	}

	public onCellClick(value: any): void {
		if (value.productKitId) {
			this.params.context.componentParent.methodFromParent(value.id, true);
		} else {
			this.params.context.componentParent.methodFromParent(value.id);
		}
	}

	refresh(): boolean {
		return false;
	}
}
