import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { Observable, of, throwError, ReplaySubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AppConfig } from 'core/app-config';
import { ApiData } from 'domain/types';
import { ProvidersResponse, ResellerUserTokenCheckResult, UserTokenUI } from 'domain/models';
import { AuthDetails } from './auth-details';
import { AuthHelper } from './auth-helper';

interface RequestTokenOptions {
	headers: HttpHeaders;
	params: HttpParams;
}

const DG_AUTH_REFRESH_COUNTER = 'dg_auth_refresh_counter';

@Injectable()
export class AuthService {
	readonly cliendId: string = '1';
	readonly cliendSecret: string = 'notasecret';
	private isRefreshLocked = false;
	private refreshResult: Observable<boolean>;
	private refreshResultId: string;
	constructor(
		private readonly http: HttpClient,
		private readonly config: AppConfig,
		private readonly router: Router
	) {
	}

	public navigateThroughSSO(reseller: string, provider?: string): void {
		if (provider) {
			window.location.href = `${this.config.apiUrl}/sso/login?b=${encodeURIComponent(reseller)}&ct=${this.cliendId}&p=${provider}`;
		} else {
			window.location.href = `${this.config.apiUrl}/sso/login?b=${encodeURIComponent(reseller)}&ct=${this.cliendId}`;
		}
	}

	public login(username: string, password: string, otp?: string): Observable<boolean> {

		const body = new HttpParams()
			.set('grant_type', 'password')
			.set('client_id', this.cliendId)
			.set('client_secret', this.cliendSecret)
			.set('username', username)
			.set('password', password)
			.set('otp', otp);
		const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
		const options = {
			headers: headers,
			params: body
		};

		return this.requestToken(options);
	}

	public loginWith2fa(username: string, password: string): Observable<boolean> {
		const body = new HttpParams()
			.set('grant_type', 'password')
			.set('client_id', this.cliendId)
			.set('client_secret', this.cliendSecret)
			.set('username', username)
			.set('password', password);
		const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
		const options = {
			headers: headers,
			params: body,
			responseType: 'Blob'
		};

		return this.requestToken(options);
	}

	public loginByCode(code: string): Observable<boolean> {

		const body = new HttpParams()
			.set('grant_type', 'authorization_code')
			.set('client_id', this.cliendId)
			.set('client_secret', this.cliendSecret)
			.set('redirect_uri', 'https://www.datagate-i.com')
			.set('code', code);
		const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
		const options = {
			headers: headers,
			params: body
		};

		return this.requestToken(options);
	}

	public getRegistrationData(token: string): Observable<ResellerUserTokenCheckResult> {
		return this.http.get<ApiData<ResellerUserTokenCheckResult>>(`${this.config.apiUrl}${this.config.apiVersion}/reseller-users/check/${token}`)
			.pipe(
				map(res => res.data),
				catchError((err) => {
					void this.router.navigate(['/login']);
					return this.handleError(err);
				}));
	}

	public getSsoProviders(company: string): Observable<ProvidersResponse> {
		return this.http.get<ApiData<ProvidersResponse>>(`${this.config.apiUrl}/sso/providers?ct=1&b=${encodeURIComponent(company)}`)
			.pipe(
				map(res => res.data),
				catchError(err => this.handleError(err)));
	}

	public ssoResellerCheck(reseller: string, clientType: number): Observable<void> {
		return this.http.get<void>(`${this.config.apiUrl}/sso/test?ct=${clientType.toString()}&b=${encodeURIComponent(reseller)}`);
	}

	public confirmAuthCode(username: string, password: string, otp: string, sessionId: string): Observable<void> {
		const body = new HttpParams()
			.set('sessionId', sessionId)
			.set('otp', otp)
			.set('username', username)
			.set('password', password);
		const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
		const options = {
			headers: headers,
			params: body
		};

		return this.http.post<void>(`${this.config.apiUrl}${this.config.apiVersion}/2fa`, undefined, options);
	}

	public ssoLogin(reseller: string, clientType: string): void {
		void this.router.navigateByUrl(`/login?b=${encodeURIComponent(reseller)}&ct=${clientType}`);
	}

	public validationRegistration(token: string, user: UserTokenUI): Observable<void> {
		return this.http.post<void>(`${this.config.apiUrl}${this.config.apiVersion}/reseller-users/complete/${token}`, user);
	}

	public refresh(timestamp?: string): Observable<boolean> {
		// allow only one refresh at a time
		if (!this.isRefreshLocked) {
			this.isRefreshLocked = true;
			const subj = new ReplaySubject<boolean>(1);
			this.refreshResultId = new Date().getUTCMinutes().toString() + '.' + new Date().getUTCSeconds().toString() + '.' + new Date().getUTCMilliseconds().toString();
			this.refreshResult = subj.asObservable();

			const authDetails = AuthHelper.getAuthDetails();

			if (!authDetails) {
				this.isRefreshLocked = false;
				return throwError(() => new Error('Can not refresh. Auth data doesn\'t exist.'));
			}

			const decodedToken = AuthHelper.getJwtTokenData(authDetails.token);

			const body = new HttpParams()
				.set('grant_type', 'refresh_token')
				.set('client_id', this.cliendId)
				.set('client_secret', this.cliendSecret)
				.set('refresh_token', authDetails.refreshToken)
				.set('brand_id', decodedToken.BrandId);

			const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
			const options = <RequestTokenOptions>{ headers: headers, params: body };

			this.requestToken(options).pipe(
				catchError(() => {
					return of(false);
				}))
				.subscribe(result => {
					subj.next(result);
					subj.complete();
					this.isRefreshLocked = false;
				});

			return this.refreshResult;
		} else {
			return this.refreshResult;
		}
	}

	public signout(): void {
		this.clearAuthAndRedirectToLogin();
	}

	private requestToken(options: RequestTokenOptions): Observable<boolean> {
		console.warn('current auth: ', AuthHelper.getAuthDetails());
		console.warn('jwt token: ', AuthHelper.getJwtTokenData());
		return this.http.post<any>(`${this.config.apiUrl}/token`, undefined, options)
			.pipe(
				map(data => {
					console.warn('token body: ', options);
					console.warn('token response: ', data);

					if (!data) {
						console.error('token response is null');
						return false;
					}

					if (data.error === 'access_denied') {
						console.warn('access_denied: ', data.error_description);
						return false;
					}

					let refreshAttempts = Number(localStorage.getItem(DG_AUTH_REFRESH_COUNTER) ?? 0);
					if (data.error === 'invalid_grant') {
						if (refreshAttempts < 10) {
							refreshAttempts++;
							localStorage.setItem(DG_AUTH_REFRESH_COUNTER, JSON.stringify(refreshAttempts));
							this.refresh();
							return true;
						}
						this.clearAuthAndRedirectToLogin();
						return false;
					}
					refreshAttempts = 0;
					localStorage.setItem(DG_AUTH_REFRESH_COUNTER, JSON.stringify(refreshAttempts));

					if (!data.access_token || !data.refresh_token || !!data.error) {
						console.error(`unexpected token error ${data.error}: `, data.error_description);
						return false;
					}

					const token = data.access_token;
					const secondsToExpire = data.expires_in;
					const refreshToken = data.refresh_token;
					const expirationDate: Date = new Date();
					expirationDate.setTime(expirationDate.getTime() + secondsToExpire * 1000);

					const auth = new AuthDetails(token, expirationDate, refreshToken);

					AuthHelper.saveAuthDetails(auth);

					return true;
				}),
				catchError(err => this.handleError(err)));
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private handleError(error: HttpResponse<any> | any): Observable<never> {
		if (error.status === 400) {
			const brandName: string = error.headers.get('x-brand-name');
			if (brandName) {
				this.ssoLogin(brandName, this.cliendId);
			}
		}

		return throwError(() => error);
	}

	public clearAuthAndRedirectToLogin(): void {
		AuthHelper.clearAuth();
		void this.router.navigate(['/login'], { state: { bypassGuard: true } });
	}
}

