import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
	selector: 'child-cell-icon',
	template: `
		<a *ngIf="params.data.typeId === 1" (click)="invokeParentMethod(params.data)">
			<img class="rating__icon rating__icon--rc"
			 src="/assets/img/Credit-card_icon.png" alt="Rate Card" title="Rate Card">
		</a>
		<a *ngIf="params.data.typeId === 2" (click)="invokeParentMethod(params.data)">
		    <img class="rating__icon rating__icon--pb"
			 src="/assets/img/Pricebook_icon.png" alt="Price Book" title="Price Book">
		</a>
		<div class="grid__status">
			<div *ngIf="params.data.invoiceNumber" class="grid__status-icon grid__status-icon--link tooltip tooltip--invoice-link"
	        title=""><i class="fa fa-link" aria-hidden="true"></i>
	            <span>Linked with Invoice {{params.data.invoiceNumber}}</span>
	        </div>
	        <div *ngIf="params.data.isHidden" class="grid__status-icon grid__status-icon--hidden tooltip tooltip--status-hidden"
	        title=""><i class="fa fa-eye-slash" aria-hidden="true"></i>
	          <span>Hidden</span>
	        </div>
		</div>
       `
})
export class ChildLinkIconRenderer implements ICellRendererAngularComp {
	public params: any;
	
	agInit(params: any): void {
		this.params = params;
	}
	
	public invokeParentMethod(data: any) {
		this.params.context.componentParent.methodFromParent(data.id, data.typeId);
	}
	
	refresh(): boolean {
		return false;
	}
}
