import { Serializable } from 'domain/models';
import { EntityName } from 'tools/decorators';
import { Utils } from 'tools/utils';
import { Constants } from 'tools/constants';
import { Guid } from 'domain/types';

export class Rating implements Serializable<Rating> {
	id: Guid;
	name: string;
	description: string;
	invoiceDescription: string;
	sell?: number;
	chargeWhenNoUsage?: boolean;
	taxRateId?: number;

	serviceId: number;
	serviceName: string;

	typeId: number;
	typeName: string;
	isDefault: boolean;

	deserialize(json: any): Rating {

		this.id = json.id;
		this.name = json.name;
		this.description = json.description;
		this.invoiceDescription = json.invoiceDescription;

		this.sell = json.sell;
		this.chargeWhenNoUsage = json.chargeWhenNoUsage;
		this.taxRateId = json.taxRateId;

		this.serviceId = json.serviceId;
		this.serviceName = json.serviceName;

		this.typeId = json.typeId;
		this.typeName = json.typeName;
		this.isDefault = json.isDefault;
		return this;
	}
}

export class TaxRateType {
	id: Guid;
	name: string;

	constructor(id: Guid, name: string) {
		this.id = id;
		this.name = name;
	}

	static list(): TaxRateType[] {

		const types = [];
		types.push(new TaxRateType(Guid.generate(), '0.15'));
		types.push(new TaxRateType(Guid.generate(), 'Tax Exempt'));

		return types;
	}

	deserialize(json: any): TaxRateType {
		this.id = json.id;
		this.name = json.name;

		return this;
	}
}

export class RateCard implements Serializable<RateCard> {
	id: Guid;
	name: string;
	description: string;
	invoiceDescription: string;

	taxRateId?: number;

	serviceId: number;
	serviceName: string;
	totalRates: number;
	isDefault: boolean;
	deserialize(json: any): RateCard {

		this.id = json.id;
		this.name = json.name;
		this.description = json.description;
		this.invoiceDescription = json.invoiceDescription;

		this.taxRateId = json.taxRateId;
		this.serviceId = json.serviceId;
		this.totalRates = json.totalRates;
		this.isDefault = json.isDefault;

		return this;
	}

	clone(): RateCard {
		const clone = Object.assign(new RateCard(), this);
		return clone;
	}
}

export class Bundle implements Serializable<Bundle> {
	id: Guid;
	name: string;
	isRollover: boolean;

	deserialize(json: any): Bundle {

		this.id = json.id;
		this.name = json.name;
		this.isRollover = json.isRollover;

		return this;
	}

	clone(): Bundle {
		return Object.assign(new Bundle(), this);
	}
}

export interface BundleQuantityService {
	serviceId: number;
	serviceName: string;
	recordTypeIds: number[];
}

export class BundleQuantity implements Serializable<BundleQuantity> {
	id: Guid;
	bundleId: number;
	isRollover: boolean;
	name: string;
	serviceId: number;
	serviceLabel: string;
	bundlingTypeId: number;
	bundlingTypeName: string;
	quantity: number;
	displayUnitId: number;
	selectedRecordLabels: string;
	bundleAllRecordTypes: boolean;
	bundleQuantityServices?: BundleQuantityService[];

	deserialize(json: any): BundleQuantity {

		this.id = json.id;
		this.bundleId = json.bundleId;
		this.isRollover = json.isRollover;
		this.serviceId = json.serviceId;
		this.name = json.name;
		this.bundlingTypeId = json.bundlingTypeId;
		this.quantity = json.quantity;
		this.displayUnitId = json.displayUnitId;
		this.bundlingTypeName = Constants.BundlingTypeName[json.bundlingTypeId];
		this.serviceLabel = json.bundleQuantityServices?.map(si => si.serviceName).join('; ') ?? '';
		this.bundleAllRecordTypes = json.bundleAllRecordTypes;
		this.bundleQuantityServices = json.bundleQuantityServices;

		return this;
	}

	clone(): BundleQuantity {
		return Object.assign(new BundleQuantity(), this);
	}
}

export class PriceBook implements Serializable<PriceBook> {
	id: Guid;
	name: string;
	description: string;
	invoiceDescription: string;
	taxRateId: number;
	isDefault: boolean;
	products: PriceBookProduct[] = [];

	deserialize(json: any): PriceBook {
		this.id = json.id;
		this.name = json.name;
		this.description = json.description;
		this.invoiceDescription = json.invoiceDescription;
		this.taxRateId = json.taxRateId;
		this.isDefault = json.isDefault;
		if (json.products !== null) {
			this.products = json.products.map(x => new PriceBookProduct().deserialize(x));
		}
		return this;
	}

	clone(): PriceBook {
		const clone = Object.assign(new PriceBook(), this);
		return clone;
	}
}

@EntityName('Product', 'Products')
export class PriceBookProduct implements Serializable<PriceBookProduct> {
	id: Guid;
	name: string;
	label: string;
	description: string;

	costAmount: number;
	ratingTypeId: string;
	ratingTypeName: string;
	ratingAmount: number;
	transactionCodeId: Guid;
	transactionCode: string;
	taxRateId: Guid;
	glCodeId: Guid;
	glCodeName: string;
	productCategoryName: string;
	proRata: boolean;
	hidden: boolean;
	doNotExportToCWAgreements: boolean;
	templateProductId: Guid;
	productCategoryId: Guid;
	telecomSpecialTermId: number;
	public xeroTrackingCategoryId?: string;
	public xeroTrackingOptionId?: string;
	bundleId: Guid;

	deserialize(json: any): PriceBookProduct {

		this.id = json.id;
		this.name = json.name;
		this.label = json.label;
		this.description = json.description;
		this.transactionCodeId = json.transactionCodeId;
		this.transactionCode = json.transactionCode;
		this.costAmount = json.costAmount;
		this.ratingTypeId = json.ratingTypeId;
		this.ratingTypeName = json.ratingTypeName;
		this.ratingAmount = json.ratingAmount ? json.ratingAmount : 0;
		this.telecomSpecialTermId = json.telecomSpecialTermId;
		this.taxRateId = json.taxRateId;
		this.proRata = json.proRata;
		this.glCodeId = json.glCodeId;
		this.hidden = json.hidden;
		this.doNotExportToCWAgreements = json.doNotExportToCWAgreements;
		this.templateProductId = json.templateProductId;
		this.productCategoryId = json.productCategoryId;
		this.xeroTrackingCategoryId = json.xeroTrackingCategoryId;
		this.xeroTrackingOptionId = json.xeroTrackingOptionId;
		this.bundleId = json.bundleId;

		return this;
	}

	clone(): PriceBookProduct {
		const clone = Object.assign(new PriceBookProduct(), this);
		return clone;
	}
}

@EntityName('Assignment', 'Assignments')
export class RatingAssignment implements Serializable<RatingAssignment> {
	id: Guid;
	startDate: string;
	startDateLabel: string;
	customerId: Guid;
	customerName: string;
	productId: Guid;
	productName: string;
	rateCardId: Guid;
	rateCardName: string;
	rateCardDescription: string;
	serviceId: number;
	serviceName: string;
	serviceItemId: Guid;
	serviceItemName: string;
	rateCardTypeId: number;
	rateCardTypeDesc: string;
	siteId?: Guid;
	siteLabel: string;
	isDefault: boolean;
	deserialize(json: any): RatingAssignment {

		this.id = json.id;
		this.startDate = json.startDate;

		this.startDateLabel = Utils.toMomentUtcFormat(json.startDate);
		this.customerId = json.customerId;
		this.customerName = json.customerName;
		this.productId = json.productId;
		this.productName = json.productName;

		this.isDefault = json.isDefault;
		this.rateCardId = json.rateCardId;
		this.rateCardName = json.rateCardName;
		this.rateCardDescription = json.rateCardDescription;

		this.serviceId = json.serviceId;
		this.serviceName = json.serviceName;

		this.serviceItemId = json.serviceItemId;
		this.serviceItemName = json.serviceItemName;

		this.rateCardTypeId = json.rateCardTypeId;
		this.rateCardTypeDesc = json.rateCardTypeDesc;
		this.siteId = json.siteId;

		return this;
	}
}
