import { Component } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { XeroTenants } from 'domain/entities';
import { ToasterService } from 'core/toaster-service';
import { XeroAuthService } from 'services';

@Component({
	selector: 'dg-xero-tenants',
	templateUrl: './xero-tenants.component.html',
	styleUrls: ['./xero-tenants.component.sass']
})
export class XeroTenantsComponent {
	xeroTenants: XeroTenants[] = [];
	tenantId: number;
	uniqueId: string;
	isError = false;

	constructor(
		private readonly xeroService: XeroAuthService,
		private readonly route: ActivatedRoute,
		protected toasterService: ToasterService,
		private readonly router: Router) {

		this.route.params.subscribe((params: Params) => {
			this.uniqueId = params.uniqueId;
			this.xeroService
				.getXeroTenants(this.uniqueId)
				.subscribe({
					next: (tenants) => this.xeroTenants = tenants,
					error: (error) => {
						this.isError = true;
						throw error;
					}
				});
		});

	}

	public onTenantSelect() {
		this.xeroService
			.postSelectedTenant(this.uniqueId, this.tenantId)
			.subscribe({
				next: () => window.close(),
				error: (error) => {
					this.isError = true;
					throw error;
				}
			});
	}

}
