import { CustomerRepository } from 'repositories/customer-repository';
import {
	GroupAction,
	EmailAction,
	ReEmailAction,
	ZipAction,
	PendAction,
	UnPendAction,
	RegenerateAction,
	MarkSentAction,
	DeleteAction,
	ExportAction,
	CreateCreditRecordAction,
	ChargeAction,
	PrintAction,
	MarkPaidAction,
	GenerateMiscellaneousAction,
	ArchiveAction,
	RestoreFromArchiveAction,
	GroupActionType
} from './actions';

import { Invoice } from 'domain/entities';
import { InvoiceActionsService } from './invoice-actions.service';
import { NavigatorService } from 'services';
import { TranslocoService } from '@ngneat/transloco';
import { ToasterService } from 'core/toaster-service';

export class GroupActionCollection {
	actions: GroupAction[];
	private allActions: GroupAction[];

	public creditAction: CreateCreditRecordAction;
	public emailAction: EmailAction;
	public reEmailAction: ReEmailAction;
	public zipAction: ZipAction;
	public pendAction: PendAction;
	public unPendAction: UnPendAction;
	public exportAction: ExportAction;
	public regenerateAction: RegenerateAction;
	public markSentAction: MarkSentAction;
	public deleteAction: DeleteAction;
	public chargeAction: ChargeAction;
	public printAction: PrintAction;
	public markPaidAction: MarkPaidAction;
	public generateMiscellaneousAction: GenerateMiscellaneousAction;
	public archiveAction: ArchiveAction;
	public restoreFromArchiveAction: RestoreFromArchiveAction;

	constructor(
		private readonly invoiceService: InvoiceActionsService,
		private readonly customerRepository: CustomerRepository,
		private readonly navigatorService: NavigatorService,
		private readonly toasterService: ToasterService,
		private readonly transloco: TranslocoService,
	) {

		this.exportAction = new ExportAction(toasterService, invoiceService, navigatorService, transloco);
		this.creditAction = new CreateCreditRecordAction(toasterService, invoiceService, navigatorService, transloco);
		this.emailAction = new EmailAction(toasterService, invoiceService, navigatorService, transloco);
		this.reEmailAction = new ReEmailAction(toasterService, invoiceService, navigatorService, transloco);
		this.zipAction = new ZipAction(toasterService, invoiceService, navigatorService, transloco);
		this.pendAction = new PendAction(toasterService, invoiceService, navigatorService, transloco);
		this.unPendAction = new UnPendAction(toasterService, invoiceService, navigatorService, transloco);
		this.markSentAction = new MarkSentAction(toasterService, invoiceService, navigatorService, transloco);
		this.deleteAction = new DeleteAction(toasterService, invoiceService, navigatorService, transloco);
		this.chargeAction = new ChargeAction(toasterService, invoiceService, navigatorService, transloco);
		this.printAction = new PrintAction(toasterService, invoiceService, navigatorService, transloco);
		this.generateMiscellaneousAction = new GenerateMiscellaneousAction(toasterService, invoiceService, navigatorService, transloco);
		// this action can be disabled
		this.regenerateAction = new RegenerateAction(toasterService, invoiceService, navigatorService, transloco);
		this.markPaidAction = new MarkPaidAction(toasterService, invoiceService, navigatorService, transloco);
		this.archiveAction = new ArchiveAction(toasterService, invoiceService, navigatorService, transloco);
		this.restoreFromArchiveAction = new RestoreFromArchiveAction(toasterService, invoiceService, navigatorService, transloco);

		this.allActions = [];

		this.allActions.push(this.creditAction);
		this.allActions.push(this.emailAction);
		this.allActions.push(this.reEmailAction);
		this.allActions.push(this.zipAction);
		this.allActions.push(this.chargeAction);
		this.allActions.push(this.pendAction);
		this.allActions.push(this.unPendAction);
		this.allActions.push(this.regenerateAction);
		this.allActions.push(this.markSentAction);
		this.allActions.push(this.markPaidAction);
		this.allActions.push(this.generateMiscellaneousAction);
		this.allActions.push(this.deleteAction);
		this.allActions.push(this.exportAction);
		this.allActions.push(this.printAction);
		this.allActions.push(this.archiveAction);
		this.allActions.push(this.restoreFromArchiveAction);

		this.actions = [...this.allActions];
	}

	async refreshAvailableActions(selectedInvoices: Invoice[]): Promise<void> {
		const allCustomers = await this.customerRepository.getAll();

		for (const invoice of selectedInvoices) {
			if (allCustomers) {
				invoice.customer = allCustomers.find(x => x.id.equals(invoice.customerId));
			}
		}
		const availableActions = this.actions.filter(action => selectedInvoices.every(invoice => action.isAvailable(invoice, invoice.customer)));

		this.reset();

		for (const action of availableActions) {
			action.items = selectedInvoices.filter(x => action.isAvailable(x, x.customer));
		}
	}

	displayAllActions(selectedInvoices: Invoice[], excludedInvoices: Invoice[]): void {
		let availableActions = [...this.allActions];
		const isArchivedFilter = JSON.parse(localStorage.getItem('isArchivedFilter-invoices')) ?? true;
		if (!isArchivedFilter) {
			availableActions = availableActions.filter(action => action.type !== GroupActionType.Archive);
		} else {
			availableActions = availableActions.filter(action => action.type !== GroupActionType.RestoreFromArchive);
		}

		for (const action of availableActions) {
			action.items = selectedInvoices;
			action.excluded = excludedInvoices;
		}
		this.actions = availableActions;
	}

	reset(): void {
		for (const action of this.actions) {
			action.items = [];
		}
	}
}
