import { Serializable } from 'domain/models';
import { Guid } from 'domain/types';

export class Surcharge implements Serializable<Surcharge> {
	id: Guid;
	name: string;
	surchargeTypeId: number;
	templateId: number;
	value: number;
	proRata: boolean;
	paymentMethodId: string;
	surchargeTypeName: string;
	transactionCodeId: number;
	deserialize(json: any): Surcharge {

		this.id = json.surchargeSettingId;
		this.name = json.name;
		this.surchargeTypeId = json.surchargeTypeId;
		this.paymentMethodId = json.paymentMethodId;

		if (this.surchargeTypeId === 1) {
			this.surchargeTypeName = 'Amount';
		} else if (this.surchargeTypeId === 2) {
			this.surchargeTypeName = 'Percentage';
		} else if (this.surchargeTypeId === 3) {
			this.surchargeTypeName = 'Percentage of Taxable Item';
		} else if (this.surchargeTypeId === 4) {
			this.surchargeTypeName = 'Opening Balance Fee';
		} else if (this.surchargeTypeId === 5) {
			this.surchargeTypeName = 'Entire Invoice Percentage';
		}

		this.templateId = json.TemplateId;
		this.value = json.value;
		this.proRata = json.proRata;
		this.transactionCodeId = json.transactionCodeId;
		return this;
	}

	clone(): Surcharge {
		return Object.assign(new Surcharge(), this);
	}
}

export class SurchargeTypesOption {

	constructor(public id: number, public name: string, public desc: string) { }

	public static get allOptions(): SurchargeTypesOption[] {

		const result = [];
		result.push(new SurchargeTypesOption(1, 'Amount',
			'Value of the surcharge times the quantity of the product transaction'));
		result.push(
			new SurchargeTypesOption(2, 'Percentage', 'Percentage of invoice item; applied to all invoice items'));
		result.push(new SurchargeTypesOption(3, 'Percentage of Taxable Item',
			'Percentage of invoice item; applied only for invoice items with tax code'));
		result.push(new SurchargeTypesOption(4, 'Opening Balance Fee',
			'Percentage of opening balance'));
		result.push(new SurchargeTypesOption(5, 'Entire Invoice Percentage',
			'Applied to the entire invoice)'));

		return result;
	}

}
