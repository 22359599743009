<form class="form form--export">
	<ul class="steps__wrapper">
		<li class="steps {{CheckStep(1)}}">
			<span class="steps__label">{{'global-export.step1_label' | transloco}}</span>
			<span class="steps__label">{{'halo-export.step1_title' | transloco}}</span>
		</li>
		<li class="steps {{CheckStep(2)}}">
			<span class="steps__label">{{'global-export.step2_label' | transloco}}</span>
			<span class="steps__label">{{'halo-export.step2_title' | transloco}}</span>
		</li>
		<li class="steps {{CheckStep(3)}}">
			<span class="steps__label">{{'global-export.step3_label' | transloco}}</span>
			<span class="steps__label">{{'halo-export.step3_title' | transloco}}</span>
		<li class="steps {{CheckStep(4)}}">
			<span class="steps__label">{{'global-export.step4_label' | transloco}}</span>
			<span class="steps__label">{{'halo-export.step4_title' | transloco}}</span>
		</li>
		<li class="steps {{CheckStep(5)}}">
			<span class="steps__label">{{'global-export.step5_label' | transloco}}</span>
			<span class="steps__label">{{'halo-export.step5_title' | transloco}}</span>
		</li>
		<li class="steps {{CheckStep(6)}}">
			<span class="steps__label">{{'global-export.step6_label' | transloco}}</span>
			<span class="steps__label">{{'halo-export.step6_title' | transloco}}</span>
		</li>
		<li class="steps {{CheckStep(7)}}">
			<span class="steps__label">{{'global-export.step7_label' | transloco}}</span>
			<span class="steps__label">{{'halo-export.step7_title' | transloco}}</span>
		</li>
		<li class="steps {{CheckStep(8)}}">
			<span class="steps__label">{{'global-export.step8_label' | transloco}}</span>
			<span class="steps__label">{{'halo-export.step8_title' | transloco}}</span>
		</li>
	</ul>
	<div class="form__content">

		<fieldset class="form__section {{CheckStep(1)}}">
			<h3 class="form__section-title">
				{{'halo-export.step1_title' | transloco}}
			</h3>
			<form #ContractsForm="ngForm">
				<div class="form__group block">
					<div [ngClass]="useXeroBrandingTheme?'form__group-item form__group-item--800-fs':'form__group-item form__group-item--500-fs'">
						<p>{{'halo-export.step1_header-invoice' | transloco}}</p>
						<p>{{'halo-export.step1_header-company' | transloco}}</p>
						<p>{{'halo-export.step1_header-contract' | transloco}}</p>
						<p *ngIf="useXeroBrandingTheme">{{'halo-export.step1_header-xeroBrandingTheme' | transloco}}</p>
					</div>
					<div *ngFor="let item of contractMappingResponse.invoices; let i = index"
						 [ngClass]="useXeroBrandingTheme?'form__group-item form__group-item--800-fs':'form__group-item form__group-item--500-fs'">
						<p>{{item.invoiceNumber}}</p>
						<p>{{item.companyName}}</p>

						<ng-select *ngIf="item.contracts" required bindLabel="name"
							bindValue="id" name="selectedHaloContractId{{i}}"
							[items]="item.contracts" [searchable]="true" [clearable]="false" [(ngModel)]="item.selectedHaloContractId"
							(change)="onChangeContract(item)">
						</ng-select>
						<ng-select *ngIf="useXeroBrandingTheme" bindLabel="label"
								   bindValue="value" name="selectedXeroBrandingThemeId{{i}}"
								   [compareWith]="xeroBrandingThemesCompareFn"
								   [items]="item.xeroBrandingThemes" [searchable]="true" [clearable]="false" [(ngModel)]="item.selectedXeroBrandingThemeId">
						</ng-select>
						<p *ngIf="!item.contracts">
							{{'halo-export.step1_contracts-not-found' | transloco}}
						</p>
					</div>
				</div>
				<div class="form__bottom form__bottom--border-none padding-b__150">
					<button type="button" class="btn btn--primary btn--import" (click)="backTo()">
						{{'global.export_btn-back' | transloco}}
					</button>
					<button type="button" class="btn btn--primary btn--import"
							[disabled]="!ContractsForm.valid || !contractMappingResponse.invoices?.length"
							(click)="NextStep()">
						{{'global.export_btn-next' | transloco}}
						<i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
					</button>
				</div>
			</form>

		</fieldset>

		<fieldset class="form__section {{CheckStep(2)}}">
			<h3 class="form__section-title">
				{{'halo-export.step2_title' | transloco}}
			</h3>
			<div class="form__section-subtitle">
				{{'halo-export.step2_subtitle' | transloco}}
			</div>

			<div class="form__group">
				<div class="form__group-item ">
					<div class="radiobox">
						<input class="radiobox-input" type="radio" name="groupingType-radio" id="groupingType-1"
							[value]="1" [(ngModel)]='selectedGroupingTypeId' />
						<label class="radiobox-label" for="groupingType-1">
							{{'halo-export.field-selectedGroupingTypeId-opt-0' | transloco}}
						</label>
					</div>
					<div class="sidebar__radiobox radiobox">
						<input class="radiobox-input" type="radio" name="groupingType-radio" id="groupingType-2"
							[value]="2" [(ngModel)]='selectedGroupingTypeId' />
						<label class="radiobox-label" for="groupingType-2">
							{{'halo-export.field-selectedGroupingTypeId-opt-1' | transloco}}
						</label>
					</div>
					<div class="sidebar__radiobox radiobox">
						<input class="radiobox-input" type="radio" name="groupingType-radio" id="groupingType-3"
							[value]="3" [(ngModel)]='selectedGroupingTypeId' />
						<label class="radiobox-label" for="groupingType-3">
							{{'halo-export.field-selectedGroupingTypeId-opt-2' | transloco}}
						</label>
					</div>
				</div>
			</div>

			<div class="form__bottom form__bottom--border-none">
				<button type="button" class="btn btn--primary btn--import" [disabled]="loading" (click)="BackStep()">
					{{'global.export_btn-back' | transloco}}
				</button>
				<button type="button" class="btn btn--primary btn--import" [disabled]="loading" (click)="onGroupingTypeSelected()">
					{{'global.export_btn-next' | transloco}}
					<i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
				</button>
			</div>
		</fieldset>

		<fieldset class="form__section {{CheckStep(3)}}">
			<h3 class="form__section-title">
				{{'halo-export.step3_title' | transloco}}
			</h3>
			<form #ProductsForm="ngForm">
				<div *ngIf="selectedGroupingTypeId === 1" class="form__group block">
					<div class="form__group-item form__group-item--500-fs">
						<p>{{'halo-export.step3_header-service' | transloco}}</p>
						<p>{{'halo-export.step3_header-record' | transloco}}</p>
						<p>{{'halo-export.step3_header-product' | transloco}}</p>
					</div>
					<div *ngFor="let items of invoiceLinesResponses | groupBy:'groupName'; let i = index"
						class="form__group-item form__group-item--500">
						<p>{{items.key}}</p>
						<div>
							<div *ngFor="let item of items.value | sort:['description', 'selectedHaloItemId']; let i1 = index" class="form__group-item-custom1">
								<p>{{item.description}}</p>
								<ng-select #inputProduct bindLabel="name" required
									bindValue="id" name="selectedHaloItemId{{i}}_{{i1}}" [items]="products"
									[searchable]="true" [clearable]="false" [(ngModel)]="item.selectedHaloItemId">
									<ng-template>
										<input class="custom-input" type="text"
											(input)="inputProduct.filter($event.target.value)" />
									</ng-template>
								</ng-select>
							</div>
						</div>
					</div>
				</div>

				<div *ngIf="selectedGroupingTypeId === 2" class="form__group block">
					<div class="form__group-item form__group-item--500-sb width-550px">
						<p>{{'halo-export.step3_header-cost-centre' | transloco}}</p>
						<p>{{'halo-export.step3_header-product' | transloco}}</p>
					</div>
					<div *ngFor="let item of invoiceLinesResponses; let i = index"
						class="form__group-item form__group-item--800">
						<p>{{item.groupName}}</p>
						<ng-select #inputProduct bindLabel="name" required bindValue="id"
							name="selectedHaloItemId{{i}}" [items]="products" [searchable]="true" [clearable]="false"
							[(ngModel)]="item.selectedHaloItemId">
							<ng-template>
								<input class="custom-input" type="text"
									(input)="inputProduct.filter($event.target.value)" />
							</ng-template>
						</ng-select>
					</div>
				</div>

				<div *ngIf="selectedGroupingTypeId === 3" class="form__group block">
					<div class="form__group-item form__group-item--500-sb width-550px">
						<p>{{'halo-export.step3_header-site' | transloco}}</p>
						<p>{{'halo-export.step3_header-product' | transloco}}</p>
					</div>
					<div *ngFor="let item of invoiceLinesResponses; let i = index"
						class="form__group-item form__group-item--800">
						<p>{{item.groupName}}</p>
						<ng-select #inputProduct bindLabel="name" required bindValue="id"
							name="selectedHaloItemId{{i}}" [items]="products" [searchable]="true" [clearable]="false"
							[(ngModel)]="item.selectedHaloItemId">
							<ng-template>
								<input class="custom-input" type="text"
									(input)="inputProduct.filter($event.target.value)" />
							</ng-template>
						</ng-select>
					</div>
				</div>

				<div class="form__bottom form__bottom--border-none mpadding-b__150">
					<button type="button" class="btn btn--primary btn--import" (click)="BackStep()">
						{{'global.export_btn-back' | transloco}}
					</button>
					<button type="button" class="btn btn--primary btn--import" [disabled]="!ProductsForm.valid" (click)="NextStep()">
						{{'global.export_btn-next' | transloco}}
						<i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
					</button>
				</div>
			</form>
		</fieldset>

		<fieldset class="form__section {{CheckStep(4)}}">
			<h3 class="form__section-title">
				{{'halo-export.step4_title' | transloco}}
			</h3>
			<form #TransactionsForm="ngForm">
				<div class="form__group block">
					<div class="form__group-item form__group-item--800 flex-start">
						<p class="width-54p">{{'halo-export.step4-field_uniteSameProductsInOne' | transloco}}</p>
						<dg-checkbox name="uniteSameProductsInOne" ngDefaultControl [id]="'uniteSameProductsInOne'"
							[(ngModel)]="uniteSameProductsInOne"></dg-checkbox>
					</div>

					<div *ngFor="let item of transactionResponses; let i = index"
						class="form__group-item form__group-item--800">
						<p>{{item.code}}-{{item.transactionName}}</p>
						<ng-select #inputProduct style="min-width: 40%" bindLabel="name"
							required bindValue="id" name="selectedHaloItemId{{i}}"
							[items]="products" [searchable]="true" [clearable]="false" [(ngModel)]="item.selectedHaloItemId">
							<ng-template>
								<input class="custom-input" type="text"
									(input)="inputProduct.filter($event.target.value)" />
							</ng-template>
						</ng-select>
					</div>
				</div>
				<div class="form__bottom form__bottom--border-none">
					<button type="button" class="btn btn--primary btn--import" (click)="BackStep()">
						{{'global.export_btn-back' | transloco}}
					</button>
					<button type="button" class="btn btn--primary btn--import" [disabled]="!TransactionsForm.valid"
						(click)="NextStep()">
						{{'global.export_btn-next' | transloco}}
						<i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
					</button>
				</div>
			</form>
		</fieldset>

		<fieldset class="form__section {{CheckStep(5)}}">
			<h3 class="form__section-title">
				{{'halo-export.step5_title' | transloco}}
			</h3>
			<form #SurchargeForm="ngForm">
				<div class="form__group block">
					<div class="form__group-item form__group-item--500 flex-start">
						<p>{{'halo-export.step5-field_combineSurchargesWithTaxes' | transloco}}</p>
						<dg-checkbox name="combineSurchargesWithTaxes" ngDefaultControl
							[id]="'combineSurchargesWithTaxes'" [(ngModel)]="combineSurchargesWithTaxes"></dg-checkbox>
					</div>
					<div *ngIf="!combineSurchargesWithTaxes">
						<div *ngFor="let item of surchargeResponses; let i = index"
							class="form__group-item form__group-item--500">
							<p>{{item.surchargeName}}</p>
							<ng-select #inputProduct bindLabel="name" required
								bindValue="id" name="selectedHaloItemId{{i}}" [items]="products"
								[searchable]="true" [clearable]="false" [(ngModel)]="item.selectedHaloItemId">
								<ng-template>
									<input class="custom-input" type="text"
										(input)="inputProduct.filter($event.target.value)" />
								</ng-template>
							</ng-select>
						</div>
					</div>
				</div>
				<div class="form__bottom form__bottom--border-none">
					<button type="button" class="btn btn--primary btn--import" (click)="BackStep()">
						{{'global.export_btn-back' | transloco}}
					</button>
					<button type="button" class="btn btn--primary btn--import" [disabled]="!SurchargeForm.valid" (click)="NextStep()">
						{{'global.export_btn-next' | transloco}}
						<i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
					</button>
				</div>
			</form>
		</fieldset>

		<fieldset class="form__section {{CheckStep(6)}}">
			<h3 class="form__section-title">
				{{combineSurchargesWithTaxes ? ('halo-export.step6_title-combineSurchargesWithTaxes' | transloco) :
				'halo-export.step6_title' | transloco }}
			</h3>
			<form #TaxForm="ngForm">
				<div *ngIf="!combineSurchargesWithTaxes" class="form__group block">
					<div class="form__group-item form__group-item--500 flex-start">
						<p>{{'halo-export.step6-field_sureTaxMultiline' | transloco}}
							<i class="fa fa-question-circle tooltip tooltip--export_1" aria-hidden="true">
								<span>
									{{'halo-export.step6-field_sureTaxMultiline-tooltip' | transloco}}
								</span>
							</i>
						</p>
						<dg-checkbox name="sureTaxMultiline" ngDefaultControl [id]="'sureTaxMultiline'"
							[(ngModel)]="sureTaxMultiline"></dg-checkbox>
					</div>
					<div *ngIf="sureTaxMultiline" class="form__group-item form__group-item--500 flex-start">
						<p>{{'halo-export.step6-field_groupTaxByState' | transloco}}
							<i class="fa fa-question-circle tooltip tooltip--export_2" aria-hidden="true">
								<span>
									{{'halo-export.step6-field_groupTaxByState-tooltip' | transloco}}
								</span>
							</i>
						</p>
						<dg-checkbox name="groupTaxByState" ngDefaultControl [id]="'groupTaxByState'"
							[(ngModel)]="groupTaxByState"></dg-checkbox>
					</div>
					<div *ngIf="sureTaxMultiline && groupTaxByState">
						<div *ngFor="let line of taxesLinesStateResponses; let i = index"
							class="form__group-item form__group-item--800">
							<p>{{line.taxTypeDesc}} {{line.stateCode}} {{!line.taxInclusive ? ('global-export.exclusive_label' | transloco) : 'global-export.inclusive_label' | transloco}}</p>
							<ng-select #inputProduct bindLabel="name" required
								bindValue="id" name="selectedHaloItemId{{i}}" [items]="products" [searchable]="true"
								[clearable]="false" [(ngModel)]="line.selectedHaloItemId">
								<ng-template>
									<input class="custom-input" type="text"
										(input)="inputProduct.filter($event.target.value)" />
								</ng-template>
							</ng-select>
						</div>
					</div>
					<div *ngIf="sureTaxMultiline && !groupTaxByState">
						<div *ngFor="let line of taxesLinesResponses; let i = index"
							class="form__group-item form__group-item--800">
							<p>{{line.taxTypeDesc}} {{!line.taxInclusive ? ('global-export.exclusive_label' | transloco) : 'global-export.inclusive_label' | transloco}}</p>
							<ng-select #inputProduct bindLabel="name" required
								bindValue="id" name="selectedHaloItemId{{i}}" [items]="products" [searchable]="true"
								[clearable]="false" [(ngModel)]="line.selectedHaloItemId">
								<ng-template>
									<input class="custom-input" type="text"
										(input)="inputProduct.filter($event.target.value)" />
								</ng-template>
							</ng-select>
						</div>
					</div>
					<div *ngIf="!sureTaxMultiline">
						<div *ngFor="let item of totalTaxesResponses; let i = index"
							class="form__group-item form__group-item--500">
							<p>{{item.taxName}} {{!item.taxInclusive ? ('global-export.exclusive_label' | transloco) : 'global-export.inclusive_label' | transloco}}</p>
							<ng-select #inputProduct bindLabel="name" bindValue="id"
								required name="selectedHaloItemId{{i}}" [items]="products"
								[searchable]="true" [clearable]="false" [(ngModel)]="item.selectedHaloItemId">
								<ng-template>
									<input class="custom-input" type="text"
										(input)="inputProduct.filter($event.target.value)" />
								</ng-template>
							</ng-select>
						</div>
					</div>
				</div>
				<div *ngIf="combineSurchargesWithTaxes" class="form__group block">
					<div *ngFor="let item of surchargesWithTaxesResponses; let i = index"
						class="form__group-item form__group-item--500">
						<p>{{item.combineName}}</p>
						<ng-select #inputProduct bindLabel="name" bindValue="id" required
							name="selectedHaloItemId{{i}}" [items]="products" [searchable]="true" [clearable]="false"
							[(ngModel)]="item.selectedHaloItemId">
							<ng-template>
								<input class="custom-input" type="text"
									(input)="inputProduct.filter($event.target.value)" />
							</ng-template>
						</ng-select>
					</div>
				</div>
				<div class="form__bottom form__bottom--border-none">
					<button type="button" class="btn btn--primary btn--import" (click)="BackStep()">
						{{'global.export_btn-back' | transloco}}
					</button>
					<button type="button" class="btn btn--primary btn--import" [disabled]="!TaxForm.valid" (click)="NextStep()">
						{{'global.export_btn-next' | transloco}}
						<i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
					</button>
				</div>
			</form>
		</fieldset>

		<fieldset class="form__section {{CheckStep(7)}}">
			<h3 class="form__section-title">
				{{'halo-export.step7_title' | transloco}}
			</h3>
			<div class="form__section-subtitle">
				{{'halo-export.step7_subtitle' | transloco}}
			</div>
			<div class="form__group">
				<div class="form__group-item ">
					<div class="radiobox">
						<input type="radio" id="KeepItems" name="radio-delete" [value]="false"
							[(ngModel)]="deleteExisting">
						<label class="radiobox-label" for="KeepItems">
							{{'halo-export.step7-field_deleteExisting-false' | transloco}}
						</label>
					</div>
					<div class="radiobox">
						<input type="radio" id="DeleteItems" name="radio-delete" [value]="true"
							[(ngModel)]="deleteExisting">
						<label class="radiobox-label" for="DeleteItems">
							{{'halo-export.step7-field_deleteExisting-true' | transloco}}
						</label>
					</div>
				</div>
			</div>

			<div class="form__bottom form__bottom--border-none">
				<button type="button" class="btn btn--primary btn--import" (click)="BackStep()">
					{{'global.export_btn-back' | transloco}}
				</button>
				<button type="button" class="btn btn--primary btn--import" (click)="NextStep()">
					<i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
					{{'global.export_btn-next' | transloco}}
				</button>
			</div>
		</fieldset>

		<fieldset class="form__section {{CheckStep(8)}}">
			<h3 class="form__section-title">{{'halo-export.step8_title' | transloco}}</h3>
			<div class="form__section-subtitle">
				{{'halo-export.step8_subtitle' | transloco}}
			</div>

			<div class="form__group">
				<div class="form__group-item ">
					<div class="radiobox">
						<input class="radiobox-input" type="radio" name="price-radio" id="price-1"
							[value]="1" [(ngModel)]='selectedPriceCalculationTypeId' />
						<label class="radiobox-label" for="price-1">
							{{'halo-export.field_priceCalculationTypes-opt-0' | transloco}}
						</label>
					</div>
					<div class="sidebar__radiobox radiobox">
						<input class="radiobox-input" type="radio" name="price-radio" id="price-2"
							[value]="2" [(ngModel)]='selectedPriceCalculationTypeId' />
						<label class="radiobox-label" for="price-2">
							{{'halo-export.field_priceCalculationTypes-opt-1' | transloco}}
						</label>
					</div>
				</div>
			</div>

			<div class="form__bottom form__bottom--border-none">
				<button type="button" class="btn btn--primary btn--import" [disabled]="loading" (click)="BackStep()">
					{{'global.export_btn-back' | transloco}}
				</button>
				<button type="button" class="btn btn--primary btn--import" [disabled]="loading" (click)="Export()">
					{{'global.export_btn-export' | transloco}}
					<i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
				</button>
			</div>
		</fieldset>

	</div>
</form>

<div *ngIf="isExported" class="modal modal--tile modal--confirmation">
	<div class="modal__content">
		<form class="modal__content-body">
			<div class="page-tile__body page-tile__body--hidden-none">
				<div class="page-tile__title">
					<b>
						{{'halo-export.confirmation_title' | transloco}}
					</b>
				</div>

				<div class="page-tile__content">
					<div class="page-tile__group">
						<span>
							{{'halo-export.confirmation_msg' | transloco}}
						</span>
					</div>
					<div class="page-tile__content-item">
						<div *ngFor="let inv of exportedInvoices" class="page-tile__content-row">
							<i class="fa fa-circle" aria-hidden="true"></i>
							{{inv}}
						</div>
					</div>
				</div>

				<p class="page-tile__body-proceed"></p>

				<div class="form__bottom">
					<button type="button" class="btn btn--secondary width-100px" (click)="onExportConfirm()">
						{{'global.modals_btn-confirm' | transloco}}
					</button>
					<button type="button" class="btn btn--default width-100px" (click)="backTo()">
						{{'global.modals_btn-cancel' | transloco}}
					</button>
				</div>
			</div>
		</form>
	</div>
</div>

<div *ngIf="isExported" class="fade"></div>
