import { Serializable } from 'domain/models';
import { Guid } from 'domain/types';

export class BillingPeriod implements Serializable<BillingPeriod> {
	id: Guid;
	month: string;
	year: number;
	label: string;
	periodStart: string;
	periodEnd: string;
	isLocked: boolean;
	isPriceLocked: boolean;

	deserialize(json: any): BillingPeriod {

		this.id = json.id;
		this.month = json.month;
		this.year = json.year;
		this.label = json.label;

		this.isLocked = json.isLocked;
		this.isPriceLocked = json.isPriceLocked;
		this.periodStart = json.periodStart;
		this.periodEnd = json.periodEnd;

		return this;
	}
}
