import { Injectable } from '@angular/core';
import { HttpAuth } from 'core/auth';
import { Guid } from 'domain/types';
import { Note } from 'domain/entities';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AppConfig } from 'core/app-config';
import { BaseRepository } from './base-repository';
import { CacheService } from 'services/cache.service';

@Injectable({
	providedIn: 'root'
})
export class NoteRepository extends BaseRepository {

	constructor(
		http: HttpAuth,
		config: AppConfig,
		cache: CacheService
	) {
		super(http, config, cache);
	}

	public getCustomerNotes(id: Guid): Observable<Note[]> {

		const observable =
			this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/notes/customers/${id}`)
				.pipe(
					map(res => {
						return res.body.data.map(x => new Note().deserialize(x));
					}));

		return observable;
	}

	public addCustomerNotes(id: Guid, note: Note): Observable<Guid> {
		const key = 'customer-notes';

		return this.createEntity(`/notes/customers/${id}`, note)
			.pipe(
				tap(s => this.addCacheCollectionItem(key, s))
				, map(s => s.id));
	}

	public updateCustomerNotes(id: Guid, note: Note): Observable<Note[]> {

		const key = 'customer-notes';

		return this.updateEntity(`/notes/customers/${id}`, note)
			.pipe(
				tap(t => this.updateCacheCollectionItem(key, t.id, t))
				, map(s => null));

	}

	public deleteCustomerNotes(customerId: Guid, item: Note): Observable<void> {

		const observable =
			this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/notes/customers/${customerId}/delete`, item, null)
				.pipe(
					tap(() => { })
					, map(e => null));

		return observable;
	}

	public getInvoiceNotes(id: Guid): Observable<Note[]> {

		const observable =
			this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/notes/invoices/${id}`)
				.pipe(
					map(res => {
						return res.body.data.map(x => new Note().deserialize(x));
					}));

		return observable;
	}

	public addInvoiceNotes(id: Guid, note: Note): Observable<Guid> {
		const key = 'invoice-notes';

		return this.createEntity(`/notes/invoices/${id}`, note)
			.pipe(
				tap(s => this.addCacheCollectionItem(key, s))
				, map(s => s.id));
	}

	public updateInvoiceNotes(id: Guid, note: Note): Observable<Note[]> {
		const key = 'invoice-notes';

		return this.updateEntity(`/notes/invoices/${id}`, note)
			.pipe(
				tap(t => this.updateCacheCollectionItem(key, t.id, t))
				, map(s => null));

	}

	public deleteInvoiceNotes(id: Guid, item: Note): Observable<void> {

		const observable =
			this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/notes/invoices/${id}/delete`, item, null)
				.pipe(
					tap(() => { })
					, map(e => null));

		return observable;
	}

	public updateInvoiceReason(id: Guid, reason: string): Observable<void> {
		return this.http.put(`${this.config.apiUrl}${this.config.apiVersion}/invoices/${id}/update`, null, { reason })
			.pipe(map(e => null));
	}
}
