import { EntityName } from 'tools/decorators';
import { Serializable } from 'domain/models';
import { Guid } from 'domain/types';

@EntityName('User', 'User')
export class ResellerUser implements Serializable<ResellerUser> {
	id: Guid;
	firstname: string;
	lastname: string;
	email: string;
	isAdmin: boolean;
	sendLink: boolean;
	statusId: number;
	statusIdToString: string;
	status: string;
	selectedRoleIds: Guid[];
	roles: string;
	userName: string;

	deserialize(json: any): ResellerUser {

		this.id = json.id;
		this.firstname = json.firstname;
		this.lastname = json.lastname;
		this.email = json.email;
		this.isAdmin = json.isAdmin;
		this.sendLink = json.sendLink;
		this.statusId = json.statusId;
		this.statusIdToString = json.statusId === 1 ? json.statusIdToString = 'Active' : json.statusIdToString = 'Pending';

		this.selectedRoleIds = json.selectedRoleIds;
		this.roles = json.roles;
		this.userName = json.userName;

		return this;
	}
}

@EntityName('Role', 'Roles')
export class ResellerRole implements Serializable<ResellerRole> {
	id: Guid;
	roleName: string;
	description: string;
	customerGroups: string;
	selectedGroupIds: Guid[] = [];
	users: number;
	isHidden: boolean;

	deserialize(json: any): ResellerRole {

		this.id = json.roleId;
		this.roleName = json.roleName;
		this.description = json.description;
		this.customerGroups = json.customerGroups;
		this.selectedGroupIds = json.selectedGroupIds;
		this.users = json.users;
		this.isHidden = json.isHidden;

		return this;
	}

	clone(): ResellerRole {
		const clone = Object.assign(new ResellerRole(), this);
		return clone;
	}
}

@EntityName('Permission', 'Permission')
export class Permission implements Serializable<Permission> {
	id: number;
	name: string;
	groupName: string;
	isHidden: boolean;

	deserialize(json: any): Permission {

		this.id = json.id;
		this.name = json.name;
		this.groupName = json.groupName;
		this.isHidden = json.isHidden;

		return this;
	}
}

@EntityName('RolePermission', 'RolePermission')
export class RolePermission implements Serializable<RolePermission> {
	roleId: Guid;
	roleName: string;
	permissionId: number;
	permissionName: string;
	active: boolean;

	deserialize(json: any): RolePermission {

		this.roleId = json.roleId;
		this.roleName = json.roleName;
		this.permissionId = json.permissionId;
		this.permissionName = json.permissionName;
		this.active = json.active;

		return this;
	}
}
