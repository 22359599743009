import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoRootModule } from 'transloco-root.module';
import { DgDialogComponent } from './dialog.component';
import { DgDialogService } from './dialog.service';

@NgModule({
	imports: [
		CommonModule,
		TranslocoRootModule
	],
	declarations: [
		DgDialogComponent
	],
	exports: [
		DgDialogComponent
	],
	providers: [
		DgDialogService
	]
})

export class DgDialogModule {
}
