import { CanDeactivate, Router } from '@angular/router';
import { Injectable, Type } from '@angular/core';
import { FormGroup } from '@angular/forms';

/**
 * Interface defining the contract for components that handle their own navigation deactivation.
 *
 * Components implementing this interface need to provide a `canDeactivate` method,
 * which will be called to determine if the component can be deactivated. This method
 * is typically called when navigating away from the route associated with the component.
 * It should return `true` to allow the navigation or `false` to prevent it, potentially
 * after asking the user for confirmation.
 *
 * The primary use of this interface is in conjunction with guards that prevent navigation
 * away from components that have unsaved changes or where other specific conditions
 * prevent the component from safely deactivating without potential data loss or other issues.
 *
 */
export interface ComponentCanDeactivate {
	/**
     * Determines whether the component can be deactivated.
     * This method should be implemented to return a boolean value,
     * indicating whether the navigation away from the component can proceed.
     *
     * @returns {boolean} True if the component can be safely deactivated (e.g., no unsaved changes),
     *                    or false if the deactivation should be prevented (e.g., after confirming with the user).
     */
	canDeactivate: () => boolean;
}

/**
 * Interface for options used in the PendingChanges decorator.
 * @interface
 * @property {string} [formPath] - Dot-separated path to the form object within the component.
 * @property {boolean} [useComponentCanDeactivate] - Flag to use the component's own canDeactivate method if available.
 */
export interface CheckPendingChangesOptions {
	/**
     * Dot-separated path to the form object within the component.
     * This is used to locate the specific form to be checked for pending changes.
     * Example: 'userForm.address'
     */
	formPath?: string;
	/**
     * If set to true, the component's original canDeactivate method is used if available,
     * overriding the default form check behavior. This allows for custom deactivation logic
     * that may consider factors beyond form state.
     */
	useComponentCanDeactivate?: boolean;
}

@Injectable()
export class DgPendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
	constructor(private router: Router) {}

	canDeactivate(component: ComponentCanDeactivate): boolean {
		if (this.router.getCurrentNavigation()?.extras?.state?.bypassGuard) {
			return true;
		}

		// if there are no pending changes, just allow deactivation; else confirm first
		return component.canDeactivate()
			? true
			: // NOTE: this warning message will only be shown when navigating elsewhere within your angular app;
			// when navigating away from your angular app, the browser will show a generic warning message
			// see http://stackoverflow.com/a/42207299/7307355
			confirm('WARNING: You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.');
	}
}

/**
 * Decorator that enhances a component with navigation confirmation capabilities based on form changes.
 * @function
 * @param {CheckPendingChangesOptions} options - Configuration options for the decorator.
 * @returns {Function} Class decorator function.
 */
export function CheckPendingChanges(options: CheckPendingChangesOptions = {}) {
	return function <T extends Type<any>>(constructor: T) {
		if (options.useComponentCanDeactivate && !('canDeactivate' in constructor.prototype)) {
			throw new Error(`The component ${constructor.name} must implement the ComponentCanDeactivate interface.`);
		}
		const originalNgOnInit = constructor.prototype.ngOnInit;
		const originalNgOnDestroy = constructor.prototype.ngOnDestroy;
		const originalCanDeactivate = constructor.prototype.canDeactivate;

		constructor.prototype.ngOnInit = function (...args: any[]) {
			setTimeout(() =>{
				// Ensure configuration enforcement at initialization
				// Delay needed due to possible async init of a form or ViewChild
				const form = options.formPath
					? options.formPath.split('.').reduce((prev, curr) => prev ? prev[curr] : undefined, this)
					: this['form'];
				if (!form && !originalCanDeactivate) {
					console.error("CheckPendingChanges requires either a 'formPath' option or an existing 'canDeactivate' method.");
				}
			}, 3000);

			if (originalNgOnInit) {
				originalNgOnInit.apply(this, args);
			}
			if (!this.__dgPendingChangesHandleBeforeUnload) {
				this.__dgPendingChangesHandleBeforeUnload = (event: BeforeUnloadEvent) => {
					if (!this.canDeactivate()) {
						event.returnValue = true;
						event.preventDefault();
						return true;
					}
					return false;
				};
			}
			window.addEventListener('beforeunload', this.__dgPendingChangesHandleBeforeUnload);
		};

		constructor.prototype.ngOnDestroy = function (...args: any[]) {
			window.removeEventListener('beforeunload', this.__dgPendingChangesHandleBeforeUnload);
			if (originalNgOnDestroy) {
				originalNgOnDestroy.apply(this, args);
			}
		};

		constructor.prototype.canDeactivate = function () {
			if (options.useComponentCanDeactivate && originalCanDeactivate) {
				// Use the original method if explicitly requested.
				return originalCanDeactivate.apply(this, arguments);
			}

			// Otherwise, check the form or use default logic.
			const form = options.formPath
				? options.formPath.split('.').reduce((prev, curr) => prev ? prev[curr] : undefined, this)
				: this['form'];

			if (isAngularForm(form)) {
				return defaultCanDeactivate(form);
			}

			if (originalCanDeactivate) {
				return originalCanDeactivate.apply(this, arguments);
			}

			return true;
		};
	};
}

function defaultCanDeactivate(form: FormGroup): boolean {
	return form.pristine;
}

function isAngularForm(property: any): property is FormGroup {
	if (!property)
		return false;

	return 'pristine' in property
		&& 'value' in property
		&& 'status' in property
		&& 'valid' in property
		&& 'dirty' in property
		&& 'touched' in property
		&& 'controls' in property;
}
