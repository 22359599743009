<div class="notification__container">
	<!-- (click)="togglePanel();$event.stopPropagation();" -->
	<button type="button" id="beamerButton" class="header__button">
		<a><i class="fa fa-bell" aria-hidden="true"></i></a>
		<!-- <div *ngIf="newNotifications" class="header__new-notifications">{{newNotifications}}</div> -->

	</button>
	<!-- <div *ngIf="panelOpen" class="notification">
		<ul class="notification__list">
			<li class="notification__group notification__group--tools">
				<span>{{'dg-notifications.title' | transloco}}</span>
				<button *ngIf="hasNotifications" type="button" class="users__row-action" (click)="onNotificationsDismiss()">
					{{'dg-notifications.dismiss-btn_label' | transloco}}
				</button>
			</li>
			<li *ngIf="!hasNotifications" class="notification__group notification__group--empty">
				<span>{{'dg-notifications.no-notif_msg' | transloco}}</span>
			</li>
			<li *ngFor="let item trackByNotification" of notifications; t>
				<a *ngIf="item.message.length || item.level === 'progress'"
					class="notification__group notification__group--{{item.level}}"
					[ngClass]="{'pointer-event__none': item.level === 'progress', '': !item.level}"
					[routerLink]="item.url">
					<div class="notification__group-icon">
						<span class="symbol"></span>
					</div>
					<div class="notification__group-label">
						<div class="notification__group-label--time">
							<span>{{formatDate(item)}}</span>
						</div>
						<div class="notification__group-label--text">
							<span *ngIf="item.message">{{item.message}}</span>
						</div>
						<div *ngIf="item.level === 'progress'" class="notification__group-label--bar">
							<progress *ngIf="item.level === 'progress' && item.value" class="progress__bar "
								max="100" value="{{item.value}}"></progress>
							<progress *ngIf="item.level === 'progress' && !item.value"
								class="progress__bar "></progress>
						</div>
					</div>
				</a>
			</li>
			<li class="arrow-up arrow-up--progress arrow-up--first"></li>

		</ul>
	</div> -->
</div>
