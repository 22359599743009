<form class="form form--export credit-note">
	<ul class="steps__wrapper">
		<li class="steps steps--active">
			<span class="steps__label">{{'global-export.step1_label' | transloco}}</span>
			<span class="steps__label">{{'credit-note-generate.step1_title' | transloco}}</span>
		</li>
	</ul>
	<div class="form__content">
		<fieldset class="form__section steps--active padding-b__180">
			<div *ngIf="loading" class="app-spinner-cw"></div>
			<h3 class="form__section-title">
				{{'credit-note-generate.step1_title' | transloco}}
			</h3>
			<form>
				<div class="form__group block">
					<div class="form__group-item form__group-item--500-fs">
						<p>
							{{'credit-note-generate.step1_header-dg-invoice' | transloco}}
						</p>
						<p>
							{{'credit-note-generate.step1_header-dg-credit-note' | transloco}}
						</p>
						<p class="margin-l__20">
							{{'credit-note-generate.step1_header-at-reason' | transloco}}
						</p>
					</div>
					<div *ngFor="let invoice of filteredInvoices; let i = index"
						class="form__group-item form__group-item--500-fs">
						<p class="label">{{invoice.invoiceNumber}}</p>

						<div class="picker">
							<input type="text" class="date-picker" ngxDaterangepickerMd startKey="start" endKey="end" [minDate]="invoice['invoiceMinDate']"
								[locale]="locale" [name]="invoice.id?.toString()" [singleDatePicker]="true" [autoApply]="true"  [(ngModel)]="invoice.invoiceDate" (datesUpdated)="datesUpdated($event, invoice)"/>
							<i aria-hidden="true" class="fa fa-calendar"></i>
							<i *ngIf="invoice['isFutureDate']" class="fa fa-question-circle tooltip tooltip--error" aria-hidden="true">
								<span>Tax Engine does not provide taxes for invoices with date in future</span>
							</i>
						</div>

						<input autocomplete="new-password" id="reason" class="page-tile__group-input width-200px" name="reason" [(ngModel)]="invoice.reason">

					</div>

				</div>
				<div class="form__bottom form__bottom--border-none">
					<button type="button" class="btn btn--primary btn--import" (click)="onBackTo()">
						{{'global.export_btn-back' | transloco}}
					</button>
					<button type="button" class="btn btn--primary btn--import" [disabled]="isFutureDateSelected" (click)="onGenerate()">
						{{'global.export_btn-create' | transloco}}
						<i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
					</button>
				</div>
			</form>
		</fieldset>
	</div>
</form>
