import { Serializable } from 'domain/models';
import { Guid } from 'domain/types';

export class MyDetails implements Serializable<MyDetails> {
	displayName: string;
	email: string;
	phoneNumber: string;
	timeZone: string;
	userName: string;
	brandId: string;
	role: string[];
	permission: string[];
	resellerid: Guid;
	userId: Guid;
	isDistributor = false;
	distributorId: number;
	deserialize(json: any): MyDetails {
		this.displayName = json.displayName;
		this.email = json.email;
		this.phoneNumber = json.phoneNumber;
		this.timeZone = json.timeZone;
		this.userName = json.userName;
		this.brandId = json.brandId;
		this.role = json.role;
		this.permission = json.permission;
		this.resellerid = json.resellerId;
		this.userId = json.nameId;
		this.isDistributor = json.isDistributor;
		this.distributorId = json.distributorId;

		return this;
	}

	clone(): MyDetails {
		const clone = Object.assign(new MyDetails(), this);
		return clone;
	}

}
