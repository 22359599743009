<ng-container *ngIf="!dropDownMode; else dropDown">
	<ng-container *ngFor="let action of actionCollection.actions">
		<button *ngIf="action.available" type="button" class="btn"
				[ngClass]="{'btn--secondary btn--bucket btn--action-bar': !isInvoice, '': isInvoice}" [title]="action.name"
				(click)="onGroupAction(action)">
			<i class="fa {{action.icon}}"></i>
		</button>
	</ng-container>
</ng-container>
<ng-template #dropDown>
	<div class="drop-down" [ngClass]="{'drop-down--active': isDropDownOpen}">
		<button id="dropDown" type="button"
				class="btn btn--primary btn--generate"
				[disabled]="!isActionAvailable"
				[title]="'invoice-action-bar.actions-dd_hint' | transloco"
				(click)="toggleDropDown()">
			{{'invoice-action-bar.actions-dd_label' | transloco}}&nbsp;&nbsp;<i class="fa fa-chevron-down" aria-hidden="true"></i>
		</button>

		<div class="drop-down__menu-box drop-down__menu-box--invoice-actions">
			<ul *ngFor="let action of actionCollection.actions" class="drop-down__menu">
				<li *ngIf="action.available || isSelectedAll" class="drop-down__item" (click)="onGroupAction(action)">
					<i class="fa {{action.icon}}"></i>&nbsp;&nbsp;{{action.name}}
				</li>
			</ul>
		</div>
	</div>
</ng-template>
