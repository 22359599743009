import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { GenericEvent } from 'domain/types';

const noop = (): void => {
};

@Component({
	selector: 'dg-multi-input[type]',
	templateUrl: './dg-multi-input.component.html',
	styleUrls: ['./dg-multi-input.component.sass'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: DgMultiInputComponent,
			multi: true
		}
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class DgMultiInputComponent implements OnInit, ControlValueAccessor {
	@Input() type!: 'email' | 'text';

	item: string;
	items: string[] = [];
	allowAdd = false;
	private listWasCreated = false;

	constructor(
		private readonly cdr: ChangeDetectorRef
	) { }

	ngOnInit(): void {
		if (this.items.length > 1) {
			this.listWasCreated = true;
		}
		this.cdr.markForCheck();
	}

	private _onTouchedCallback: () => void = noop;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private _onChangeCallback: (_: any) => void = noop;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	writeValue(value: any): void {
		if (value !== undefined) {
			this.value = value;
		}
		this.cdr.markForCheck();
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	registerOnChange(fn: any): void {
		this._onChangeCallback = fn;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	registerOnTouched(fn: any): void {
		this._onTouchedCallback = fn;
	}

	private get value(): string {
		if (this.items.length === 0) {
			return '';
		} else {
			return this.items.join(';');
		}
	}

	private set value(val: string) {
		if (!val) {
			this.items = [];
			this.cdr.markForCheck();
			return;
		}

		const items = val.split(';');
		const tmp = [];
		for (const item of items) {
			tmp.push(this.type === 'email' ? item.toLowerCase() : item);
		}
		this.items = tmp;
		this.cdr.markForCheck();
	}

	onInputValueChange(): void {
		// queue
		setTimeout(() => {
			this._onTouchedCallback();

			let isValidEmail = true;
			if (this.type === 'email') {
				isValidEmail = this.testEmail(this.item);
			}

			this.allowAdd = isValidEmail && !!this.item;
			this.cdr.markForCheck();
		}, 1);
	}


	addItem(event?: GenericEvent<HTMLInputElement>): void {
		event?.stopPropagation();
		event?.preventDefault();

		if (!this.item || !this.allowAdd)
			return;

		let sanitizedValue = '';
		let isValidEmail = true;
		if (this.type === 'email') {
			sanitizedValue = this.item.trim().toLowerCase();
			isValidEmail = this.testEmail(this.item);
		} else {
			sanitizedValue = this.item.trim();
		}

		if (!isValidEmail || sanitizedValue.length === 0)
			return;

		// ignore duplicates
		if (this.items.some(x => x === sanitizedValue)) {
			this.item = '';
			this.cdr.markForCheck();
			return;
		}

		this.items.push(sanitizedValue);
		this.item = '';
		this.listWasCreated = true;
		this.allowAdd = false;

		this._onChangeCallback(this.value);
		this._onTouchedCallback();

		this.cdr.markForCheck();
	}

	remove(index: number): void {
		this.items.splice(index, 1);
		this.items = this.items.slice();

		this._onChangeCallback(this.value);
		this._onTouchedCallback();

		this.cdr.markForCheck();
	}

	private testEmail(email: string): boolean {
		return /^[\w'.%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$/i.test(email);
	}
}
