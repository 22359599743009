import { inject } from '@angular/core';
import { CanMatchFn, Route, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthHelper } from './auth-helper';
import { AuthService } from './auth-service';

export const authGuard: CanMatchFn = (route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
	const authService = inject(AuthService);
	if (AuthHelper.getAuthDetails()) {
		return true;
	}

	authService.clearAuthAndRedirectToLogin();
	return false;
};
