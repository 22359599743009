<form class="form form--export">
	<ul class="steps__wrapper">
		<li class="steps steps--active">
			<span class="steps__label">{{'global-export.step1_label' | transloco}}</span>
			<span class="steps__label">{{'cw-return-taxes-export.step1_title' | transloco}}</span>
		</li>
	</ul>
	<div class="form__content">
		<fieldset class="form__section steps--active padding-b__180">
			<div *ngIf="loading" class="app-spinner-cw"></div>
			<h3 class="form__section-title">
				{{'cw-return-taxes-export.step1_title' | transloco}}
			</h3>
			<form #TaxForm="ngForm">
				<div class="form__group block">
					<div class="form__group-item form__group-item--500 flex-start">
						<p>{{'cw-return-taxes-export.step1-field_sureTaxMultiline' | transloco}}<i
								class="fa fa-question-circle tooltip tooltip--export_1" aria-hidden="true">
								<span>
									{{'cw-return-taxes-export.step1-field_sureTaxMultiline-tooltip' | transloco}}
								</span>
							</i>
						</p>
						<dg-checkbox name="sureTaxMultiline" ngDefaultControl [id]="'sureTaxMultiline'"
							[(ngModel)]="sureTaxMultiline"></dg-checkbox>
					</div>
					<div *ngIf="sureTaxMultiline" class="form__group-item form__group-item--500 flex-start">
						<p>{{'cw-return-taxes-export.step1-field_groupTaxByState' | transloco}}
							<i class="fa fa-question-circle tooltip tooltip--export_2" aria-hidden="true">
								<span>
									{{'cw-return-taxes-export.step1-field_groupTaxByState-tooltip' | transloco}}
								</span>
							</i>
						</p>
						<dg-checkbox name="groupTaxByState" ngDefaultControl [id]="'groupTaxByState'"
							[(ngModel)]="groupTaxByState"></dg-checkbox>
					</div>
					<div *ngIf="sureTaxMultiline && groupTaxByState">
						<div *ngFor="let line of cwReturnTaxes?.taxesLinesStateResponses; let i = index"
							class="form__group-item form__group-item--800">
							<p>{{line.taxTypeDesc}} {{!line.taxInclusive ? ('global-export.exclusive_label' | transloco) : 'global-export.inclusive_label' | transloco}}</p>
							<ng-select #inputProduct bindLabel="identifier" required
								bindValue="id" name="selectedProductId{{i}}" [items]="cwReturnTaxes.cwProducts"
								[searchable]="true" [clearable]="false" [(ngModel)]="line.selectedCwProductId">
								<ng-template>
									<input class="ng-select-input" type="text"
										(input)="inputProduct.filter($event.target.value)" />
								</ng-template>
							</ng-select>
						</div>
					</div>
					<div *ngIf="sureTaxMultiline && !groupTaxByState">
						<div *ngFor="let line of cwReturnTaxes?.taxesLinesResponses; let i = index"
							class="form__group-item form__group-item--800">
							<p>{{line.taxTypeDesc}} {{!line.taxInclusive ? ('global-export.exclusive_label' | transloco) : 'global-export.inclusive_label' | transloco}}</p>
							<ng-select #inputProduct bindLabel="identifier" required
								bindValue="id" name="selectedProductId{{i}}" [items]="cwReturnTaxes.cwProducts"
								[searchable]="true" [clearable]="false" [(ngModel)]="line.selectedCwProductId">
								<ng-template>
									<input class="ng-select-input" type="text"
										(input)="inputProduct.filter($event.target.value)" />
								</ng-template>
							</ng-select>
						</div>
					</div>
					<div *ngIf="!sureTaxMultiline">
						<div *ngFor="let item of cwReturnTaxes?.totalTaxesResponses; let i = index"
							class="form__group-item form__group-item--500">
							<p>{{item.taxName}} {{!item.taxInclusive ? ('global-export.exclusive_label' | transloco) : 'global-export.inclusive_label' | transloco}}</p>
							<ng-select #inputProduct bindLabel="identifier" bindValue="id"
								required name="selectedProductId{{i}}" [items]="cwReturnTaxes.cwProducts"
								[searchable]="true" [clearable]="false" [(ngModel)]="item.selectedCwProductId">
								<ng-template>
									<input class="ng-select-input" type="text"
										(input)="inputProduct.filter($event.target.value)" />
								</ng-template>
							</ng-select>
						</div>
					</div>
				</div>
				<div class="form__bottom form__bottom--border-none">
					<button type="button" class="btn btn--primary btn--import" (click)="onBackTo()">
						{{'global.export_btn-back' | transloco}}
					</button>
					<button type="button" class="btn btn--primary btn--import" [disabled]="!TaxForm.valid" (click)="onExport()">
						{{'global.export_btn-export' | transloco}}
						<i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
					</button>
				</div>
			</form>
		</fieldset>
	</div>
</form>
