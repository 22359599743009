import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavigatorService } from 'services';
import { CacheModel, CacheService } from 'services/cache.service';
import { catchError, finalize, takeUntil } from 'rxjs/operators';

import { HashMap, TranslateParams, TranslocoService } from '@ngneat/transloco';
import {
	ExportMultilineModel, Invoice, Product, ProductMapping, Surcharge, SurchargeMapping, SurchargeSeparatelyMapping, SurchargeWithTaxMapping, SureTaxByStateLineMapping,
	SureTaxLineMapping, TaxMapping, TransactionMapping
} from 'domain/entities';
import { QbTaxRate, QbTerms } from 'domain/entities/qb-entities';
import { InvoiceActionsService } from 'shared/modules/invoice-actions';
import { QBExportInvoiceRepository } from 'repositories/qb-export-invoice-repository';
import { Location } from '@angular/common';
import { ToasterService } from 'core/toaster-service';
import { EMPTY, Observable, Subject, throwError } from 'rxjs';
import { PollingControlService } from 'services';
import { IntegrationRepository, SurchargeRepository } from 'repositories';

@Component({
	selector: 'dg-qb-invoice-export-multiline',
	templateUrl: './qb-invoice-export-multiline.component.html',
	styleUrls: ['./qb-invoice-export-multiline.component.sass']
})
export class QbInvoiceExportMultilineComponent implements OnInit, OnDestroy {
	currentStep = 1;
	adjustmentLineProduct: undefined;
	invoices: Invoice[] = [];
	excluded: Invoice[] = [];
	productsMapping: ProductMapping[];
	transactionsMapping: TransactionMapping[];
	taxMapping: TaxMapping[];
	surchargeMapping: SurchargeMapping[];
	surchargeWithTaxMapping: SurchargeWithTaxMapping[];
	surchargeSeparatelyMapping: SurchargeSeparatelyMapping[];
	sureTaxLineMapping: SureTaxLineMapping[] = [];
	sureTaxByStateLineMapping: SureTaxByStateLineMapping[] = [];
	sureTaxLinesMappingForInvoiceTaxes: SureTaxLineMapping[] = [];
	sureTaxByStateLinesMappingForInvoiceTaxes: SureTaxByStateLineMapping[] = [];
	products: Product[];
	taxRates: QbTaxRate[];
	loading = false;
	selectedEntityTypeId = 1;
	selectedGroupingTypeId = 1;
	selectedPaymentTermId: number;
	selectedPriceCalculationTypeId = 1;
	deleteExisting = false;
	public combineSurchargesWithTaxes = false;
	public surchargeSeparately = false;
	public sureTaxMultiline = false;
	public useProductsTaxRates = false;
	public groupTaxByState = false;
	public groupTaxByStateForInvoiceTaxes = false;
	public uniteProducts = false;
	public isExported = false;
	public exportedInvoices: string | string[];
	public isUseInvoiceDueDate: boolean;
	public allowAdjustmentLine: boolean;
	public cacheModel: CacheModel;
	public cacheModelExcluded: CacheModel;
	public qbTerms: QbTerms[];
	filteredInvoices: number[];
	qbSteps: any = [];
	public resellerSurcharges$: Observable<Surcharge[]>;
	t: <T = string>(key: TranslateParams, params?: HashMap, lang?: string) => T;
	private unsubscribe = new Subject<void>();
	constructor(
		private readonly invoiceRepo: InvoiceActionsService,
		private readonly transloco: TranslocoService,
		private readonly integrationRepository: IntegrationRepository,
		private readonly cache: CacheService,
		private readonly router: Router,
		private readonly navigator: NavigatorService,
		private readonly repository: QBExportInvoiceRepository,
		private readonly toasterService: ToasterService,
		private readonly location: Location,
		private readonly surchargeRepository: SurchargeRepository,
		private readonly pollingControlService: PollingControlService
	) {
		this.t = transloco.translate.bind(transloco);

		this.cacheModel = this.cache.get('exportQbInvoicesMultiline');
		this.cacheModelExcluded = this.cache.get('exportQbInvoicesMultiline-excluded');

		this.invoices = this.cacheModel ? <Invoice[]>this.cacheModel.Object : [];
		this.excluded = this.cacheModelExcluded ? <Invoice[]>this.cacheModelExcluded.Object : [];

		if (this.invoices.length) {
			this.GroupingTypeSelected();

			this.resellerSurcharges$ = this.surchargeRepository.getSurcharges();
		} else {
			this.location.back();
		}
	}

	async ngOnInit(): Promise<void> {
		const [quickBooksDetails] = await Promise.all([
			this.integrationRepository.getQuickBooksDetails(this.unsubscribe),
		]).withBusy();

		this.isUseInvoiceDueDate = quickBooksDetails.invoiceDueDateTypeId === 1;
		this.allowAdjustmentLine = quickBooksDetails.allowAdjustmentLine;
	}

	public ngOnDestroy(): void {
		this.pollingControlService.stopBackgroundPolling();
		this.unsubscribe.next();
		this.unsubscribe.complete();
	}

	public GroupingTypeSelected(force: boolean = false): void {
		this.loading = true;
		const groupingTypeId = +this.selectedGroupingTypeId;
		const actionFilters = this.cache.get('invoices-action-filterParams');
		const filterParams = this.invoiceRepo.selectedAll && actionFilters ? actionFilters : '';

		this.repository.GroupingTypePost(groupingTypeId, this.invoices, force, this.excluded, filterParams)
			.pipe(
				takeUntil(this.unsubscribe),
				catchError(err => {
					if (Array.isArray(err.error?.references)) {
						this.isExported = true;
						this.exportedInvoices = err.error.references;
						return EMPTY;
					} else {
						this.location.back();
						return throwError(() => err);
					}
				}),
				finalize(() => this.loading = false)
			)
			.subscribe(res => {
				this.productsMapping = res.productsMapping.map(x => new ProductMapping().deserialize(x));
				this.transactionsMapping = res.transactionsMapping.map(x => new TransactionMapping().deserialize(x));
				this.taxMapping = res.taxMapping.map(x => new TaxMapping().deserialize(x));
				this.surchargeMapping = res.surchargeMapping.map(x => new SurchargeMapping().deserialize(x));
				this.surchargeSeparatelyMapping =  res.surchargeSeparatelyMapping.map(x => new SurchargeSeparatelyMapping().deserialize(x));
				this.surchargeWithTaxMapping =  res.surchargeWithTaxMapping.map(x => new SurchargeWithTaxMapping().deserialize(x));
				this.products = res.products.map(x => new Product().deserialize(x));
				this.sureTaxLineMapping = res.sureTaxLinesMapping.map(x => new SureTaxLineMapping().deserialize(x));
				this.sureTaxByStateLineMapping = res.sureTaxByStateLinesMapping.map(x => new SureTaxByStateLineMapping().deserialize(x));
				this.sureTaxLinesMappingForInvoiceTaxes = res.sureTaxLinesMappingForInvoiceTaxes.map(x => new SureTaxLineMapping().deserialize(x));
				this.sureTaxByStateLinesMappingForInvoiceTaxes = res.sureTaxByStateLinesMappingForInvoiceTaxes.map(x => new SureTaxByStateLineMapping().deserialize(x));
				this.taxRates = res.taxRates.map(x => new QbTaxRate().deserialize(x));
				this.qbTerms = res.qbTerms.map(x => new QbTerms().deserialize(x));
				this.filteredInvoices = res.invoices;
				this.qbSteps = res.qbSteps;
				this.adjustmentLineProduct = res.adjustmentLine?.selectedProductId || undefined;
			});
	}

	public Export(): void {
		if (!this.invoices) {
			this.toasterService.error(this.t('qb-export.step8_none-exported-warning'));
			return;
		}
		this.loading = true;
		const exportModel = new ExportMultilineModel();
		exportModel.selectedGroupingTypeId = this.selectedGroupingTypeId;
		exportModel.selectedPriceCalculationTypeId = this.selectedPriceCalculationTypeId;
		exportModel.deleteExisting = this.deleteExisting;
		exportModel.invoices = this.filteredInvoices;
		exportModel.productsMapping = this.productsMapping;
		exportModel.transactionMapping = this.transactionsMapping;
		exportModel.taxMapping = this.taxMapping;
		exportModel.surchargeMapping = this.surchargeMapping;
		exportModel.surchargeWithTaxMapping = this.surchargeWithTaxMapping;
		exportModel.combineSurchargesWithTaxes = this.combineSurchargesWithTaxes;
		exportModel.surchargeSeparatelyMapping = this.surchargeSeparatelyMapping;
		exportModel.surchargesSeparately = this.surchargeSeparately;
		exportModel.products = this.products;
		exportModel.sureTaxByStateLineMapping = this.sureTaxByStateLineMapping;
		exportModel.groupTaxByState = this.groupTaxByState;
		exportModel.sureTaxLineMapping = this.sureTaxLineMapping;
		exportModel.sureTaxMultiline = this.sureTaxMultiline;
		exportModel.useProductsTaxRates = this.useProductsTaxRates;
		exportModel.sureTaxLinesForInvoiceTaxesMapping = this.sureTaxLinesMappingForInvoiceTaxes;
		exportModel.sureTaxByStateLinesForInvoiceTaxesMapping = this.sureTaxByStateLinesMappingForInvoiceTaxes;
		exportModel.groupTaxByStateForInvoiceTaxes = this.groupTaxByStateForInvoiceTaxes;
		exportModel.uniteProducts = this.uniteProducts;
		exportModel.selectedPaymentTermId = this.isUseInvoiceDueDate ? this.selectedPaymentTermId : 0;
		exportModel.selectedEntityTypeId = this.selectedEntityTypeId;
		exportModel.selectedProductIdForAdjustmentLine = this.allowAdjustmentLine ? this.adjustmentLineProduct : undefined;

		exportModel.tag = this.cacheModel.Tag;
		this.repository.Export(exportModel)
			.pipe(takeUntil(this.unsubscribe), finalize(() => this.loading = false))
			.subscribe({
				next: result => {
					if (result.operationId) {
						this.navigator.toOperation(result.operationId);
					}
				}
			});
	}

	public CheckStep(value: number): string {
		return this.currentStep === value ? 'steps--active' : '';
	}

	NextStep(): void {
		if (this.currentStep < 8) {
			this.currentStep = this.currentStep + 1;
		}
	}

	PreviosStep(): void {
		if (this.currentStep > 1) {
			this.currentStep = this.currentStep - 1;
		}
	}

	backTo(): void {
		const backUrl = (this.cacheModel.Tag.split(':').pop()).slice(2, -2);
		void this.router.navigate([backUrl]);
	}

	onExportConfirm(): void {
		this.isExported = false;
		this.exportedInvoices = [];
		this.GroupingTypeSelected(true);
	}
}
