import { Injectable } from '@angular/core';
import { HttpAuth } from 'core/auth';
import { InvoiceActionsService } from 'shared/modules/invoice-actions';
import { ToasterService } from 'core/toaster-service';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
	ContractMappingResponse, ExportModel,
	InvoiceLineMappingResponse,
	SurchargeMappingResponse,
	SurchargeWithTaxMappingResponse,
	TaxLineMappingResponse,
	TaxLineStateMappingResponse,
	TaxMappingResponse,
	TransactionMappingResponse
} from 'domain/entities/halo';

import { Invoice } from 'domain/entities';
import { AppConfig } from 'core/app-config';
import { ApiData, ApiOperation, Guid } from 'domain/types';
import { HaloChargesExportModel, HaloExportContractsPrepareResponse, HaloExportDataPrepareResponse } from 'domain/models/halo-export';

@Injectable({
	providedIn: 'root'
})
export class HaloRepository {
	constructor(
		private readonly http: HttpAuth,
		private readonly invoiceService: InvoiceActionsService,
		private readonly toasterService: ToasterService,
		private readonly config: AppConfig
	) {
	}

	public contractsPrepare(
		invoices: Guid[],
		isForce: boolean,
		excluded?: Invoice[],
		filterParams?: any): Observable<ContractMappingResponse> {

		const model = {
			invoices: invoices,
			excludedInvoices: excluded.map(x => x.id),
			selectedAll: this.invoiceService.selectedAll,
			force: isForce
		};
		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/halo/invoices-multiline/contracts-prepare`,
			model, { params: filterParams })
			.pipe(
				map(res => {
					return new ContractMappingResponse().deserialize(res.body.data);
				}));
	}

	public dataPrepare(groupType: number, invoices: number[]): Observable<any> {

		const model = {
			groupType: groupType,
			invoices: invoices
		};
		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/halo/invoices-multiline/data-prepare`, model, null)
			.pipe(
				map(res => res.body.data),
				map(data => {
					const invoiceLinesResponses = data.invoiceLinesResponses ?
						data.invoiceLinesResponses.map(x => new InvoiceLineMappingResponse().deserialize(x)) :
						null;
					const transactionResponses = data.transactionResponses.map(x => new TransactionMappingResponse().deserialize(x));
					const surchargeResponses = data.surchargeResponses.map(x => new SurchargeMappingResponse().deserialize(x));
					const surchargesWithTaxesResponses = data.surchargesWithTaxesResponses.map(x => new SurchargeWithTaxMappingResponse().deserialize(x));
					const totalTaxesResponses = data.totalTaxesResponses.map(x => new TaxMappingResponse().deserialize(x));
					const taxesLinesResponses = data.taxesLinesResponses.map(x => new TaxLineMappingResponse().deserialize(x));
					const taxesLinesStateResponses = data.taxesLinesStateResponses.map(x => new TaxLineStateMappingResponse().deserialize(x));

					const responseModel = {
						invoiceLinesResponses: invoiceLinesResponses,
						surchargeResponses: surchargeResponses,
						surchargesWithTaxesResponses: surchargesWithTaxesResponses,
						totalTaxesResponses: totalTaxesResponses,
						taxesLinesResponses: taxesLinesResponses,
						transactionResponses: transactionResponses,
						taxesLinesStateResponses: taxesLinesStateResponses
					};

					return responseModel;
				}), catchError(er => {
					return throwError(() => er);
				}));
	}

	public export(model: ExportModel): Observable<any> {
		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/halo/invoices-multiline/export`, model, null)
			.pipe(map(res => res.body.data));
	}

	async haloChargesContractsPrepare(
		invoices: Guid[],
		force: boolean,
		excludedInvoices?: Guid[],
		filterParams?: Object
	): Promise<HaloExportContractsPrepareResponse> {
		const model = {
			invoices,
			excludedInvoices,
			force,
			selectedAll: this.invoiceService.selectedAll,
		};

		const res = await this.http.promise().post<ApiData<HaloExportContractsPrepareResponse>>(`${this.config.apiUrl}${this.config.apiVersion}/halo/charges/contracts-prepare`, model, { params: filterParams });
		return res.body?.data;
	}

	async haloChargesDataPrepare(groupType: number, invoices: number[], destroy$?: Subject<void>): Promise<HaloExportDataPrepareResponse> {
		const model = {
			groupType: groupType,
			invoices: invoices
		};

		const res = await this.http.promise(destroy$).post<ApiData<HaloExportDataPrepareResponse>>(`${this.config.apiUrl}${this.config.apiVersion}/halo/charges/data-prepare`, model);
		return res.body?.data;
	}

	async exportHaloCharges(model: HaloChargesExportModel, destroy$?: Subject<void>): Promise<ApiOperation> {
		const response = await this.http.promise(destroy$).post<ApiData<ApiOperation>>(`${this.config.apiUrl}${this.config.apiVersion}/halo/charges/export`, model);
		return response.body?.data;
	}
}
