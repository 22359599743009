import { Injectable } from '@angular/core';

import {
	HttpEvent,
	HttpInterceptor,
	HttpHandler,
	HttpRequest,
	HttpResponse
} from '@angular/common/http';
import { concatMap, filter, interval, Observable, of, takeUntil, takeWhile } from 'rxjs';
import { Guid } from 'domain/types';
import { AppConfig } from 'core/app-config';
import { HttpAuth } from 'core/auth';
import { PollingControlService } from 'services';

const POLLING_INTERVAL_MS = 5000;

@Injectable()
export class BackgroundPollingInterceptor implements HttpInterceptor {

	constructor(
		private readonly http: HttpAuth,
		private readonly config: AppConfig,
		private readonly pollingControlService: PollingControlService
	) {
	}
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			concatMap(event => {
				if (event instanceof HttpResponse) {
					if (event.status === 202 && event.body?.backgroundTaskId) {
						// Start long polling and return its observable
						return this.startPolling(event.body.backgroundTaskId);
					}
				}
				// For other cases, just pass through the event
				return of(event);
			})
		);
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	startPolling(requestId: Guid): Observable<HttpEvent<any>> {
		return interval(POLLING_INTERVAL_MS).pipe(
			takeUntil(this.pollingControlService.stopPolling$), // Stop polling when signaled
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			concatMap(() => this.http.get<HttpEvent<any>>(`${this.config.apiUrl}${this.config.apiVersion}/background-polling/${requestId}`)),
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			takeWhile((response: HttpResponse<any>) => response.status === 204, true), // Keep polling while status is 204, include the final emission
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			filter((response: HttpResponse<any>) => response.status !== 204), // Filter out the 204 responses
			concatMap((response: HttpResponse<any>) => {
				// For status 200 or any other response, wrap in an Observable and stop polling
				return of(response);
			})
		);
	}
}
