import { Serializable } from 'domain/models';
import { Guid } from 'domain/types';

export class CustomReportTypes implements Serializable<CustomReportTypes> {
	id: Guid;
	reportType: string;
	template: string;

	deserialize(json: any): CustomReportTypes {

		this.id = json.id;
		this.reportType = json.reportType;
		this.template = json.template;

		return this;
	}
}

export class CustomReport implements Serializable<CustomReport> {
	id: Guid;
	reportName: string;
	reportType: string;
	template: string;

	deserialize(json: any): CustomReport {

		this.id = json.id;
		this.reportName = json.reportName;
		this.reportType = json.reportType;
		this.template = json.template;

		return this;
	}
}

export class CustomReportTemplate implements Serializable<CustomReportTemplate> {
	id: Guid;
	reportType: string;
	template: string;

	deserialize(json: any): CustomReportTemplate {

		this.id = json.id;
		this.reportType = json.reportType;
		this.template = json.template;

		return this;
	}
}

export class CustomReportScheduler implements Serializable<CustomReportScheduler> {
	id: Guid;
	emailTemplateId: Guid;
	name: string;
	settings: ScheduleSettings;

	deserialize(json: any): CustomReportScheduler {

		this.id = json.id;
		this.emailTemplateId = json.emailTemplateId;
		this.name = json.name;
		this.settings = json.settings;

		return this;
	}
}

export interface ScheduleSettings  {
	reports: ScheduleReports[];
	recipients: ScheduleRecipients[];
	triggers: ScheduleTriggers[];
}

export interface ScheduleReports {
	reportName: string;
	reportId: Guid;
}

export interface ScheduleRecipients {
	type: 'Customer' | 'Group' | 'Email' | '';
	customer?: CustomerRecipient;
	customerGroup?: CustomerGroupRecipient;
	email?: EmailRecipient;
}

export interface CustomerRecipient {
	customerName: string;
	customerId: Guid;
}

export interface CustomerGroupRecipient {
	groupName: string;
	groupId: number;
}

export interface EmailRecipient {
	email: string;
}

export interface ScheduleTriggers {
	type: 'Event' | 'Date';
	event: string;
	date?: {
		[keys: string]: {
			repeats: number;
			weekly: TriggerDateRepeatsWeekly;
			monthly: TriggerDateRepeatsMonthly;
			yearly: TriggerDateRepeatsYearly;
		};
	};
}

export interface TriggerDateRepeatsWeekly {
	at: string;
	on: number;
}

export interface TriggerDateRepeatsMonthly {
	at: string;
	dayOfMonth: number;
}

export interface TriggerDateRepeatsYearly {
	at: string;
	date: Date;
}
