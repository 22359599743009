<div *ngIf="form" class="page-tile__body page-tile__body--hidden-none height__auto" [formGroup]="form">
	<div class="page-tile__title">
		{{'dg-tax-details.details-title' | transloco}}
	</div>

	<!-- if Avalara for Communication NOT integrated form start-->
	<div *ngIf="!isAvalaraCommunication && !isCereTax" class="page-tile__group" [ngClass]="{'ng-select-disabled-tr': disable}">
		<label class="page-tile__group-label" for="txtTaxCode">{{'dg-tax-details.details-fields_suretaxTranCodeId' |
			transloco}}</label>
		<ng-select bindLabel="code" bindValue="id" labelForId="txtTaxCode" name="code" formControlName="suretaxTranCodeId"
			[compareWith]="suretaxTranCodesCompareFn" [items]="suretaxTranCodes">
			<ng-template ng-option-tmp let-item="item">
				{{item.code}} <br />
				<small>{{item.description}}</small>
			</ng-template>
		</ng-select>
	</div>
	<!-- if Avalara for Communication NOT integrated form start-->

	<!-- if Avalara for Communication integrated form start-->
	<div *ngIf="isAvalaraCommunication" class="page-tile__group" [ngClass]="{'ng-select-disabled-tr': disable}">
		<label class="page-tile__group-label" for="ddSaleType">
			{{customer ? ('dg-tax-details.details-fields_avalaraSaleType' | transloco) : 'dg-tax-details.details-fields_avalaraSaleType-isCustomer' | transloco}}
			<i class="fa fa-asterisk" aria-hidden="true"></i>
		</label>
		<ng-select labelForId="ddSaleType" name="saleType" formControlName="avalaraSaleType" [required]="true"
		[clearable]="false">
			<ng-option [value]="0">{{'dg-tax-details.details-fields_avalaraSaleType-opt-1' | transloco}}</ng-option>
			<ng-option [value]="1">{{'dg-tax-details.details-fields_avalaraSaleType-opt-2' | transloco}}</ng-option>
			<ng-option [value]="2">{{'dg-tax-details.details-fields_avalaraSaleType-opt-3' | transloco}}</ng-option>
			<ng-option [value]="3">{{'dg-tax-details.details-fields_avalaraSaleType-opt-4' | transloco}}</ng-option>
		</ng-select>
	</div>

	<fieldset *ngIf="customer && isAvalaraCommunication">
		<div class="page-tile__group">
			<label class="page-tile__group-label" for="ddCustomerType">
				{{'dg-tax-details.details-fields_avalaraCustomerType' | transloco}}
			</label>
			<ng-select labelForId="ddCustomerType" name="customerType" formControlName="avalaraCustomerType"
				[clearable]="false">
				<ng-option [value]="0">{{'dg-tax-details.details-fields_avalaraCustomerType-opt-1' | transloco}}</ng-option>
				<ng-option [value]="1">{{'dg-tax-details.details-fields_avalaraCustomerType-opt-2' | transloco}}</ng-option>
				<ng-option [value]="2">{{'dg-tax-details.details-fields_avalaraCustomerType-opt-3' | transloco}}</ng-option>
				<ng-option [value]="3">{{'dg-tax-details.details-fields_avalaraCustomerType-opt-4' | transloco}}</ng-option>
			</ng-select>
		</div>

		<div class="page-tile__group">
			<label class="page-tile__group-label">
				{{'dg-tax-details.details-fields_avalaraLifeline' | transloco}}
			</label>
			<div class="page-tile__group-input page-tile__group-input--border-none">
				<dg-checkbox name="lifeline" formControlName="avalaraLifeline" ngDefaultControl [id]="'cbxLifeline'">
				</dg-checkbox>
			</div>
		</div>
		<div class="page-tile__group">
			<label class="page-tile__group-label">
				{{'dg-tax-details.details-fields_avalaraIncorporated' | transloco}}
			</label>
			<div class="page-tile__group-input page-tile__group-input--border-none">
				<dg-checkbox name="incorporated" formControlName="avalaraIncorporated" ngDefaultControl
					[id]="'cbxIncorporated'">
				</dg-checkbox>
			</div>
		</div>
		<div class="page-tile__group page-tile__group--multiple-h">
			<label class="page-tile__group-label" for="ddTaxExemptions">
				{{'dg-tax-details.details-fields_avalaraTaxExemptions' | transloco}}
			</label>
			<ng-select labelForId="ddTaxExemptions" bindLabel="name" bindValue="id" name="ddTaxExemptions"
				formControlName="avalaraTaxExemptions" [items]="taxExemptions" [compareWith]="taxExemptionsCompareFn" [multiple]="true" [closeOnSelect]="false">
			</ng-select>
		</div>
	</fieldset>

	<fieldset *ngIf="product && isAvalaraCommunication">
		<div class="page-tile__group" [ngClass]="{'ng-select-disabled-tr': disable}">
			<label class="page-tile__group-label" for="txtTaxCode">
				{{'dg-tax-details.details-fields_suretaxTranCodeId-product' | transloco}}
			</label>
			<ng-select bindLabel="code" bindValue="id" labelForId="txtTaxCode" name="code" formControlName="suretaxTranCodeId"
				[compareWith]="suretaxTranCodesCompareFn" [items]="suretaxTranCodes">
				<ng-template ng-option-tmp let-item="item">
					{{item.code}} <br />
					<small>{{item.description}}</small>
				</ng-template>
			</ng-select>
		</div>

		<div *ngIf="interfaceType === 2 || interfaceType === 3" class="page-tile__group"
			[ngClass]="{'ng-select-disabled-tr': disable}">
			<label class="page-tile__group-label" for="txtSauId">
				{{'dg-tax-details.details-fields_avalaraSalesAndUseId' | transloco}}
			</label>
			<ng-select bindLabel="name" bindValue="id" labelForId="txtSauId" name="txtSauId"
				formControlName="avalaraSalesAndUseId" [items]="avalaraSalesAndUses">
				<ng-template ng-option-tmp let-item="item">
					{{item.name}} <br />
					<small>{{item.group}}</small>
				</ng-template>
			</ng-select>
		</div>
	</fieldset>
	<!-- if Avalara for Communication integrated form end-->

	<fieldset *ngIf="customer && isCereTax">
		<div class="page-tile__group">
			<label class="page-tile__group-label" for="ceretaxBusinessTypeId">
				{{'dg-tax-details.details-fields_ceretaxBusinessTypeId' | transloco}}
			</label>
			<ng-select labelForId="ceretaxBusinessTypeId" name="ceretaxBusinessTypeId" formControlName="ceretaxBusinessTypeId"
				[clearable]="false">
				<ng-option [value]="1">{{'dg-tax-details.details-fields_ceretaxBusinessTypeId-opt-1' | transloco}}</ng-option>
				<ng-option [value]="2">{{'dg-tax-details.details-fields_ceretaxBusinessTypeId-opt-2' | transloco}}</ng-option>
				<ng-option [value]="3">{{'dg-tax-details.details-fields_ceretaxBusinessTypeId-opt-3' | transloco}}</ng-option>
				<ng-option [value]="4">{{'dg-tax-details.details-fields_ceretaxBusinessTypeId-opt-4' | transloco}}</ng-option>
				<ng-option [value]="5">{{'dg-tax-details.details-fields_ceretaxBusinessTypeId-opt-5' | transloco}}</ng-option>
				<ng-option [value]="6">{{'dg-tax-details.details-fields_ceretaxBusinessTypeId-opt-6' | transloco}}</ng-option>
				<ng-option [value]="7">{{'dg-tax-details.details-fields_ceretaxBusinessTypeId-opt-7' | transloco}}</ng-option>
				<ng-option [value]="8">{{'dg-tax-details.details-fields_ceretaxBusinessTypeId-opt-8' | transloco}}</ng-option>
			</ng-select>

		</div>
		<div class="page-tile__group">
			<label class="page-tile__group-label" for="ceretaxCustomerTypeId">
				{{'dg-tax-details.details-fields_ceretaxCustomerTypeId' | transloco}}
			</label>
			<ng-select labelForId="ceretaxCustomerTypeId" name="ceretaxCustomerTypeId" formControlName="ceretaxCustomerTypeId"
				[clearable]="false">
				<ng-option [value]="1">{{'dg-tax-details.details-fields_ceretaxCustomerTypeId-opt-1' | transloco}}</ng-option>
				<ng-option [value]="2">{{'dg-tax-details.details-fields_ceretaxCustomerTypeId-opt-2' | transloco}}</ng-option>
				<ng-option [value]="3">{{'dg-tax-details.details-fields_ceretaxCustomerTypeId-opt-3' | transloco}}</ng-option>
				<ng-option [value]="4">{{'dg-tax-details.details-fields_ceretaxCustomerTypeId-opt-4' | transloco}}</ng-option>
				<ng-option [value]="5">{{'dg-tax-details.details-fields_ceretaxCustomerTypeId-opt-5' | transloco}}</ng-option>
				<ng-option [value]="6">{{'dg-tax-details.details-fields_ceretaxCustomerTypeId-opt-6' | transloco}}</ng-option>
				<ng-option [value]="7">{{'dg-tax-details.details-fields_ceretaxCustomerTypeId-opt-7' | transloco}}</ng-option>
				<ng-option [value]="8">{{'dg-tax-details.details-fields_ceretaxCustomerTypeId-opt-8' | transloco}}</ng-option>
				<ng-option [value]="9">{{'dg-tax-details.details-fields_ceretaxCustomerTypeId-opt-9' | transloco}}</ng-option>
				<ng-option [value]="10">{{'dg-tax-details.details-fields_ceretaxCustomerTypeId-opt-10' | transloco}}</ng-option>
				<ng-option [value]="11">{{'dg-tax-details.details-fields_ceretaxCustomerTypeId-opt-11' | transloco}}</ng-option>
				<ng-option [value]="12">{{'dg-tax-details.details-fields_ceretaxCustomerTypeId-opt-12' | transloco}}</ng-option>
			</ng-select>
		</div>
			<div class="page-tile__group">
			<label class="page-tile__group-label" for="ceretaxSellerTypeId">
				{{'dg-tax-details.details-fields_ceretaxSellerTypeId' | transloco}}
			</label>
			<ng-select labelForId="ceretaxSellerTypeId" name="ceretaxSellerTypeId" formControlName="ceretaxSellerTypeId"
				[clearable]="false">
				<ng-option [value]="1">{{'dg-tax-details.details-fields_ceretaxSellerTypeId-opt-1' | transloco}}</ng-option>
			</ng-select>
		</div>

		<div class="page-tile__group page-tile__group--multiple-h">
			<label class="page-tile__group-label" for="ceretaxTaxExemptions">
				{{'dg-tax-details.details-fields_ceretaxTaxExemptions' | transloco}}
			</label>
			<ng-select
				labelForId="ceretaxTaxExemptions"
				bindLabel="code"
				bindValue="id"
				name="ddTaxExemptions"
				formControlName="ceretaxTaxExemptions"
				[items]="ceretaxExemptions"
				[compareWith]="taxExemptionsCompareFn"
				[multiple]="true"
				[closeOnSelect]="false">
			</ng-select>
		</div>
	</fieldset>

	<fieldset *ngIf="product && isSureTax">
		<dg-tile-input formControlName="unitTypeCode" [id]="'txtUnitTypeCode'"
					   [label]="'dg-tax-details.details-fields_UnitTypeCode'" [isRequired]="false" [name]="'unitTypeCode'">
		</dg-tile-input>
		<dg-tile-select formControlName="suretaxRegCodeId" [id]="'txtRegCode'"
						[items]="suretaxRegCodes" [label]="'portal-integration-suretax.details-fields_regulatoryCodeId'" [bindLabel]="'description'"
						[bindValue]="'id'" [name]="'regCode'" [isDescription]="true" >
		</dg-tile-select>
		<dg-tile-select formControlName="exemptionCodeId" [id]="'txtExCode'"
						[items]="suretaxExemptionCodes" [label]="'customer-sites.details-fields_exemptionCodeId'" [bindLabel]="'description'"
						[bindValue]="'id'" [name]="'exCode'" [isDescription]="true">
		</dg-tile-select>
	</fieldset>
</div>
