export class IntegrationService {
	public static Wise = 'Wise';
	public static SureTax = 'SureTax';
	public static Authorize = 'Authorize';
	public static WebHooks = 'WebHooks';
	public static Census = 'Census';
	public static CsiTax = 'CsiTax';
	public static Xero = 'Xero';
	public static Ipnd = 'Ipnd';
	public static QuickBooks = 'Quickbooks Online';
	public static Avalara = 'Avalara';
	public static Stripe = 'Stripe';
	public static Quoter = 'Quoter';
	public static Pinch = 'Pinch';
	public static ItQuoter = 'ItQuoter';
	public static Moneris = 'Moneris';
	public static Kaseya = 'Kaseya';
	public static QuickBooksDesktop = 'Quickbooks Desktop';
	public static AvalaraCommunication = 'AvalaraCommunication';
	public static IPPay = 'IPPay';
	public static Autotask = 'Autotask';
	public static Halo = 'Halo';
	public static Nexio = 'Nexio';
	public static CereTax = 'CereTax';

	public getIntegrationUrl(value: string): string {
		const url = 'settings/integrations';

		switch (value) {
			case IntegrationService.Wise: {
				return `${url}` + '/connect-wise';
			}
			case IntegrationService.SureTax: {
				return `${url}` + '/sure-tax';
			}
			case IntegrationService.CsiTax: {
				return `${url}` + '/csi-tax';
			}
			case IntegrationService.AvalaraCommunication: {
				return `${url}` + '/avalara-communication';
			}
			case IntegrationService.IPPay: {
				return `${url}` + '/ippay';
			}
			case IntegrationService.QuickBooksDesktop: {
				return `${url}` + '/quickbooks-desktop';
			}
			default: {
				return `${url}` + '/' + value.toLowerCase();
			}
		}
	}

	static sortIntegrations(configured: any, available: any): void {
		configured.sort((a, b) => {
			return a.title > b.title ? 1 : -1;
		});

		available.sort((a, b) => {
			return a.title > b.title ? 1 : -1;
		});

		this.defineOtherPayment(configured, available);
	}

	static defineOtherPayment(configured: any, available: any): void {

		if (configured.some((i) =>
			i.alt === IntegrationService.Nexio ||
			i.alt === IntegrationService.Moneris ||
			i.alt === IntegrationService.Authorize ||
			i.alt === IntegrationService.Pinch ||
			i.alt === IntegrationService.Stripe ||
			i.alt === IntegrationService.IPPay)) {
			available.forEach(i => {
				// check if property exists
				if (i.isOtherPayment === false) {
					i.isOtherPayment = true;
				}
			});
		} else {
			const availableIntegrations = available.map((i) => i.alt);

			if (availableIntegrations.includes('Moneris') &&
				availableIntegrations.includes('Stripe') &&
				availableIntegrations.includes('Nexio') &&
				availableIntegrations.includes('Authorize') &&
				availableIntegrations.includes('Pinch') &&
				availableIntegrations.includes('IPPay')) {
				available.forEach(i => {
					// check if property exists
					if (i.isOtherPayment === true) {
						i.isOtherPayment = false;
					}
				});
			}
		}

	}
}
