import { HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpAuth } from 'core/auth';
import { InvoiceFormatType } from 'domain/enums';
import { Guid } from 'domain/types';
import { CustomerTransaction, InvoicesResponse, Invoicing, MiscellaneousInvoice } from 'domain/entities';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfig } from 'core/app-config';
import { CacheService } from 'services/cache.service';

@Injectable({
	providedIn: 'root'
})
export class InvoiceRepository {

	constructor(
		private readonly http: HttpAuth,
		private readonly cache: CacheService,
		private readonly config: AppConfig
	) {

	}

	getInvoicesParams(params: any): Observable<InvoicesResponse> {

		let filterParams = new HttpParams();

		filterParams = !params.customerName ? filterParams : filterParams.append('customerName', params.customerName);
		filterParams = !params.invoiceType ? filterParams : filterParams.append('invoiceType', params.invoiceType);
		filterParams = !params.paymentMethod ? filterParams : filterParams.append('paymentMethod', params.paymentMethod);
		filterParams = !params.invoiceNumber ? filterParams : filterParams.append('invoiceNumber', params.invoiceNumber);
		filterParams = !params.agreementName ? filterParams : filterParams.append('agreementName', params.agreementName);
		filterParams = !params.dueStart ? filterParams : filterParams.append('dueStart', params.dueStart);
		filterParams = !params.dueEnd ? filterParams : filterParams.append('dueEnd', params.dueEnd);
		filterParams = !params.periodStart ? filterParams : filterParams.append('periodStart', params.periodStart);
		filterParams = !params.periodEnd ? filterParams : filterParams.append('periodEnd', params.periodEnd);
		filterParams = !params.invoiceDateStart ? filterParams : filterParams.append('invoiceDateStart', params.invoiceDateStart);
		filterParams = !params.invoiceDateEnd ? filterParams : filterParams.append('invoiceDateEnd', params.invoiceDateEnd);
		filterParams = !params.accountManager ? filterParams : filterParams.append('accountManager', params.accountManager);
		filterParams = !params.order ? filterParams : filterParams.append('order', params.order);
		filterParams = !params.orderDirection ? filterParams : filterParams.append('orderDirection', params.orderDirection);
		filterParams = filterParams.append('page', params.page);

		filterParams = !params.status ? filterParams : filterParams.append('status', params.status);
		filterParams = !params.cwExported ? filterParams : filterParams.append('cwExported', params.cwExported);
		filterParams = !params.deliveryMethod ? filterParams : filterParams.append('deliveryMethod', params.deliveryMethod);
		filterParams = !params.group ? filterParams : filterParams.append('group', params.group);

		filterParams = filterParams.append('isArchived', params.isArchived);

		this.cache.set('invoices-action-filterParams', filterParams);

		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/invoices`, { params: filterParams })
			.pipe(map(res => res.body));
	}


	async downloadInvoice(id: Guid, format: InvoiceFormatType ): Promise<HttpResponse<Blob>> {
		const options = { responseType: 'blob' };
		const res = await this.http.promise().get(`${this.config.apiUrl}${this.config.apiVersion}/invoices/${id}?type=${format}`, options);

		return res;
	}

	getInvoicing(date: string): Observable<Invoicing[]> {

		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/invoicing/${date}`)
			.pipe(
				map(res => res.body.data.map(x => new Invoicing().deserialize(x))));
	}

	setIgnored(type: boolean, ids: Guid[]): Observable<HttpResponse<void>> {

		const model = {
			ignored: type,
			records: ids
		};

		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/invoicing/ignore`, model);
	}

	createMiscellaneousInvoice(invoice: MiscellaneousInvoice): Observable<HttpResponse<void>> {
		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/miscellaneous-invoices`, invoice);
	}

	updateMiscellaneousInvoice(invoice: MiscellaneousInvoice): Observable<HttpResponse<void>> {

		const payload = {
			customerId: invoice.customerId,
			invoiceNumber: invoice.invoiceNumber,
			transactions: invoice.transactions,
			date: invoice.date,
			dueDate: invoice.dueDate,
			invoiceId: invoice.invoiceId,
			billingSiteId: invoice.billingSiteId,
			shippingSiteId: invoice.shippingSiteId,
			shippingSiteCustomerId: invoice.shippingSiteCustomerId
		};

		return this.http.put(`${this.config.apiUrl}${this.config.apiVersion}/miscellaneous-invoices`, undefined, payload);
	}

	getMiscellaneousInvoiceTransactions(invoiceId: Guid): Observable<CustomerTransaction[]> {
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/miscellaneous-invoices/${invoiceId}/transactions`)
			.pipe(
				map(res => res.body.data.map(x => new CustomerTransaction().deserialize(x))));
	}

	exportProductTaxes(invoiceId: Guid): Observable<HttpResponse<Blob>> {
		const options = {
			responseType: 'blob'
		};

		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/invoices/${invoiceId}/products/export-taxes`, options);
	}
}
