import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { lastValueFrom, Observable, of, Subject, throwError } from 'rxjs';
import { map, catchError, mergeMap, takeUntil } from 'rxjs/operators';

import { AuthService } from './auth-service';
import { AuthHelper } from './auth-helper';
import { environment } from '@environments/environment';

interface IRequester<T> {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(url: string, body: any, options: any): Observable<HttpResponse<T>>;
}

interface IPromiseRequests {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	get<T = any>(url: string, options?: any): Promise<HttpResponse<T>>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	post<T = any>(url: string, body: any, options?: any): Promise<HttpResponse<T>>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	put<T = any>(url: string, body: any, options?: any): Promise<HttpResponse<T>>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	patch<T = any>(url: string, body: any, options?: any): Promise<HttpResponse<T>>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	delete<T = any>(url: string): Promise<HttpResponse<T>>;
}

@Injectable()
export class HttpAuth {
	constructor(
		private readonly http: HttpClient,
		private readonly authService: AuthService
	) {

	}

	private get isAuthenticatedAndExpired(): boolean | undefined {
		const authDetails = AuthHelper.getAuthDetails();

		return authDetails?.expired;
	}

	private get token(): string | undefined {

		const authDetails = AuthHelper.getAuthDetails();

		return authDetails?.token;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	get<T = any>(url: string, options?: any): Observable<HttpResponse<T>> {

		const extendOptions = this.extendOptions(options);

		return this.makeRequest<T>(
			(url, body, options) => this.http.get<T>(url, extendOptions), url, extendOptions, undefined);
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	post<T = any>(url: string, body: any, options?: any): Observable<HttpResponse<T>> {

		const extendOptions = this.extendOptions(options);
		extendOptions.headers = extendOptions.headers.set('Content-Type', 'application/json');

		return this.makeRequest(
			(url, body, options) => this.http.post<T>(url, body, extendOptions),
			url, extendOptions, body);
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	put<T = any>(url: string, options: any, body?: any): Observable<HttpResponse<T>> {

		const extendOptions = this.extendOptions(options);
		if (!extendOptions.headers.has('Content-Type') && !body) {
			extendOptions.headers = extendOptions.headers.set('Content-Type', 'application/json');
		}

		return this.makeRequest(
			(url, body, options) => this.http.put<T>(url, body, extendOptions),
			url, extendOptions, body);
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	patch<T = any>(url: string, body: any, options?: any): Observable<HttpResponse<T>> {

		const extendOptions = this.extendOptions(options);

		extendOptions.headers = extendOptions.headers.set('Content-Type', 'application/json');

		return this.makeRequest(
			(url, body, options) => this.http.patch<T>(url, body, extendOptions),
			url, extendOptions, body);

	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	delete<T = any>(url: string): Observable<HttpResponse<T>> {

		const extendOptions = this.extendOptions();

		return this.makeRequest(
			(url, body, options) => this.http.delete<T>(url, extendOptions),
			url, extendOptions, undefined);
	}

	promise(cancel$?: Subject<void>): IPromiseRequests {
		return {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			get: async <T = any>(url: string, options?: any): Promise<HttpResponse<T>> => {
				let observable = this.get<T>(url, options);
				if (cancel$) {
					observable = observable.pipe(takeUntil(cancel$));
				}
				const result = await lastValueFrom(observable, { defaultValue: { body: undefined, canceledRequest: url } });
				if ('canceledRequest' in result) {
					return Promise.reject({ canceledRequest: result.canceledRequest });
				}
				return result as HttpResponse<T>;
			},
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			post: async <T = any>(url: string, body: any, options?: any): Promise<HttpResponse<T>> => {
				let observable = this.post<T>(url, body, options);
				if (cancel$) {
					observable = observable.pipe(takeUntil(cancel$));
				}
				const result = await lastValueFrom(observable, { defaultValue: { body: undefined, canceledRequest: url } });
				if ('canceledRequest' in result) {
					return Promise.reject({ canceledRequest: result.canceledRequest });
				}
				return result as HttpResponse<T>;
			},
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			put: async <T = any>(url: string, body: any, options?: any): Promise<HttpResponse<T>> => {
				let observable = this.put<T>(url, options, body);
				if (cancel$) {
					observable = observable.pipe(takeUntil(cancel$));
				}
				const result = await lastValueFrom(observable, { defaultValue: { body: undefined, canceledRequest: url } });
				if ('canceledRequest' in result) {
					return Promise.reject({ canceledRequest: result.canceledRequest });
				}
				return result as HttpResponse<T>;
			},
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			patch: async <T = any>(url: string, body: any, options?: any): Promise<HttpResponse<T>> => {
				let observable = this.patch<T>(url, body, options);
				if (cancel$) {
					observable = observable.pipe(takeUntil(cancel$));
				}
				const result = await lastValueFrom(observable, { defaultValue: { body: undefined, canceledRequest: url } });
				if ('canceledRequest' in result) {
					return Promise.reject({ canceledRequest: result.canceledRequest });
				}
				return result as HttpResponse<T>;
			},
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			delete: async <T = any>(url: string): Promise<HttpResponse<T>> => {
				let observable = this.delete<T>(url);
				if (cancel$) {
					observable = observable.pipe(takeUntil(cancel$));
				}
				const result = await lastValueFrom(observable, { defaultValue: { body: undefined, canceledRequest: url } });
				if ('canceledRequest' in result) {
					return Promise.reject({ canceledRequest: result.canceledRequest });
				}
				return result as HttpResponse<T>;
			}
		};
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private extendOptions(options?: any): {
		headers?: HttpHeaders;
		observe: 'response';
	} {
		let extendOptions = options;
		if (!extendOptions) {
			extendOptions = {
				observe: 'response'
			};
		} else {
			extendOptions.observe = 'response';
		}

		if (!extendOptions.headers) {
			extendOptions.headers = new HttpHeaders({ 'Authorization': 'bearer ' + this.token });
		} else {
			extendOptions.headers = extendOptions.headers.set('Authorization', 'bearer ' + this.token);
		}

		return extendOptions;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private makeRequest<T = any>(requestAction: IRequester<T>, url: string, options: any, body?: any): Observable<HttpResponse<T>> {

		const timestamp = new Date().getUTCMinutes().toString() + '.' + new Date().getUTCSeconds().toString() + '.' + new Date().getUTCMilliseconds().toString();

		let canRequest = of(true);

		if (this.isAuthenticatedAndExpired) {
			canRequest = this.authService.refresh(timestamp).pipe(
				map(result => {
					if (!result)
						return false;

					// update token or the request as we just refreshed it
					options.headers = options.headers.set('Authorization', 'bearer ' + this.token);
					return true;
				})
			);
		} else if (this.isAuthenticatedAndExpired === undefined) {
			this.authService.clearAuthAndRedirectToLogin();
		}

		return canRequest.pipe(mergeMap(can => {
			if (can) {

				// first attempt of request
				return requestAction(url, body, options).pipe(
					catchError(initialError => {
					// first attempt errored
						if (initialError && (!initialError.status || initialError.status === 0)) {
							let errorMsg: string = undefined;
							if (environment.env === 'development-local') {
								errorMsg = initialError;
							} else {
								errorMsg = window.navigator.onLine
									? `Server is not responding. Please reload the page to try again.`
									: `Looks like you are Offline. Check your internet settings and reload the page.`;
							}
							return throwError(() => new Error(errorMsg));
						} else if (initialError && initialError.status === 401) {
							// looks like we have to refresh
							return this.authService.refresh(timestamp).pipe(
								mergeMap(success => {
									options.headers = options.headers.set('Authorization', 'bearer ' + this.token);
									// retry with new token
									return requestAction(url, body, options);
								}),
								catchError(refreshError => {
									if (refreshError && refreshError.status === 401) {
										// refresh failed and if have no options, prevent redirect for admin area
										if (!options.params) {
											setTimeout(() => {
												// redirect to login after microtask is finished, giving to observble stream chance to finish sequence
												this.authService.clearAuthAndRedirectToLogin();
											}, 0);
										}
									} else if (refreshError) {

										// removing token from UI
										if (!options.params) {
											setTimeout(() => {
												this.authService.clearAuthAndRedirectToLogin();
											}, 0);
										}
									}
									return throwError(() => refreshError);
								})
							);
						// Handle possible CloudFlare error codes
						} else if (initialError && [502, 504, 520, 521, 522, 523, 524].includes(initialError.status)) {
							const errorMsg = `We're currently experiencing some technical difficulties. Our team is aware and working to resolve this as quickly as possible. Please try again later.`;
							return throwError(() => new Error(errorMsg));
						}

						return throwError(() => initialError);
					}));
			} else {

				// refresh token return error, authentication failed
				// redirect to login
				this.authService.clearAuthAndRedirectToLogin();
				return throwError(() => new Error('need auth'));
			}
		}));
	}
}
