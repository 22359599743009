import * as Moment from 'moment';

import { Serializable } from 'domain/models';

export class Notification implements Serializable<Notification> {
	id: number;
	level: string;
	// url: string;
	title: string;
	message: string;
	date: Moment.Moment;
	seen: boolean;
	value: number;
	tag: any;
	operationId: number;
	operationTag: any;

	deserialize(json: any): Notification {

		this.id = json.id;
		this.level = json.level;
		// this.url = json.url;
		this.operationId = json.operationId;
		this.operationTag = json.operationTag;
		this.title = json.title;
		this.message = json.message;
		this.date = Moment(json.date);
		this.seen = json.seen;
		this.value = json.value;
		this.tag = json.tag;

		this.tag = null;
		if (json.tag) {
			this.tag = JSON.parse(json.tag);
		}

		return this;
	}

	get url() {
		if(this.operationTag){
			switch (this.level) {
			case 'success': return JSON.parse(this.operationTag).url;
			case 'progress': return `/operations/${this.operationId}`;
			}
		} else {
			return `/files`;
		}
	}
}
