import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpAuth } from 'core/auth';
import { CacheService } from 'services/cache.service';
import { BaseRepository } from './base-repository';
import { Permission } from 'domain/entities';
import { AppConfig } from 'core/app-config';
import { ApiData } from 'domain/types';

@Injectable({
	providedIn: 'root'
})
export class PermissionRepository extends BaseRepository {

	constructor(
		http: HttpAuth,
		config: AppConfig,
		cache: CacheService
	) {
		super(http, config, cache);
	}

	async getPermissions(destroy?: Subject<void>): Promise<Permission[]> {
		const res = await this.http.promise(destroy).get<ApiData<Permission[]>>(`${this.config.apiUrl}${this.config.apiVersion}/permissions`);
		return res.body.data.map(x => new Permission().deserialize(x));;
	}
}
