<div class="sidebar" [ngClass]="{'sidebar--open': open, 'sidebar--close': !open}">
	<div *ngIf="!hideButtonIsHidden" class="sidebar__title">
		<span>{{title | transloco}}</span>
		<button type="button" class="sidebar__hide" (click)="onCancelClicked()">{{'dg-slider.hide-btn_label' | transloco}} <i
				class="fa fa-chevron-right" aria-hidden="true"></i></button>
	</div>
	<div *ngIf="open">
		<ng-content></ng-content>

	</div>
</div>
