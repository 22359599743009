
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AppConfig } from 'core/app-config';
import { CustomerSite, ExemptionCode, IntegrationType, Rating } from 'domain/entities';
import { Guid } from 'domain/types';
import { CustomerRepository, RatingRepository, SureTaxCodeRepository } from 'repositories';
import { Subject, switchMap, takeUntil } from 'rxjs';
import { RetrieveResultData } from 'services/address-lookup.service';
import { Globals } from 'tools/globals';
import { permissions } from 'tools/permissions';
import { CustomerSiteService } from './customer-site.service';

@Component({
	selector: 'dg-customer-site',
	templateUrl: './dg-customer-site.component.html',
	styleUrls: ['./dg-customer-site.component.sass']
})

export class DgCustomerSiteComponent implements OnInit, OnDestroy {
	@Input() isModalView = false;
	@Output() saveClicked = new EventEmitter<CustomerSite>();
	@Output() resetClicked = new EventEmitter();
	@Output() backToClicked = new EventEmitter();

	private selectedEntityOrig: CustomerSite;
	public isCustomerSiteAdd = false;
	public form: UntypedFormGroup;
	public selectedEntity: CustomerSite;
	public exemptionCodes: ExemptionCode[] = [];
	public ratings: Rating[];
	public isDiscard = false;
	public suretaxTranCodeEnabled = false;
	public addressData: RetrieveResultData;
	public permissions = permissions;
	public isPostalRequired = false;
	public isCSI = false;
	public isAvalaraCommunication = false;
	public isSureTax = false;
	public isSaving$ = this.customerSiteService.isSaving$;

	private destroy$ = new Subject<void>();
	constructor(
		private readonly route: ActivatedRoute,
		private readonly fb: UntypedFormBuilder,
		private readonly customerRepository: CustomerRepository,
		private readonly ratingRepository: RatingRepository,
		private readonly customerSiteService: CustomerSiteService,
		private readonly sureTaxCodeRepository: SureTaxCodeRepository,
		private readonly globals: Globals,
		private readonly config: AppConfig
	) {
		this.sureTaxCodeRepository.getExemptionCode()
			.pipe(takeUntil(this.destroy$))
			.subscribe(exemptionCodes => this.exemptionCodes = exemptionCodes);

		this.isCSI = config.integrationSettings.hasTaxEngine(IntegrationType.Csi);
		this.isAvalaraCommunication = config.integrationSettings.hasTaxEngine(IntegrationType.AvalaraCommunications);
		this.isSureTax = config.integrationSettings.hasTaxEngine(IntegrationType.Suretax);
	}

	async ngOnInit(): Promise<void> {
		this.route.paramMap.pipe(
			switchMap(params => {
				const customerId = new Guid(params.get('customerId'));
				const customerSiteId = new Guid(params.get('customerSiteId'));

				return this.customerRepository.getCustomerSiteById(customerId, customerSiteId);
			})
		).subscribe(entity => {

			this.selectedEntityOrig = entity;

			if (this.selectedEntityOrig) {
				this.selectedEntity = this.selectedEntityOrig.clone();
				this.globals.customerSiteName = this.selectedEntity.name;
				this.globals.customerSiteId = this.selectedEntity.id;
				this.isDiscard = true;
			} else {
				this.isCustomerSiteAdd = true;
				this.selectedEntity = new CustomerSite();
				this.selectedEntityOrig = null;
			}

			this.form = this.createForm(this.selectedEntity);
		});

		this.ratings = await this.ratingRepository.getAllRating().withBusy();

		if (this.selectedEntityOrig) {
			this.onCountryChanged(this.selectedEntityOrig.country);
		}
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	private createForm(ent: CustomerSite): UntypedFormGroup {
		const form = this.fb.group({
			name: [ent.name, Validators.required],
			siteCode: ent.siteCode,
			address1: ent.address1,
			address2: ent.address2,
			city: ent.city,
			postalCode: ent.postalCode,
			country: ent.country,
			state: ent.state,
			cchCode: ent.cchCode,
			referenceCode: ent.referenceCode,
			externalReference: ent.externalReference,
			exemptionCodeId: ent.exemptionCodeId,
			exemptionCode: ent.exemptionCode,
			csiTaxExemptionCode: ent.csiTaxExemptionCode,
			csiTaxExemptionList: ent.csiTaxExemptionList,
			rateCardId: ent.rateCardId,
			incorporated: ent.incorporated,
			hideTaxes: ent.hideTaxes
		});
		form.controls['country'].valueChanges.subscribe(code => this.onCountryChanged(code));

		form.get('address1').valueChanges.subscribe(val => this.onAnyAddressChange(this.selectedEntity.address1, val));
		form.get('address2').valueChanges.subscribe(val => this.onAnyAddressChange(this.selectedEntity.address2, val));
		form.get('city').valueChanges.subscribe(val => this.onAnyAddressChange(this.selectedEntity.city, val));
		form.get('postalCode').valueChanges.subscribe(val => this.onAnyAddressChange(this.selectedEntity.postalCode, val));
		form.get('country').valueChanges.subscribe(val => this.onAnyAddressChange(this.selectedEntity.country, val));
		form.get('state').valueChanges
			.pipe(takeUntil(this.destroy$))
			.subscribe(val => this.onAnyAddressChange(this.selectedEntity.state, val));

		return form;
	}

	private onCountryChanged(code: string): void {
		if (code === 'US') {
			this.form.controls['postalCode'].setValidators([Validators.required]);
			this.isPostalRequired = true;
		} else {
			this.form.controls['postalCode'].clearValidators();
			this.isPostalRequired = false;
		}
		this.form.controls['postalCode'].updateValueAndValidity();
	}

	private mapFormToEntity(form: UntypedFormGroup, entity: CustomerSite): CustomerSite {

		const formValue = form.value;

		entity.name = formValue.name;
		entity.address1 = formValue.address1;
		entity.address2 = formValue.address2;
		entity.city = formValue.city;
		entity.postalCode = formValue.postalCode;
		entity.country = formValue.country;
		entity.state = formValue.state;
		entity.cchCode = formValue.cchCode;
		entity.referenceCode = formValue.referenceCode;
		entity.externalReference = formValue.externalReference;
		entity.exemptionCode = formValue.exemptionCode;
		entity.exemptionCodeId = formValue.exemptionCodeId;
		entity.incorporated = formValue.incorporated ? formValue.incorporated : false;

		if (entity.exemptionCodeId) {
			entity.exemptionCode = this.exemptionCodes.find(x => x.id === this.selectedEntity.exemptionCodeId).code;
		} else {
			entity.exemptionCode = null;
		}
		entity.csiTaxExemptionCode = formValue.csiTaxExemptionCode;
		entity.csiTaxExemptionList = formValue.csiTaxExemptionList;
		entity.rateCardId = formValue.rateCardId;
		entity.hideTaxes = formValue.hideTaxes;
		return entity;
	}

	private mapFormToValidationEntity(form: UntypedFormGroup, entity: CustomerSite): RetrieveResultData {
		const formValue = form.value;
		return {
			addressLine1: formValue.address1,
			addressLine2: formValue.address2,
			buildingFloorNumber: entity.buildingFloorNumber,
			buildingFloorNumberSuffix: entity.buildingFloorNumberSuffix,
			buildingFloorType: entity.buildingFloorType,
			buildingLocation: entity.buildingLocation,
			buildingProperty: entity.buildingProperty,
			city: formValue.city,
			country: formValue.country,
			locality: entity.locality,
			postalCode: formValue.postalCode,
			state: entity.state,
			streetFirstName: entity.streetFirstName,
			streetFirstSuffix: entity.streetFirstSuffix,
			streetFirstType: entity.streetFirstType,
			streetHouseFirstNumber: entity.streetHouseFirstNumber,
			streetHouseFirstNumberSuffix: entity.streetHouseFirstNumberSuffix,
			streetHouseSecondNumber: entity.streetHouseSecondNumber,
			streetHouseSecondNumberSuffix: entity.streetHouseSecondNumberSuffix,
			streetSecondName: entity.streetSecondName,
			streetSecondSuffix: entity.streetSecondSuffix,
			streetSecondType: entity.streetSecondType,
			unitFirstNumber: entity.unitFirstNumber,
			unitFirstSuffix: entity.unitFirstSuffix,
			unitSecondNumber: entity.unitSecondNumber,
			unitSecondSuffix: entity.unitSecondSuffix,
			unitType: entity.unitType
		};
	}

	resetForm(): void {
		this.form.markAsPristine();
		this.form.markAsUntouched();
		this.form.updateValueAndValidity();
	}

	private onAnyAddressChange(old: string, change: string): void {
		if (this.selectedEntity.validated && old !== change) {
			this.selectedEntity.validated = null;
		}
	}

	public backTo(): void {
		this.backToClicked.emit();
	}

	public onSubmit(): void {
		this.mapFormToEntity(this.form, this.selectedEntity);

		this.saveClicked.emit(this.selectedEntity);
	}

	public discard(): void {
		const ent = this.selectedEntityOrig.clone();
		if (ent) {
			this.form.patchValue({
				name: ent.name,
				address1: ent.address1,
				address2: ent.address2,
				referenceCode: ent.referenceCode,
				externalReference: ent.externalReference,
				city: ent.city,
				country: ent.country,
				state: ent.state,
				cchCode: ent.cchCode,
				postalCode: ent.postalCode,
				exemptionCode: ent.exemptionCode,
				exemptionCodeId: ent.exemptionCodeId,
				csiTaxExemptionCode: ent.csiTaxExemptionCode,
				csiTaxExemptionList: ent.csiTaxExemptionList,
				rateCardId: ent.rateCardId,
				incorporated: ent.incorporated,
				hideTaxes: ent.hideTaxes
			});
		}
		this.resetForm();
	}

	public onAddressData(result: RetrieveResultData): void {
		this.selectedEntity.buildingFloorNumber = result.buildingFloorNumber;
		this.selectedEntity.buildingFloorNumberSuffix = result.buildingFloorNumberSuffix;
		this.selectedEntity.buildingFloorType = result.buildingFloorType;
		this.selectedEntity.buildingLocation = result.buildingLocation;
		this.selectedEntity.buildingProperty = result.buildingProperty;
		this.selectedEntity.locality = result.locality;
		this.selectedEntity.streetFirstName = result.streetFirstName;
		this.selectedEntity.streetFirstSuffix = result.streetFirstSuffix;
		this.selectedEntity.streetFirstType = result.streetFirstType;
		this.selectedEntity.streetHouseFirstNumber = result.streetHouseFirstNumber;
		this.selectedEntity.streetHouseFirstNumberSuffix = result.streetHouseFirstNumberSuffix;
		this.selectedEntity.streetHouseSecondNumber = result.streetHouseSecondNumber;
		this.selectedEntity.streetHouseSecondNumberSuffix = result.streetHouseSecondNumberSuffix;
		this.selectedEntity.streetSecondName = result.streetSecondName;
		this.selectedEntity.streetSecondSuffix = result.streetSecondSuffix;
		this.selectedEntity.streetSecondType = result.streetSecondType;
		this.selectedEntity.unitFirstNumber = result.unitFirstNumber;
		this.selectedEntity.unitFirstSuffix = result.unitFirstSuffix;
		this.selectedEntity.unitSecondNumber = result.unitSecondNumber;
		this.selectedEntity.unitSecondSuffix = result.unitSecondSuffix;
		this.selectedEntity.unitType = result.unitType;
		this.selectedEntity.state = result.state;

		this.form.patchValue({
			address1: result.addressLine1,
			address2: result.addressLine2,
			country: result.country,
			city: result.city,
			postalCode: result.postalCode,
			state: result.state
		});

		this.selectedEntity.validated = true;
	}

	public onValidationAnswer(result: boolean): void {
		if (this.selectedEntity.validated !== result) {
			this.form.markAsDirty();
		}
		this.selectedEntity.validated = result;
	}

	public onValidationSubmit(): void {
		this.addressData = this.mapFormToValidationEntity(this.form, this.selectedEntity);
	}
}
