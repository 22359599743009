import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { Job } from 'domain/entities';
import { JobRepository } from 'repositories/job-repository';
import * as Moment from 'moment-timezone';
import { FileRepository } from 'repositories/file-repository';
import { HttpResponse } from '@angular/common/http';
import { saveAs } from 'file-saver-es';
import { JobType } from 'domain/enums';

@Component({
	selector: 'dg-modal-progress',
	templateUrl: './dg-modal-progress.component.html',
	styleUrls: ['./dg-modal-progress.component.sass']
})
export class DgModalProgressComponent implements OnChanges {
	@Input() modalTitle: string;
	@Input() modalClass: string;
	@Input() modalForm: string;
	@Input() open = true;
	@Input() operationId: number;
	@Output() close: EventEmitter<void> = new EventEmitter<void>();

	public job: Job;
	public jobStarted: string;
	public jobStarterHandler: any;
	public prevPercentDone: number;
	public percentComplete: number;

	constructor(private jobRepository: JobRepository,
		private fileRepository: FileRepository) {
	}

	public onBackgroundClicked(): void {
		this.close.emit();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.open.currentValue) {
			this.jobInit();
			this.update();
		}
	}

	jobInit(): void {
		this.jobStarted = '';
		this.percentComplete = 0;
	}

	jobStarter(job: Job): void {
		this.jobStarterHandler = setInterval(() => {
			this.jobStarted = Moment(this.job.dateStarted).from(Moment());
		}, 1000);
	}

	update(): void {
		const handle = setInterval(() => {
			if (this.operationId) {
				this.jobRepository.get(this.operationId, true).subscribe((job: Job) => {
					this.job = job;
					this.jobStarter(job);
					this.detectPercent(job);
					if (!this.job.isCompleted) {
						this.prevPercentDone = 0;
					} else {
						if (this.job.jobType === JobType.CustomerUsageExport || this.job.jobType === JobType.ServiceItemsExport) {

							const id = this.job.tag.fileid;
							const filename = this.job.tag.filename;
							this.fileRepository.downloadFile(id).subscribe((blob: HttpResponse<Blob>) => {
								saveAs(blob.body, `${filename}`);
								this.close.emit();
								clearInterval(this.jobStarterHandler);
							}, er => {
							});
							clearInterval(handle);
						}
					}
				});
			}
		}, 1000);
	}

	private detectPercent(job: Job): void {
		if (job.isCompleted || job.isFailed || job.isSucceded) {
			this.percentComplete = 100;
		} else if (job.percentComplete === null) {
			this.percentComplete = null;
		} else {
			this.percentComplete = job.percentComplete;
		}
	}
}
