import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { CustomerProduct } from 'domain/entities';

@Component({
	selector: 'dg-child-cell-double',
	template: `
	<a class="ag-cell__double" (click)="invokeParentMethod(params.data)">
        <span class="ag-cell__double-item">
          <div *ngIf="params.data.productKitId && !params.data.productLabel"
			class="tooltip tooltip--transaction-type grid__type-icon grid__type-icon--kit">
            <span>Product Kit</span>
          </div>
          {{params.data.label || params.data.productLabel}}
        </span>
		<span class="ag-cell__double-item_">
			<span>Code:</span>
			<span class="ag-cell__double-item"> {{params.data.code || params.data.productName}}</span>
        </span>
	</a>`
})
export class ChildCellProductCodeRendererComponent implements ICellRendererAngularComp {
	public params: any;

	agInit(params: any): void {
		this.params = params;
	}

	public invokeParentMethod(product: CustomerProduct): void {
		if (product.productKitId) {
			this.params.context.componentParent.methodFromParent(product.id, true);
		} else {
			this.params.context.componentParent.methodFromParent(product.id);
		}
	}

	refresh(): boolean {
		return false;
	}
}
