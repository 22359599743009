import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class CustomerSiteService {
	private isSavingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	isSaving$: Observable<boolean> = this.isSavingSubject.asObservable();
	constructor() {}

	onSaveAction(value: boolean): void {
		this.isSavingSubject.next(value);
	}
}

export class CacheModel {}
