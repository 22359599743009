import { Serializable } from 'domain/models';
import { EntityName } from 'tools/decorators';
import { Guid } from 'domain/types';

@EntityName('SureTax Code', 'SureTax Codes')
export class SureTaxCode implements Serializable<SureTaxCode> {
	id: Guid;
	code: string;
	description: string;
	glCode: string;
	glCodeId: Guid;
	interfaceType: number;
	inputType: number;
	isBundle: false;

	deserialize(json: any): SureTaxCode {

		this.id = json.id;
		this.code = json.code;
		this.description = json.description;
		this.glCode = json.glCode;
		this.glCodeId = json.glCodeId;

		this.interfaceType = json.interfaceType;
		this.inputType = json.inputType;
		this.isBundle = json.isBundle;

		return this;
	}
}

export class ExemptionCode implements Serializable<ExemptionCode> {
	id: number;
	code: string;
	description: string;

	deserialize(json: any): ExemptionCode {
		this.id = json.id;
		this.code = json.code;
		this.description = json.description;
		return this;
	}
}
