import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Job } from 'domain/entities';

@Component({
	selector: 'dg-operation-progress-bar',
	templateUrl: './operation-progress-bar.component.html',
	styleUrls: ['./operation-progress-bar.component.sass']
})
export class OperationProgressBarComponent implements OnChanges {

	public percentComplete: number;

	@Input() job: Job;

	constructor() {

	}
	ngOnChanges(changes: SimpleChanges): void {

		const job = changes.job.currentValue as Job;

		if (job) {
			this.detectPercent(job);
		}
	}

	private detectPercent(job: Job): void {

		if (job.isCompleted || job.isFailed || job.isSucceded) {
			this.percentComplete = 100;
		} else if (job.percentComplete === null) {
			this.percentComplete = null;
		} else {
			this.percentComplete = job.percentComplete;
		}
	}

}
