import { Component, ElementRef, ViewChild } from '@angular/core';
import flatpickr from 'flatpickr';
import * as Moment from 'moment-timezone';

@Component({
	selector: 'child-cell-dateRange',
	template: `
        <div #flatpickrEl class="ag-input-wrapper custom-date-filter">
            <input type='text' data-input />
        </div>`
})

export class CustomDateComponent {
	@ViewChild('flatpickrEl', { read: ElementRef, static: true }) flatpickrEl: ElementRef;
	private date: Date;
	private dateFrom: any;
	private dateTo: any;
	private params: any;
	private picker: any;

	agInit(params: any): void {
		this.params = params;
	}

	ngAfterViewInit(): void {
		this.picker = flatpickr(this.flatpickrEl.nativeElement, {
			onChange: this.onDateChanged.bind(this),
			wrap: true,
			mode: 'range'
		});
		this.picker.calendarContainer.classList.add('ag-custom-component-popup');
	}

	onDateChanged(selectedDates: any) {
		this.date = selectedDates[0] || null;

		if (selectedDates[0] && selectedDates[1]) {
			this.dateFrom = Moment(selectedDates[0]).format('YYYY-MM-DD 00:00:00');
			this.dateTo = Moment(selectedDates[1]).format('YYYY-MM-DD 23:59:59');

			filterParams['dateStart'] = this.dateFrom;
			filterParams['dateEnd'] = this.dateTo;

			filterParams['dateStartDates'] = Moment(selectedDates[0]).format('YYYY-MM-DD');
			filterParams['dateEndDates'] = Moment(selectedDates[1]).format('YYYY-MM-DD');
		}

		this.params.onDateChanged();
	}

	getDate(): Date {
		return this.date;
	}

	setDate(date: Date): void {
		this.date = null;

		if (this.params.filterParams) {
			const colId = this.params.filterParams.column.colId;
			let dates = '';

			const pickers: any = document.querySelectorAll('.flatpickr-input');
			const arrPickers: any = Array.from(pickers);

			if (filterParams['dateStartDates'] === filterParams['dateEndDates']) {
				dates = filterParams['dateStartDates'];
			} else {
				dates = filterParams['dateStartDates'] + ' to ' + filterParams['dateEndDates'];
			}

			if (colId === 'invoiceDateMoment' && arrPickers[0] && arrPickers[0].value === dates) {
				sessionStorage.setItem('invoicing-filter-invDate', JSON.stringify(filterParams));
			}

			if (colId === 'dateDueMoment' && arrPickers[1] && arrPickers[1].value === dates) {
				sessionStorage.setItem('invoicing-filter-due', JSON.stringify(filterParams));
			}

		}
	}
}

const filterParams: object = {
	dateStart: '',
	dateEnd: '',
	dateStartDates: '',
	dateEndDates: ''
};
