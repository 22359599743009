import { Injectable } from '@angular/core';
import { HttpAuth } from 'core/auth';
import { InvoiceTemplateEntity } from 'domain/entities';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfig } from 'core/app-config';

@Injectable({
	providedIn: 'root'
})
export class InvoiceTemplateRepository {
	constructor(
		private http: HttpAuth,
		private config: AppConfig
	) {

	}

	public getAll(): Observable<InvoiceTemplateEntity[]> {
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/invoice-templates`)
			.pipe(
				map(res => res.body.data.map(x => new InvoiceTemplateEntity().deserialize(x))));
	}
}
