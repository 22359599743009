<div *ngIf="open" class="modal-wrapper" [ngClass]="{'modal-wrapper--open': open, 'modal-wrapper--close': !open}">
	<div class="fade"></div>
	<div class="modal modal--progress">
		<div class="modal__content">
			<div class="modal__content-header">
        <span class="modal__content-header-closebtn" [title]="'dg-modal-progress.run-in-background_text' | transloco"
			  (click)="onBackgroundClicked()">&#10006;</span>
			</div>
			<div class="modal__content-body">
				<span class="modal__content-header-title">{{modalTitle | transloco}}</span>
				<div class="page__operation-progress">
					<progress
						id="prgComplete"
						class="container-process"
						max="100"
						value="{{percentComplete}}">
					</progress>
					<p class="process-message_gray">{{'dg-modal-progress.process_msg' | transloco: { jobStarted: jobStarted } }}</p>
				</div>
			</div>
			<div class="modal__content-footer">
				<button type="button" class="btn btn--primary" [title]="'dg-modal-progress.run-in-background_text' | transloco" (click)="onBackgroundClicked()">
					{{'dg-modal-progress.run-in-background_text' | transloco}}
				</button>
			</div>

		</div>
	</div>
</div>


