import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
	selector: 'dg-child-cell',
	template: `
		<a *ngIf="!params.data.typeId" (click)="invokeParentMethod(params.data.id)">{{params.value}}</a>
		<a *ngIf="params.data.typeId" (click)="invokeParentTwoMethod(params.data.id, params.data.typeId)">{{params.value}}</a>
	`
})
export class ChildLinkRenderer implements ICellRendererAngularComp {
	public params: any;

	agInit(params: any): void {
		this.params = params;
	}

	public invokeParentMethod(value: number): void {
		this.params.context.componentParent.methodFromParent(value);
	}

	public invokeParentTwoMethod(id: number, value: number): void {
		this.params.context.componentParent.methodFromParent(id, value);
	}

	refresh(): boolean {
		return false;
	}
}
