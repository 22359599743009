import { Serializable } from 'domain/models';
import { Guid } from 'domain/types';
import { Utils } from 'tools/utils';
import { Tag } from './tag-entities';

export class InvoiceTemplateEntity implements Serializable<InvoiceTemplateEntity> {
	id: Guid;
	resellerId: Guid;
	name: string;
	path: string;
	description: string;
	type: string;

	deserialize(input: any): InvoiceTemplateEntity {
		this.id = input.id;
		this.resellerId = input.resellerId;
		this.name = input.name;
		this.path = input.path;
		this.description = input.description;
		this.type = input.type;
		return this;
	}
}

export class Invoicing implements Serializable<Invoicing> {
	customerId: Guid;
	companyName: string;
	agreementId: Guid;
	agreementName: string;
	site: string;
	billingFrequency: string;
	lastInvoiceDate: Date;
	billingPeriodId: Guid;
	periodStart: any;
	periodEnd: Date;
	ignored: boolean;
	periodStartMoment: string;
	periodEndMoment: string;
	lastInvoiceDateMoment: string;
	customerGroup: string;
	public tags: Tag[];

	deserialize(input: any): Invoicing {
		this.customerId = input.customerId;
		this.companyName = input.companyName;
		this.agreementId = input.agreementId;
		this.agreementName = input.agreementName;
		this.site = input.site;
		this.billingFrequency = input.billingFrequency;
		this.lastInvoiceDate = input.lastInvoiceDate;
		this.billingPeriodId = input.billingPeriodId;
		this.periodStart = input.periodStart;
		this.periodEnd = input.periodEnd;
		this.ignored = input.ignored;

		this.periodStartMoment = Utils.toMomentUtcFormat(input.periodStart);
		this.periodEndMoment = Utils.toMomentUtcFormat(input.periodEnd);
		this.lastInvoiceDateMoment = Utils.toMomentUtcFormat(input.lastInvoiceDate);

		this.customerGroup = input.customerGroup;
		this.tags = input.tags;
		return this;
	}
}
