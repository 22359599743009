<div *ngIf="loading" class="app-spinner-qb"></div>
<form *ngIf="!loading" class="form form--export">
	<ul class="steps__wrapper">
		<li *ngIf="xeroBrandingThemes" class="steps {{CheckStep(1)}}">
			<span class="steps__label">{{'global-export.step1_label' | transloco}}</span>
			<span class="steps__label">{{'xero-export.step0_title' | transloco}}</span>
		</li>
		<li class="steps {{CheckStep(xeroBrandingThemes ? 2 : 1)}}">
			<span class="steps__label">{{'global-export.step' + (xeroBrandingThemes ? '2' : '1') + '_label' | transloco}}</span>
			<span class="steps__label">{{'xero-export.step1_title' | transloco}}</span>
		</li>
		<li class="steps {{CheckStep(xeroBrandingThemes ? 3 : 2)}}">
			<span class="steps__label">{{'global-export.step' + (xeroBrandingThemes ? '3' : '2') + '_label' | transloco}}</span>
			<span class="steps__label">{{'xero-export.step2_title' | transloco}}</span>
		</li>
	</ul>
	<div class="form__content">
		<fieldset class="form__section {{CheckStep(1)}}">
			<h3 class="form__section-title">
				{{'xero-export.step0_title' | transloco}}
			</h3>
			<form *ngIf="xeroBrandingThemes" #BrandingForm="ngForm">
				<div class="form__group block">
					<div [ngClass]="'form__group-item form__group-item--400'">
						<p>{{'xero-export.step1_header-invoice' | transloco}}</p>
						<p>{{'xero-export.step1_header-brandingTheme' | transloco}}</p>
					</div>
					<div *ngFor="let item of xeroBrandingThemes.brandingThemesMapping; let i = index"
						 [ngClass]="'form__group-item form__group-item--400'">
						<p>{{item.invoiceNumber}}</p>

						<ng-select bindLabel="name"
								   bindValue="brandingThemeID" name="selectedXeroBrandingThemeId{{i}}"
								   [compareWith]="xeroBrandingThemesCompareFn"
								   [items]="xeroBrandingThemes.brandingThemes" [searchable]="true" [clearable]="false" [(ngModel)]="item.selectedXeroBrandingThemeId">
						</ng-select>
					</div>
				</div>
				<div class="form__bottom form__bottom--border-none padding-b__150">
					<button type="button" class="btn btn--primary btn--import" (click)="backTo()">
						{{'global.export_btn-back' | transloco}}
					</button>
					<button type="button" class="btn btn--primary btn--import"
							[disabled]="!BrandingForm.valid"
							(click)="NextStep()">
						{{'global.export_btn-next' | transloco}}
						<i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
					</button>
				</div>
			</form>

		</fieldset>
		<fieldset class="form__section {{CheckStep(xeroBrandingThemes ? 2 : 1)}}">
			<h3 class="form__section-title">{{'xero-export.step1_title' | transloco}}</h3>
			<form #XeroInvoicesForm="ngForm">
				<div class="form__group block">
					<div class="form__group-item form__group-item--500 flex">
						<p>{{'xero-export.step1_field-accountCode' | transloco}}<i class="fa fa-asterisk" aria-hidden="true"></i></p>
						<ng-select #inputProduct name="xeroAccount" bindLabel="name" required
								   bindValue="code" [items]="xeroAccounts" [searchable]="true" [clearable]="false" [(ngModel)]="exportParams.accountCode">
							<ng-template>
								<input style="width: 100%; line-height: 24px" type="text"
									   (input)="inputProduct.filter($event.target.value)" />
							</ng-template>
						</ng-select>
						<button type="button" class="btn btn--default" [title]="'xero-export.refresh-btn_hint' | transloco" (click)="onExportConfirm()"><i class="fa fa-refresh"
							aria-hidden="true"></i>
						</button>
					</div>
					<div class="form__group-item form__group-item--500 flex-start">
						<p>{{'xero-export.step1_field-useGlCode' | transloco}}</p>
						<dg-checkbox name="useGlCode" ngDefaultControl [id]="'cbxUseGlCode'" [(ngModel)]="exportParams.useGlCode">
						</dg-checkbox>
					</div>
					<div class="form__group-item form__group-item--500">
						<p>{{'xero-export.step1_field-paymentCode' | transloco}}</p>
						<ng-select #inputProduct name="paymentCode" bindLabel="name" bindValue="code"
								   [items]="xeroPayments" [searchable]="true" [(ngModel)]="exportParams.paymentCode">
							<ng-template>
								<input style="width: 100%; line-height: 24px" type="text"
									   (input)="inputProduct.filter($event.target.value)" />
							</ng-template>
						</ng-select>
					</div>
					<div class="form__group-item form__group-item--500">
						<p>{{'xero-export.step1_field-invoiceStatus' | transloco}}</p>
						<ng-select name="invoiceStatus" [clearable]="false" [(ngModel)]="exportParams.invoiceStatus">
							<ng-option [value]="1">{{'xero-export.step1_field-invoiceStatus-opt-0' | transloco}}</ng-option>
							<ng-option [value]="4">{{'xero-export.step1_field-invoiceStatus-opt-1' | transloco}}</ng-option>
						</ng-select>
					</div>
					<div class="form__group-item form__group-item--500">
						<p>{{'xero-export.step1_field-reference' | transloco}}</p>
						<input class="form__group-input" autocomplete="new-password" id="txtReference" name="reference"
							   [(ngModel)]="exportParams.reference" />
					</div>
					<div class="form__group-item form__group-item--500 flex-start">
						<p>{{'xero-export.step1_field-sentToContact' | transloco}}</p>
						<dg-checkbox name="sentToContact" [id]="'cbxSentLink'" [(ngModel)]="exportParams.sentToContact">
						</dg-checkbox>
					</div>
				</div>
				<div class="form__bottom form__bottom--border-none">
					<button type="button" class="btn btn--primary btn--import" (click)="xeroBrandingThemes ? BackStep() : backTo()">
						{{'global.export_btn-back' | transloco}}
					</button>
					<button type="button" class="btn btn--primary btn--import"
							[disabled]="!XeroInvoicesForm.valid" (click)="NextStep()">
							{{'global.export_btn-next' | transloco}}
						<i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
					</button>
				</div>
			</form>
		</fieldset>

		<fieldset class="form__section {{CheckStep(xeroBrandingThemes ? 3 : 2)}}">
			<h3 class="form__section-title">
				{{'xero-export.step2_title' | transloco}}
			</h3>
			<form #TaxForm="ngForm">
				<div class="form__group block">
					<div class="form__group-item form__group-item--500 flex-start">
						<p>{{'xero-export.step2_field-xeroTaxMultiline' | transloco}}
							<i class="fa fa-question-circle tooltip tooltip--export_1" aria-hidden="true">
								<span>{{'xero-export.step2_field-xeroTaxMultiline-tooltip' | transloco}}</span>
							</i>
						</p>
						<dg-checkbox name="xeroTaxMultiline"
									 ngDefaultControl
									 [id]="'xeroTaxMultiline'"
									 [(ngModel)]="xeroTaxMultiline"></dg-checkbox>
					</div>
					<div *ngIf="xeroTaxMultiline" class="form__group-item form__group-item--500 flex-start">
						<p>{{'xero-export.step2_field-groupTaxByState' | transloco}}
							<i class="fa fa-question-circle tooltip tooltip--export_2" aria-hidden="true">
								<span>
									{{'xero-export.step2_field-groupTaxByState-tooltip' | transloco}}
								</span>
							</i>
						</p>
						<dg-checkbox name="groupTaxByState"
									 ngDefaultControl
									 [id]="'groupTaxByState'"
									 [(ngModel)]="groupTaxByState"></dg-checkbox>
					</div>
					<div *ngIf="xeroTaxMultiline && groupTaxByState">
						<div *ngFor="let line of xeroTaxLinesByState; let i = index"
							 class="form__group-item form__group-item--800">
							<p>{{line.taxTypeDesc}} {{line.stateCode}}</p>
							<ng-select #inputProduct
									   bindLabel="name"
									   required
									   bindValue="code"
									   name="selectedProductId{{i}}"
									   [items]="xeroAccounts"
									   [searchable]="true"
									   [clearable]="false"
									   [(ngModel)]="line.selectedAccountCode">
								<ng-template>
									<input style="width: 100%; line-height: 24px" type="text"
										   (input)="inputProduct.filter($event.target.value)"/>
								</ng-template>
							</ng-select>
						</div>
					</div>
					<div *ngIf="xeroTaxMultiline && !groupTaxByState">
						<div *ngFor="let line of xeroTaxLines; let i = index"
							 class="form__group-item form__group-item--800">
							<p>{{line.taxTypeDesc}}</p>
							<ng-select #inputProduct
									   bindLabel="name"
									   required
									   bindValue="code"
									   name="accountCode{{i}}"
									   [items]="xeroAccounts"
									   [searchable]="true"
									   [clearable]="false"
									   [(ngModel)]="line.selectedAccountCode">
								<ng-template>
									<input style="width: 100%; line-height: 24px" type="text"
										   (input)="inputProduct.filter($event.target.value)"/>
								</ng-template>
							</ng-select>
						</div>
					</div>
					<div *ngIf="!xeroTaxMultiline">
						<div *ngFor="let item of xeroTaxMapping; let i = index"
							 class="form__group-item form__group-item--500">
							<p>{{item.taxName}}</p>
							<ng-select #inputProduct
									   bindLabel="name"
									   bindValue="code"
									   required
									   name="accountCode{{i}}"
									   [items]="xeroAccounts"
									   [searchable]="true"
									   [clearable]="false"
									   [(ngModel)]="item.selectedAccountCode">
								<ng-template>
									<input style="width: 100%; line-height: 24px" type="text"
										   (input)="inputProduct.filter($event.target.value)"/>
								</ng-template>
							</ng-select>
						</div>
					</div>
				</div>

				<div class="form__bottom form__bottom--border-none">
					<button type="button" class="btn btn--primary btn--import"
							(click)="BackStep()">
							{{'global.export_btn-back' | transloco}}
					</button>
					<button type="button" class="btn btn--primary btn--import"
							[disabled]="!TaxForm.valid"
							(click)="Export()">
							{{'global.export_btn-export' | transloco}}
						<i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
					</button>
				</div>
			</form>
		</fieldset>
	</div>
</form>

<div *ngIf="isExported" class="modal modal--tile modal--confirmation">
	<div class="modal__content">
		<form class="modal__content-body">
			<div class="page-tile__body page-tile__body--hidden-none">
				<div class="page-tile__title">
					<b>
						{{'xero-export.confirmation_title' | transloco}}
					</b>
				</div>

				<div class="page-tile__content">
					<div class="page-tile__group">
						<span>
							{{'xero-export.confirmation_msg' | transloco}}
						</span>
					</div>
					<div class="page-tile__content-item" >
						<div *ngFor="let inv of exportedInvoices" class="page-tile__content-row">
							<i class="fa fa-circle" aria-hidden="true"></i>
							{{inv}}
						</div>
					</div>
				</div>

				<p class="page-tile__body-proceed"></p>

				<div class="form__bottom">
					<button type="button" class="btn btn--secondary width-100px" (click)="onExportConfirm()">
						{{'global.modals_btn-confirm' | transloco}}
					</button>
					<button type="button" class="btn btn--default width-100px" (click)="backTo()">
						{{'global.modals_btn-cancel' | transloco}}
					</button>
				</div>
			</div>
		</form>
	</div>
</div>

<div *ngIf="isExported" class="fade"></div>
