import { Invoice, InvoiceStatus } from 'domain/entities';
import { HashMap, TranslateParams, TranslocoService } from '@ngneat/transloco';

export class Labels {
	public t: <T = string>(key: TranslateParams, params?: HashMap, lang?: string) => T;
	constructor(
		protected transloco: TranslocoService
	) {
		this.t = transloco.translate.bind(transloco);
	}
	static statusToLabel(invoice: Invoice): string {
		let result = '';

		if (invoice) {
			switch (invoice.status) {
				case InvoiceStatus.None:
					result = 'None';
					break;
				case InvoiceStatus.Generating:
					result = 'Generating';
					break;
				case InvoiceStatus.Generated:
					result = 'Generated';
					break;
				case InvoiceStatus.Failed:
					result = 'Gen. Failed';
					break;
				case InvoiceStatus.Emailed:
					result = 'Emailed';
					break;
				case InvoiceStatus.Sent:
					result = 'Sent';
					break;
				case InvoiceStatus.Deleted:
					result = 'Deleted';
					break;
				case InvoiceStatus.Sending:
					result = 'Sending';
					break;
				case InvoiceStatus.SendFailed:
					result = 'Send Failed';
					break;
				case InvoiceStatus.SentManually:
					result = 'Sent Manually';
					break;
				case InvoiceStatus.Paid:
					result = 'Paid';
					break;
				case InvoiceStatus.PaidFailed:
					result = 'Paid Failed';
					break;
				case InvoiceStatus.PaidManually:
					result = 'Paid Manually';
					break;
				case InvoiceStatus.PartiallyPaid:
					result = 'Paid Partially';
					break;
				case InvoiceStatus.Uninvoiced:
					result = 'Uninvoiced';
					break;
				case InvoiceStatus.Draft:
					result = 'Draft';
					break;
				case InvoiceStatus.PartiallyPaidManually:
					result = 'Partially Paid Manually';
			}
		}

		return result;
	}

	static statusToLabelClass(invoice: Invoice): string {
		let labelClass = 'label';

		if (invoice) {
			if (invoice.status === InvoiceStatus.Generated) {
				labelClass = 'label label--warning label--status';
			} else if (
				invoice.status === InvoiceStatus.Paid ||
				invoice.status === InvoiceStatus.PaidManually
			) {
				labelClass = 'label label--success-custom label--status';
			} else if (
				invoice.status === InvoiceStatus.Sent ||
				invoice.status === InvoiceStatus.SentManually ||
				invoice.status === InvoiceStatus.PartiallyPaid ||
				invoice.status === InvoiceStatus.PartiallyPaidManually
			) {
				labelClass = 'label label--success label--status';
			} else if (
				invoice.status === InvoiceStatus.Failed ||
				invoice.status === InvoiceStatus.PaidFailed ||
				invoice.status === InvoiceStatus.SendFailed) {
				labelClass = 'label label--error label--status';
			} else if (invoice.status === InvoiceStatus.Draft) {
				labelClass = 'label label--grey label--status';
			} else if (invoice.status === InvoiceStatus.Generating) {
				labelClass = 'label label--default label--status';
			}
		}

		return labelClass;
	}
}
