<dg-alert></dg-alert>
<dg-dialog></dg-dialog>
<section class="main-side">
	<aside class="side-menu" [ngClass]="{'side-menu--open': openNav, 'side-menu--close': !openNav}">
		<ul class="side-menu__list">
			<li class="side-menu__item" (click)="toggleSideMenu()">
				<i class="fa fa-bars"></i>
			</li>
			<li class="side-menu__item">
				<a class="side-menu__item-link" routerLink="/dashboard"
					routerLinkActive="side-menu__item--active" [title]="'layout-main-menu.labels_dashboard' | transloco">
					<i class="fa fa-tachometer" aria-hidden="true"></i>
					{{'layout-main-menu.labels_dashboard' | transloco}}
				</a>
			</li>
			<li *ngxPermissionsOnly="[permissions.ViewCustomers]" class="side-menu__item">
				<a class="side-menu__item-link" routerLink="/customers"
					routerLinkActive="side-menu__item--active" [title]="'layout-main-menu.labels_customers' | transloco">
					<i class="fa fa-user-o" aria-hidden="true"></i>
					{{'layout-main-menu.labels_customers' | transloco}}
				</a>
			</li>
			<li *ngxPermissionsOnly="[permissions.Invoicing]" class="side-menu__item">
				<a class="side-menu__item-link" routerLink="/invoicing"
					routerLinkActive="side-menu__item--active" [title]="'layout-main-menu.labels_invoicing' | transloco">
					<i class="icon-invoice-dollar" aria-hidden="true"></i>
					{{'layout-main-menu.labels_invoicing' | transloco}}
				</a>
			</li>
			<li *ngxPermissionsOnly="[permissions.ViewProductTemplates]" class="side-menu__item">
				<a class="side-menu__item-link" routerLink="/product-templates"
					routerLinkActive="side-menu__item--active" [title]="'layout-main-menu.labels_product-templates' | transloco">
					<i class="fa fa-book" aria-hidden="true"></i>
					{{'layout-main-menu.labels_product-templates' | transloco}}
				</a>
			</li>
			<li *ngxPermissionsOnly="[permissions.ViewProductKits]" class="side-menu__item">
				<a class="side-menu__item-link" routerLink="/product-kits"
					routerLinkActive="side-menu__item--active" [title]="'layout-main-menu.labels_kit-templates' | transloco">
					<i class="fa fa-cubes" aria-hidden="true"></i>
					{{'layout-main-menu.labels_kit-templates' | transloco}}
				</a>
			</li>
			<li *ngxPermissionsOnly="[permissions.ViewServiceItems]" class="side-menu__item">
				<a class="side-menu__item-link" routerLink="/service-items"
					routerLinkActive="side-menu__item--active" [title]="'layout-main-menu.labels_service-items' | transloco">
					<i class="fa fa-qrcode" aria-hidden="true"></i>
					{{'layout-main-menu.labels_service-items' | transloco}}
				</a>
			</li>

			<ng-container *ngIf="isITQuoter || isQuoter">
				<li 
					*ngxPermissionsOnly="[permissions.ViewExternalQuotes]" 
					class="side-menu__item side-menu__item--disable-hover overflow-auto">
					<a class="side-menu__item-link flex-between"
						[title]="'layout-main-menu.labels_external-quotes' | transloco"
						(click)="onToggleQuotesDropDown()">
						<i class="fa fa-cogs" aria-hidden="true"></i>
						{{'layout-main-menu.labels_external-quotes' | transloco}}
						<i class="fa margin-l__5" aria-hidden="true" [ngClass]="{'fa-chevron-down': !isQuotesDropDown, 'fa-chevron-up': isQuotesDropDown}"></i>
					</a>

					<ul class="side-menu__list-child" [ngClass]="{'side-menu__list-child--active': isQuotesDropDown, '': !isQuotesDropDown}">
						<li *ngIf="isQuoter" class="side-menu__item">
							<a class="side-menu__item-link" routerLink="/external-quotes/quoter/quotes" 
								routerLinkActive="side-menu__item--active" 
								[title]="'layout-main-menu.labels_external-quotes-quoter' | transloco">
								{{'layout-main-menu.labels_external-quotes-quoter' | transloco}}
							</a>
						</li>
						<li *ngIf="isITQuoter" class="side-menu__item">
							<a class="side-menu__item-link" routerLink="/external-quotes/it-quoter/quotes"
								routerLinkActive="side-menu__item--active" 
								[title]="'layout-main-menu.labels_external-quotes-it-quoter' | transloco">
								{{'layout-main-menu.labels_external-quotes-it-quoter' | transloco}}
							</a>
						</li>
					</ul>
				</li>
			</ng-container>

			<li *ngxPermissionsOnly="[permissions.ViewCustomerRates]" class="side-menu__item">
				<a class="side-menu__item-link" routerLink="/rating" routerLinkActive="side-menu__item--active"
					[title]="'layout-main-menu.labels_rating' | transloco">
					<i class="fa fa-usd" aria-hidden="true"></i>
					{{'layout-main-menu.labels_rating' | transloco}}
				</a>
			</li>
			<li *ngxPermissionsOnly="[permissions.ManageBundles]" class="side-menu__item">
				<a class="side-menu__item-link" routerLink="/bundles" routerLinkActive="side-menu__item--active"
					[title]="'layout-main-menu.labels_bundles' | transloco">
					<i class="fa fa-database" aria-hidden="true"></i>
					{{'layout-main-menu.labels_bundles' | transloco}}
				</a>
			</li>
			<li *ngxPermissionsOnly="[permissions.ManageUsersAndGroups]" class="side-menu__item">
				<a class="side-menu__item-link" routerLink="/reseller-users"
					routerLinkActive="side-menu__item--active" [title]="'layout-main-menu.labels_reseller-users' | transloco">
					<i class="fa fa-users" aria-hidden="true"></i>
					{{'layout-main-menu.labels_reseller-users' | transloco}}
				</a>
			</li>
			<li *ngxPermissionsOnly="[permissions.ViewFiles]" class="side-menu__item">
				<a class="side-menu__item-link" routerLink="/files" routerLinkActive="side-menu__item--active"
					[title]="'layout-main-menu.labels_files' | transloco">
					<i class="fa fa-file" aria-hidden="true"></i>
					{{'layout-main-menu.labels_files' | transloco}}
				</a>
			</li>
			<li *ngxPermissionsOnly="[permissions.CustomReports]"
				class="side-menu__item side-menu__item--disable-hover overflow-auto">
				<a class="side-menu__item-link flex-between" [title]="'layout-main-menu.labels_reports' | transloco"
					(click)="onToggleReportDropDown()">
					<i class="fa fa-file-text-o" aria-hidden="true"></i>
					{{'layout-main-menu.labels_reports' | transloco}}
					<i class="fa" aria-hidden="true"
						[ngClass]="{'fa-chevron-down': !isReportDropDown, 'fa-chevron-up': isReportDropDown}"></i>
				</a>
				<ul class="side-menu__list-child"
					[ngClass]="{'side-menu__list-child--active': isReportDropDown, '': !isReportDropDown}">
					<li class="side-menu__item">
						<a class="side-menu__item-link" routerLink="/reports"
							routerLinkActive="side-menu__item--active" [title]="'layout-main-menu.labels_reports-standard' | transloco">
							{{'layout-main-menu.labels_reports-standard' | transloco}}
						</a>
					</li>
					<li class="side-menu__item">
						<a class="side-menu__item-link" routerLink="/reporting"
							routerLinkActive="side-menu__item--active" [title]="'layout-main-menu.labels_reports-custom' | transloco">
							{{'layout-main-menu.labels_reports-custom' | transloco}}
						</a>
					</li>
				</ul>
			</li>
			<li *ngIf="isAutoBilling" class="side-menu__item">
				<a class="side-menu__item-link" routerLink="/auto-billing" routerLinkActive="side-menu__item--active"
					[title]="'layout-main-menu.labels_jobs' | transloco">
					<i class="fa fa-tasks" aria-hidden="true"></i>
					{{'layout-main-menu.labels_jobs' | transloco}}
				</a>
			</li>
			<li *ngxPermissionsOnly="[permissions.ImportData]" class="side-menu__item">
				<a class="side-menu__item-link" routerLink="/import" routerLinkActive="side-menu__item--active"
					[title]="'layout-main-menu.labels_import' | transloco">
					<i class="fa fa-file-archive-o" aria-hidden="true"></i>
					{{'layout-main-menu.labels_import' | transloco}}
				</a>
			</li>
			<!-- <li  class="side-menu__item">
				<a class="side-menu__item-link" routerLink="/bulk-update" routerLinkActive="side-menu__item--active"
					[title]="'layout-main-menu.labels_bulk-update' | transloco">
					<i class="fa fa-pencil-square-o" aria-hidden="true"></i>
					{{'layout-main-menu.labels_bulk-update' | transloco}}
				</a>
			</li> -->

			<li *ngxPermissionsOnly="[permissions.ManageCustomerPortal]" class="side-menu__item">
				<a class="side-menu__item-link" routerLink="/customer-portal"
					routerLinkActive="side-menu__item--active" [title]="'layout-main-menu.labels_customer-portal' | transloco">
					<i class="fa fa-paint-brush" aria-hidden="true"></i>
					{{'layout-main-menu.labels_customer-portal' | transloco}}
				</a>
			</li>
			<li *ngxPermissionsOnly="[permissions.ViewFeeds, permissions.ManageFeeds]" class="side-menu__item">
				<a class="side-menu__item-link" routerLink="/feeds" routerLinkActive="side-menu__item--active"
					[title]="'layout-main-menu.labels_feeds' | transloco">
					<i class="fa fa-rss" aria-hidden="true"></i>
					{{'layout-main-menu.labels_feeds' | transloco}}
				</a>
			</li>
			<ng-container *ngIf="isIpnd">
				<li *ngxPermissionsOnly="[permissions.ManageIpnd]" class="side-menu__item">
					<a class="side-menu__item-link" routerLink="/ipnd-files"
						routerLinkActive="side-menu__item--active" [title]="'layout-main-menu.labels_ipnd' | transloco">
						<i class="side-menu_ipnd-icon" aria-hidden="true"></i>
						{{'layout-main-menu.labels_ipnd' | transloco}}
					</a>
				</li>
			</ng-container>
		</ul>
	</aside>
	<article class="side-menu__content"
		[ngClass]="{'side-menu__content--open': openNav, 'side-menu__content--close': !openNav}">
		<dg-header></dg-header>
		<router-outlet></router-outlet>
	</article>
</section>
