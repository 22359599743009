import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ProductRatePlanCharge } from 'domain/entities';

@Component({
	selector: 'dg-child-cell-charges',
	template: `
	<div *ngIf="this.params.data.productRatePlans[0]">
		<div *ngFor="let item of this.params.data.productRatePlans[0].charges; let i = index" class="charge__row-table"
		(click)="invokeParentMethod(params.data)">
			<div class="charge__row-group-table">
				<div class="charge__row-item">
					{{item.name}}
				</div>
				<div class="charge__row-item" *ngIf="item.chargeTypeId === 1">
					{{item.sell}} (Sell) x {{item.quantity}} (Qty)
				</div>
				<div class="charge__row-item" *ngIf="item.chargeTypeId === 2">
					{{item.amount}} <span *ngIf="item.scopeTypeId === 1">(Customer Level)</span>
				</div>
				<div class="charge__row-item" *ngIf="item.chargeTypeId === 3">
					{{item.amountPercentage}}% <span *ngIf="item.scopeTypeId === 1">(Customer Level)</span>
				</div>
			</div>
		</div>
	</div>`
})
export class ChildCellChargesRenderer implements ICellRendererAngularComp {
	public params: any;

	agInit(params: any): void {
		this.params = params;
		if (this.params.data.productRatePlans[0]) {
			this.params.data.productRatePlans[0].charges.forEach((ch: ProductRatePlanCharge)  => {
				if (!ch.units) return;

				const currentUnit = ch.units.find(u => u.current) || ch.units[0];

				ch.cost = currentUnit.cost;
				ch.sell = currentUnit.sell;
				ch.quantity = currentUnit.quantity;
				ch.amountPercentage = currentUnit.amountPercentage;
				ch.amount = currentUnit.amount;
			});
		}
	}

	public invokeParentMethod(value: any): void {
		if (value.productKitId) {
			this.params.context.componentParent.methodFromParent(value.id, true);
		} else {
			this.params.context.componentParent.methodFromParent(value.id || value.productId);
		}
	}

	refresh(): boolean {
		return false;
	}
}
