import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { HttpAuth } from 'core/auth';
import { Tag } from 'domain/entities';
import { map } from 'rxjs/operators';
import { AppConfig } from 'core/app-config';
import { BaseRepository } from './base-repository';
import { CacheService } from 'services/cache.service';
import { ApiData, Guid } from 'domain/types';

@Injectable({
	providedIn: 'root'
})
export class TagRepository extends BaseRepository {

	constructor(
		http: HttpAuth,
		config: AppConfig,
		cache: CacheService
	) {

		super(http, config, cache);
	}

	/**
	 *  @deprecated DO NOT USE - will be removed. Use Promise instead
	 */
	public getTags$(): Observable<Tag[]> {

		const observable =
			this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/tags`)
				.pipe(
					map(res => res.body.data.map(x => new Tag().deserialize(x))));

		return observable;
	}

	async getTags(destroy$?: Subject<void>): Promise<Tag[]> {

		const res = await this.http.promise(destroy$).get<ApiData<Tag[]>>(`${this.config.apiUrl}${this.config.apiVersion}/tags`);
		return res.body.data.map(x => new Tag().deserialize(x));
	}

	public saveTags(customerId: Guid, tags: Tag[]): Observable<any> {
		const observable =
			this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/tags/${customerId}/values`, tags)
				.pipe(
					map(res => res));

		return observable;
	}
}
