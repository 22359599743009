import { Component, OnInit, EventEmitter, SimpleChanges, Output, Input, OnChanges } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { CeretaxTaxExemption } from 'domain/models';
import { Guid } from 'domain/types';
import {
	AvalaraTaxExemption,
	CustomerDetails,
	CustomerProduct,
	SureTaxCode,
	AvalaraSalesAndUse,
	ProductTemplate, SureTaxRegCodeEntity, ExemptionCode, CustomerTransaction, KitProduct,
	CustomerProductKitProduct
} from 'domain/entities';
import { IntegrationRepository } from 'repositories';


@Component({
	selector: 'dg-tax-details',
	templateUrl: './dg-tax-details.component.html',
	styleUrls: ['./dg-tax-details.component.sass']
})

export class DgTaxDetailsComponent implements OnInit, OnChanges {
	form: UntypedFormGroup;
	@Input() customer: CustomerDetails;
	@Input() product: CustomerProduct | CustomerTransaction | KitProduct | CustomerProductKitProduct;
	@Input() productTempate: ProductTemplate;
	@Input() taxExemptions: AvalaraTaxExemption[];
	@Input() suretaxTranCodes: SureTaxCode[];
	@Input() suretaxRegCodes: SureTaxRegCodeEntity[];
	@Input() suretaxExemptionCodes: ExemptionCode[];
	@Input() disable = false;
	@Input() isAvalaraCommunication = false;
	@Input() isCereTax = false;
	@Input() isSureTax = false;
	@Output() taxDetails: EventEmitter<void> = new EventEmitter<void>();
	@Output() onFormTouch: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() isTaxDetailsValid: EventEmitter<boolean> = new EventEmitter<boolean>();
	public interfaceType: number;
	public avalaraSalesAndUses: AvalaraSalesAndUse[];
	public ceretaxExemptions: CeretaxTaxExemption[];

	constructor(private readonly fb: UntypedFormBuilder,
		private readonly integrationRepo: IntegrationRepository) {
	}

	async ngOnInit(): Promise<void> {
		if (this.isAvalaraCommunication) {
			this.integrationRepo.getAvalaraSalesAndUse().subscribe(sau => this.avalaraSalesAndUses = sau);
			this.form = this.createAvalaraCommunicationForm();
		} else if (this.isCereTax) {
			this.ceretaxExemptions = await this.integrationRepo.getCeretaxTaxExemptions().withBusy();
			this.form = this.createCeretaxForm();
		} else {
			this.form = this.createForm(this.product);
		}

		setTimeout(() => this.onFormValid(this.form), 1);
	}

	suretaxTranCodesCompareFn(item: SureTaxCode, selected: Guid): boolean {
		return item.id.equals(selected);
	}

	ngOnChanges(changes: SimpleChanges): void {
		// provide trsancation code from product template

		if (changes.productTempate) {
			if (!changes.productTempate.currentValue) return;
			this.form.patchValue({
				suretaxTranCodeId: changes.productTempate.currentValue.transactionCodeId,
				unitTypeCode: changes.productTempate.currentValue.unitTypeCode,
				suretaxRegCodeId: changes.productTempate.currentValue.regulatoryCodeId,
				exemptionCodeId: changes.productTempate.currentValue.exemptionCodeId
			});
		}
	}

	public createAvalaraCommunicationForm(): UntypedFormGroup {
		let form = this.fb.group({});

		if (this.customer) {
			const ent = this.customer;
			const avalaraTaxExemptions = ent.avalaraTaxExemptions?.map(item => item.id);

			form = this.fb.group({
				avalaraSaleType: [ent.avalaraSaleType, Validators.required],
				avalaraCustomerType: ent.avalaraCustomerType,
				avalaraIncorporated: ent.avalaraIncorporated,
				avalaraLifeline: ent.avalaraLifeline,
				avalaraTaxExemptions: new UntypedFormControl(avalaraTaxExemptions)
			});
		} else {
			const ent = this.product;
			form = this.fb.group({
				avalaraSaleType: [ent.avalaraSaleType, Validators.required],
				suretaxTranCodeId: ent.transactionCodeId,
				avalaraSalesAndUseId: ent.avalaraSalesAndUseId,
				unitTypeCode: ent.unitTypeCode,
				suretaxRegCodeId: ent.regulatoryCodeId,
				exemptionCodeId: ent.exemptionCodeId
			});
			this.suretaxTranCode(ent.transactionCodeId);
			form.get('suretaxTranCodeId').valueChanges.subscribe((suretaxTranCodeId) => this.suretaxTranCode(suretaxTranCodeId));
		}

		form.valueChanges.subscribe(() => this.mapFormToEntity(form, this.customer, this.product));

		return form;
	}

	public createCeretaxForm(): UntypedFormGroup {
		const ent = this.customer;

		const ceretaxExemptions = ent.ceretaxTaxExemptions?.map(item => item.id);

		const form = this.fb.group({
			ceretaxBusinessTypeId: [ent.ceretaxBusinessTypeId ?? 1, Validators.required],
			ceretaxCustomerTypeId: [ent.ceretaxCustomerTypeId ?? 2, Validators.required],
			ceretaxSellerTypeId: [ent.ceretaxSellerTypeId ?? 1, Validators.required],
			ceretaxTaxExemptions: new UntypedFormControl(ceretaxExemptions)
		});

		form.valueChanges.subscribe(() => this.mapCeretaxFormToEntity(form, this.customer));

		return form;
	}


	public createForm(ent: CustomerProduct | CustomerTransaction | KitProduct | CustomerProductKitProduct): UntypedFormGroup {
		const form = this.fb.group({
			suretaxTranCodeId: ent.transactionCodeId,
			unitTypeCode: ent.unitTypeCode,
			suretaxRegCodeId: ent.regulatoryCodeId,
			exemptionCodeId: ent.exemptionCodeId
		});
		this.suretaxTranCode(ent.transactionCodeId);
		form.get('suretaxTranCodeId').valueChanges.subscribe((suretaxTranCodeId) => this.suretaxTranCode(suretaxTranCodeId));

		form.valueChanges.subscribe(() => this.mapFormToEntity(form, this.customer, this.product));

		return form;
	}

	public taxExemptionsCompareFn(item: AvalaraTaxExemption | CeretaxTaxExemption, selected: Guid): boolean {
		return item.id.equals(selected);
	}

	private suretaxTranCode(id: Guid): void {
		if (!id) return;

		this.product.transactionCodeId = id;
		// in case for old trancastion codes
		this.interfaceType = this.suretaxTranCodes.some(tax => tax.id.equals(id)) ? this.suretaxTranCodes.find(tax => tax.id.equals(id)).interfaceType : 0;
	}

	private mapCeretaxFormToEntity(form: UntypedFormGroup, ent: any): void {
		const formValue = form.value;

		ent.ceretaxBusinessTypeId = formValue.ceretaxBusinessTypeId;
		ent.ceretaxCustomerTypeId = formValue.ceretaxCustomerTypeId;
		ent.ceretaxSellerTypeId = formValue.ceretaxSellerTypeId;
		ent.ceretaxTaxExemptions = formValue.ceretaxTaxExemptions?.map(id => ({ id }));

		this.taxDetails.emit(ent);
		this.onFormTouch.emit(true);


		this.onFormValid(this.form);
	}

	private mapFormToEntity(form: UntypedFormGroup, customer?: CustomerDetails, product?: any): void {
		const ent = customer || product;
		const formValue = form.value;

		ent.avalaraSaleType = formValue.avalaraSaleType;

		if (customer && this.isAvalaraCommunication) {
			ent.avalaraCustomerType = formValue.avalaraCustomerType;
			ent.avalaraIncorporated = formValue.avalaraIncorporated;
			ent.avalaraLifeline = formValue.avalaraLifeline;
			ent.avalaraTaxExemptions = formValue.avalaraTaxExemptions?.map(id => ({ id }));
		} else {
			ent.transactionCodeId = formValue.suretaxTranCodeId;
			ent.avalaraSalesAndUseId = formValue.avalaraSalesAndUseId >= 0 ? formValue.avalaraSalesAndUseId : null;
			ent.unitTypeCode = formValue.unitTypeCode;
			ent.regulatoryCodeId = formValue.suretaxRegCodeId;
			ent.exemptionCodeId = formValue.exemptionCodeId;
		}

		this.taxDetails.emit(ent);
		this.onFormTouch.emit(true);
		this.onFormValid(this.form);
	}

	private onFormValid(form: UntypedFormGroup): boolean {
		if (form.status === 'VALID') {
			this.isTaxDetailsValid.emit(true);
		}
		return false;
	}
}
