import { Component } from '@angular/core';

@Component({
	selector: 'dg-page',
	templateUrl: './dg-page.component.html',
	styleUrls: ['./dg-page.component.scss']
})

export class DgPageComponent {
}
