import { Serializable } from 'domain/models';
import { EntityName } from 'tools/decorators';
import { Guid } from 'domain/types';

@EntityName('Tax Rate', 'Tax Rates')
export class TaxRate implements Serializable<TaxRate> {
	id: Guid;
	displayName: string;
	taxRateTypeId: Guid;
	rate: number;
	startDate: string;
	endDate: string;
	isDefault: boolean;
	deserialize(json: any): TaxRate {

		this.id = json.id;
		this.taxRateTypeId = json.taxRateTypeId;
		this.rate = json.rate;
		this.startDate = json.startDate;
		this.endDate = json.endDate;
		this.displayName = json.displayName;
		this.isDefault = json.isDefault;
		return this;
	}

	clone(): TaxRate {
		return Object.assign(new TaxRate(), this);
	}

	restore(src: TaxRate): void {
		Object.assign(this, src);
	}
}

export class TaxRateType implements Serializable<TaxRateType> {
	id: Guid;
	displayName: string;
	description: string;
	isDefault: boolean;
	taxCategoryCode: string;

	deserialize(json: any): TaxRateType {

		this.id = json.id;
		this.displayName = json.displayName;
		this.description = json.description;
		this.isDefault = json.isDefault;
		this.taxCategoryCode = json.taxCategoryCode;
		return this;
	}

	clone(): TaxRateType {
		return Object.assign(new TaxRateType(), this);
	}

	restore(src: TaxRateType): void {
		Object.assign(this, src);
	}
}
