type EqualitySelector<T, U> = (item: T) => U;
type EqualityComparer<T> = (first: T, second: T) => boolean;

declare interface Array<T> {
	flatMap<U>(callbackfn: (value: T, index: number) => U[]): U[];
	groupBy<U>(keySelector: (value: T) => U, comparer?: EqualityComparer<U>): Map<U, T[]>;
	unique<U>(equality?: EqualitySelector<T, U>): T[];
	union<U>(second: T[], equality?: EqualitySelector<T, U>): T[];
	except<U>(second: T[], equality?: EqualitySelector<T, U>): T[];
	intersection(second: T[]): T[];
	intersectionWith(second: T[], comparer: EqualityComparer<T>): T[];
	equals<U>(second: T[], equality?: EqualitySelector<T, U>): boolean;
	orderBy<K>(keySelector: (item: T) => K, descending?: boolean): T[];
}

Array.prototype.flatMap = function (callbackfn) {
	return (<any>this.map(callbackfn)).reduce((a, b) => a.concat(b), []);
};

Array.prototype.groupBy = function (callbackfn, comparer) {
	return this.reduce(function (acc, item) {
		const key = callbackfn(item);

		if (!acc.has(key)) acc.set(key, [item]);
		else acc.get(key).push(item);

		return acc;
	}, comparer ? new EqMap(comparer) : new Map());
};

Array.prototype.unique = function (equalityPropFunc) {
	return !equalityPropFunc
		? this.filter((v, i, a) => a.indexOf(v) === i)
		: this.filter((v, i, a) => a.map(equalityPropFunc).indexOf(equalityPropFunc(v)) === i);
};

Array.prototype.union = function (second, equalityPropFunc) {
	const propFunc = equalityPropFunc || ((x) => x);
	const result = this.slice(0);
	second.forEach(function (secondItem) {
		if (result.findIndex((firstItem) => propFunc(firstItem) === propFunc(secondItem)) < 0) {
			result.push(secondItem);
		}
	});
	return result;
};

Array.prototype.except = function (second, equalityPropFunc) {
	if (equalityPropFunc) {
		return this.filter(firstItem =>
			!second.find(secondItem => equalityPropFunc(firstItem) === equalityPropFunc(secondItem)));
	} else {
		return this.filter(firstItem => second.findIndex(secondItem => firstItem === secondItem) < 0);
	}
};

Array.prototype.intersection = function (second) {
	return Array.from(new Set(this.filter(firstItem => second.includes(firstItem))));
};

Array.prototype.intersectionWith = function (second, comparer) {
	return Array.from(new Set(this.filter(firstItem => second.findIndex(secondItem => comparer(firstItem, secondItem)) >= 0)));
};

Array.prototype.equals = function (second, equalityPropFunc) {
	// if the other array is a falsy value, return
	if (!second)
		return false;

	// compare lengths - can save a lot of time
	if (this.length !== second.length)
		return false;

	const firstSorted = this.slice().sort();
	const secondSorted = second.slice().sort();

	const propFunc = equalityPropFunc || ((x) => x);

	for (let i = 0, l = firstSorted.length; i < l; i++) {
		// Check if we have nested arrays
		if (firstSorted[i] instanceof Array && secondSorted[i] instanceof Array) {
			// recurse into the nested arrays
			if (!firstSorted[i].equals(secondSorted[i], propFunc))
				return false;
		}
		else if (propFunc(firstSorted[i]) !== propFunc(secondSorted[i])) {
			// Warning - two different object instances will never be equal: {x:20} != {x:20}
			return false;
		}
	}
	return true;
};

Array.prototype.orderBy = function <T, K>(keySelector: (item: T) => K, descending: boolean = false): T[] {
	const copiedArray = this.slice();
	return copiedArray.sort((a: T, b: T) => {
		const keyA = keySelector(a);
		const keyB = keySelector(b);

		if (keyA < keyB) {
			return descending ? 1 : -1;
		}
		if (keyA > keyB) {
			return descending ? -1 : 1;
		}
		return 0;
	});
};
