import { Injectable } from '@angular/core';
import { AppConfig } from 'core/app-config';
import { HttpAuth } from 'core/auth';
import { Country } from 'domain/models';
import { Subject } from 'rxjs';
import { ApiData } from 'domain/types';

@Injectable({
	providedIn: 'root'
})
export class CountriesRepository {
	constructor(
		private readonly http: HttpAuth,
		private readonly config: AppConfig
	) {}

	async getCountries(destroy$?: Subject<void>): Promise<Country[]> {
		const countries = await this.http.promise(destroy$).get<ApiData<Country[]>>(`${this.config.apiUrl}${this.config.apiVersion}/countries`);

		return countries?.body?.data;
	}
}
