import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
	selector: 'child-cell-double-service',
	template: `
	<a class="ag-cell__double" *ngIf="params.data.serviceItem3">
		<span class="ag-cell__double-item">{{params.data.serviceItem2}}</span>
		<span class="ag-cell__double-item" title="Service Item 3" style="line-height: 1;">{{params.data.serviceItem3}}</span>
	</a>
	<span *ngIf="!params.data.serviceItem3">{{params.data.serviceItem2}}</span>
`
})
export class ChildCellDoubleServiceRenderer implements ICellRendererAngularComp {
	public params: any;
	
	agInit(params: any): void {
		this.params = params;
	}
	
	refresh(): boolean {
		return false;
	}
}
