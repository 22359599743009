<div class="page-tile__group page-tile__group--select-220">
	<label class="page-tile__group-label" for="{{id}}">{{'dg-dd-inadvance.details-fields_inAdvance' | transloco}}
		<i *ngIf="isRequired" class="fa fa-asterisk" aria-hidden="true"></i>
	</label>
	<ng-select dropdownPosition="bottom" labelForId="{{id}}" name="{{name}}" [required]="isRequired"
		[clearable]="clearable" [(ngModel)]="value" (ngModelChange)="onChanged()">
		<ng-option [value]="0">{{'dg-dd-inadvance.details-fields_inAdvance-opt-0' | transloco}}</ng-option>
		<ng-option [value]="1">{{'dg-dd-inadvance.details-fields_inAdvance-opt-1' | transloco}}</ng-option>
		<ng-option [value]="-1">{{'dg-dd-inadvance.details-fields_inAdvance-opt--1' | transloco}}</ng-option>
		<ng-option [value]="2">{{'dg-dd-inadvance.details-fields_inAdvance-opt-2' | transloco}}</ng-option>
		<ng-option [value]="-2">{{'dg-dd-inadvance.details-fields_inAdvance-opt--2' | transloco}}</ng-option>
		<ng-option [value]="3">{{'dg-dd-inadvance.details-fields_inAdvance-opt-3' | transloco}}</ng-option>
		<ng-option [value]="-3">{{'dg-dd-inadvance.details-fields_inAdvance-opt--3' | transloco}}</ng-option>
	</ng-select>
</div>
