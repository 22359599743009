import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpAuth } from 'core/auth';
import { AutoBillingJob, AutoBillingRun, AutoBillingTask } from 'domain/models';
import { Guid } from 'domain/types';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfig } from 'core/app-config';
import { BaseRepository } from './base-repository';
import { CacheService } from 'services/cache.service';


@Injectable({
	providedIn: 'root'
})
export class AutoBillingRepository extends BaseRepository {

	constructor(
		http: HttpAuth,
		config: AppConfig,
		cache: CacheService
	) {

		super(http, config, cache);
	}

	async getAutoBillingJobs(destroy$?: Subject<void>): Promise<AutoBillingJob[]> {

		const autobillingResponse = await this.http.promise(destroy$).get<AutoBillingJob[]>(`${this.config.apiUrl}${this.config.apiVersion}/schedule`);

		return autobillingResponse.body;
	}

	async getAutoBillingJob(id: number, destroy$?: Subject<void>): Promise<AutoBillingJob> {
		const autobillingResponse = await this.http.promise(destroy$).get<AutoBillingJob>(`${this.config.apiUrl}${this.config.apiVersion}/schedule/${id}`);

		return autobillingResponse.body;
	}

	public addAutoBillingJob(job: AutoBillingJob): Observable<number> {
		return this.http.post<{ id: number; }>(`${this.config.apiUrl}${this.config.apiVersion}/schedule`, job)
			.pipe(map(res => res.body.id));
	}

	public updateAutoBillingJob(job: AutoBillingJob): Observable<HttpResponse<AutoBillingJob>> {
		return this.http.put<AutoBillingJob>(`${this.config.apiUrl}${this.config.apiVersion}/schedule/${job.id}`, null, job);
	}

	public deleteAutoBillingJob(scheduleId: Guid): Observable<unknown> {

		return this.deleteEntity(`/schedule/${scheduleId}`);
	}

	async getAutoBillingJobTasks(jobId: number, destroy$?: Subject<void>): Promise<AutoBillingTask[]> {
		const autobillingResponse = await this.http.promise(destroy$).get<AutoBillingTask[]>(`${this.config.apiUrl}${this.config.apiVersion}/schedule/${jobId}/task`);

		return autobillingResponse.body;
	}

	async getAutoBillingJobTask(jobId: number, taskId: number, destroy$?: Subject<void>): Promise<AutoBillingTask> {

		const autobillingResponse = await this.http.promise(destroy$).get<AutoBillingTask>(`${this.config.apiUrl}${this.config.apiVersion}/schedule/${jobId}/task/${taskId}`);

		return autobillingResponse.body;
	}


	public addAutoBillingJobTask(jobId: number, task: AutoBillingTask): Observable<number> {
		return this.http.post<{ id: number; }>(`${this.config.apiUrl}${this.config.apiVersion}/schedule/${jobId}/task`, task)
			.pipe(map(res => res.body.id));
	}

	public updateAutoBillingJobTask(jobId: number, task: AutoBillingTask): Observable<unknown> {

		return this.updateEntity(`/schedule/${jobId}/task/${task.id}`, task);
	}

	public deleteAutoBillingJobTask(jobId: number, taskId: number): Observable<unknown> {

		return this.deleteEntity(`/schedule/${jobId}/task/${taskId}`);
	}

	async getAutoBillingRuns(destroy$?: Subject<void>): Promise<AutoBillingRun[]> {
		const autobillingResponse = await this.http.promise(destroy$).get<AutoBillingRun[]>(`${this.config.apiUrl}${this.config.apiVersion}/runs`);

		return autobillingResponse.body;
	}

	async getAutoBillingRun(id: number, destroy$?: Subject<void>): Promise<AutoBillingRun> {
		const autoBillingRunResponse = await this.http.promise(destroy$).get<AutoBillingRun>(`${this.config.apiUrl}${this.config.apiVersion}/runs/${id}`);

		return autoBillingRunResponse.body;
	}

	async getAutoBillingRunTask(id: number, destroy$?: Subject<void>): Promise<AutoBillingRun> {
		const autoBillingRunResponse = await this.http.promise(destroy$).get<AutoBillingRun>(`${this.config.apiUrl}${this.config.apiVersion}/runs/${id}`);

		return autoBillingRunResponse?.body;
	}
}
