<div *ngIf="loading" class="app-spinner-qb"></div>
<form *ngIf="!loading" class="form form--export">
	<ul class="steps__wrapper">
		<li class="steps {{CheckStep(1)}}">
			<span class="steps__label">{{'global-export.step1_label' | transloco}}</span>
			<span class="steps__label">{{'qbd-export.step1_title' | transloco}}</span>
		</li>
		<li class="steps {{CheckStep(2)}}">
			<span class="steps__label">{{'global-export.step2_label' | transloco}}</span>
			<span class="steps__label">{{'qbd-export.step2_title' | transloco}}</span>
		</li>
		<li class="steps {{CheckStep(3)}}">
			<span class="steps__label">{{'global-export.step3_label' | transloco}}</span>
			<span class="steps__label">{{'qbd-export.step3_title' | transloco}}</span>
		</li>
		<li class="steps {{CheckStep(4)}}">
			<span class="steps__label">{{'global-export.step4_label' | transloco}}</span>
			<span class="steps__label">{{'qbd-export.step4_title' | transloco}}</span>
		</li>
		<li class="steps {{CheckStep(5)}}">
			<span class="steps__label">{{'global-export.step5_label' | transloco}}</span>
			<span class="steps__label">{{'qbd-export.step5_title' | transloco}}</span>
		</li>
	</ul>
	<div class="form__content">
		 <fieldset class="form__section {{CheckStep(1)}}">
			<h3 class="form__section-title">
				{{'qbd-export.step1_title' | transloco}}
			</h3>
			<form #ProductsForm="ngForm">
				<div class="form__group block">
					<div class="form__group-item form__group-item--500-fs">
						<p>{{'qbd-export.step1_header-service' | transloco}}</p>
						<p>{{'qbd-export.step1_header-record' | transloco}}</p>
						<p>{{'qbd-export.step1_header-product' | transloco}}</p>
					</div>
					<div *ngFor="let items of qbdPrepareRes?.invoiceLinesResponses | groupBy:'groupName'; let i = index"
						class="form__group-item form__group-item--500">
						<p>{{items.key}}</p>
						<div>
							<div *ngFor="let item of items.value | sort:['description', 'selectedQbdProductId']; let i1 = index" class="form__group-item-custom1">
								<p>{{item.description}}</p>
								<ng-select #inputProduct bindLabel="fullName" required
									bindValue="listID" name="selectedQbdProductId{{i}}_{{i1}}" [items]="products"
									[searchable]="true" [clearable]="false" [(ngModel)]="item.selectedQbdProductId">
									<ng-template>
										<input class="custom-input" type="text"
											(input)="inputProduct.filter($event.target.value)" />
									</ng-template>
								</ng-select>
							</div>
						</div>
					</div>
				</div>

				<div class="form__bottom form__bottom--border-none padding-b__150">
					<button type="button" class="btn btn--primary btn--import" (click)="backTo()">
						{{'global.export_btn-back' | transloco}}
					</button>
					<button type="button" class="btn btn--primary btn--import" [disabled]="!ProductsForm.valid || !isDataPrepared"
						(click)="onNextStep()">
						{{'global.export_btn-next' | transloco}}
					</button>
				</div>
			</form>
		</fieldset>

		<fieldset class="form__section {{CheckStep(2)}}">
			<h3 class="form__section-title">{{'qbd-export.step2_title' | transloco}}</h3>
			<form #TransactionsForm="ngForm">
				<div class="form__group block">
					<div class="form__group-item form__group-item--800 flex-start">
						<p>{{'qbd-export.step2_field-uniteSomeProductsInOne' | transloco}}</p>
						<dg-checkbox name="uniteSomeProductsInOne" ngDefaultControl
							[id]="'uniteSomeProductsInOne'" [(ngModel)]="uniteSomeProductsInOne"></dg-checkbox>
					</div>
					<div class="form__group-item--800 block">
						<div *ngFor="let item of qbdPrepareRes?.transactionResponses; let i = index"
							class="form__group-item form__group-item--800">
							<p>{{item.name}}</p>
							<ng-select #inputProduct bindLabel="fullName" required bindValue="listID"
								name="selectedQbdProductId{{i}}" [items]="products" [searchable]="true" [clearable]="false"
								[(ngModel)]="item.selectedQbdProductId">
								<ng-template>
									<input class="ng-select-input" type="text"
										(input)="inputProduct.filter($event.target.value)" />
								</ng-template>
							</ng-select>
						</div>
					</div>

				</div>
				<div class="form__bottom form__bottom--border-none padding-b__150">
					<button type="button" class="btn btn--primary btn--import" (click)="onPreviosStep()">
						{{'global.export_btn-back' | transloco}}
					</button>
					<button type="button" class="btn btn--primary btn--import" [disabled]="!TransactionsForm.valid"
						(click)="onNextStep()">
						{{'global.export_btn-next' | transloco}}
					</button>
				</div>
			</form>
		</fieldset>

		<fieldset class="form__section {{CheckStep(3)}}">
			<h3 class="form__section-title">
				{{'qbd-export.step3_title' | transloco}}
			</h3>
			<form #SurchargeForm="ngForm">
				<div class="form__group block">
					<div class="form__group-item form__group-item--500 flex-start">
						<p>{{'qbd-export.step3_field-combineSurchargesWithTaxes' | transloco}}</p>
						<dg-checkbox name="combineSurchargesWithTaxes" ngDefaultControl
							[id]="'combineSurchargesWithTaxes'" [(ngModel)]="combineSurchargesWithTaxes"></dg-checkbox>
					</div>
					<div *ngIf="!combineSurchargesWithTaxes" class="form__group-item form__group-item--500 flex-start">
						<p>{{'qbd-export.step3_field-surchargeSeparately' | transloco}}
							<i class="fa fa-question-circle tooltip tooltip--export_2" aria-hidden="true">
								<span>{{'qbd-export.step3_field-surchargeSeparately-tooltip' | transloco}}</span>
							</i>
						</p>
						<dg-checkbox name="surchargeSeparately" ngDefaultControl
							[id]="'surchargeSeparately'" [(ngModel)]="surchargeSeparately"></dg-checkbox>
					</div>
					<div *ngIf="(!combineSurchargesWithTaxes && !surchargeSeparately)">
						<div *ngFor="let item of qbdPrepareRes?.surchargeResponses; let i = index"
							class="form__group-item form__group-item--500">
							<p>{{item.surchargeName}}</p>
							<ng-select #inputProduct bindLabel="fullName" required
								bindValue="listID" name="selectedQbdProductId{{i}}" [items]="products"
								[searchable]="true" [clearable]="false" [(ngModel)]="item.selectedQbdProductId">
								<ng-template>
									<input class="custom-input" type="text"
										(input)="inputProduct.filter($event.target.value)" />
								</ng-template>
							</ng-select>
						</div>
					</div>
					<div *ngIf="!combineSurchargesWithTaxes && surchargeSeparately">
						<div *ngFor="let item of qbdPrepareRes?.surchargeSeparatelyResponses; let i = index"
							class="form__group-item form__group-item--500">
							<p>{{item.surchargeName}}</p>
							<ng-select #inputProduct bindLabel="fullName" required
								bindValue="listID" name="selectedQbdProductId{{i}}" [items]="products"
								[searchable]="true" [clearable]="false" [(ngModel)]="item.selectedQbdProductId">
								<ng-template>
									<input class="custom-input" type="text"
										(input)="inputProduct.filter($event.target.value)" />
								</ng-template>
							</ng-select>
						</div>
					</div>
				</div>
				<div class="form__bottom form__bottom--border-none">
					<button type="button" class="btn btn--primary btn--import" (click)="onPreviosStep()">
						{{'global.export_btn-back' | transloco}}
					</button>
					<button type="button" class="btn btn--primary btn--import" [disabled]="!SurchargeForm.valid"
						(click)="onNextStep()">{{'global.export_btn-next' | transloco}}
					</button>
				</div>
			</form>
		</fieldset>

		<fieldset class="form__section {{CheckStep(4)}}">
			<h3 class="form__section-title">
				{{combineSurchargesWithTaxes ? ('qbd-export.step4_title-combineSurchargesWithTaxes' | transloco) : 'qbd-export.step4_title' | transloco }}
			</h3>
			<form #TaxForm="ngForm">
				<div *ngIf="!combineSurchargesWithTaxes" class="form__group block">
					<div class="form__group-item form__group-item--500 flex-start">
						<p>{{'qbd-export.step4_field-sureTaxMultiline' | transloco}}
							<i class="fa fa-question-circle tooltip tooltip--export_1" aria-hidden="true">
								<span>{{'qbd-export.step4_field-sureTaxMultiline-tooltip' | transloco}}</span>
							</i>
						</p>
						<dg-checkbox name="sureTaxMultiline" ngDefaultControl [id]="'sureTaxMultiline'"
									 [(ngModel)]="sureTaxMultiline"></dg-checkbox>
					</div>
					<div *ngIf="sureTaxMultiline" class="form__group-item form__group-item--500 flex-start">
						<p>{{'qbd-export.step4_field-groupTaxByState' | transloco}}
							<i class="fa fa-question-circle tooltip tooltip--export_2" aria-hidden="true">
								<span>
									{{'qbd-export.step4_field-groupTaxByState-tooltip' | transloco}}
								</span>
							</i>
						</p>
						<dg-checkbox name="groupTaxByState" ngDefaultControl [id]="'groupTaxByState'"
									 [(ngModel)]="groupTaxByState"></dg-checkbox>
					</div>
					<div *ngIf="sureTaxMultiline && groupTaxByState">
						<div *ngFor="let line of qbdPrepareRes?.taxesLinesStateResponses; let i = index"
							 class="form__group-item form__group-item--800">
							<p>{{line.taxTypeDesc}} {{!line.taxInclusive ? ('global-export.exclusive_label' | transloco) : 'global-export.inclusive_label' | transloco}}</p>

							<ng-select #inputProduct bindLabel="fullName" required
									   bindValue="listID" name="selectedQbdProductId{{i}}" [items]="products" [searchable]="true"
									   [clearable]="false" [(ngModel)]="line.selectedQbdProductId">
								<ng-template>
									<input class="custom-input"type="text"
										   (input)="inputProduct.filter($event.target.value)" />
								</ng-template>
							</ng-select>
						</div>
					</div>
					<div *ngIf="sureTaxMultiline && !groupTaxByState">
						<div *ngFor="let line of qbdPrepareRes?.taxesLinesResponses; let i = index"
							 class="form__group-item form__group-item--800">

							<p>{{line.taxTypeDesc}} {{!line.taxInclusive ? ('global-export.exclusive_label' | transloco) : 'global-export.inclusive_label' | transloco}}</p>

							<ng-select #inputProduct bindLabel="fullName" required
									   bindValue="listID" name="selectedQbdProductId{{i}}" [items]="products" [searchable]="true"
									   [clearable]="false" [(ngModel)]="line.selectedQbdProductId">
								<ng-template>
									<input class="custom-input" type="text"
										   (input)="inputProduct.filter($event.target.value)" />
								</ng-template>
							</ng-select>
						</div>
					</div>
					<div *ngIf="!sureTaxMultiline">
						<div *ngFor="let item of qbdPrepareRes?.totalTaxesResponses; let i = index"
							 class="form__group-item form__group-item--500">
							<p>{{item.taxName}} {{!item.taxInclusive ? ('global-export.exclusive_label' | transloco) : 'global-export.inclusive_label' | transloco}}</p>

							<ng-select #inputProduct bindLabel="fullName" bindValue="listID"
									   required name="selectedQbdProductId{{i}}" [items]="products"
									   [searchable]="true" [clearable]="false" [(ngModel)]="item.selectedQbdProductId">
								<ng-template>
									<input  class="custom-input" type="text"
											(input)="inputProduct.filter($event.target.value)" />
								</ng-template>
							</ng-select>
						</div>
					</div>
				</div>
				<div *ngIf="combineSurchargesWithTaxes" class="form__group block">
					<div *ngFor="let item of qbdPrepareRes?.surchargesWithTaxesResponses; let i = index"
						class="form__group-item form__group-item--500">
						<p>{{item.combineName}}</p>
						<ng-select #inputProduct bindLabel="fullName" bindValue="listID" required
							name="selectedQbdProductId{{i}}" [items]="products" [searchable]="true"
							[clearable]="false" [(ngModel)]="item.selectedQbdProductId">
							<ng-template>
								<input  class="custom-input" type="text"
									(input)="inputProduct.filter($event.target.value)" />
							</ng-template>
						</ng-select>
					</div>
				</div>
				<div class="form__bottom form__bottom--border-none">
					<button type="button" class="btn btn--primary btn--import" (click)="onPreviosStep()">
						{{'global.export_btn-back' | transloco}}
					</button>
					<button type="button" class="btn btn--primary btn--import" [disabled]="!TaxForm.valid"
						(click)="onNextStep()">
						{{'global.export_btn-next' | transloco}}
					</button>
				</div>
			</form>
		</fieldset>

		<fieldset class="form__section {{CheckStep(5)}}">
			<h3 class="form__section-title">
				{{'qbd-export.step5_title' | transloco}}
			</h3>
			<div class="form__section-subtitle">
				{{'qbd-export.step5_subtitle' | transloco}}
			</div>

			<div class="form__group">
				<div class="form__group-item ">
					<div class="radiobox">
						<input class="radiobox-input" type="radio" name="price-radio" id="price-1"
							[value]="1" [(ngModel)]='selectedPriceCalculationTypeId' />
						<label class="radiobox-label" for="price-1">
							{{'qbd-export.field-priceCalculationTypes-opt-0' | transloco}}
						</label>
					</div>
					<div class="sidebar__radiobox radiobox">
						<input class="radiobox-input" type="radio" name="price-radio" id="price-2"
							[value]="2" [(ngModel)]='selectedPriceCalculationTypeId' />
						<label class="radiobox-label" for="price-2">
							{{'qbd-export.field-priceCalculationTypes-opt-1' | transloco}}
						</label>
					</div>
				</div>
			</div>

			<div class="form__bottom form__bottom--border-none">
				<button type="button" class="btn btn--primary btn--import" [disabled]="loading" (click)="onPreviosStep()">
					{{'global.export_btn-back' | transloco}}
				</button>
				<button type="button" class="btn btn--primary btn--import" [disabled]="loading" (click)="Export()">
					{{'global.export_btn-export' | transloco}}
					<i *ngIf="loading" class="fa fa-spinner fa-spin"></i>
				</button>
			</div>
		</fieldset>
	</div>
</form>

<div *ngIf="exportConfShow" class="modal modal--tile modal--confirmation">
	<div class="modal__content">
		<form class="modal__content-body">
			<div class="page-tile__body page-tile__body--hidden-none">
				<div class="page-tile__title"> <b>{{'qbd-export.confirmation_title' | transloco}}</b></div>

				<div *ngIf="qbdPrepareEmptyInvoices.length" class="page-tile__content">
					<div class="page-tile__group">
						<span>
							{{'qbd-export.confirmation_msg1' | transloco}}
						</span>
					</div>
					<div class="page-tile__content-item" >
						<div *ngFor="let inv of qbdPrepareEmptyInvoices" class="page-tile__content-row">
							<i class="fa fa-circle" aria-hidden="true"></i>
							{{' - ' + inv.invoiceNumber}}
						</div>
					</div>
				</div>

				<p class="page-tile__body-proceed">
					{{'qbd-export.confirmation_msg2' | transloco}}
				</p>

				<div class="form__bottom">
					<button type="button" class="btn btn--secondary width-100px" (click)="onExportConfirm()">
						{{'global.modals_btn-confirm' | transloco}}
					</button>
					<button type="button" class="btn btn--default width-100px" (click)="backTo()">
						{{'global.modals_btn-cancel' | transloco}}
					</button>
				</div>
			</div>
		</form>
	</div>
</div>
