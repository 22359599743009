import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HashMap, TranslateParams, TranslocoService } from '@ngneat/transloco';
import { Invoice } from 'domain/entities';
import {
	ContractMappingResponse, ExportModel,
	HaloProduct, InvoiceLineMappingResponse,
	InvoiceMappingResponse,
	SurchargeMappingResponse, SurchargeWithTaxMappingResponse,
	TaxLineMappingResponse,
	TaxLineStateMappingResponse,
	TaxMappingResponse,
	TransactionMappingResponse,
	XeroBrandingTheme
} from 'domain/entities/halo';
import { Guid } from 'domain/types';
import { HaloRepository, IntegrationRepository } from 'repositories';
import { EMPTY, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NavigatorService } from 'services';
import { CacheModel, CacheService } from 'services/cache.service';
import { InvoiceActionsService } from 'shared/modules/invoice-actions';
import { DgAlertService } from 'tools/alert';

@Component({
	selector: 'dg-halo-export',
	templateUrl: './halo-export.component.html',
	styleUrls: ['./halo-export.component.sass']
})
export class HaloExportComponent implements OnInit, OnDestroy {
	currentStep = 1;
	invoices: Invoice[];
	excluded: Invoice[];
	contractMappingResponse: ContractMappingResponse;
	products: HaloProduct[];
	loading = false;
	selectedGroupingTypeId = 1;
	selectedPriceCalculationTypeId = 1;
	deleteExisting = false;
	public cacheModel: CacheModel;
	public cacheModelExcluded: CacheModel;
	public combineSurchargesWithTaxes = false;
	public sureTaxMultiline = false;
	public uniteSameProductsInOne = false;
	public groupTaxByState = false;
	public isExported = false;
	public exportedInvoices: string[];
	public invoiceLinesResponses: InvoiceLineMappingResponse[];
	public transactionResponses: TransactionMappingResponse[];
	public surchargeResponses: SurchargeMappingResponse[];
	public surchargesWithTaxesResponses: SurchargeWithTaxMappingResponse[];
	public totalTaxesResponses: TaxMappingResponse[];
	public taxesLinesResponses: TaxLineMappingResponse[];
	public taxesLinesStateResponses: TaxLineStateMappingResponse[];
	useXeroBrandingTheme = false;
	t: <T = string>(key: TranslateParams, params?: HashMap, lang?: string) => T;
	private destroy$ = new Subject<void>();
	constructor(
		private readonly cache: CacheService,
		private readonly navigator: NavigatorService,
		private readonly router: Router,
		private readonly transloco: TranslocoService,
		private readonly repository: HaloRepository,
		private readonly invoiceRepo: InvoiceActionsService,
		private readonly alertService: DgAlertService,
		private readonly location: Location,
		private readonly integrationRepository: IntegrationRepository,
	) {
		this.t = transloco.translate.bind(transloco);
		this.contractMappingResponse = new ContractMappingResponse();

		this.cacheModel = this.cache.get('haloExportInvoices');
		this.cacheModelExcluded = this.cache.get('haloExportInvoices-excluded');

		this.invoices = this.cacheModel ? <Invoice[]>this.cacheModel.Object : [];
		this.excluded = this.cacheModelExcluded ? <Invoice[]>this.cacheModelExcluded.Object : [];

		if (!this.invoices.length) {
			this.location.back();
		} else {
			this.onContractPrepare();
		}
	}

	async ngOnInit(): Promise<void> {
		const selectedEntity = await this.integrationRepository.getHaloIntegration(this.destroy$).withBusy();
		this.useXeroBrandingTheme = selectedEntity.useXeroBrandingTheme;
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	public onExportConfirm(): void {
		this.isExported = false;
		this.exportedInvoices = [];
		this.onContractPrepare(true);
	}

	public onContractPrepare(force: boolean = false): void {
		if (this.invoices != null) {
			this.loading = true;
			const invoiceIds = this.invoices.map(x => x.id);
			const actionFilters = this.cache.get('invoices-action-filterParams');
			const filterParams = this.invoiceRepo.selectedAll && actionFilters ? actionFilters : '';
			this.repository.contractsPrepare(invoiceIds, force, this.excluded, filterParams)
				.pipe(takeUntil(this.destroy$))
				.subscribe({
					next: (res) => {
						if (res) {
							this.contractMappingResponse = res;
							this.products = res.products;
						} else {
							this.alertService.show(this.t('halo-export.not-found-warning'), 'error');
						}
						this.loading = false;
					},
					error: (res) => {
						this.loading = false;
						if (Array.isArray(res.error.references)) {
							this.isExported = true;
							this.exportedInvoices = res.error.references;
							return EMPTY;
						} else {
							throw res;
						}
					}
				});
		}
	}

	public onGroupingTypeSelected(): void {
		this.loading = true;
		const groupingTypeId = +this.selectedGroupingTypeId;
		const haloMappingInvoices = this.contractMappingResponse.invoices.map(x => x.invoiceId);
		this.repository.dataPrepare(groupingTypeId, haloMappingInvoices)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (res) => {
					this.transactionResponses = res.transactionResponses;
					this.invoiceLinesResponses = res.invoiceLinesResponses;
					this.surchargeResponses = res.surchargeResponses;
					this.surchargesWithTaxesResponses = res.surchargesWithTaxesResponses;
					this.totalTaxesResponses = res.totalTaxesResponses;
					this.taxesLinesResponses = res.taxesLinesResponses;
					this.taxesLinesStateResponses = res.taxesLinesStateResponses;

					this.NextStep();
					this.loading = false;
				},
				error: () => this.loading = false
			});
	}

	public Export(): void {
		this.loading = true;
		const exportModel = new ExportModel();
		exportModel.invoices = this.contractMappingResponse.invoices;
		exportModel.transactionResponses = this.transactionResponses;
		exportModel.invoiceLinesResponses = this.invoiceLinesResponses;
		exportModel.surchargeResponses = this.surchargeResponses;
		exportModel.surchargesWithTaxesResponses = this.surchargesWithTaxesResponses;
		exportModel.totalTaxesResponses = this.totalTaxesResponses;
		exportModel.taxesLinesStateResponses = this.taxesLinesStateResponses;
		exportModel.taxesLinesResponses = this.taxesLinesResponses;
		exportModel.selectedGroupingTypeId = this.selectedGroupingTypeId;
		exportModel.selectedPriceCalculationTypeId = this.selectedPriceCalculationTypeId;
		exportModel.deleteExisting = this.deleteExisting;
		exportModel.combineSurchargesWithTaxes = this.combineSurchargesWithTaxes;
		exportModel.taxMultiline = this.sureTaxMultiline;
		exportModel.taxForState = this.groupTaxByState;
		exportModel.tag = this.cacheModel.Tag;
		exportModel.uniteSameProductsInOne = this.uniteSameProductsInOne;

		this.repository.export(exportModel)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (result) => {
					if (result.operationId) {
						this.navigator.toOperation(result.operationId);
					}
					this.loading = false;
				},
				error: (err) => {
					this.loading = false;
					throw err;
				}
			});
	}

	public CheckStep(value: number): string {
		return this.currentStep === value ? 'steps--active' : '';
	}

	public onChangeContract(item: InvoiceMappingResponse): void {
		item.selectedHaloContractId = +item.selectedHaloContractId;
	}

	public NextStep(): void {
		if (this.currentStep < 9) {
			this.currentStep = this.currentStep + 1;
		}
	}

	public BackStep(): void {
		if (this.currentStep > 1) {
			this.currentStep = this.currentStep - 1;
		}
	}

	public backTo(): void {
		const backUrl = (this.cacheModel.Tag.split(':').pop()).slice(2, -2);
		void this.router.navigate([backUrl]);
	}

	xeroBrandingThemesCompareFn(item: XeroBrandingTheme, selected: Guid): boolean {
		return item.value.toString() === selected.toString();
	}
}
