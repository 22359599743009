import { Location } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig } from 'core/app-config';
import { GenerateCreditNote, IntegrationType, Invoice } from 'domain/entities';
import * as Moment from 'moment-timezone';
import { LocaleConfig } from 'ngx-daterangepicker-material';
import { TimePeriod } from 'ngx-daterangepicker-material/daterangepicker.component';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { NavigatorService } from 'services';
import { CacheModel, CacheService } from 'services/cache.service';
import { InvoiceActionsService } from 'shared/modules/invoice-actions';

@Component({
	selector: 'dg-credit-note',
	templateUrl: './credit-note.component.html',
	styleUrls: ['./credit-note.component.sass']
})
export class CreditNoteComponent implements OnDestroy {
	public filteredInvoices: GenerateCreditNote[];
	public loading = false;
	public isFutureDateSelected = false;
	private invoices: Invoice[];
	private excluded: Invoice[];
	private cacheModel: CacheModel;
	private cacheModelExcluded: CacheModel;
	private isSureTax: boolean;
	private destroy$ = new Subject<void>();
	locale: LocaleConfig = {
		format: 'YYYY-MM-DD',
		displayFormat: 'YYYY-MM-DD'
	};

	constructor(
		private readonly cache: CacheService,
		private readonly navigator: NavigatorService,
		private readonly router: Router,
		private readonly invoiceService: InvoiceActionsService,
		private readonly location: Location,
		private readonly config: AppConfig
	) {
		this.cacheModel = this.cache.get('generateCreditNote');
		this.cacheModelExcluded = this.cache.get('generateCreditNote-excluded');

		this.invoices = this.cacheModel ? <Invoice[]>this.cacheModel.Object : [];
		this.excluded = this.cacheModelExcluded ? <Invoice[]>this.cacheModelExcluded.Object : [];

		this.isSureTax = config.integrationSettings.hasIntegrationType(IntegrationType.Suretax);

		if (!this.invoices.length) {
			this.location.back();
		} else {
			this.getFilteredInvoices();
		}
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	datesUpdated(event: TimePeriod, invoice: GenerateCreditNote): void {
		if (this.isSureTax) {
			const invoiceDate = event.startDate?.toString();
			const lastDayOfMonth = Moment().endOf('month').format();
			invoice['isFutureDate'] = Moment(invoiceDate).isAfter(lastDayOfMonth);
			this.isFutureDateSelected = this.filteredInvoices.some(i => i['isFutureDate']);
		}
	}

	private getFilteredInvoices(): void {
		this.loading = true;

		const actionFilters: HttpParams = this.cache.get('invoices-action-filterParams');
		const filterParams: HttpParams = this.invoiceService.selectedAll && actionFilters ? actionFilters : undefined;

		this.invoiceService.getCreditNoteInvoices(this.invoices, this.excluded, filterParams)
			.pipe(
				takeUntil(this.destroy$),
				finalize(() => {
					this.loading = false;
					if (!this.filteredInvoices) {
						this.location.back();
					}
				})
			)
			.subscribe((invoices: GenerateCreditNote[]) => {
				invoices.forEach((i) => {
					i['invoiceMinDate'] = Moment(i.invoiceDate).format('YYYY-MM-DD');
					i.invoiceDate = { start: i.invoiceDate, end: i.invoiceDate };
				});
				this.filteredInvoices = invoices;
			});
	}

	public onGenerate(): void {
		this.filteredInvoices.forEach((i) => i.invoiceDate = Moment(i.invoiceDate.start.toString()).utc().set({ hour: 0, minute: 0, second: 0 }).format());

		this.invoiceService.credit(this.filteredInvoices, this.cacheModel.Tag)
			.then(
				data => {
					this.cache.removeKey('generateCreditNote');
					this.cache.removeKey('generateCreditNote-excluded');
					this.handleSuccessBackgroundOperation(data);
				},
				(err) => { throw err; });
	}

	onBackTo(): void {
		const backUrl = (this.cacheModel.Tag.split(':').pop()).slice(2, -2);
		void this.router.navigate([backUrl]);
	}

	handleSuccessBackgroundOperation(data: any): void {
		const operationId = data.operationId || data.data.operationId;
		this.navigator.toOperation(operationId);
	}
}
