import { Injectable } from '@angular/core';
import { Observable, Subject, of, throwError } from 'rxjs';
import { DgAlertService } from 'tools/alert';
import { HttpAuth } from 'core/auth';
import {
	CustomerArTransaction,
	CustomerArTransactionResponse,
	CustomerDetails,
	CustomerProduct,
	CustomerSite,
	CustomerSurcharge,
	CustomerTransaction,
	CustomerUsageLine,
	CustomerUser,
	Invoice,
	InvoiceDetails,
	InvoiceEmails,
	InvoiceTaxDetails,
	InvoiceTaxTypes
} from 'domain/entities';
import { BaseRepository } from './base-repository';
import { CacheService } from 'services/cache.service';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { ToasterService } from 'core/toaster-service';
import { AppConfig } from 'core/app-config';
import { Guid, ApiData, ApiError, ApiOperation } from 'domain/types';
import { CustomerCheque, CustomerChequePayment } from 'domain/models/customer-cheque';
import { CustomerPayment } from 'domain/models';

@Injectable({
	providedIn: 'root'
})
export class CustomerRepository extends BaseRepository {

	constructor(
		http: HttpAuth,
		config: AppConfig,
		cache: CacheService,
		private readonly alertService: DgAlertService,
		private readonly toasterService: ToasterService
	) {
		super(http, config, cache);
	}

	/**
	 *  @deprecated DO NOT USE - will be removed. Use Promise instead
	 */
	getAll$(): Observable<CustomerDetails[]> {
		const key = 'all-customers';

		if (this.cacheHasKey(key)) {
			return this.getCache<CustomerDetails[]>(key);
		}

		const requestOptions = {};

		const observable =
			this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/customers`, requestOptions)
				.pipe(
					map(res => {
						const customers = res.body.data.map(x => new CustomerDetails().deserialize(x));
						this.setCacheValue(key, customers, null);
						return customers;
					}));

		this.cache.set$(key, observable);

		return observable;
	}

	async getAll(destroy$?: Subject<void>): Promise<CustomerDetails[]> {
		const key = 'all-customers';

		const cached = this.getCacheSync<CustomerDetails[]>(key);
		if (cached) return cached;

		const requestOptions = {};

		const res = await this.http.promise(destroy$).get<ApiData<CustomerDetails[]>>(`${this.config.apiUrl}${this.config.apiVersion}/customers`, requestOptions);
		const customers = res.body.data.map(x => new CustomerDetails().deserialize(x));
		this.setCacheValue(key, customers, null);

		return customers;
	}

	getActiveCustomers$(): Observable<CustomerDetails[]> {
		const key = 'active-customers';

		if (this.cacheHasKey(key)) {
			return this.getCache<CustomerDetails[]>(key);
		}

		const requestOptions = {};

		const observable =
			this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/customers?active=true`, requestOptions)
				.pipe(
					map(res => {
						const customers = res.body.data.map(x => new CustomerDetails().deserialize(x));
						this.setCacheValue(key, customers, null);
						return customers;
					}));

		this.cache.set$(key, observable);

		return observable;
	}

	async getActiveCustomers(destroy?: Subject<void>): Promise<CustomerDetails[]> {
		const requestOptions = {};

		const res = await this.http.promise(destroy).get<ApiData<CustomerDetails[]>>(`${this.config.apiUrl}${this.config.apiVersion}/customers?active=true`, requestOptions);
		return res.body.data.map(x => new CustomerDetails().deserialize(x));
	}

	reset(): void {
		this.cache.removeKey('all-customers');
		this.cache.removeKey('active-customers');
	}

	getById(id: Guid): Observable<CustomerDetails> {
		return this.getAll$().pipe(map((customers: CustomerDetails[]) => customers.find(x => x.id.equals(id))));
	}

	public addCustomer(customer: CustomerDetails): Observable<CustomerDetails> {

		const key = 'all-customers';

		const observable =
			this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/customers`, customer)
				.pipe(
					map(res => {

						const newCustomer = new CustomerDetails().deserialize(res.body.data);

						customer.id = newCustomer.id;
						customer.accountCode = newCustomer.accountCode;

						let allCustomers = this.cache.get<CustomerDetails[]>(key);
						if (allCustomers) {
							allCustomers.push(newCustomer);
							allCustomers = allCustomers.sort((a, b) => {
								if (a.name > b.name) { return 1; } else { return -1; }
							});
							this.cache.set(key, allCustomers);
						}
						this.cache.removeKey('active-customers');

						return newCustomer;
					}));

		return observable;
	}

	public updateCustomer(customer: CustomerDetails): Observable<void> {

		const keyAll = 'all-customers';

		if (typeof customer.creditLimit !== 'number') {
			customer.creditLimit = customer.creditLimit = Number(customer.creditLimit) || 0;
		}

		return this.http.put(`${this.config.apiUrl}${this.config.apiVersion}/customers/${customer.id}`, null, customer)
			.pipe(map(() => this.updateCacheCollectionItem(keyAll, customer.id, customer)));
	}

	public deleteCustomer(customerId: Guid): Observable<HttpResponse<void>> {

		const key = 'all-customers';

		return this.deleteEntity(`/customers/${customerId}`)
			.pipe(
				tap(() => this.removeFromCacheCollection(key, customerId)),
				catchError(er => {
					return throwError(() => er);
				}));
	}

	public synchroniseCustomers(): Observable<any> {

		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/connectwise/sync-customers`, null, null)
			.pipe(
				map(res => res.body.data)
				, catchError(response => {
					if (response.status === 500) {
						this.alertService.show('Customer Synchronization has been started, please wait.', 'success');
					}
					return throwError(() => response);
				}));
	}

	public synchroniseAutotaskCustomers(): Observable<any> {

		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/autotask/sync-customers`, null, null)
			.pipe(
				map(res => res.body.data)
				, catchError(response => {
					if (response.status === 500) {
						this.alertService.show('Customer Synchronization has been started, please wait.', 'success');
					}
					return throwError(() => response);
				}));
	}

	public synchroniseKaseyaCustomers(): Observable<any> {

		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/kaseya/sync-customers`, null, null)
			.pipe(
				map(res => res.body.data)
				, catchError(response => {
					if (response.status === 500) {
						this.alertService.show('Customer Synchronization has been started, please wait.', 'success');
					}
					return throwError(() => response);
				}));
	}

	public synchroniseHaloCustomers(): Observable<any> {

		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/halo/sync-customers`, null, null)
			.pipe(
				map(res => res.body.data)
				, catchError(response => {
					if (response.status === 500) {
						this.alertService.show('Customer Synchronization has been started, please wait.', 'success');
					}
					return throwError(() => response);
				}));
	}

	public synchroniseQbCustomers(customerTypeId?: string[]): Observable<any> {

		const data = {
			selectedCustomerTypeIds: customerTypeId ? customerTypeId : null
		};

		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/quickbooks/sync-customers`, data)
			.pipe(
				map(res => res.body.data)
				, catchError(response => {
					if (response.status === 500) {
						this.alertService.show('Customer Synchronization has been started, please wait.', 'success');
					}
					return throwError(() => response);
				}));
	}

	public synchroniseXeroCustomers(): Observable<any> {
		const data = {
			tag: JSON.stringify({ url: '/customers' })
		};

		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/customers/xero-sync`, data)
			.pipe(
				map(res => res.body)
				, catchError(response => {
					if (response.status === 500) {
						this.alertService.show('Customer Synchronization has been started, please wait.', 'success');
					}
					return throwError(() => response);
				}));
	}

	public exportCustomers(): Observable<any> {

		const observable =
			this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/customers-export`, null, null)
				.pipe(
					map(res => {
						const result = res.body.data;
						return result;
					}));

		return observable;
	}

	public ResetCustomersUsages(customerIds: Guid[]): Observable<any> {

		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/customers-reset-usages`, { customerIds: customerIds }, null)
			.pipe(
				map(res => {
					return res.body.data;
				}));
	}

	public exportCustomerByDateRange(id: Guid, startDate: string, endDate: string, tag: any, serviceId: number | number[]): Observable<ApiOperation> {
		const data = {
			tag: tag
		};

		const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

		return this.http.post<ApiOperation>(`${this.config.apiUrl}${this.config.apiVersion}/customers/${id}/usage/export?startDate=${startDate}&endDate=${endDate}&service=${serviceId}`, data, options)
			.pipe(
				map(res => {
					return res.body;
				}));
	}

	// ======= SITES =============

	getCustomerSites(customerId: Guid): Observable<CustomerSite[]> {
		const key = `customer-${customerId}-sites`;
		let condition = this.cache.get('sites-' + customerId + '-needsRefresh');
		condition = true;

		if (this.cacheHasKey(key) && condition !== true)
			return this.getCache<CustomerSite[]>(key);

		const observable =
			this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/customers/${customerId}/sites`)
				.pipe(
					map(res => {
						const sites = res.body.data.map(x => new CustomerSite().deserialize(x));

						this.setCacheValue(key, sites, null);

						return sites;
					}));

		this.cache.set$(key, observable);

		return observable;
	}

	getCustomerSiteById(customerId: Guid, siteId: Guid): Observable<CustomerSite> {
		const key = `customer-${customerId}-sites-${siteId}`;

		if (this.cacheHasKey(key)) {
			return this.getCache<CustomerSite[]>(key)
				.pipe(map(site => site.find(x => x.id.equals(siteId))));
		}

		return this.getCustomerSites(customerId).pipe(map(site => site.find(x => x.id.equals(siteId))));
	}

	async addCustomerSite(customerId: Guid, site: CustomerSite, destroy$?: Subject<void>): Promise<Guid> {
		const res = await this.http.promise(destroy$).post<ApiData<Guid>>(`${this.config.apiUrl}${this.config.apiVersion}/customers/${customerId}/sites`, site);

		const key = `customer-${customerId}-sites`;
		this.addCacheCollectionItem(key, res.body?.data);

		return res.body.data;
	}

	async updateCustomerSite(customerId: Guid, site: CustomerSite, destroy$?: Subject<void>): Promise<void>  {
		const res = await this.http.promise(destroy$).put(`${this.config.apiUrl}${this.config.apiVersion}/customers/${customerId}/sites/${site.id}`, site);

		const key = `customer-${customerId}-sites`;
		this.updateCacheCollectionItem(key, site.id, site);

		return res.body;
	}

	public deleteCustomerSites(customerId: Guid, ids: Guid[]): Observable<HttpResponse<void>> {

		const key = `customer-${customerId}-sites`;

		const observable =
			this.deleteEntities(`/customers/${customerId}/sites/delete`, ids)
				.pipe(
					tap(() => this.removeManyFromCacheCollection(key, ids)));

		return observable;
	}

	// ======= END SITES =============

	// ======= PRODUCTS =============

	async getCustomerProductsAsync(customerId: Guid, displayOption: number, destroy$: Subject<void>): Promise<CustomerProduct[]> {
		const key = `customer-${customerId}-products`;

		const condition = this.cache.get('products-needsRefresh');

		if (this.cacheHasKey(key) && condition !== true)
			return this.getCacheSync<CustomerProduct[]>(key);

		const res = await this.http.promise(destroy$).get(`${this.config.apiUrl}${this.config.apiVersion}/customers/${customerId}/products?displayOption=${displayOption}`);
		const products = res.body.data.map(x => new CustomerProduct().deserialize(x));

		this.setCacheValue(key, products, null);
		this.cache.set('products-needsRefresh', false);

		return products;
	}

	/**
	 *  @deprecated DO NOT USE - will be removed. Use Promise instead
	 */
	getCustomerProducts$(customerId: Guid): Observable<CustomerProduct[]> {
		const key = `customer-${customerId}-products`;

		const condition = this.cache.get('products-needsRefresh');

		if (this.cacheHasKey(key) && condition !== true)
			return this.getCache<CustomerProduct[]>(key);

		const observable =
			this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/customers/${customerId}/products`)
				.pipe(
					map(res => {
						const products = res.body.data.map(x => new CustomerProduct().deserialize(x));
						this.setCacheValue(key, products, null);
						this.cache.set('products-needsRefresh', false);

						return products;
					}));

		this.cache.set$(key, observable);

		return observable;
	}

	/**
	 *  @deprecated DO NOT USE - will be removed. Use Promise instead
	 */
	getCustomerProductById$(customerId: Guid, id: Guid): Observable<CustomerProduct> {
		const key = `customer-${customerId}-products-${id}`;

		if (this.cacheHasKey(key)) {
			return this.getCache<CustomerProduct[]>(key)
				.pipe(map(prdct => prdct.find(x => x.id.equals(id))));
		}

		return this.getCustomerProducts$(customerId).pipe(map(prdct => prdct.find(x => x.id.equals(id))));
	}

	async getCustomerProducts(customerId: Guid, destroy$?: Subject<void>): Promise<CustomerProduct[]> {
		const key = `customer-${customerId}-products`;
		const condition = this.cache.get('products-needsRefresh');

		if (this.cacheHasKey(key) && condition !== true)
			return this.getCacheSync<CustomerProduct[]>(key);

		const res = await this.http.promise(destroy$).get<ApiData<CustomerDetails[]>>(`${this.config.apiUrl}${this.config.apiVersion}/customers/${customerId}/products`);
		const products = res.body.data.map(x => new CustomerProduct().deserialize(x));

		this.setCacheValue(key, products, null);
		this.cache.set('products-needsRefresh', false);

		return products;
	}

	async getCustomerProductById(customerId: Guid, id: Guid, destroy$?: Subject<void>): Promise<CustomerProduct | null> {
		const products = await this.getCustomerProducts(customerId, destroy$);
		const product = products.find(prdct => prdct.id.equals(id));

		if (!product) {
			throw new Error(`Product not found`);
		}

		return product;
	}

	public updateProductsOrder(customerId: Guid, orderedProdutcs: any[]): Observable<any> {

		const payload = {
			products: orderedProdutcs
		};

		return this.http.put(`${this.config.apiUrl}${this.config.apiVersion}/customers/${customerId}/products`, null, payload)
			.pipe(
				tap(() => this.cache.set('products-needsRefresh', true)),
				catchError(er => {
					return throwError(() => er);
				}));
	}

	public addProductInstance(customerId: Guid, instance: CustomerProduct): Observable<Guid> {
		const key = `customer-${customerId}-products`;

		return this.createEntity(`/customers/${customerId}/products`, instance)
			.pipe(
				tap(product => this.addCacheCollectionItem(key, product))
				, map(s => s.id));
	}

	public updateProductInstance(customerId: Guid, instance: CustomerProduct): Observable<{ chargeId: Guid; numberOfAdjustmentLines: number; }> {
		return this.http.put(`${this.config.apiUrl}${this.config.apiVersion}/customers/${customerId}/products/${instance.id}`, null, instance)
			.pipe(
				tap(() => this.cache.set('products-needsRefresh', true)),
				map(res => {
					if (res.body?.data?.numberOfAdjustmentLines) {
						this.toasterService.success('toaster-service.toast-error-adjustment-created_msg');
					}

					return { chargeId: undefined, numberOfAdjustmentLines: 0 };
				}));
	}

	public deleteProductInstance(customerId: Guid, id: Guid): Observable<void> {

		const key = `customer-${customerId}-products`;

		return this.deleteEntity(`/customers/${customerId}/products/${id}`)
			.pipe(
				tap(() => this.removeFromCacheCollection(key, id))
				, map(s => null));
	}

	// ======= END PRODUCTS =============
	// ======= INVOICES =============

	public getCustomerInvoices(customerId: Guid): Observable<Invoice[]> {
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/customers/${customerId}/invoices`)
			.pipe(
				map(res => {
					return res.body.data.map(x => new Invoice().deserialize(x));
				}));
	}

	public getCustomerInvoice(customerId: Guid, invoiceId: Guid): Observable<Invoice[]> {
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/customers/${customerId}/invoices/${invoiceId}`)
			.pipe(
				map(res => {
					return res.body.data.map(x => new Invoice().deserialize(x));
				}));
	}

	public getCustomerInvoiceById(id: Guid): Observable<InvoiceDetails> {
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/invoice/${id}`)
			.pipe(map(res => new InvoiceDetails().deserialize(res.body.data)));
	}

	public getCustomerInvoiceEmails(invoiceId: Guid): Observable<InvoiceEmails[]> {
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/invoices/${invoiceId}/emails`)
			.pipe(
				map(res => res.body.data.map(x => new InvoiceEmails().deserialize(x))));
	}

	public getCustomerInvoiceTax(cusId: Guid, invId: Guid, entId: Guid, type: InvoiceTaxTypes): Observable<InvoiceTaxDetails[]> {

		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/customers/${cusId}/invoices/${invId}/${type}/${entId}/taxes`)
			.pipe(
				map(res => res.body.data.map(x => new InvoiceTaxDetails().deserialize(x))));
	}

	public getCustomerInvoiceProductTax(cusId: Guid, invId: Guid, productId: Guid, productKitId: Guid): Observable<InvoiceTaxDetails[]> {
		const obj = {
			productId: productId,
			productKitId: productKitId
		};

		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/customers/${cusId}/invoices/${invId}/products/${productId}/taxes`, obj, null)
			.pipe(
				map(res => {
					if (res.body.data) {
						return res.body.data.map(x => new InvoiceTaxDetails().deserialize(x));
					}
					return [];
				}));
	}

	// ======= END INVOICES =============

	// ========= USERS ===============

	async getCustomerUsers(customerId: Guid, destroy$?: Subject<void>): Promise<CustomerUser[]> {
		const res = await this.http.promise(destroy$).get<ApiData<CustomerCheque[]>>(`${this.config.apiUrl}${this.config.apiVersion}/customers/${customerId}/users`);
		return res.body?.data ? res.body?.data.map(x => new CustomerUser().deserialize(x)) : [];
	}

	async getCustomerUserById(customerId: Guid, customerUserId: Guid, destroy$: Subject<void>): Promise<CustomerUser | null> {
		const users = await this.getCustomerUsers(customerId, destroy$);
		const user = users.find(user => user.id.equals(customerUserId));

		if (!user) {
			throw new Error(`Customer User not found`);
		}

		return user;
	}

	public addCustomerUser(customerId: Guid, user: CustomerUser): Observable<number> {
		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/customers/${customerId}/users`, user, null)
			.pipe(map(res => res.body.data));
	}

	public updateCustomerUser(customerId: Guid, user: CustomerUser): Observable<void> {
		return this.http.put(`${this.config.apiUrl}${this.config.apiVersion}/customers/${customerId}/users/${user.id}`, null, user)
			.pipe(map(() => null));
	}

	async sendInvitationToCustomerUser(customerId: Guid, userId: Guid, destroy$?: Subject<void>): Promise<void>  {
		const res = await this.http.promise(destroy$).post(`${this.config.apiUrl}${this.config.apiVersion}/customers/${customerId}/users/${userId}/invite`, null);
		return res.body;
	}

	public removeCustomerUser(customerId: Guid, user: CustomerUser, isAssignment: boolean): Observable<void> {
		const data = {
			custUserId: user.id,
			isAssignment: isAssignment,
		};

		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/customers/${customerId}/users/remove`, data, user)
			.pipe(map(() => null));
	}

	public viewAsEcpUser(customerId: Guid, userId: Guid): Observable<string> {
		const observable = this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/customers/${customerId}/users/${userId}/loginas`)
			.pipe(map(res => res.body.data.toString()));

		return observable;
	}

	public deleteCustomerUser(customerId: Guid, id: Guid): Observable<HttpResponse<void>> {
		return this.http.delete(`${this.config.apiUrl}${this.config.apiVersion}/customers/${customerId}/users/${id}`);
	}

	checkIfCustomerExists(entityName: string): Observable<CustomerUser> {
		return this.http.get<ApiData<CustomerUser>>(`${this.config.apiUrl}${this.config.apiVersion}/customers/users/search?username=${entityName}`)
			.pipe(
				map(res => res.body.data),
				catchError(() => of(null))
			);
	}

	attachExistsUserToCustomer(customerUserId: Guid, userName: string, customerId: Guid): Observable<boolean> {
		const payload = {
			customerUserId,
			userName
		};

		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/customers/${customerId}/users/attach`, payload)
			.pipe(
				map(res => res.body.data),
				catchError(() => of(false))
			);
	}

	// ========= END USERS ===============

	// ========= TRANSACTIONS ===============

	getCustomerTransactions(customerId: Guid, periodId: Guid): Observable<CustomerTransaction[]> {
		const requestOptions = {
			params: new HttpParams()
				.set('period', periodId.toString())
		};

		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/customers/${customerId}/transactions`, requestOptions)
			.pipe(map(res => res.body.data.map(x => new CustomerTransaction().deserialize(x))));
	}

	getCustomerTransactionById(transactionId: Guid, customerId: Guid): Observable<CustomerTransaction> {
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/customers/${customerId}/transactions/${transactionId}`)
			.pipe(map(res => new CustomerTransaction().deserialize(res.body.data)));
	}

	updateCustomerTransaction(customerId: Guid, tran: CustomerTransaction): Observable<void> {
		return this.http.put(`${this.config.apiUrl}${this.config.apiVersion}/customers/${customerId}/transactions/${tran.id}`, null, tran)
			.pipe(map(() => null));
	}

	addCustomerTransaction(customerId: Guid, tran: CustomerTransaction): Observable<Guid> {
		return this.createEntity(`/customers/${customerId}/transactions`, tran)
			.pipe(map(res => res.id));

	}

	public deleteCustomerTransaction(customerId: Guid, id: Guid, periodId: Guid): Observable<HttpResponse<void>> {

		const key = `transactions-c-${customerId}-p-${periodId}`;

		return this.deleteEntity(`/customers/${customerId}/transactions/${id}`)
			.pipe(
				tap(() => this.removeFromCacheCollection(key, id)));
	}

	// ========= END TRANSACTIONS ===============

	// ========= AR TRANSACTIONS ===============

	async getCustomerArTransactions(customerId: Guid, destroy$?: Subject<void>): Promise<CustomerArTransactionResponse> {
		const res = await this.http.promise(destroy$).get<ApiData<CustomerArTransactionResponse>>(`${this.config.apiUrl}${this.config.apiVersion}/customers/${customerId}/accounts-receivable-transactions`);
		return res.body?.data;
	}

	async getCustomerArTransactionById(customerId: Guid, id: Guid, destroy$: Subject<void>): Promise<CustomerArTransaction | null> {
		const res = await this.getCustomerArTransactions(customerId, destroy$);
		const transaction = res.transactions.find(transaction => transaction['transactionId']?.equals(id));

		if (!transaction) {
			throw new Error(`AR transaction not found`);
		}

		return transaction;
	}

	async addCustomerArTransaction(customerId: Guid, tran: CustomerArTransaction, destroy$?: Subject<void>): Promise<void> {
		const res = await this.http.promise(destroy$).post(`${this.config.apiUrl}${this.config.apiVersion}/customers/${customerId}/accounts-receivable-transactions`, tran);
		return res.body;
	}

	async updateCustomerArTransaction(customerId: Guid, tran: CustomerArTransaction, destroy$?: Subject<void>): Promise<void> {
		const res = await this.http.promise(destroy$).put(`${this.config.apiUrl}${this.config.apiVersion}/customers/${customerId}/accounts-receivable-transactions/${tran['transactionId']}`, tran);
		return res.body;
	}

	public deleteCustomerArTransaction(customerId: Guid, id: Guid): Observable<void> {
		return this.deleteEntity(`/customers/${customerId}/accounts-receivable-transactions/${id}`)
			.pipe(map(s => null));
	}

	async getCustomerCheques(customerId: Guid, destroy$?: Subject<void>): Promise<CustomerCheque[]> {
		const res = await this.http.promise(destroy$).get<ApiData<CustomerCheque[]>>(`${this.config.apiUrl}${this.config.apiVersion}/customers/${customerId}/checks`);
		return res.body?.data;
	}

	async getCustomerPayments(customerId: Guid, destroy$?: Subject<void>): Promise<CustomerPayment[]> {
		const res = await this.http.promise(destroy$).get<ApiData<CustomerPayment[]>>(`${this.config.apiUrl}${this.config.apiVersion}/customers/${customerId}/payments`);
		return res.body?.data;
	}

	async getCustomerChequeById(customerId: Guid, checkId: Guid, destroy$: Subject<void>): Promise<CustomerCheque | null> {
		const checks = await this.getCustomerCheques(customerId, destroy$);
		const check = checks.find(check => check.id.equals(checkId));

		if (!check) {
			throw new Error(`Check not found`);
		}

		return check;
	}

	async addCustomerCheque(customerId: Guid, instance: CustomerCheque, destroy$?: Subject<void>): Promise<void> {
		const res = await this.http.promise(destroy$).post(`${this.config.apiUrl}${this.config.apiVersion}/customers/${customerId}/checks`, instance);
		return res.body;
	}

	async deleteCustomerCheque(customerId: Guid, id: Guid, destroy$?: Subject<void>): Promise<void>  {
		const res = await this.http.promise(destroy$).delete(`${this.config.apiUrl}${this.config.apiVersion}/customers/${customerId}/checks/${id}`);
		return res.body;
	}

	async addCustomerChequePayment(customerId: Guid, checkId: Guid, payment: CustomerChequePayment, destroy$?: Subject<void>): Promise<void> {
		const res = await this.http.promise(destroy$).post(`${this.config.apiUrl}${this.config.apiVersion}/customers/${customerId}/checks/${checkId}/payments`, payment);
		return res.body;
	}

	async deleteCustomerChequePayment(customerId: Guid, id: Guid, paymentId: Guid, destroy$?: Subject<void>): Promise<void>  {
		const res = await this.http.promise(destroy$).delete(`${this.config.apiUrl}${this.config.apiVersion}/customers/${customerId}/checks/${id}/payments/${paymentId}`);
		return res.body;
	}

	// ========= END AR TRANSACTIONS ===============

	getCustomerUsageByDateRange(customerId: Guid, startDate: string, endDate: string, serviceId: number | number[]): Observable<CustomerUsageLine[] | ApiError> {
		const params = new HttpParams()
			.set('startDate', startDate)
			.set('endDate', endDate)
			.set('service', serviceId.toString());

		const requestOptions = {
			params: params
		};

		const observable =
			this.http.get<ApiData<CustomerUsageLine[]>>(`${this.config.apiUrl}${this.config.apiVersion}/customers/${customerId}/usage`, requestOptions)
				.pipe(
					map(res => res.body.data ? res.body.data.map(x => new CustomerUsageLine().deserialize(x)) : (<ApiError>res.body))
				);
		return observable;
	}

	async getCustomerSurcharges(customerId: Guid, cancel$?: Subject<void>): Promise<CustomerSurcharge[]> {
		const cacheKey = `customer-${customerId}-surcharges`;
		const cached = this.getCacheSync<CustomerSurcharge[]>(cacheKey);

		if (cached)
			return cached;

		const res = await this.http.promise(cancel$).get<ApiData<CustomerSurcharge[]>>(`${this.config.apiUrl}${this.config.apiVersion}/customers/${customerId}/surcharges`);
		const surcharges = res.body.data.map(x => new CustomerSurcharge().deserialize(x));
		this.setCacheValue(cacheKey, surcharges, undefined);

		return surcharges;
	}

	updateCustomerSurcharge(customerId: Guid, surcharge: CustomerSurcharge): Observable<any> {
		const key = `customer-${customerId}-surcharges`;
		const body = { enabled: surcharge.enabled };

		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/customers/${customerId}/surcharges/${surcharge.id}`, body)
			.pipe(
				tap(s => this.updateCacheCollectionItem(key, surcharge.id, surcharge)),
				map(s => null));
	}

	// ========== END USAGE ===========
}
