import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';

import { HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { HttpAuth } from 'core/auth';
import { Guid } from 'domain/types';
import { GenerateCreditNote, Invoice, InvoiceStatus } from 'domain/entities';
import { NavigatorService } from 'services';
import { CacheService } from 'services/cache.service';
import { JobRepository } from 'repositories/job-repository';
import { map } from 'rxjs/operators';
import { AppConfig } from 'core/app-config';
import { GroupActionType } from './actions';

@Injectable()
export class InvoiceActionsService {

	constructor(
		private http: HttpAuth,
		private config: AppConfig,
		private jobRepository: JobRepository,
		private cache: CacheService,
		private navigatorService: NavigatorService
	) {

	}

	get selectedAll(): boolean {
		const isSelectedAll = JSON.parse(sessionStorage.getItem('isSelectedAll'));
		return isSelectedAll ? isSelectedAll : false;
	}

	public credit(filteredInvoices: GenerateCreditNote[], returnUrl: string) {
		return this.creditInvoiceOperation(filteredInvoices, returnUrl);
	}

	public sendEmails(invoices: Invoice[], excluded?: Invoice[]) {
		return this.invoiceOperation(invoices, GroupActionType.Email, excluded);
	}

	public sendREmails(invoices: Invoice[], excluded?: Invoice[]) {
		return this.invoiceOperation(invoices, GroupActionType.ReEmail, excluded);
	}

	public markSent(invoices: Invoice[], excluded?: Invoice[]) {
		return this.invoiceOperation(invoices, GroupActionType.MarkSent, excluded);
	}

	public changeStatus(invoices: Invoice[], newStatus: InvoiceStatus, excluded?: Invoice[]) {
		return this.changeStatusOperation(invoices, newStatus, excluded);
	}

	public reGenerateInvoices(invoices: Invoice[], excluded?: Invoice[]) {
		return this.invoiceOperationRegeneration(invoices, excluded);
	}

	public generateMiscellaneousInvoices(invoices: Invoice[], excluded?: Invoice[]) {
		return this.generateMiscellaneous(invoices, excluded);
	}

	public putOnHold(invoices: Invoice[], excluded?: Invoice[]) {
		return this.invoiceOperation(invoices, GroupActionType.Pend, excluded);
	}

	public putOffHold(invoices: Invoice[], excluded?: Invoice[]) {
		return this.invoiceOperation(invoices, GroupActionType.UnPend, excluded);
	}

	public download(invoices: Invoice[], excluded?: Invoice[]) {
		return this.invoiceOperation(invoices, GroupActionType.Zip, excluded);
	}

	public archive(invoices: Invoice[], excluded?: Invoice[]) {
		return this.invoiceOperation(invoices, GroupActionType.Archive, excluded);
	}

	public restoreArchive(invoices: Invoice[], excluded?: Invoice[]) {
		return this.invoiceOperation(invoices, GroupActionType.RestoreFromArchive, excluded);
	}

	public invoiceDelete(invoices: Invoice[], excluded?: Invoice[], url?: string) {
		let redirectUrl = this.navigatorService.currentUrl;

		if (url) {
			redirectUrl = url;
		}

		const data = {
			invoices: invoices.map(x => x.id),
			excludedInvoices: excluded ? excluded.map(x => x.id) : [],
			selectedAll: this.selectedAll,
			type: 'delete-invoices',
			operationType: 4,
			tag: JSON.stringify({ url: redirectUrl })
		};

		return lastValueFrom(this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/invoices/delete`, data, this.headersToOptions())
			.pipe(
				map(res => {
					const responseData = res.body;

					if (responseData.operationId) {
						this.jobRepository.get(responseData.operationId);
					}
					return responseData;
				})));
	}

	public invoiceCharge(invoices: Invoice[], includeAdjustments: boolean, excluded?: Invoice[]) {

		const data = {
			invoices: invoices.map(x => x.id),
			excludedInvoices: excluded ? excluded.map(x => x.id) : [],
			selectedAll: this.selectedAll,
			operationType: 4,
			includeAdjustments: includeAdjustments,
			tag: JSON.stringify({ url: this.navigatorService.currentUrl })
		};

		return lastValueFrom(this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/invoices/charge`, data, this.headersToOptions())
			.pipe(
				map(res => {
					const responseData = res.body.data;
					if (responseData.operationId) {
						this.jobRepository.get(responseData.operationId);
					}
					return responseData;
				})));
	}

	public invoicesPrint(invoices: Invoice[], excluded?: Invoice[]): Promise<HttpResponse<string>> {
		const data = {
			invoices: invoices.map(x => x.id),
			excludedInvoices: excluded ? excluded.map(x => x.id) : [],
			selectedAll: this.selectedAll,
			operationType: 4
		};

		return lastValueFrom(
			this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/invoices/print`, data, this.headersToOptions())
		);
	}

	public resend(params: ResendingEmailsParameters): Observable<any> {

		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/invoices/resending`, params, this.headersToOptions())
			.pipe(
				map(res => {

					const response = res.body;
					if (response.data.operationId) {
						this.jobRepository.get(response.data.operationId);
					}

					return response;
				}));
	}

	public generateMiscellaneous(invoices: Invoice[], excluded?: Invoice[]) {

		const data = {
			invoices: invoices.map(x => x.id),
			excludedInvoices: excluded ? excluded.map(x => x.id) : [],
			selectedAll: this.selectedAll,
			tag: JSON.stringify({ url: this.navigatorService.currentUrl })
		};

		const headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});

		return lastValueFrom(this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/invoices/generate/miscellaneous`, data, headers)
			.pipe(
				map(res => {
					const responseData = res.body.data;
					if (responseData.operationId) {
						this.jobRepository.get(responseData.operationId);
					}
					return responseData;
				})));
	}

	public generate(params: GenerationParameters): Observable<any> {

		const headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});

		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/invoices/generate`, params, headers)
			.pipe(
				map(res => {

					const responseData = res.body;

					if (responseData.data.operationId) {

						// start preloading the job immediately
						this.jobRepository.get(responseData.data.operationId);
					}

					return responseData;
				}));
	}

	public exportInvoicesToXero(invoices: Invoice[], exportedData: any, tag: string, excluded?: Invoice[]) {
		const data = {
			invoices: invoices.map(x => x.id),
			reference: exportedData.reference,
			accountCode: exportedData.accountCode,
			useGlCode: exportedData.useGlCode,
			//prevent post without sentToContact field
			sentToContact: exportedData.sentToContact ? exportedData.sentToContact : false,
			tag: tag,
			excludedInvoices: excluded ? excluded.map(x => x.id) : [],
			selectedAll: this.selectedAll,
			operationType: 1,
			invoiceStatus: Number(exportedData.invoiceStatus)
		};

		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/invoices/xero-export-singleline`, data, this.headersToOptions())
			.pipe(map(res => res.body.data));
	}

	public getCreditNoteInvoices(invoices: Invoice[], excluded: Invoice[], filters?: HttpParams): Observable<GenerateCreditNote[]> {
		const data = {
			invoices: invoices.map(x => x.id),
			excludedInvoices: excluded.length ? excluded.map(x => x.id) : [],
			selectedAll: this.selectedAll
		};

		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/invoices/credit/filter`, data, { params: filters })
			.pipe(map(res => res.body.data));
	}

	public exportInvoicesToXeroMultiline(invoices: Invoice[], exportedData: any, tag: string, excluded?: Invoice[]) {

		const data = {
			invoices: invoices.map(x => x.id),
			reference: exportedData.reference,
			accountCode: exportedData.accountCode,
			paymentCode: exportedData.paymentCode,
			useGlCode: exportedData.useGlCode,
			invoiceStatus: Number(exportedData.invoiceStatus),
			//prevent post without sentToContact field
			sentToContact: exportedData.sentToContact ? exportedData.sentToContact : false,
			extendedExport: exportedData.extendedExport ? exportedData.extendedExport : false,
			tag: tag,
			excludedInvoices: excluded ? excluded.map(x => x.id) : [],
			selectedAll: this.selectedAll,
			operationType: 2,
			jobType: 28,
			taxes: exportedData.taxes,
			taxLines: exportedData.taxLines,
			taxLinesByState: exportedData.taxLinesByState,
			taxMultiline: exportedData.taxMultiline ? exportedData.taxMultiline : false,
			taxByState: exportedData.taxByState ? exportedData.taxByState : false,
			brandingThemesMapping: exportedData.brandingThemesMapping
		};

		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/invoices/xero-export-multiline`, data, this.headersToOptions())
			.pipe(map(res => res.body.data));
	}
	public xeroInvoicesMultilineFilter(invoices: Invoice[], isForce: boolean, excluded?: Invoice[]): Observable<number[]> {
		const data = {
			invoices: invoices.map(x => x.id),
			excludedInvoices: excluded ? excluded.map(x => x.id) : [],
			selectedAll: this.selectedAll,
			jobType: 28,
			force: isForce
		};
		return this.http.post<number[]>(`${this.config.apiUrl}${this.config.apiVersion}/invoices/filter`, data, this.headersToOptions())
			.pipe(map(res => res.body));
	}

	public exportInvoicesToCsv(invoices: Invoice[], tag: string, excluded?: Invoice[]) {
		const data = {
			invoices: invoices.map(x => x.id),
			tag: tag,
			excludedInvoices: excluded ? excluded.map(x => x.id) : [],
			selectedAll: this.selectedAll,
			operationType: 3
		};

		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/invoices/export-csv`, data, this.headersToOptions())
			.pipe(map(res => res.body.data));
	}

	public exportInvoicesMultiline(invoices: Invoice[], tag: string, excluded?: Invoice[]) {

		const model = {
			invoices: invoices.map(x => x.id),
			excludedInvoices: excluded ? excluded.map(x => x.id) : [],
			selectedAll: this.selectedAll,
			tag: tag
		};

		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/invoices/export-multiline`, model, this.headersToOptions())
			.pipe(map(res => res.body));
	}

	public exportInvoicesCsvQbdMultiline(invoices: Invoice[], tag: string, excluded?: Invoice[]) {

		const model = {
			invoices: invoices.map(x => x.id),
			excludedInvoices: excluded ? excluded.map(x => x.id) : [],
			selectedAll: this.selectedAll,
			tag: tag
		};

		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/qbd/invoices/export-csv-multiline`, model, this.headersToOptions())
			.pipe(map(res => res.body));
	}

	public exportInvoicesToConnectwise(invoices: Invoice[], tag: string, excluded?: Invoice[]) {
		const data = {
			invoices: invoices.map(x => x.id),
			excludedInvoices: excluded ? excluded.map(x => x.id) : [],
			selectedAll: this.selectedAll,
			tag: tag
		};

		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/connectwise/export-invoices`, data, this.headersToOptions())
			.pipe(map(res => res.body.data));
	}

	private invoiceOperation(invoices: Invoice[], actionType: GroupActionType, excluded?: Invoice[]) {

		const data = {
			invoices: invoices.map(x => x.id),
			excludedInvoices: excluded ? excluded.map(x => x.id) : [],
			selectedAll: this.selectedAll,
			type: actionType,
			operationType: 4,
			tag: JSON.stringify({ url: this.navigatorService.currentUrl }),
			toArchive: undefined
		};

		let url = `${this.config.apiUrl}${this.config.apiVersion}/invoices/filter/operations`;

		switch (actionType) {
			case GroupActionType.MarkSent:
				url = `${this.config.apiUrl}${this.config.apiVersion}/invoices/mark-sent`;
				break;
			case GroupActionType.Zip:
				url = `${this.config.apiUrl}${this.config.apiVersion}/invoices/zip`;
				break;
			case GroupActionType.Pend:
				url = `${this.config.apiUrl}${this.config.apiVersion}/invoices/on-hold`;
				break;
			case GroupActionType.UnPend:
				url = `${this.config.apiUrl}${this.config.apiVersion}/invoices/off-hold`;
				break;
			case GroupActionType.Email:
				data.invoices = invoices.filter(invoice =>
					invoice.status !== InvoiceStatus.Sent
					&& invoice.status !== InvoiceStatus.SentManually
				).map(x => x.id);
				url = `${this.config.apiUrl}${this.config.apiVersion}/invoices/send`;
				break;
			case GroupActionType.ReEmail:
				url = `${this.config.apiUrl}${this.config.apiVersion}/invoices/re-email`;
				break;
			case GroupActionType.Archive:
				data.toArchive = true;
				url = `${this.config.apiUrl}${this.config.apiVersion}/invoices/archive`;
				break;
			case GroupActionType.RestoreFromArchive:
				data.toArchive = false;
				url = `${this.config.apiUrl}${this.config.apiVersion}/invoices/archive`;
				break;
		}

		return lastValueFrom(this.http.post(url, data, this.headersToOptions())
			.pipe(map(res => res.body)));
	}

	private changeStatusOperation(invoices: Invoice[], newStatus: InvoiceStatus, excluded?: Invoice[]) {

		const data = {
			invoices: invoices.map(x => x.id),
			excludedInvoices: excluded ? excluded.map(x => x.id) : [],
			selectedAll: this.selectedAll,
			operationType: 4,
			tag: JSON.stringify({ url: this.navigatorService.currentUrl }),
			newStatus: newStatus
		};

		return lastValueFrom(this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/invoices/status`, data, this.headersToOptions())
			.pipe(map(res => res.body.data)));
	}

	private creditInvoiceOperation(generateCreditNotes: GenerateCreditNote[], returnUrl: string) {

		const data = {
			invoices: generateCreditNotes.map(x => x.id),
			type: 'credit',
			selectedAll: this.selectedAll,
			operationType: 4,
			tag: returnUrl,
			generateCreditNotes
		};

		return lastValueFrom(this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/invoices/credit`, data, this.headersToOptions())
			.pipe(map(res => res.body)));
	}

	private invoiceOperationRegeneration(invoices: Invoice[], excluded?: Invoice[]) {

		const data = {
			invoices: invoices.map(x => x.id),
			excludedInvoices: excluded ? excluded.map(x => x.id) : [],
			type: 'regeneratesame',
			operationType: 4,
			selectedAll: this.selectedAll,
			tag: JSON.stringify({ url: this.navigatorService.currentUrl })
		};

		return lastValueFrom(this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/invoices/regenerate`, data, this.headersToOptions())
			.pipe(map(res => res.body)));
	}

	private headersToOptions() {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});

		const filterParams = this.cache.get('invoices-action-filterParams');

		return { headers, params: filterParams && this.selectedAll ? filterParams : '' };
	}
}

export class GenerationParameters {

	public billingPeriodId?: any;
	public customers: number[];
	public invoiceDate: Date;

	// defaultDueDate is not required by api. Used for UI purposes only.
	public defaultDueDate = true;
	public customInvoiceDueDate?: Date;
	public transactionGenerationType: number;
	public taxGroupId: number | string;
	public generateAdditionalTransactions = false;
	public tag: string;
	public suppressZeroInvoices = false;
}

export class ResendingEmailsParameters {
	public invoiceId?: Guid;
	public emailsId: number[];

	public tag: string;
}

export enum TransactionGenerationType {
	NotGenerateTransactions = 1,
	DeleteExistingTransactions = 2,
	RetainTransactions = 3,
	GenerateTransactionsWithNoTransactions = 4
}
