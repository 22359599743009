import { Serializable } from 'domain/models';
import { Guid } from 'domain/types';

export class PaymentTerm implements Serializable<PaymentTerm> {
	id: Guid;
	label: string;
	netDays: number;
	proxDueDate: number;
	proxDueDateLabel: string;
	proxMonths: number;
	isProx: boolean;
	isProxLabel: string;
	isDefault: boolean;
	proxEndOfMonth: boolean;
	enabled: boolean;
	deserialize(json: any): PaymentTerm {
		this.id = json.id;
		this.label = json.label;
		this.netDays = json.netDays;
		this.proxMonths = json.proxMonths;
		this.proxDueDate = json.proxDueDate;
		this.isProx = json.isProx;
		this.isProxLabel = json.isProx ? 'Prox' : 'Net';
		this.proxDueDateLabel = this.setDueDateLabel(json.proxDueDate, json.isProx);
		this.isDefault = json.isDefault;
		this.enabled = json.enabled;
		this.proxEndOfMonth = json.proxEndOfMonth;

		return this;
	}

	clone(): PaymentTerm {
		const clone = Object.assign(new PaymentTerm(), this);
		return clone;
	}

	setDueDateLabel(date: number, type: boolean): string {
		if (type) {
			if (!date) {
				return 'End Of Months';
			} else if (date === 0) {
				return 'Immediately';
			} else if (date > 0 && date < 32) {
				return `${date}` + 'th';
			}
		}
	}

}

export class MonthsOption {

	constructor(public value: number, public label: string) {

	}

	public static get allOptions(): MonthsOption[] {

		const result = [];

		for (let i = 0; i < 13; i++) {
			result.push(new MonthsOption(i, `${i}`));
		}

		return result;
	}

}

export class DaysOption {

	constructor(public value: number, public label: string) {

	}

	public static get allNetOptions(): DaysOption[] {
		const result = [];

		for (let i = 1; i < 31; i++) {
			result.push(new DaysOption(i, `${i}`));
		}

		return result;
	}

	public static get allProxOptions(): DaysOption[] {

		const result = [];
		let label: string;

		result.push(new DaysOption(0, 'Immediately'));

		for (let i = 1; i < 32; i++) {
			if (i === 1 || i === 21 || i === 31) {
				label = 'st';
			} else if (i === 2 || i === 22) {
				label = 'nd';
			} else if (i === 3 || i === 23) {
				label = 'rd';
			} else {
				label = 'th';
			}

			result.push(new DaysOption(i, 'The ' + `${i}${label}` + ' of the month'));
		}
		return result;
	}
}
