import { Serializable } from 'domain/models';
import * as Moment from 'moment';
import { Utils } from 'tools/utils';
import { Guid } from '../types';

export class UnmatchedErrors implements Serializable<UnmatchedErrors> {
	serviceId: number;
	serviceLabel: string;
	serviceItem: string;
	supServiceItem1: string;
	supServiceItem2: string;
	recordTypeCode: string;
	supplierName: string;
	records: number;
	units: number;
	cost: number;
	alias: string;

	processErrorId: number;
	errorDescription: string;
	errorHelp: string;
	earliestRecord: string;
	firstUsageId: number;

	deserialize(json: any): UnmatchedErrors {

		this.serviceId = json.serviceId;
		this.serviceLabel = json.serviceLabel;
		this.serviceItem = json.serviceItem;
		this.supplierName = json.supplierName;
		this.records = json.records;
		this.units = json.units;
		this.cost = json.cost;
		this.processErrorId = json.processErrorId;
		this.errorDescription = json.errorDescription;
		this.errorHelp = json.errorHelp;
		this.earliestRecord = Utils.toMomentTzFormatTime(json.earliestRecord);
		this.alias = json.alias;
		this.supServiceItem1 = json.supServiceItem1;
		this.supServiceItem2 = json.supServiceItem2;
		this.recordTypeCode = json.recordTypeCode;
		this.firstUsageId = json.firstUsageId;
		return this;
	}

	constructor(unmatched?: UnmatchedErrors) {

		if (unmatched) {
			Object.assign(this, unmatched);
		}
	}
}

export class NoPricingErrors implements Serializable<NoPricingErrors> {
	serviceId: number;
	serviceLabel: string;
	serviceItem: string;
	serviceItem2: string;
	supServiceItem1: string;
	supServiceItem2: string;
	customerLabel: string;
	customerId: number;
	supplierName: string;
	records: number;
	recordTypeCode: string;
	units: number;
	cost: number;
	earliestRecord: string;
	processErrorId: number;
	errorDescription: string;
	errorHelp: string;
	alias: string;
	serviceCustomer: string;
	serviceCustomerServiceItem: string;
	firstUsageId: number;
	serviceItemGroup: string;
	deserialize(json: any): NoPricingErrors {

		this.serviceId = json.serviceId;
		this.serviceLabel = json.serviceLabel;
		this.serviceItem = json.serviceItem;
		this.serviceItem2 = json.serviceItem2;
		this.supplierName = json.supplierName;
		this.customerLabel = json.customerLabel;
		this.recordTypeCode = json.recordTypeCode;
		this.records = json.records;
		this.units = json.units;
		this.cost = json.cost;
		this.processErrorId = json.processErrorId;
		this.errorDescription = json.errorDescription;
		this.errorHelp = json.errorHelp;
		this.earliestRecord = Utils.toMomentTzFormatTime(json.earliestRecord);
		this.alias = json.alias;
		this.supServiceItem1 = json.supServiceItem1;
		this.supServiceItem2 = json.supServiceItem2;
		this.firstUsageId = json.firstUsageId;
		this.serviceItemGroup = json.serviceItemGroup ?? '';
		return this;
	}

	constructor(unmatched?: NoPricingErrors) {

		if (unmatched) {
			Object.assign(this, unmatched);
		}
	}
}

export class ErrorReport implements Serializable<ErrorReport> {
	errors110: UnmatchedErrors[];
	errors130: NoPricingErrors[];
	errors110Count: number;
	errors130Count: number;
	deserialize(json: any): ErrorReport {
		this.errors110 = json.errors110;
		this.errors130 = json.errors130;
		this.errors110Count = json.errors110Count;
		this.errors130Count = json.errors130Count;
		return this;
	}
}

export class TransactionErrors implements Serializable<TransactionErrors> {
	serviceName: string;
	serviceItem: string;
	productName: string;
	productLabel: string;
	customerLabel: string;
	processErrorId: number;
	processStageId: number;
	errorDescription: string;
	errorHelp: string;
	supReference: string;
	supLabel: string;
	supplierShortCode: string;
	supplierName: string;
	cost: number;
	firstTransactionId: number;
	alias: string;
	serviceItemGroup: string;
	deserialize(json: any): TransactionErrors {

		this.serviceName = json.serviceName;
		this.serviceItem = json.serviceItem;
		this.productName = json.productName;
		this.productLabel = json.productLabel;
		this.customerLabel = json.customerLabel;
		this.processErrorId = json.processErrorId;
		this.processStageId = json.processStageId;
		this.errorDescription = json.errorDescription;
		this.errorHelp = json.errorHelp;
		this.supReference = json.supReference;
		this.supLabel = json.supLabel;
		this.supplierShortCode = json.supplierShortCode;
		this.supplierName = json.supplierName;
		this.cost = json.cost;
		this.firstTransactionId = json.firstTransactionId;
		this.alias = json.alias;
		this.serviceItemGroup = json.serviceItemGroup ?? '';

		return this;
	}

	constructor(tranErrors?: TransactionErrors) {

		if (tranErrors) {
			Object.assign(this, tranErrors);
		}
	}
}

export class TransactionErrorModelResponse implements Serializable<TransactionErrorModelResponse> {
	transactionErrors110: TransactionErrors[];
	transactionErrorsCount110: number;
	transactionErrors130: TransactionErrors[];
	transactionErrorsCount130: number;

	deserialize(json: any): TransactionErrorModelResponse {
		this.transactionErrors110 = json.transactionErrors110 ? json.transactionErrors110.map(x => new TransactionErrors().deserialize(x)) : [];
		this.transactionErrorsCount110 = json.transactionErrorsCount110;
		this.transactionErrors130 = json.transactionErrors130 ? json.transactionErrors130.map(x => new TransactionErrors().deserialize(x)) : [];
		this.transactionErrorsCount130 = json.transactionErrorsCount130;
		return this;
	}
}

export class FccReport implements Serializable<FccReport> {
	billingCompanyName: string;
	billProfileID: string;
	quantity: number;
	class: string;
	description: string;
	serviceAddress: number;
	serviceCity: string;
	serviceState: string;
	serviceZip: string;
	geoCode: string;
	taxCode: string;

	deserialize(json: any): FccReport {
		this.billingCompanyName = json.billingCompanyName;
		this.billProfileID = json.billProfileID;
		this.quantity = json.quantity;
		this.class = json.class;
		this.description = json.description;
		this.serviceAddress = json.serviceAddress;
		this.serviceCity = json.serviceCity;
		this.serviceState = json.serviceState;
		this.serviceZip = json.serviceZip;
		this.geoCode = json.geoCode;
		this.taxCode = json.taxCode;

		return this;
	}
}

export class CsiTaxReport implements Serializable<CsiTaxReport> {
	countyName: string;
	state: string;
	cityName: string;
	taxType: string;
	taxCat: string;
	taxLevel: number;
	taxDescription: string;
	wholesale: number;
	revenue: number;
	taxes: number;
	taxRate: number;
	lines: number;
	invoiceDate: string;
	customerName: string;
	periodStart: string;
	periodEnd: string;

	deserialize(json: any): CsiTaxReport {
		this.countyName = json.countyName;
		this.state = json.state;
		this.cityName = json.cityName;
		this.taxType = json.taxType;
		this.taxCat = json.taxCat;
		this.taxLevel = json.taxLevel;
		this.taxDescription = json.taxDescription;
		this.wholesale = json.wholesale;
		this.revenue = json.revenue;
		this.taxes = json.taxes;
		this.taxRate = json.taxRate;
		this.lines = json.lines;
		this.invoiceDate = json.invoiceDate;
		this.customerName = json.customerName;
		this.periodStart = Utils.toMomentUtcFormat(json.periodStart);
		this.periodEnd = Utils.toMomentUtcFormat(json.periodEnd);

		return this;
	}
}

export class CsiTaxReportDetailed implements Serializable<CsiTaxReportDetailed> {
	periodLabel: string;
	periodStart: string;
	periodEnd: string;
	geocode: string;
	authorityName: string;
	reportToId: number;
	reportTo: string;
	taxAuthType: number;
	invoiceNumber: number;
	invoiceDate: number;
	taxType: string;
	taxCat: string;
	taxLevel: number;
	groupCode: string;
	itemCode: string;

	passFlag: boolean;
	taxDescription: string;
	taxRate: number;
	taxAmount: number;
	feeRate: number;
	originalChargeAmount: number;
	exemptAmount: number;
	chargeAmount: number;
	units: number;
	customerName: string;
	agreementName: string;
	country: string;
	state: string;
	county: string;
	city: string;

	deserialize(json: any): CsiTaxReportDetailed {
		this.periodLabel = json.periodLabel;
		this.periodStart = Utils.toMomentUtcFormat(json.periodStart);
		this.periodEnd = Utils.toMomentUtcFormat(json.periodEnd);
		this.geocode = json.geocode;
		this.authorityName = json.authorityName;
		this.reportToId = json.reportToId;
		this.reportTo = json.reportTo;
		this.taxAuthType = json.taxAuthType;
		this.invoiceNumber = json.invoiceNumber;
		this.customerName = json.customerName;
		this.invoiceDate = json.invoiceDate;
		this.taxType = json.taxType;
		this.taxCat = json.taxCat;
		this.taxLevel = json.taxLevel;
		this.groupCode = json.groupCode;
		this.itemCode = json.itemCode;

		this.passFlag = json.passFlag;
		this.taxDescription = json.taxDescription;
		this.taxRate = json.taxRate;
		this.taxAmount = json.taxAmount ? Number(json.taxAmount) : 0;
		this.feeRate = json.feeRate;
		this.originalChargeAmount = json.originalChargeAmount;
		this.exemptAmount = json.exemptAmount;
		this.chargeAmount = json.chargeAmount;
		this.units = json.units;
		this.agreementName = json.agreementName;

		this.country = json.country;
		this.city = json.city;
		this.state = json.state;
		this.county = json.county;

		return this;
	}
}

export class SuretaxTaxesReportDetailed implements Serializable<SuretaxTaxesReportDetailed> {
	invoiceNumber: string;
	customerName: string;
	totalTax: string;
	totalInvoiceAmount: number;
	totalInvoiceSubtotal: number;
	invoiceDate: string;
	siteName: string;
	geocode: string;
	taxLevel: number;
	taxType: string;
	taxDescription: string;
	taxCat: string;
	country: string;
	state: string;
	county: string;
	city: string;
	taxAuthority: string;
	taxRate: number;
	feeRate: number;
	percentTaxable: number;
	revenue: string;
	revenueBase: string;
	taxAmount: string;
	taxOnTax: number;
	isExempt: boolean;

	deserialize(json: any): SuretaxTaxesReportDetailed {
		this.invoiceNumber = json.invoiceNumber;
		this.customerName = json.customerName;
		this.totalTax = json.totalTax;
		this.totalInvoiceAmount = json.totalInvoiceAmount;
		this.totalInvoiceSubtotal = json.totalInvoiceSubtotal;
		this.invoiceDate = json.invoiceDate;
		this.siteName = json.siteName;
		this.geocode = json.geocode;
		this.taxLevel = json.taxLevel;
		this.taxType = json.taxType;
		this.taxDescription = json.taxDescription;
		this.taxCat = json.taxCat;
		this.country = json.country;
		this.city = json.city;
		this.state = json.state;
		this.county = json.county;
		this.taxAuthority = json.taxAuthority;
		this.taxRate = json.taxRate;
		this.feeRate = json.feeRate;
		this.percentTaxable = json.percentTaxable;
		this.revenue = json.revenue;
		this.revenueBase = json.revenueBase;
		this.taxAmount = json.taxAmount;
		this.taxOnTax = json.taxOnTax ? Number(json.taxOnTax) : 0;
		this.isExempt = json.isExempt;

		return this;
	}
}

export class CommissionReport implements Serializable<CommissionReport> {
	accountManager: string;
	customer: string;
	tollsTotalCost: number;
	tollsTotalSell: number;

	tollFreeTotalCost: number;
	tollFreeTotalSell: number;
	broadbandTotalCost: number;
	broadbandTotalSell: number;
	productsTotalCost: number;
	productsTotalSell: number;

	totalCost: number;
	totalSell: number;
	commissionDue: number;
	gmAmount: number;
	gmPersentage: number;

	deserialize(json: any): CommissionReport {

		this.accountManager = json.accountManager;
		this.customer = json.customer;
		this.tollsTotalCost = json.tollsTotalCost;
		this.tollsTotalSell = json.tollsTotalSell;

		this.tollFreeTotalCost = json.tollFreeTotalCost;
		this.tollFreeTotalSell = json.tollFreeTotalSell;
		this.broadbandTotalCost = json.broadbandTotalCost;
		this.broadbandTotalSell = json.broadbandTotalSell;
		this.productsTotalCost = json.productsTotalCost;
		this.productsTotalSell = json.productsTotalSell;
		this.totalCost = json.totalCost;
		this.totalSell = json.totalSell;

		this.commissionDue = json.commissionDue;
		this.gmAmount = json.gmAmount;
		this.gmPersentage = json.gmPersentage;

		return this;
	}
}

export class UsageCallTypeDto implements Serializable<UsageCallTypeDto> {
	name: string;
	supRecordType: string;
	periodLabel: string;
	total_Minutes: number;

	deserialize(json: any): UsageCallTypeDto {

		this.name = json.name;
		this.supRecordType = json.supRecordType;
		this.periodLabel = json.periodLabel;
		this.total_Minutes = json.total_Minutes;

		return this;
	}
}

export class UsageTotalCallsDto implements Serializable<UsageTotalCallsDto> {
	customer: string;
	customerCode: string;
	service: string;
	minutes: number;

	deserialize(json: any): UsageTotalCallsDto {

		this.customer = json.customer;
		this.customerCode = json.customerCode;
		this.service = json.service;
		this.minutes = json.minutes;

		return this;
	}
}

export class UsageDetailsDto implements Serializable<UsageDetailsDto> {
	customerLabel: string;
	periodLabel: string;
	recordType: string;
	usageDescription: string;
	ratedUnits: number;
	ratedUnitsMinutes: number;
	actualUnits: number;
	actualUnitsMinutes: number;
	adjustedUnits: number;
	adjustedUnitsMinutes: number;
	invoiceQuantity: number;
	invoiceQuantityMinutes: number;
	freeUnits: number;
	freeUnitsMinutes: number;
	cost: number;
	price: number;
	invoiceSell: number;
	records: number;
	supplier: string;
	supRecordtypecode: string;
	date: string;
	serviceItem: string;
	serviceItem2: string;
	serviceItem3: string;

	deserialize(json: any): UsageDetailsDto {

		this.customerLabel = json.customerLabel;
		this.periodLabel = json.periodLabel;
		this.recordType = json.recordType;
		this.usageDescription = json.usageDescription;
		this.ratedUnits = json.ratedUnits;
		this.ratedUnitsMinutes = json.ratedUnitsMinutes;
		this.actualUnits = json.actualUnits;
		this.actualUnitsMinutes = json.actualUnitsMinutes;
		this.adjustedUnits = json.adjustedUnits;
		this.adjustedUnitsMinutes = json.adjustedUnitsMinutes;
		this.invoiceQuantity = json.invoiceQuantity;
		this.invoiceQuantityMinutes = json.invoiceQuantityMinutes;
		this.freeUnits = json.freeUnits;
		this.freeUnitsMinutes = json.freeUnitsMinutes;
		this.cost = json.cost;
		this.price = json.price;
		this.invoiceSell = json.invoiceSell;
		this.records = json.records;
		this.supplier = json.supplier;
		this.supRecordtypecode = json.supRecordtypecode;
		this.date = json.date;
		this.serviceItem = json.serviceItem;
		this.serviceItem2 = json.serviceItem2;
		this.serviceItem3 = json.serviceItem3;

		return this;
	}
}

export class ProfitabilityReportDto implements Serializable<ProfitabilityReportDto> {
	status: string;
	customerLabel: string;
	accountManager: string;
	customerCode: string;
	invoiceNumber: string;
	invoiceDate: string;
	cost: number;
	sell: number;
	margin: number;
	marginPercent: number;

	deserialize(json: any): ProfitabilityReportDto {

		this.status = json.status;
		this.customerLabel = json.customerLabel;
		this.accountManager = json.accountManager;
		this.customerCode = json.customerCode;
		this.invoiceNumber = json.invoiceNumber;
		this.invoiceDate = json.invoiceDate;
		this.cost = json.cost;
		this.sell = json.sell;
		this.margin = json.margin;
		this.marginPercent = json.marginPercent;

		return this;
	}
}

export class CommissionReportFilters implements Serializable<CommissionReportFilters> {
	glCodes:    CommissionReportFilterGlCode[];
	productCodes: string[];
	categories: CommissionReportFilterCategory[];
	isCalculateCommissionForSaleAmount: boolean;

	deserialize(json: any): CommissionReportFilters {
		this.glCodes = json.glCodes;
		this.productCodes = json.productCodes;
		this.categories = json.categories;
		this.isCalculateCommissionForSaleAmount = json.isCalculateCommissionForSaleAmount;

		return this;
	}
}

export class CommissionReportFilterGlCode implements Serializable<CommissionReportFilterGlCode> {
	id: Guid;
	name: string;

	deserialize(json: any): CommissionReportFilterGlCode {
		this.id = json.id;
		this.name = json.name;

		return this;
	}
}

export class CommissionReportFilterCategory implements Serializable<CommissionReportFilterCategory> {
	id: Guid;
	name: string;

	deserialize(json: any): CommissionReportFilterCategory {
		this.id = json.id;
		this.name = json.name;

		return this;
	}
}
