import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
	selector: 'child-default-cell',
	template: `<dg-checkbox
					[id]="id"
					[isGridCheckbox]="true"
					(change)="invokeParentMethod(params.data)"
					[(ngModel)]="params.data.isDefault"
					ngDefaultControl>
				</dg-checkbox>`
})
export class ChildDefaultCellRendererComponent implements ICellRendererAngularComp {
	public params: any;
	public id: string;

	agInit(params: any): void {
		this.params = params;
		this.id = (Math.floor(Math.random() * 100) + 1).toString();
	}

	public invokeParentMethod(value: any) {
		const find = this.params.context.componentParent.taxRateTypes.find(w => w.isDefault);

		if (!find) {
			value.isDefault = !value.isDefault;
		} else {
			this.params.context.componentParent.methodFromParent(value);
		}

	}

	refresh(): boolean {
		return false;
	}
}
