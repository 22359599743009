import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpAuth } from 'core/auth';
import { CacheService } from 'services/cache.service';
import { GlCode, ResellerService, InvoiceGroupingMethod, PortalGroupingMethod, UsageUnit, DisplayUnit } from 'domain/entities';
import { BaseRepository } from './base-repository';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { AppConfig } from 'core/app-config';

@Injectable({
	providedIn: 'root'
})
export class LookupRepository extends BaseRepository {

	constructor(
		http: HttpAuth,
		config: AppConfig,
		cache: CacheService
	) {
		super(http, config, cache);
	}

	getResellerServices(): Observable<ResellerService[]> {
		const key = 'reseller-services';

		// if (this.cacheHasKey(key)) {
		// 	return this.getCache<ResellerService[]>(key);
		// }

		const observable =
			this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/reseller-services`)
				.pipe(
					map(res => {
						const result = res.body.data.map(x => new ResellerService().deserialize(x));
						this.setCacheValue(key, result, null);
						return result;
					}));
		return observable;
	}

	getInvoiceGroupingMethods(): Observable<InvoiceGroupingMethod[]> {
		const key = 'invoice-grouping-methods';

		// if (this.cacheHasKey(key)) {
		// 	return this.getCache<InvoiceGroupingMethod[]>(key);
		// }

		const observable =
			this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/lookup/invoice-grouping-methods`)
				.pipe(
					map(res => {
						const result = res.body.data.map(x => new InvoiceGroupingMethod().deserialize(x));
						this.setCacheValue(key, result, null);
						return result;
					}));
		return observable;
	}

	getPortalGroupingMethods(): Observable<PortalGroupingMethod[]> {
		const key = 'portal-grouping-methods';

		// if (this.cacheHasKey(key)) {
		// 	return this.getCache<PortalGroupingMetho[]>(key);
		// }

		const observable =
			this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/lookup/portal-grouping-methods`)
				.pipe(
					map(res => {
						const result = res.body.data.map(x => new PortalGroupingMethod().deserialize(x));
						this.setCacheValue(key, result, null);
						return result;
					}));
		return observable;
	}

	getUsageUnits(): Observable<UsageUnit[]> {
		const key = 'usage-units';

		if (this.cacheHasKey(key)) {
			return this.getCache<UsageUnit[]>(key);
		}

		const observable =
			this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/lookup/usage-units`)
				.pipe(
					map(res => {
						const result = res.body.data.map(x => new UsageUnit().deserialize(x));
						this.setCacheValue(key, result, null);
						return result;
					}));
		return observable;
	}

	getDisplayUnits(): Observable<DisplayUnit[]> {
		const key = 'display-units';

		if (this.cacheHasKey(key)) {
			return this.getCache<DisplayUnit[]>(key);
		}

		const observable =
			this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/lookup/display-units`)
				.pipe(
					map(res => {
						const result = res.body.data.map(x => new DisplayUnit().deserialize(x));
						this.setCacheValue(key, result, null);
						return result;
					}));
		return observable;
	}

}
