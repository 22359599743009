import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { HttpAuth } from 'core/auth';
import { catchError, map } from 'rxjs/operators';

import {
	AgreementMapping,
	AgreementType,
	ProductMapping,
	ExportInvoiceModel,
	ExportModel,
	TransactionMapping,
	TaxMapping,
	SurchargeMapping,
	Product,
	SurchargeWithTaxMapping,
	SureTaxLineMapping,
	SureTaxByStateLineMapping,
	ExportCWSyncInvoices,
	Invoice,
	CWInvoiceSyncMapping,
	Vendor,
	SurchargeSeparatelyMapping,
	BillingStatus
} from 'domain/entities';

import { InvoiceActionsService } from 'shared/modules/invoice-actions';
import { AppConfig } from 'core/app-config';
import { ApiData, Guid } from 'domain/types';
import { HttpResponse } from '@angular/common/http';
import { ToasterService } from 'core/toaster-service';

@Injectable({
	providedIn: 'root'
})
export class CWRepository {

	constructor(
		private http: HttpAuth,
		private invoiceService: InvoiceActionsService,
		private config: AppConfig,
		private readonly toasterService: ToasterService
	) {

	}

	public GetAgreementTypes(): Observable<AgreementType[]> {
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/connectwise/agreements/types`, null)
			.pipe(
				map(res => {
					const agreementTypes = res.body.data.map(x => new AgreementType().deserialize(x));
					return agreementTypes;
				}));
	}

	public GetAgreements(model: ExportInvoiceModel, filterParams?: any): Observable<AgreementMapping[]> {
		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/connectwise/agreements/mapping`, model, { params: filterParams })
			.pipe(
				map(res => res.body.data.map(x => new AgreementMapping().deserialize(x)) || []));
	}

	public GetWizardSettings(): Observable<Map<string, any>> {
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/connectwise/agreements/wizard-settings`, null)
			.pipe(
				map(res => {
					if (res.body.data != null) {
						return res.body.data;
					} else {
						return null;
					}
				}));
	}

	public GetMultilineWizardSettings(): Observable<Map<string, any>> {
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/connectwise/invoices-multiline/wizard-settings`, null)
			.pipe(
				map(res => {
					if (res.body.data != null) {
						return res.body.data;
					} else {
						return null;
					}
				}));
	}

	public InvoiceNumberSyncRequest(invoices: Guid[], excluded?: Invoice[], filterParams?: any): Observable<any> {
		const model = {
			invoices: invoices,
			excludedInvoices: excluded.map(x => x.id),
			selectedAll: this.invoiceService.selectedAll
		};
		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/invoices/sync/prepare`, model, { params: filterParams })
			.pipe(
				map(res => {
					const data = res.body.data.map(x => new CWInvoiceSyncMapping().deserialize(x));

					return data;
				}));
	}

	public GroupingTypePost(groupingType: number, agreements: AgreementMapping[], vendorId: number): Observable<any> {
		const model = {
			groupingType: groupingType,
			agreementsMapping: agreements,
			selectedVendorId: vendorId
		};
		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/connectwise/grouping-type`, model, null)
			.pipe(
				map(res => {
					const productsMapping = res.body.data.productsMapping.map(x => new ProductMapping().deserialize(x));
					const transactionsMapping = res.body.data.transactionsMapping.map(x => new TransactionMapping().deserialize(x));
					const taxMapping = res.body.data.taxMapping.map(x => new TaxMapping().deserialize(x));
					const surchargeMapping = res.body.data.surchargeMapping.map(x => new SurchargeMapping().deserialize(x));
					const products = res.body.data.products.map(x => new Product().deserialize(x));
					const surchargesWithTaxes = res.body.data.surchargeWithTaxMapping.map(x => new SurchargeWithTaxMapping().deserialize(x));
					const sureTaxLineMapping = res.body.data.sureTaxLineMapping.map(x => new SureTaxLineMapping().deserialize(x));
					const sureTaxByStateLineMapping = res.body.data.sureTaxByStateLineMapping.map(x => new SureTaxByStateLineMapping().deserialize(x));
					const surchargeSeparatelyMapping = res.body.data.surchargeSeparatelyMapping.map(x => new SurchargeSeparatelyMapping().deserialize(x));

					const responseModel = {
						productsMapping: productsMapping,
						transactionsMapping: transactionsMapping,
						taxMapping: taxMapping,
						sureTaxLineMapping: sureTaxLineMapping,
						sureTaxByStateLineMapping: sureTaxByStateLineMapping,
						surchargeMapping: surchargeMapping,
						surchargeWithTaxMapping: surchargesWithTaxes,
						surchargeSeparatelyMapping,
						products: products
					};
					return responseModel;
				}));
	}

	public Export(model: ExportModel): Observable<any> {
		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/connectwise/export`, model, null)
			.pipe(map(res => res.body.data));
	}

	public ExportCWSyncInvoices(model: ExportCWSyncInvoices): Observable<any> {
		return this.http.post(`${this.config.apiUrl}${this.config.apiVersion}/invoices/sync`, model, null)
			.pipe(map(res => res.body.data));
	}

	public GetVendors(): Observable<Vendor[]> {
		return this.http.get(`${this.config.apiUrl}${this.config.apiVersion}/connectwise/vendors`)
			.pipe(
				map(res => {
					if (res.body.data != null) {
						const vendors = res.body.data.map(x => new Vendor().deserialize(x));
						return vendors;
					} else {
						return null;
					}
				}));
	}

	async GetCwBillingStatuses(destroy$?: Subject<void>): Promise<BillingStatus[]> {
		let res : HttpResponse<ApiData<BillingStatus[]>>;

		try {
			res = await this.http.promise(destroy$).get<ApiData<BillingStatus[]>>(
				`${this.config.apiUrl}${this.config.apiVersion}/connectwise/invoices-multiline/billing-statuses`);
		} catch (error) {
			this.toasterService.error('cw-repository.getCwBillingStatuses-error');
		}

		return res?.body?.data?.map(x => new BillingStatus().deserialize(x));
	}
}
