import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
	selector: '[dgNumberTrim]'
})

export class DgNumberTrimDirective {
	// Allow decimal numbers and negative values
	private regex = new RegExp(/^[-+]?\d*\.?\d{0,4}$/g);
	// Allow key codes for special events. Reflect : ^[-+]?\d+(\.\d+)?$
	// Backspace, tab, end, home
	private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-', 'Control', 'c', 'v', 'x'];

	constructor(private el: ElementRef) {
	}

	@HostListener('keydown', ['$event'])

	onKeyDown(event: KeyboardEvent): void {
		// Allow Backspace, tab, end, and home keys
		if (this.specialKeys.indexOf(event.key) !== -1) {
			return;
		}

		const current: string = this.el.nativeElement.value;
		const next: string = current.concat(event.key);
		if (next && !String(next).match(this.regex)) {
			event.preventDefault();
		}
	}
}
